import { ServingAction, CHANGE_SERVING, SET_SERVING } from './serving.action.js';
import { SCORE, PENALTY_SCORE } from '../set-score/set-score.action.js';
import { TeamCodes, TeamCode } from '../../../../interfaces/models/team-codes.js';

export interface Serving {
  // TODO: SCORE-BEACH startedServing necessary? is in match prep
  startedServing: TeamCode;
  currentlyServing: TeamCode;
  changedByScore: boolean;
  currentServings: number;
  isFirstServing: boolean;
};

export const initialState: Serving = {
  startedServing: TeamCodes.team1,
  currentlyServing: TeamCodes.team1,
  changedByScore: false,
  currentServings: 0,
  isFirstServing: true
};

export const servingReducer = (state = initialState, action: ServingAction): Serving => {
  switch (action.type) {
    case CHANGE_SERVING:
      return {
        ...state,
        changedByScore: false,
        currentlyServing: state.startedServing === TeamCodes.team1 ? TeamCodes.team2 : TeamCodes.team1,
        startedServing: state.startedServing === TeamCodes.team1 ? TeamCodes.team2 : TeamCodes.team1
      };
    case SET_SERVING:
      return {
        ...state,
        changedByScore: false,
        currentlyServing: action.payload.servingTeamCode,
        startedServing: action.payload.servingTeamCode
      }
    case SCORE:
    case PENALTY_SCORE:
      const teamThatScored = action.payload;
      // if the team that has scored is currently serving the serving right does not change
      // and we reset changedByScore to false
      if (state.currentlyServing === teamThatScored) {
        return {
          ...state,
          changedByScore: false,
          currentServings: state.currentServings + 1
        }
      }
      return {
        ...state,
        changedByScore: true,
        currentlyServing: teamThatScored,
        currentServings: 0,
        isFirstServing: false
      };
    default:
      return state;
  }
};

// -- selectors --
export const getStartedServing = (state: Serving) => state.startedServing;
export const getCurrentlyServing = (state: Serving) => state.currentlyServing;
export const isCurrentlyServing = (currentlyServing: TeamCode, teamCode: TeamCode) => currentlyServing === teamCode;
