import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import * as moment from 'moment';

import { MatchSets } from '../../../../shared/reducers/game-state/match-sets/match-sets.reducer';
import { TimeKeeping, getTotalDurationMs } from '../../../../shared/reducers/game-state/match-sets/time-keeping/time-keeping.reducer';
import { MatchState } from '../../../../shared/reducers/match-states/match-state.reducer';

@Component({
  selector: 'sams-match-sets',
  template: `
    <div class="match-sets-container">
      <table class="match-sets-table">
        <thead>
          <tr>
            <th class="app-set">{{'app.set' | translate}}</th>
            <th class="team-name">{{matchState.match.team1.shortName}}</th>
            <th></th>
            <th class="team-name">{{matchState.match.team2.shortName}}</th>
            <th>{{'app.duration' | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let set of matchSets; let i = index">
            <td>{{i + 1}}</td>
            <td>
              <span>{{set.setScore.team1}}</span>
            </td>
            <td>:</td>
            <td>
              <span>{{set.setScore.team2}}</span>
            </td>
            <td>{{getDuration(set.timeKeeping)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  `,
  styles: [`
    th.team-name {
      width: 50px;
      font-size: 16px;
    }
    div.match-sets-container {
      font-size: 20px;
      min-height: 50px;
    }
    table.match-sets-table {
      text-align: center;
      width: 100%;
      border-collapse: collapse;
    }
    tr:not(:last-child) td,th {
      border-bottom: 1px solid #333333;
    }
    td, th {
    }
    td:nth-child(2), th:nth-child(2) {
      text-align: right;
    }
    td:nth-child(3), th:nth-child(3) {
      width: 20px;
    }
    td:nth-child(4), th:nth-child(4) {
      text-align: left;
    }
    th {
      font-weight: normal;
    }

  `],
})
export class MatchSetsComponent implements OnInit {

  @Input() matchState: MatchState;

  @Input() matchSets: MatchSets;

  ngOnInit() {
  }

  millisecondsToTime(ms) {
    return moment.utc(ms).format('mm:ss');
  }

  getDuration(tk: TimeKeeping) {
    let duration = getTotalDurationMs(tk);
    if (tk.startTimeMs === 0 || tk.endTimeMs === 0) {
      duration = 0;
    }
    return this.millisecondsToTime(duration);
  }

}
