
import {merge as observableMerge,  Observable, Subscription } from 'rxjs';

import {first, map, filter} from 'rxjs/operators';
import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { OutOfSyncResponse } from '../../../../shared/interfaces/interfaces';
import { ActionHistory } from '../../../../shared/reducers/connections/connections.reducer';

import { ConnectionsService } from './connections.service';
import * as fromShared from 'app/root/shared.reducer';
import { SyncingDialogComponent, SyncingDialogResult, SyncingDialogData } from './syncing-dialog.component';
import * as moment from 'moment'
import { SocketService } from './socket.service';

@Component({
  selector: 'sams-syncing',
  template: ``,
  styles: [``]
})
export class SyncingComponent implements OnInit, OnDestroy {

  @Input() matchId: string;

  actionHistory: ActionHistory;

  isInSync$: Observable<boolean>;

  private subscription = new Subscription();

  private dialogOpen = false;

  constructor(
    public store: Store<fromShared.Root>,
    public socketService: SocketService,
    public connectionsService: ConnectionsService,
    public dialog: MatDialog
    ) {
    }

  ngOnInit() {

    // TODO: find a better way for this
    // problem: InitializeMatchAction is send on match select
    // then CheckHistoryRequest is send with InitAction uuid
    // so we get out of sync even though we are in sync
    // TODO: SCORE-343 we should check the history in onRoomJoinRequest not here
    setTimeout(() => {
      this.socketService.sendHistoryChecksum(this.matchId);
    }, 1000);

    this.subscription.add(
      this.store.select(fromShared.getConnections)
        .subscribe(connections => this.actionHistory = connections[this.matchId].actionHistory)
    );

    const inSync$ = this.socketService.inSync$.pipe(
      filter(matchId => matchId === this.matchId),
      map(_ => true),);

    const isNotInSync$ = this.socketService.outOfSync$.pipe(
      filter(({ matchId }) => matchId === this.matchId),
      map(_ => false),);

    this.isInSync$ = observableMerge(inSync$, isNotInSync$);

    // Promise workaround see: https://github.com/angular/angular/issues/15634
    Promise.resolve().then(() => {
      this.subscription.add(
        this.socketService.outOfSync$
          .subscribe((res: OutOfSyncResponse) => {
            const matchId = res.matchId
            const clientHasSuperset = this.connectionsService.connections[matchId].actionHistory.slice(-1)[0].uuidHistoryChecksum === res.checksum
            if (clientHasSuperset) {
              this.socketService.pushActionHistory(matchId, this.connectionsService.connections[matchId].actionHistory)
            } else {
              this.openDialog(res);
            }
          })
      );
    });

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private openDialog(outOfSyncResponse: OutOfSyncResponse) {
    const dateFormat = 'DD.MM.YY HH:mm:ss'
    if (this.dialogOpen) {
      return;
    }
    const { lastActionTimestamp, numberOfMatchActions } = outOfSyncResponse
    const data: SyncingDialogData = {
      clientSyncState: { timestamp: moment(this.actionHistory[this.actionHistory.length - 1].timestamp).format(dateFormat), numberOfMatchActions: this.actionHistory.length - 1 },
      serverSyncState: { timestamp: moment(lastActionTimestamp).format(dateFormat), numberOfMatchActions }
    }
    const dialogRef: MatDialogRef<SyncingDialogComponent, SyncingDialogResult> = this.dialog.open(SyncingDialogComponent, { data });
    this.dialogOpen = true;
    dialogRef.afterClosed().pipe(first()).subscribe(result => this.onCloseDialog(result));
  }

  private onCloseDialog(option: SyncingDialogResult) {
    this.dialogOpen = false;
    switch (option) {
      case 'PULL': {
        this.socketService.pullActionHistory(this.matchId);
        break;
      }

      case 'PUSH': {
        this.socketService.pushActionHistory(this.matchId, this.actionHistory);
        break;
      }
      default: {
        break;
      }
    }
  }

}
