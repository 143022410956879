import { GameStateAction } from '../game-state/game-state.action.js';
import { UndoableAction } from '../undoable/undoable.action.js';
import { TeamSquadAction } from '../match-preparation/team-squad.action.js';
import { Match } from '../../interfaces/models/match.js';
import { MatchAction } from '../../interfaces/interfaces.js';
import { MatchModificationAction } from './match-modification.action.js';
import { TeamHighlightAction } from '../team-highlights/team-highlights.action.js';

export const INITIALIZE_MATCH = '[MatchState] Initialize Match';

export class InitializeMatchAction extends MatchAction {
  readonly type = INITIALIZE_MATCH;
  constructor(public payload: Match, matchId: string) {
    super(matchId);
  }
}

export type MatchStateAction
  = InitializeMatchAction
  | GameStateAction
  | UndoableAction
  | TeamSquadAction
  | MatchModificationAction
  | TeamHighlightAction;
