import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { Store } from '@ngrx/store';

import { TeamCode, AdditionalClubPlayer } from 'app/models';
import * as fromIndoor from 'app/root/indoor.reducer';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'sams-load-players',
  template: `
    <h2 style="font-size: 20px; text-align: center;">
      {{'component.load-players.add_additional_players' | translate}}
    </h2>

    <div class="additional-players-filter">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" [placeholder]="'component.load-players.filter' | translate">
      </mat-form-field>
    </div>

    <div class="additional-players-container">

      <mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="select">
          <th class="select-col" mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && areAllSelected()"
                          [indeterminate]="selection.hasValue() && !areAllSelected()">
            </mat-checkbox>
          </th>

          <td class="select-col" mat-cell *matCellDef="let player">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(player) : null"
                          [checked]="selection.isSelected(player)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="firstName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'app.firstname' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let player"> {{player.firstName}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'app.lastname' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let player"> {{player.lastName}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="licenseNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'app.license_number' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let player"> {{player.license?.licenseNumber}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dateOfBirth">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{'app.date_of_birth' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let player"> {{dateOfBirth(player)}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let player; columns: displayedColumns;">
        </mat-row>
      </mat-table>

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>

    <button mat-raised-button class="positive" [disabled]="selection.selected.length === 0 || confirmed" (click)="onSubmit()">
      {{"app.save" | translate}}
    </button>

    <button mat-raised-button class="reset" (click)="onReset()" [disabled]="confirmed">
      {{"app.reset" | translate}}
    </button>

    <button mat-raised-button class="cancel" (click)="onCancel()" [disabled]="confirmed">
      {{"app.cancel" | translate}}
    </button>

  `,
  styles: [`
    .additional-players-container {
      display: flex;
      flex-direction: column;
      min-width: 300px;
    }
    
    .additional-players-header {
      min-height: 64px;
      padding: 8px 24px 0;
    }
    
    .mat-form-field {
      font-size: 14px;
      width: 100%;
    }
    
    .mat-table {
      overflow: auto;
      min-height: 300px;
      max-height: 500px;
    }

    .select-col {
      padding-right: 10px;
    }
    .reset {
      background-color: LightCoral;
    }

  `]
})
export class LoadPlayersComponent implements OnInit, OnDestroy {

  @Input() title: string;

  @Input() teamCode: TeamCode;

  @Input() confirmed: boolean;

  @Output() cancel = new EventEmitter<boolean>();

  @Output() submit = new EventEmitter<AdditionalClubPlayer[]>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  length = 100;
  pageSize = 10;
  pageSizeOptions = [5, 10, 25, 100];

  displayedColumns = ['select', 'firstName', 'lastName', 'dateOfBirth', 'licenseNumber'];

  dataSource: MatTableDataSource<AdditionalClubPlayer>;

  selection: SelectionModel<AdditionalClubPlayer>;

  private subscription = new Subscription();

  constructor(public translate: TranslateService, private store: Store<fromIndoor.IndoorRoot>) {

  }

  /**
   * Set the paginator and sort after the view init since this component will
   * be able to query its view for the initialized paginator and sort.
  */
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit() {
    const initialSelection = [];
    const allowMultiSelect = true;
    this.selection = new SelectionModel<AdditionalClubPlayer>(allowMultiSelect, initialSelection);
    this.dataSource = new MatTableDataSource();
    this.subscription.add(
      this.store.select(root => fromIndoor.getAdditionalPlayers(root, this.teamCode))
        .subscribe(additionalPlayers => this.dataSource.data = additionalPlayers)
    );

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  areAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.areAllSelected() ?
        this.selection.clear() :
        this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  onSubmit() {
    this.submit.emit(this.selection.selected);
    this.selection.clear();
  }

  onReset() {
    this.selection.clear();
  }

  onCancel() {
    this.selection.clear();
    this.cancel.emit(null);
  }

  dateOfBirth(p: AdditionalClubPlayer) {
    return moment(p.dateOfBirth).format("DD.MM.YYYY")
  }

}
