import { Subscription } from 'rxjs';
import {
  Component,
  OnInit,
  Input,
  Output,
  OnDestroy,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';

import { Lineup } from '../../../../../shared/reducers/game-state/match-sets/lineups/lineups.reducer';
import { LiberoSwitch, LiberoOut } from '../../../../../shared/reducers/game-state/match-sets/lineups/lineups.action';
import { SetScore } from '../../../../../shared/reducers/game-state/match-sets/set-score/set-score.reducer';
import {
  IndividualSanctions,
} from '../../../../../shared/reducers/game-state/sanctions/individual-sanction/individual-sanction.reducer';
import { Injuries } from '../../../../../shared/reducers/game-state/injuries/injuries.reducer';
import { Liberos, isLiberoUnableToPlay } from '../../../../../shared/reducers/game-state/liberos/liberos.reducer';
import { isLiberoUnallowedToPlay } from '../../../../../shared/reducers/game-state/game-state.reducer';

import { TeamCode, Player, TeamSide, TeamSideValues } from 'app/models';
import { Dragged } from 'app/match-view/draggable.service';
import { SortedBy } from 'app/pipes/sort-players';
import { SetScorePipe } from 'app/pipes/set-score';
import { Mvp } from '../../../../../shared/reducers/game-state/mvps/mvp.reducer';
import { TeamSquad } from '../../../../../shared/reducers/match-preparation/team-squad.reducer';

@Component({
  selector: 'sams-liberos',
  template: `

    <div class="liberos">

      <sams-player-list-header
        [teamSide]="teamSide"
        [titleTranslationKey]="'app.liberos'"
        (sortedBy)="onSortedBy($event)">
      </sams-player-list-header>

     <div
        *ngFor = "let p of lineupLiberos | sortPlayers: sortedBy : teamSide; let i = index;"
        class="table-row"
        [ngStyle]="{ 'flex-direction': isLeftTeam ? 'row' : 'row-reverse' }"
        [ngClass] = "{ 'on-field': isLiberoOnField(p) }">
        <div class="menu-column"
          [ngStyle]="{ 'flex-direction': isLeftTeam ? 'row' : 'row-reverse' }">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>menu</mat-icon>
          </button>
          <button [disabled]="!isLiberoSwitchAllowed(p)" mat-icon-button (click)="onLiberoSub(p)">
            <mat-icon>compare_arrows</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <sams-select-mvp [hidden]="isSelectMvpHidden" [disabled]="isSelectMvpDisabled" [playerUuid]="p.uuid" (selectMvp)="selectMvp.emit(p.uuid)"></sams-select-mvp>
            <sams-injury *ngIf="isLiberoOnField(p)"
              [teamCode]="teamCode"
              [injuries]="injuries"
              [matchId]="matchId"
              [playerId]="p.uuid">
            </sams-injury>
            <sams-individual-sanctions
              [individualSanctions]="individualSanctions"
              [teamCode]="teamCode"
              [teamMemberId]="p.uuid"
              [matchId]="matchId"
              [teamSide]="teamSide"
              [setScore]="setScore"
              [currentSetNumber]="currentSetNumber"
              [isMatchFinished]="isMatchFinished">
            </sams-individual-sanctions>
            <button [disabled]="isLiberoUnableToPlay(p) || isLiberoOnField(p) || isMatchFinished" mat-menu-item (click)="liberoUnableToPlay.emit(p.uuid)">
              <mat-icon>not_interested</mat-icon>
              <span>{{'component.liberos.libero_unable_to_play' | translate}}</span>
            </button>
            <button [disabled]="isStartingSixLocked || isDefaultLibero(p)" mat-menu-item (click)="setDefaultLibero.emit(p.uuid)">
              <mat-icon>looks_one</mat-icon>
              <span>{{'component.liberos.set_default_libero' | translate}}</span>
            </button>
            <button [disabled]="hasLiberoPlayed(p)" *ngIf="liberoRegistrationEnabled" mat-menu-item (click)="liberoPlayed.emit(p.uuid)">
              <mat-icon>check_circled</mat-icon>
              <span>{{'component.liberos.libero_played' | translate}}</span>
            </button>
          </mat-menu>
        </div>
        <div class="icon-column">
          <sams-injury-display
            [injuries]="injuries"
            [teamCode]="teamCode"
            [playerId]="p.uuid">
          </sams-injury-display>
          <sams-team-member-sanction-display
            [teamMemberId]="p.uuid"
            [state]="individualSanctions">
          </sams-team-member-sanction-display>
          <mat-icon *ngIf="isLiberoUnableToPlay(p)">not_interested</mat-icon>
          <div *ngIf="isCaptain(p)" class="captain">C</div>
          <sams-mvp-display [player]="p" [mvp]="mvp"></sams-mvp-display>
          <div *ngIf="isDefaultLibero(p)" class="default-libero">L1</div>
        </div>
        <div class="player-info draggable-element"
            [ngClass] = "{ 'draggable': isDraggable(p) }"
            [draggable] = "isDraggable(p)"
            (dragstart) = "onDragStart($event, p)"
            (dragend) = "onDragEnd($event)">
          <sams-jersey-number-column [jerseyNumber]="p.jerseyNumber" [substitutionInfo]="getSubstitutionInfo(p)">
          </sams-jersey-number-column>
          <sams-player-name-column [player]="p"></sams-player-name-column>
        </div>
      </div>
    </div>
    <button
      mat-raised-button
      style="background-color: gold;"
      *ngIf="isAddNewLiberoAllowed"
      (click)="addNewLibero.emit()">
        {{'component.liberos.add_new_libero' | translate}}
    </button>
  `,
  styles: [`

    .default-libero {
      text-align: center;
      color: black;
      width: 20px;
      background-color: gold;
    }

    .on-field {
      background-color: gold;
    }

    .table-row {
      border-bottom: 1px solid #e0e0e0;
      border-collapse: collapse;
      display: flex;
    }

    .draggable-element:hover {
      background-color: tomato;
    }
    .draggable-element.draggable:hover {
      background-color: lightgreen;
    }
    .draggable-element {
      cursor: not-allowed;
    }
    .draggable-element.draggable {
      cursor: move;
    }

    .droppable {
      background-color: lightgreen;
    }


    .player-info {
      display: flex;
      width: 70%;
      flex-grow: 0;
    }

    .icon-column {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-grow: 1;
      flex-direction: row;
      width: 15%;
    }

    .captain {
      text-align: center;
      color: white;
      width: 20px;
      background-color: RoyalBlue;
    }

    .menu-column {
      display: flex;
      flex-grow: 1;
      width: 20%;
    }

    .icon-column, .menu-column {
      background-color: #e8e8e8;
    }


  `],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class LiberosComponent implements OnInit, OnDestroy {

  @Input() individualSanctions: IndividualSanctions;

  @Input() matchId: string;

  @Input() teamCode: TeamCode;

  @Input() teamSide: TeamSide;

  @Input() setScore: SetScore;

  @Input() lineup: Lineup<Player>;

  @Input() liberos: Liberos<Player>;

  @Input() injuries: Injuries<Player>;

  @Input() teamSquad: TeamSquad;

  @Input() currentSetNumber: number;

  @Input() hasSetStarted: boolean;

  @Input() isMatchFinished: boolean;

  @Input() isSelectMvpHidden: boolean;

  @Input() isSelectMvpDisabled: boolean;

  @Input() mvp: Mvp<Player>;

  @Input() isStartingSixLocked: boolean;

  @Input() liberoRegistrationEnabled: boolean;

  @Output() liberoOut = new EventEmitter<LiberoOut>();

  @Output() liberoSwitch = new EventEmitter<LiberoSwitch>();

  @Output() addNewLibero = new EventEmitter<null>();

  @Output() liberoUnableToPlay = new EventEmitter<string>();

  @Output() setDefaultLibero = new EventEmitter<string>();

  @Output() liberoPlayed = new EventEmitter<string>();

  @Output() draggedChange = new EventEmitter<Dragged>();

  @Output() selectMvp = new EventEmitter<string>();

  sortedBy = SortedBy.jerseyNumber;

  private subscription = new Subscription();

  constructor() {

  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  get lineupLiberos() {
    return this.lineup.liberos;
  }

  get isAnyLiberoOnField() {
    return this.lineupLiberos.find(p => this.lineup.liberoOnField === p);
  }

  isLiberoSwitchAllowed(p: Player) {
    if (this.isMatchFinished) return false
    return !this.isLiberoUnallowedToPlay(p) && this.isLiberoAllowedToChange;
  }

  get isLiberoAllowedToChange() {
    return this.isAnyLiberoOnField && this.isScoreBetweenLiberoChange;
  }

  get isAddNewLiberoAllowed() {
    return this.areAllLiberosUnallowedToPlay && this.isScoreBetweenLiberoChange;
  }

  get areAllLiberosUnallowedToPlay() {
    if (this.lineupLiberos.length === 0) {
      return false;
    };
    return this.lineupLiberos.every(p => this.isLiberoUnallowedToPlay(p));
  }

  isLiberoUnallowedToPlay(l: Player) {
    return isLiberoUnallowedToPlay(this.liberos, this.injuries, this.individualSanctions, this.currentSetNumber, l.uuid);
  }

  isLiberoUnableToPlay(p: Player) {
    return isLiberoUnableToPlay(this.liberos, p.uuid);
  }

  isDefaultLibero(p: Player) {
    return this.liberos.defaultLibero === p
  }

  isLiberoOnField(p: Player) {
    return this.lineup.liberoOnField === p;
  }

  hasLiberoPlayed(player: Player) {
    return this.liberos.liberosPlayed.find(p => p.uuid === player.uuid)
  }

  onLiberoSub(libero: Player) {

    if (this.lineup.liberoOnField === libero) {
      this.liberoOut.emit({
        teamCode: this.teamCode,
        liberoId: libero.uuid,
        playerInId: this.lineup.liberoSubstitute.uuid
      });
    } else {

      this.liberoSwitch.emit({
        teamCode: this.teamCode,
        liberoInId: libero.uuid,
        liberoOutId: this.lineupLiberos.find(p => p.uuid !== libero.uuid).uuid
      });

    }
  }

  onSortedBy(sortedBy: SortedBy) {
    this.sortedBy = sortedBy;
  }

  onDragEnd(event: DragEvent) {
    this.draggedChange.emit(null);
  }

  isDraggable(p: Player) {
    return this.lineup.liberoOnField !== p && this.isScoreBetweenLiberoChange && !this.isLiberoUnallowedToPlay(p) && this.hasSetStarted;
  }

  isCaptain(p: Player) {
    return this.teamSquad.captain === p && p !== null;
  }

  get isScoreBetweenLiberoChange() {
    if (this.setScoreWhenLiberoChanged === null) return true
    return this.setScore.team1 !== this.setScoreWhenLiberoChanged.team1
      || this.setScore.team2 !== this.setScoreWhenLiberoChanged.team2;
  }

  onDragStart(event: DragEvent, benchPlayer: Player) {
    event.dataTransfer.setData('text', 'dummy');
    this.draggedChange.emit({ benchPlayer, teamCode: this.teamCode });
  }

  get liberoSubstitute() {
    return this.lineup.liberoSubstitute;
  }

  get setScoreWhenLiberoChanged() {
    return this.lineup.setScoreWhenLiberoChanged;
  }

  get isLeftTeam() {
    return this.teamSide === TeamSideValues.leftTeam;
  }

  getSubstitutionInfo(p: Player) {
    if (!this.isLiberoOnField(p)) return;
    const pipe = new SetScorePipe();
    const formattedSetScore = pipe.transform(this.setScoreWhenLiberoChanged, this.teamCode);
    return `(${this.liberoSubstitute.jerseyNumber}) ${formattedSetScore}`;
  }

}
