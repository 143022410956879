import { Injectable, OnDestroy } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as fromIndoor from 'app/root/indoor.reducer';

@Injectable({
  providedIn: 'root'
})
export class MatchGuard implements CanActivate, OnDestroy {

  selected = false;

  private sub = new Subscription()

  constructor(public store: Store<fromIndoor.IndoorRoot>, private router: Router) {
    this.sub.add(
      this.store.select(fromIndoor.getSelectedMatch).subscribe(match => {
        this.selected = match ? true : false
      })
    )
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.selected ? true : this.router.createUrlTree(['/matches-overview'])
  }
  
}
