import { LIBERO_UNABLE_TO_PLAY, ADD_NEW_LIBERO, SET_DEFAULT_LIBERO, LIBERO_PLAYED } from './liberos.action.js';
import { GameStateAction, REPLACE_LIBERO } from '../game-state.action.js';
import { Player } from '../../../interfaces/models/player.js';
import { LIBERO_IN, LIBERO_SWITCH } from '../match-sets/lineups/lineups.action.js';

export interface Liberos<T> {
  newLiberos: T[];
  unableToPlay: T[];
  defaultLibero: T;
  liberosPlayed: T[];
}

export const initialState: Liberos<string> = {
  newLiberos: [],
  unableToPlay: [],
  defaultLibero: null,
  liberosPlayed: []
};

export const liberosReducer = (state = initialState, action: GameStateAction) => {
  switch (action.type) {
    case LIBERO_UNABLE_TO_PLAY:
      return {
        ...state,
        unableToPlay: [ ...state.unableToPlay, action.payload.liberoId ]
      };
    case ADD_NEW_LIBERO: 
      return {
        ...state,
        newLiberos: [ ...state.newLiberos, action.payload.playerId ]
      };
    case SET_DEFAULT_LIBERO:
      return {
        ...state,
        defaultLibero: action.payload.liberoId
      }
    case LIBERO_PLAYED: {
      const liberoId = action.payload.liberoId
      if (state.liberosPlayed.indexOf(liberoId) > -1) {
        return {
          ...state,
          liberosPlayed: state.liberosPlayed.filter(l => l !== liberoId)
        }
      }
      return {
        ...state,
        liberosPlayed: state.liberosPlayed.indexOf(liberoId) > -1 ? state.liberosPlayed : [...state.liberosPlayed, liberoId]
      }
    }
    case LIBERO_IN: {
      const liberoId = action.payload.liberoId
      return {
        ...state,
        liberosPlayed: state.liberosPlayed.indexOf(liberoId) > -1 ? state.liberosPlayed : [...state.liberosPlayed, liberoId]
      }
    }
    case LIBERO_SWITCH: {
      const liberoId = action.payload.liberoInId
      return {
        ...state,
        liberosPlayed: state.liberosPlayed.indexOf(liberoId) > -1 ? state.liberosPlayed : [...state.liberosPlayed, liberoId]
      }
    }
    case REPLACE_LIBERO: {
      return {
        ...state,
        liberosPlayed: [...state.liberosPlayed, action.payload.newLiberoId]
      }
    }
    default:
      return state;
  }
};

// -- selectors --
export const getLiberosUnableToPlay = <T>(state: Liberos<T>) => state.unableToPlay;
export const getNewLiberos = <T>(state: Liberos<T>) => state.newLiberos;

export const isLiberoUnableToPlay = (liberos: Liberos<Player>, liberoUuid: string) => getLiberosUnableToPlay(liberos).find(p => p.uuid === liberoUuid) !== undefined;
export const isNewLibero = (liberos: Liberos<Player>, liberoUuid: string) => getNewLiberos(liberos).find(p => p.uuid === liberoUuid) !== undefined;
