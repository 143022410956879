
import { Injectable } from '@angular/core';

import {
  MatchAction
} from '../../../../shared/interfaces/interfaces';

import { ConnectionsService } from './connections.service';
import { SocketService } from './socket.service';

@Injectable()
export class DispatchService {

  constructor(
    public connectionsService: ConnectionsService,
    public socketService: SocketService,
  ) {
  }

  public dispatchRemoteAction(action: MatchAction) {
    const remoteAction = this.connectionsService.toRemoteAction(action)
    if (this.connectionsService.connections[action.matchId]?.auth?.id_token === 'test_token') {
      return
    }
    this.socketService.sendRemoteAction(remoteAction)
  }

}
