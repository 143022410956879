import { MatchAction } from '../../../../interfaces/interfaces.js';
import { TeamCode } from '../../../../interfaces/models/team-codes.js';
import { ScoreAction, PenaltyScoreAction } from '../set-score/set-score.action.js';

export const CHANGE_SERVING = '[Serving] Change Serving';
export const SET_SERVING = '[Serving] Set Serving';

export class ChangeServingAction extends MatchAction {
  readonly type = CHANGE_SERVING;
  constructor(matchId: string) {
    super(matchId);
  }
}

export interface SetServingActionPayload {
  servingTeamCode: TeamCode
}

export class SetServingAction extends MatchAction {
  readonly type = SET_SERVING;
  constructor(public payload: SetServingActionPayload, matchId: string) {
    super(matchId);
  }
}

export type ServingAction
  = SetServingAction
  | ChangeServingAction
  | ScoreAction
  | PenaltyScoreAction;
