import { MatchAction } from '../../../../interfaces/interfaces.js';
import { TeamCode } from '../../../../interfaces/models/team-codes.js';
import { SetScore } from './set-score.reducer.js';

export const SCORE = '[Set] Score';
export const PENALTY_SCORE = '[Set] Penalty Score';
export const SCORE_OVERRIDE = '[Set] Score override';

export class ScoreAction extends MatchAction {
  readonly type = SCORE;
  constructor(public payload: TeamCode, matchId: string) {
    super(matchId);
  }
}

export class PenaltyScoreAction extends MatchAction {
  readonly type = PENALTY_SCORE;
  constructor(public payload: TeamCode, matchId: string) {
    super(matchId);
  }
}

export class ScoreOverrideAction extends MatchAction {
  readonly type = SCORE_OVERRIDE;
  constructor(public payload: SetScore, matchId: string) {
    super(matchId);
  }
}

export type SetScoreAction = ScoreAction | ScoreOverrideAction | PenaltyScoreAction;
