import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  RotateClockwiseAction,
  RotateAntiClockwiseAction,
  NominationAction
} from '../../../../../shared/reducers/game-state/match-sets/lineups/lineups.action';

import * as fromIndoor from 'app/root/indoor.reducer';
import { TeamCode, TeamSide } from 'app/models';
import { DispatchService } from 'app/connections/dispatch.service';

@Component({
  selector: 'sams-lineups-modification',
  template: `
    <sams-lineup-modification
      [previousStartingLineupIds]="previousStartingLineupIds$ | async"
      [isStartingSixLocked]="isStartingSixLocked"
      (rotateClockwise)="onRotateClockwise()"
      (rotateAnticlockwise)="onRotateAnticlockwise()"
      (nominatePreviousStartingLineup)="onNominatePreviousStartingLineup($event)">
    </sams-lineup-modification>
  `,
  styles: [`
    .lineup-modification-container {
      display: flex;
      justify-content: space-around;
    }
  `]
})
export class LineupsModificationComponent implements OnInit {

  @Input() teamCode: TeamCode;

  @Input() teamSide: TeamSide;

  @Input() matchId: string;

  @Input() currentSetNumber: number;

  @Input() isStartingSixLocked: boolean;

  previousStartingLineupIds$: Observable<string[]>;

  constructor(private dispatchService: DispatchService, private store: Store<fromIndoor.IndoorRoot>) {
  }

  ngOnInit() {
    this.previousStartingLineupIds$ = this.store.select(root => fromIndoor.getPreviousStartingLineupNomination(root, this.teamSide));
  }

  onRotateClockwise() {
    this.dispatchService.dispatchRemoteAction(new RotateClockwiseAction(this.teamCode, this.matchId));
  }

  onRotateAnticlockwise() {
    this.dispatchService.dispatchRemoteAction(new RotateAntiClockwiseAction(this.teamCode, this.matchId));
  }

  onNominatePreviousStartingLineup(nominationPlayerIds: string[]) {
    nominationPlayerIds.filter(id => id).map((playerInId, playerOutIndex) => {
      const nomination = {
        teamCode: this.teamCode,
        playerOutIndex,
        playerInId
      };
      this.dispatchService.dispatchRemoteAction(new NominationAction(nomination, this.matchId));
    });
  }

}