import { MatchAction } from "../../../../../interfaces/interfaces.js";
import { TeamCode } from "../../../../../interfaces/models/team-codes.js";
import { SetScore } from "../../../../../reducers/game-state/match-sets/set-score/set-score.reducer.js";
import {
  MEDICAL_TIMEOUT_CAUSE,
  RECOVERY_INTERRUPTION_CAUSE
} from "../../../../model/beach-interruption.js";

export const MEDICAL_TIMEOUT = '[BeachInterruption] medical timeout';
export const RECOVERY_INTERRUPTION = '[BeachInterruption] recovery interruption'

export interface BeachInterruptionPayload {
  playerId: string
  teamCode: TeamCode
  currentSetNumber: number
  setScore: SetScore
  cause: MEDICAL_TIMEOUT_CAUSE | RECOVERY_INTERRUPTION_CAUSE
}

export interface MedicalTimeoutPayload extends BeachInterruptionPayload {
  cause: MEDICAL_TIMEOUT_CAUSE
}

export class MedicalTimeoutAction extends MatchAction {
  readonly type = MEDICAL_TIMEOUT;
  constructor(public payload: MedicalTimeoutPayload, matchId: string) {
    super(matchId);
  }
}

export interface RecoveryInterruptionPayload extends BeachInterruptionPayload {
  cause: RECOVERY_INTERRUPTION_CAUSE
}

export class RecoveryInterruptionAction extends MatchAction {
  readonly type = RECOVERY_INTERRUPTION;
  constructor(public payload: RecoveryInterruptionPayload, matchId: string) {
    super(matchId)
  }
}

export type BeachInterruptionAction =
    MedicalTimeoutAction
  | RecoveryInterruptionAction
