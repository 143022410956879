import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sams-lineup-modification',
  template: `
    <button [disabled]="isStartingSixLocked" class="fab-button" *ngIf="preNominatePreviousStartingLineup" mat-mini-fab color="primary" (click)="onNominatePreviousStartingLineup()">
      <mat-icon>group_add</mat-icon>
    </button>
    <button [disabled]="isStartingSixLocked" class="fab-button" mat-mini-fab color="primary" (click)="rotateClockwise.emit()">
      <mat-icon>rotate_right</mat-icon>
    </button>
    <button [disabled]="isStartingSixLocked" class="fab-button" mat-mini-fab color="primary" (click)="rotateAnticlockwise.emit()">
      <mat-icon>rotate_left</mat-icon>
    </button>
  `,
  styles: [`
    button.fab-button {
      box-shadow: none;
      border: 2px solid lightgrey;
    }
  `]
})
export class LineupModificationComponent {

  @Input() previousStartingLineupIds: string[];

  @Output() nominatePreviousStartingLineup = new EventEmitter<string[]>();

  @Output() rotateClockwise = new EventEmitter<null>();

  @Output() rotateAnticlockwise = new EventEmitter<null>();

  @Input() isStartingSixLocked: boolean;

  onNominatePreviousStartingLineup() {
    this.nominatePreviousStartingLineup.emit(this.previousStartingLineupIds);
  }

  get preNominatePreviousStartingLineup() {
    return this.previousStartingLineupIds.length > 0;
  }

}
