import scoresheetCss from '../../../../scoresheet-generator/src/scss/scoresheet.css';
import scoresheetHbs from 'raw-loader!../../../../scoresheet-generator/src/hbs/scoresheet.hbs';

import { ScoresheetGenerator } from '../../../../scoresheet-generator/src/generator/generator';
import { MatchState } from '../../../../shared/reducers/match-states/match-state.reducer';

export class ScoresheetPresenter {

  constructor(private matchState: MatchState) {
  }
  
  openScoresheetView() {
    const w = window.open();
    const html = new ScoresheetGenerator(this.matchState, scoresheetHbs, scoresheetCss).toHtmlString();
    w.document.write(html);
  }
}