import {
  GameState,
  gameStateReducer
} from '../game-state/game-state.reducer.js';

import { TeamSquads, teamSquadsReducer } from '../match-preparation/team-squads.reducer.js';
import { GameStateAction } from '../game-state/game-state.action.js';
import { MatchStateAction, INITIALIZE_MATCH } from './match-state.action.js';
import { TeamSquadAction, UNDO_TEAMSQUAD_CONFIRMATION } from '../match-preparation/team-squad.action.js';
import { Undoable, undoable } from '../undoable/undoable-action-based.reducer.js';
import { Match } from '../../interfaces/models/match.js';
import { matchModificationReducer } from './match-modification.reducer.js';
import { AdditionalGameState } from '../game-state/game-state.reducer.js';
import { REPLACE_HISTORY, ReplaceHistoryAction } from '../connections/connections.action.js';
import { Jerseys, SelectedJersey } from '../../interfaces/models/jerseys.js';
import {
  ADD_MATCH_SUCCESS,
  ADD_MATCH,
  MatchesOverviewAction,
  ADD_MATCH_FAIL
} from '../matches-overview/matches-overview.action.js';
import { TeamCode } from '../../interfaces/models/team-codes.js';

export type GameStates = Undoable<GameState, GameStateAction>; 

export interface MatchState {
  gameStates: Undoable<GameState, GameStateAction>;
  teamSquads: TeamSquads;
  match?: Match;
}

const undoableGameStateReducer = undoable(gameStateReducer);

const defaultJersey = {
  shirtColorHex: '#ffffff',
  shortsColorHex: '#ffffff'
};

const getSelectedJersey = (jerseys?: Jerseys): SelectedJersey => {
  if (jerseys) {
    if (jerseys.home) return "home"
    if (jerseys.away) return "away"
  }
  return "custom"
}

export const getInitialState = (match: Match, additionalGameState: AdditionalGameState): MatchState => ({
  gameStates: undoableGameStateReducer(undefined, {} as GameStateAction, additionalGameState) as GameStates,
  teamSquads: teamSquadsReducer(undefined, {} as TeamSquadAction),
  match: {
    ...match,
    liberoRegistrationEnabled: match.scoreConfiguration ? match.scoreConfiguration.liberoRegistration === 'ONCE' ? true : false : false,
    team1: {
      ...match.team1,
      clubCode: match.team1.clubCode ? match.team1.clubCode : (match.team1.shortName ? match.team1.shortName : match.team1.name),
      jerseys: {
        ...match.team1.jerseys,
        custom: defaultJersey 
      },
      selectedJersey: getSelectedJersey(match.team1.jerseys)
    },
    team2: {
      ...match.team2,
      clubCode: match.team2.clubCode ? match.team2.clubCode : (match.team2.shortName ? match.team2.shortName : match.team2.name),
      jerseys: {
        ...match.team2.jerseys,
        custom: defaultJersey 
      },
      selectedJersey: getSelectedJersey(match.team2.jerseys)
    }
  }
});

const getAdditionalGameState = (state: MatchState, match: Match): AdditionalGameState => ({
  matchConfig: match.matchConfiguration,
  scoreConfig: match.scoreConfiguration,
  teamSquads: state ? state.teamSquads : teamSquadsReducer(undefined, {} as TeamSquadAction)
});

export const matchStateReducer = (state: MatchState, action: MatchStateAction | ReplaceHistoryAction | MatchesOverviewAction): MatchState => {

  if (!action.type) return state

  switch (action.type) {

    case ADD_MATCH:
    case ADD_MATCH_FAIL: {
      return state
    }

    case ADD_MATCH_SUCCESS: {
      return getInitialState(action.payload.match as Match, getAdditionalGameState(state, action.payload.match as Match))
    }

    case INITIALIZE_MATCH: {
      return getInitialState(action.payload, getAdditionalGameState(state, action.payload))
    }

    case REPLACE_HISTORY: {
      return action.payload.reduce((s, a: any) => matchStateReducer(s, a), matchStateReducer(undefined, { } as MatchStateAction)) as MatchState;
    }

    case UNDO_TEAMSQUAD_CONFIRMATION: {

      // wipe gamestate so we can't redo actions which might be incompatible with new teamsquad
      const stateWithCleanGamestate = {
        ...state,
        gameStates: undoableGameStateReducer(undefined, {} as GameStateAction, getAdditionalGameState(state, state.match)) as GameStates
      };

      return delegateAction(stateWithCleanGamestate, action, getAdditionalGameState(state, state.match)) as MatchState;
    }

  }

  return delegateAction(state, action as any, getAdditionalGameState(state, state.match)) as MatchState;
};

const delegateAction = (state: MatchState, action: MatchStateAction, additionalGameState: AdditionalGameState) => {
  return {
    ...state,
    match: matchModificationReducer(state.match, action),
    gameStates: undoableGameStateReducer(state.gameStates, action as GameStateAction, additionalGameState),
    teamSquads: teamSquadsReducer(state.teamSquads, action)
  };
};

// ==== Selectors ====
export const getGameStates = (matchState: MatchState) => matchState.gameStates;
export const getGameState = (matchState: MatchState) => matchState.gameStates.present;
export const isGameStateTouched = (matchState: MatchState) => matchState.gameStates.past.length !== 0;
export const getTeamSquads = (matchState: MatchState) => matchState.teamSquads;
export const getMatch = (matchState: MatchState) => matchState.match;
export const getGameHistory = (matchState: MatchState) => [...matchState.gameStates.past, matchState.gameStates.action].filter(a => a && a.type)

export const hasLiberoPlayed = (state: MatchState, liberoUuid: string, teamCode: TeamCode) => {
  const gameState = getGameState(state)
  return gameState.liberosByTeamCode[teamCode].liberosPlayed.indexOf(liberoUuid) > -1;
}
