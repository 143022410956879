import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ScoreConfiguration, TeamCode, TeamSide } from 'app/models';
import {
  ChallengeAction,
  ChallengeType
} from '../../../../../shared/reducers/game-state/match-sets/challenges/challenges.action';
import {
  Challenge,
  ChallengeState
} from '../../../../../shared/reducers/game-state/match-sets/challenges/challenges.reducer';
import { DispatchService } from 'app/connections/dispatch.service';

@Component({
  selector: 'sams-challenge',
  template: `
    <div class="challenges-container">
      <mat-menu #challengeMenu="matMenu">

        <button *ngIf="isVideoChallengeOptionEnabled(ChallengeType.BALL_IN_OUT)" mat-menu-item (click)="onChallenge(teamCode, ChallengeType.BALL_IN_OUT)">
          <span>{{'component.challenge.ball_in_out' | translate}}</span>
        </button>
        <button *ngIf="isVideoChallengeOptionEnabled(ChallengeType.BLOCK_TOUCH)" mat-menu-item (click)="onChallenge(teamCode, ChallengeType.BLOCK_TOUCH)">
          <span>{{'component.challenge.block_touch' | translate}}</span>
        </button>
        <button *ngIf="isVideoChallengeOptionEnabled(ChallengeType.NET_FAULT)" mat-menu-item (click)="onChallenge(teamCode, ChallengeType.NET_FAULT)">
          <span>{{'component.challenge.net_fault' | translate}}</span>
        </button>
        <button *ngIf="isVideoChallengeOptionEnabled(ChallengeType.ANTENNA_CONTACT)" mat-menu-item (click)="onChallenge(teamCode, ChallengeType.ANTENNA_CONTACT)">
          <span>{{'component.challenge.antenna_contact' | translate}}</span>
        </button>
        <button *ngIf="isVideoChallengeOptionEnabled(ChallengeType.FOOT_FAULT)" mat-menu-item (click)="onChallenge(teamCode, ChallengeType.FOOT_FAULT)">
          <span>{{'component.challenge.foot_fault' | translate}}</span>
        </button>
        <button *ngIf="isVideoChallengeOptionEnabled(ChallengeType.GROUND_CONTACT)" mat-menu-item (click)="onChallenge(teamCode, ChallengeType.GROUND_CONTACT)">
          <span>{{'component.challenge.ground_contact' | translate}}</span>
        </button>

      </mat-menu>
      <button [disabled]="isChallengeButtonDisabled" [class]="getNumOfChallengesClass()" mat-raised-button color="primary" [matMenuTriggerFor]="challengeMenu" class="challenge-button">
        <mat-icon>videocam</mat-icon>
        <div class="num-of-challenges">
          {{numOfChallengesFailed}}
        </div>
      </button>
    </div>
  `,
  styles: [`
    .num-of-challenges {
      font-size: 25px;
}

    .challenges-container {
      display: flex;
      flex-direction: row;
    }

    .challenge-button.exceeded {
      background-color: tomato;
      color: white;
      cursor: not-allowed;
    }

    .challenge-button.warning {
      background-color: gold;
      color: white;
    }

    .challenge-button {
      width: 3.5vw;
      min-width: 40px;
      max-width: 100px;
      padding: 0;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChallengeComponent {

  @Input() teamCode: TeamCode;

  @Input() teamSide: TeamSide;

  @Input() matchId: string;

  @Input() isSetRunning: boolean;

  @Input() isMatchFinished: boolean;

  @Input() hasSetStarted: boolean;

  @Input() challengeState: ChallengeState;

  @Input() scoreConfiguration: ScoreConfiguration;

  ChallengeType = ChallengeType

  constructor(private dispatchService: DispatchService) {
  }

  onChallenge(teamCode: TeamCode, challengeType: ChallengeType) {
    const challenge: Challenge =  {
      teamCode,
      challengesLeft: this.numOfChallengesLeft,
      challengeType,
      timestamp: +new Date(),
      durationMs: 0,
      successful: false
    }
    this.dispatchService.dispatchRemoteAction(new ChallengeAction(challenge, this.matchId))
  }

  getNumOfChallengesClass() {
    if (this.numOfChallengesLeft === 0) {
      return 'exceeded'
    }
    if (this.numOfChallengesLeft === 1) {
      return 'warning'
    }
  }

  get isChallengeButtonDisabled() {
    return !this.hasSetStarted || !this.isSetRunning || this.isMaxChallengesExceeded()
  }

  get numOfChallengesLeft() {
    return this.numberOfChallengeRequestsByTeam - this.challengeState.numOfChallengesFailed
  }

  get numberOfChallengeRequestsByTeam() {
    return this.scoreConfiguration.numberOfChallengeRequestsByTeam
  }

  get numOfChallengesFailed(){
    return this.challengeState.numOfChallengesFailed
  }

  get videoChallengeOptions() {
    return this.scoreConfiguration.videoChallengeOptions
  }

  isVideoChallengeOptionEnabled(challengeType: ChallengeType) {
    return this.videoChallengeOptions.includes(challengeType)
  }

  isMaxChallengesExceeded() {
    return this.numOfChallengesLeft === 0
  }

}
