import { Component } from '@angular/core';
import { config } from '../config/config';

@Component({
  selector: 'sams-score-logo',
  template: `
    <img class="score-logo" src="assets/icons/{{logoFileName}}">
  `,
  styles: [`
    .score-logo {
      margin: 5px;
      height: 45px;
      width: auto;
    }
  `]
})
export class ScoreLogoComponent {

  get logoFileName() {
    return config.isBeach ? 'beach-score-icon.svg' : 'score-icon.svg';
  }

}
