import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import {
  IndividualSanctions,
  isTeamMembersHighestSanctionWarning,
  isTeamMembersHighestSanctionPenalty,
  isTeamMembersHighestSanctionExpulsion,
  isTeamMembersHighestSanctionDisqualification
} from '../../../../../shared/reducers/game-state/sanctions/individual-sanction/individual-sanction.reducer';

@Component({
  selector: 'sams-team-member-sanction-display',
  template: `
    <mat-icon *ngIf="isTeamMembersHighestSanctionWarning" class="yellow-card">book</mat-icon>
    
    <mat-icon *ngIf="isTeamMembersHighestSanctionPenalty" class="red-card">book</mat-icon>
    
    <div *ngIf="isTeamMembersHighestSanctionExpulsion">
      <mat-icon class="yellow-card">book</mat-icon>
      <mat-icon class="red-card red-expulsion-card">book</mat-icon>    
    </div>

    <div *ngIf="isTeamMembersHighestSanctionDisqualification">
      <mat-icon class="yellow-card">book</mat-icon>
      <mat-icon class="red-card red-disqualification-card">book</mat-icon>
    </div>

  `,
  styles: [`
    .yellow-card {
      color: gold;
    }
    .red-card {
      color: tomato;
    }
    .red-expulsion-card {
      margin-left: -17px;
    }
    .red-disqualification-card {
      margin-left: -8px;
    }
    mat-icon {
      background-color: #e8e8e8;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamMemberSanctionDisplayComponent {

  @Input() state: IndividualSanctions;

  @Input() teamMemberId: string;

  get isTeamMembersHighestSanctionWarning() {
    return isTeamMembersHighestSanctionWarning(this.state, this.teamMemberId);
  }

  get isTeamMembersHighestSanctionPenalty() {
    return isTeamMembersHighestSanctionPenalty(this.state, this.teamMemberId);
  }

  get isTeamMembersHighestSanctionExpulsion() {
    return isTeamMembersHighestSanctionExpulsion(this.state, this.teamMemberId);
  }

  get isTeamMembersHighestSanctionDisqualification() {
    return isTeamMembersHighestSanctionDisqualification(this.state, this.teamMemberId);
  }

}
