import md5 from 'blueimp-md5'

/**
 * Calculate a checksum based on the an action history of a match.
 * 
 * Used on the clients to send the new checksum to the backend
 * 
 * Used on the backend to validate the checksum against the one stored in
 * redis in order to sync clients
 * 
 * If there is no history for a given match we initialize the checksum
 */
export class Md5Util {
  static caluclateChecksum = (uuids: string[]): string => md5(uuids.sort().join());
  static initializeChecksum = (): string => md5('');
}
