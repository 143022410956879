import { SetScoreAction } from "../../../../../../reducers/game-state/match-sets/set-score/set-score.action.js";
import { TeamSidesAction } from "../../../../../../reducers/game-state/match-sets/team-sides/team-sides.action.js";
import { TimeKeepingAction } from "../../../../../../reducers/game-state/match-sets/time-keeping/time-keeping.action.js";
import { BeachLineupsAction } from "../match-set/lineups/beach-lineups.action.js";
import { BeachMatchSetRulesAction } from "../match-set/beach-match-set-rules.action.js";
import { MatchAction } from "../../../../../../interfaces/interfaces.js";
import { TeamCode } from "../../../../../../interfaces/models/team-codes.js";
import {
  IndividualDisqualificationAction,
  IndividualExpulsionAction
} from "../../../../../../reducers/game-state/sanctions/individual-sanction/individual-sanction.action.js";

export const COIN_TOSS = '[BeachMatchSet] coin toss'

export class CoinTossAction extends MatchAction {
  readonly type = COIN_TOSS;
  constructor(public payload: TeamCode, matchId: string) {
    super(matchId);
  }
}


export type BeachMatchSetAction =
    SetScoreAction
  | BeachMatchSetRulesAction
  | TimeKeepingAction
  | TeamSidesAction
  | BeachLineupsAction
  | CoinTossAction
  | IndividualExpulsionAction
  | IndividualDisqualificationAction;
