import {
  Component,
  Input,
  OnInit
} from '@angular/core';

import { MatchSets, SetPoints } from '../../../../shared/reducers/game-state/match-sets/match-sets.reducer';
import { MatchState } from '../../../../shared/reducers/match-states/match-state.reducer';

@Component({
  selector: 'sams-match-final-scores',
  template: `
    <div class='final-scores-container'>
      <h1>{{setPoints.team1}}</h1>
      <div class="set-scores-container">
        <div class="set-score-entry" *ngFor="let set of matchSets; let i = index">
          <div className="team-score">{{set.setScore.team1}}</div>
          <div className="set-number">({{i + 1}})</div>
          <div className="team-score">{{set.setScore.team2}}</div>
        </div>
      </div>
      <h1>{{setPoints.team2}}</h1>
    </div>
  `,
  styles: [`
    
    .final-scores-container {
      display: grid;
      grid-column: 1 / 10;
      grid-template-columns: repeat(9, 1fr);
      align-items: center;
    }
    h1 {
      margin: 0 30px;
      font-size: 50px;
      font-weight: normal;
      color: #424242;
      text-align: center;
    }
    .final-scores-container h1:first-child{
      grid-column: 1 / 4;
    }
    .final-scores-container h1:last-child{
      grid-column: 7 / 10;
    }
    .set-scores-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      grid-column: 4 / 7;
    }

    .set-score-entry {
      margin-bottom: 5px;
    }
    .set-score-entry {
      color: #424242;
      display: grid;
      grid-template-columns: 1fr 3fr 1fr;
      font-weight: bold;
      text-align: center;
      box-sizing: content-box;
      width: 120px;
      justify-content: center;
    }
    .set-number {
      letter-spacing: 4px;
    }
  `],
})
export class MatchFinalScoresComponent implements OnInit {

  @Input() matchState: MatchState;

  @Input() matchSets: MatchSets;

  @Input() setPoints: SetPoints

  ngOnInit() {
  }

}
