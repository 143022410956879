import { timer as observableTimer, Observable, Subscription } from 'rxjs';

import { first, filter, bufferCount, combineLatest, map, take } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';

import { TeamSides } from '../../../../../shared/reducers/game-state/match-sets/team-sides/team-sides.reducer';
import { SwitchSidesAction } from '../../../../../shared/reducers/game-state/match-sets/team-sides/team-sides.action';
import { TimeKeeping } from '../../../../../shared/reducers/game-state/match-sets/time-keeping/time-keeping.reducer';
import {
  StartSetAction,
  StartTimeoutAction,
  Timeout,
  StartMatchAction,
  CancelTechnicalTimeoutAction,
  CancelSetPauseAction,
  CancelTimeoutAction,
} from '../../../../../shared/reducers/game-state/match-sets/time-keeping/time-keeping.action';
import { Serving } from '../../../../../shared/reducers/game-state/match-sets/serving/serving.reducer';
import { ChangeServingAction } from '../../../../../shared/reducers/game-state/match-sets/serving/serving.action';
import { SetScore } from '../../../../../shared/reducers/game-state/match-sets/set-score/set-score.reducer';
import { ScoreAction, ScoreOverrideAction } from '../../../../../shared/reducers/game-state/match-sets/set-score/set-score.action';
import { UndoAction, RedoAction } from '../../../../../shared/reducers/undoable/undoable.action';
import { SaveCommentsAction } from '../../../../../shared/reducers/match-states/match-modification.action';

import * as fromShared from 'app/root/shared.reducer';
import * as fromBeach from 'app/root/beach.reducer';
import {
  ByTeamSide,
  TeamSideValues,
  TeamCode,
  TeamCodes
} from 'app/models';

import { LocalizedHistoryAction } from 'app/models/localized-history-action';
import { LocalizedHistoryActionBuilder } from 'app/helpers/localized-history-action-builder';
import { AmbientHelpers } from 'app/helpers/ambient-helpers';

import { CommentsDialogComponent, CommentsDialogData } from 'app/match-view/dialogs/comments-dialog.component';
import {
  ManualChangesDialogComponent,
  ManualChangesDialogConfig,
  ManualChangesDialogData,
  ManualChangesDialogResult
} from "app/match-view/dialogs/manual-changes-dialog.component";
import { BeachMatch } from '../../../../../shared/beach/model/beach-match';
import { BeachMatchConfiguration } from '../../../../../shared/beach/model/beach-match-configuration';
import { BeachScoreConfiguration } from '../../../../../shared/beach/model/beach-score-configuration';
import { BeachGameStateAction } from '../../../../../shared/beach/reducers/match-states/game-state/beach-game-state.action';
import { BeachTeam } from '../../../../../shared/beach/model/beach-team';
import { BeachMatchSets } from '../../../../../shared/beach/reducers/match-states/game-state/match-sets/beach-match-sets.reducer';
import { BeachGameStates, BeachMatchState } from '../../../../../shared/beach/reducers/match-states/beach-match-state.reducer';
import { SetScoreByTeamSides } from 'app/root/shared.reducer';
import { BeachMatchSet } from '../../../../../shared/beach/reducers/match-states/game-state/match-sets/match-set/beach-match-set.reducer';
import { CoinTossAction } from '../../../../../shared/beach/reducers/match-states/game-state/match-sets/match-set/beach-match-set.action';
import {
  TeamImproperRequestAction,
  TeamPenalty,
  TeamPenaltyAction,
  TeamSanction,
  TeamWarningAction
} from '../../../../../shared/reducers/game-state/sanctions/team-sanction/team-sanction.action';
import { DispatchService } from '../../connections/dispatch.service';

@Component({
  selector: 'sams-beach-match-view',
  template: `
  <sams-syncing *ngIf="!match.isTestMatch" [matchId]="matchId"></sams-syncing>

  <div *ngIf="matchId" class="match-view">
    <mat-sidenav-container class="history-container">
      <mat-sidenav #sidenav [mode]="'push'" class="history-sidenav">
        <button mat-raised-button color="primary" (click)="sidenav.close()">{{'app.close' | translate}}</button>
        <h3 mat-subheader>{{'app.history' | translate}}</h3>
        <table>
          <tr *ngFor="let action of localizedHistory; let idx = index; trackBy: trackActionHistory">
            <td>{{action.time}} - </td>
            <td [ngStyle]="{'font-weight': action.message.primary ? 'bold' : 'normal', 'work-break': 'break-word', 'white-space': 'pre-wrap'}">
              {{action.message.key | translate:action.message.params}}
            </td>
          </tr>
        </table>
      </mat-sidenav>

      <mat-toolbar color="secondary">
        <div (click)="navigateHome()" style="height: 100%; width: auto; cursor: pointer;">
          <sams-score-logo></sams-score-logo>
        </div>
        <sams-listening *ngIf="!match.isTestMatch" [matchId]="matchId"></sams-listening>
        <sams-connection></sams-connection>
        <button mat-button [disabled]="isGameStateTouched$ | async" (click)="navigateBack()">{{'app.back' | translate}}</button>
      </mat-toolbar>

      <div class="flex-container">

        <div class="middle-column">

          <div class="flex-container time-keeping">

            <div class="team-data left-team">
              <h2>{{(leftTeam$ | async)?.name}}</h2>
              <div class="team-actions">
                <sams-team-sanctions
                  [teamSanctions] = "(sanctionsByTeamSides$ | async).leftTeam.team"
                  [teamSide] = "TeamSideValues.leftTeam"
                  [teamCode] = "teamSides.leftTeam"
                  [matchId] = "matchId"
                  [setScore] = "setScore$ | async"
                  [currentSetNumber] = "currentSetNumber"
                  [isMatchFinished] = "isMatchFinished$ | async"
                  (improperRequest) = "onImproperRequest($event)"
                  (teamWarning) = "onTeamWarning($event)"
                  (teamPenalty) = "onTeamPenalty($event)">
                </sams-team-sanctions>
                <span class="set-points">{{(setPoints$ | async)?.leftTeam}}</span>
              </div>
            </div>

            <div class="team-data right-team">
              <h2>{{(rightTeam$ | async).name}}</h2>
              <div class="team-actions-right">
                <sams-team-sanctions
                  [teamSanctions] = "(sanctionsByTeamSides$ | async).rightTeam.team"
                  [teamSide] = "TeamSideValues.rightTeam"
                  [teamCode] = "teamSides.rightTeam"
                  [matchId] = "matchId"
                  [setScore] = "setScore$ | async"
                  [currentSetNumber] = "currentSetNumber"
                  [isMatchFinished] = "isMatchFinished$ | async"
                  (improperRequest) = "onImproperRequest($event)"
                  (teamWarning) = "onTeamWarning($event)"
                  (teamPenalty) = "onTeamPenalty($event)">
                </sams-team-sanctions>
                <span class="set-points">{{(setPoints$ | async)?.rightTeam}}</span>
              </div>
            </div>
          </div>

          <sams-countdown-display
            [matchId]="matchId"
            [timeKeeping]="timeKeeping$ | async"
            [scoreConfiguration]="scoreConfiguration"
            [hasSetStarted]="hasSetStarted"
            [isMatchFinished]="isMatchFinished$ | async"
            [currentMatchSet]="currentMatchSet"
            [currentSetPauseStart]="currentSetPauseStart$ | async"
            (cancelSetPause)="onCancelSetPause()"
            (cancelTimeout)="onCancelTimeout()"
            (cancelTechnicalTimeout)="onCancelTechnicalTimeout()">
          </sams-countdown-display>

          <div class="timing">
            <div class="flex-container challenge-timeouts">
              <sams-timeout
                (timeout)="timeout($event)"
                [hasStarted]="hasSetStarted"
                [numberOfTimeouts]="(numberOfTimeoutsByTeamSides$ | async).leftTeam"
                [maxTimeoutsPerSet]="maxTimeoutsPerSet$ | async"
                [teamCode]="teamSides.leftTeam"
                [setScore]="setScore$ | async"
                [isSetRunning]="isSetRunning$ | async">
              </sams-timeout>
            </div>

            <div class="time-keeping-container">
              <div class="set-number">
                {{'app.set' | translate}} {{currentSetNumber}}
              </div>
              <sams-time-keeping
                (start)="onStart($event)"
                [isMatchFinished]="isMatchFinished$ | async"
                [isStartable]="isStartable"
                [hasStarted]="hasSetStarted"
                [timeKeeping]="timeKeeping$ | async"
                [isSetRunning]="isSetRunning$ | async">
              </sams-time-keeping>
            </div>

            <div class="flex-container challenge-timeouts">
              <sams-timeout
                (timeout)="timeout($event)"
                [hasStarted]="hasSetStarted"
                [numberOfTimeouts]="(numberOfTimeoutsByTeamSides$ | async).rightTeam"
                [maxTimeoutsPerSet]="maxTimeoutsPerSet$ | async"
                [teamCode]="teamSides.rightTeam"
                [setScore]="setScore$ | async"
                [isSetRunning]="isSetRunning$ | async">
              </sams-timeout>
            </div>
          </div>

          <div class="vertical-spacer"></div>

          <sams-scoreboard
            [setScore]="setScore$ | async"
            [isSetRunning]="isSetRunning$ | async"
            [teamSides]="teamSides"
            (incrementScore)="incrementScore($event)">
          </sams-scoreboard>

          <button style="margin-top: 15px;" *ngIf="isMatchFinished$ | async" mat-raised-button class="positive" (click)="finalizeMatch()">{{'app.finalize' | translate}}</button>

          <div class="vertical-spacer"></div>

          <sams-beach-lineups></sams-beach-lineups>

          <div class="vertical-spacer"></div>

          <sams-undoable
            [gameStates]="gameStates$ | async"
            [matchState]="matchState"
            (undo)="undo($event)"
            (redo)="redo($event)">
          </sams-undoable>

          <div class="vertical-spacer"></div>

          <sams-match-sets [matchSets]="matchSets.sets" [matchState]="matchState"></sams-match-sets>

          <div class="vertical-spacer"></div>

          <div class="bottom-action-buttons">
            <button mat-raised-button (click)="openCommentsDialog()">{{'app.comments' | translate}} <mat-icon *ngIf="comments$ | async">content_paste</mat-icon></button>
            <button mat-raised-button (click)="openManualChangesDialog()">{{'app.manual_changes' | translate}}</button>
            <button mat-raised-button (click)="sidenav.open()">{{'app.history' | translate}}</button>
            <button mat-raised-button (click)="openScoresheetView()">{{'app.scoresheet' | translate}}</button>
          </div>

          <div class="vertical-spacer"></div>

        </div>

      </div>

    </mat-sidenav-container>
  </div>
    
  `,
  styles: [`

    .team-squads {
      display: flex;
      flex-direction: row;
    }

    sams-match-sets {
      width: 375px;
    }

    .timing {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }

    sams-timeout {
      display: flex;
      height: 70%;
    }

    sams-time-keeping {
      display: flex;
      height: 70%;
    }

    .timeout-countdown {
      text-align: center;
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      color: #424242;
    }
    .set-number {
      color: #424242;
      margin: 5px;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      width: 100%;
    }

    button.hide-button {
      min-width: 0;
      padding: 0;
      width: 2.5%;
    }
    div.flex-container {
      display: flex;
      justify-content: center;
    }
    .time-keeping {
      width: 370px;
      align-items: flex-end;
    }

    .time-keeping-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    div.right-column {
      display: flex;
      flex-direction: column;
      text-align: right;
      justify-content: flex-start;
      align-items: center;
      width: 32.5%;
    }

    div.left-column {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 32.5%;
    }

    div.middle-column {
      margin-top: 15px;
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
    }

    .team-data {
      width: 100%;
      overflow-x: hidden;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        margin: 0;
        font-size: 20px;
        white-space: pre-wrap;
        word-break: break-word;
        text-align: center;
      }
    }

    .teams {
      display: flex;
      flex-direction: row;
    }

    .team-actions {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .team-actions-right {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
    }

    h2 {
      margin-top: 0px;
      color: #424242;
    }
    span.team-color {
      border: 2px solid lightgrey;
      width: 3.5em;
      height: 4.4em;
    }
    span.set-points {
      margin: 15px;
      background-color: whitesmoke;
      padding: 0px 20px;
      font-size: 3em;
    }

    .history-container {
      margin-bottom: 36px;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .history-sidenav {
      padding: 15px;
      flex: 1 0 auto;
    }

    .sidenav-header {
      display: flex;
      button {
        margin-right: 5px;
      }
    }

    .bottom-action-buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .bottom-action-buttons button {
      margin: 5px 5px;
    }

    .team-logo {
      width: calc( 40px + 4vw );
      max-width: 100px;
    }

    .pre-match-actions {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    .pre-match-actions > button, .coin-toss > button {
      padding: 0px 8px;
      margin: 0 15px;
    }

    @media (max-width: 460px) {
      .team-data h2 {
        font-size: 16px;
      }
    }

  `]
})
export class BeachMatchViewComponent implements OnDestroy, OnInit {

  scoreConfiguration: BeachScoreConfiguration;

  matchConfiguration: BeachMatchConfiguration;

  matchId: string;

  match: BeachMatch;

  matchState: BeachMatchState;

  gameStates$: Observable<BeachGameStates>;

  comments: string;

  currentMatchSet: BeachMatchSet;

  gameHistory$: Observable<BeachGameStateAction[]>;

  localizedHistory: LocalizedHistoryAction[];

  leftTeam$: Observable<BeachTeam>;

  rightTeam$: Observable<BeachTeam>;

  teamSides: TeamSides;

  timeKeeping$: Observable<TimeKeeping>;

  numberOfTimeoutsByTeamSides$: Observable<ByTeamSide<number>>;

  setPoints$: Observable<fromShared.SetPointsByTeamSides>;

  matchSets: BeachMatchSets;

  serving$: Observable<Serving>;

  lineupsByTeamSides$: Observable<fromBeach.BeachLineupsByTeamSides>;

  teamSquadsByTeamSides$: Observable<fromBeach.BeachTeamSquadsByTeamSides>;

  setScore$: Observable<SetScore>;

  setScoreByTeamSides: SetScoreByTeamSides;

  currentSetNumber: number;

  currentSetIndex: number;

  isMatchFinished$: Observable<boolean>;

  isSetRunning$: Observable<boolean>;

  isSetFinished$: Observable<boolean>;

  isDecidingSet: boolean;

  sanctionsByTeamSides$: Observable<fromShared.SanctionsByTeamSides>;

  isGameStateTouched$: Observable<boolean>;

  maxTimeoutsPerSet$: Observable<number>;

  currentSetPauseStart$: Observable<number>;

  hasSetStarted: boolean;

  hasMatchStarted: boolean;

  listening: boolean;

  private subscription = new Subscription();

  constructor(
    public store: Store<fromBeach.BeachRoot>,
    public dispatchService: DispatchService,
    public dialog: MatDialog,
    public router: Router,
    public translate: TranslateService) {
  }

  ngOnInit() {

    this.subscription.add(this.store.select(fromShared.getListening).subscribe(listening => this.listening = listening));

    this.subscription.add(this.store.select(fromBeach.getBeachMatch).subscribe(match => this.match = match));

    this.gameHistory$ = this.store.select(fromBeach.getGameHistory);

    this.timeKeeping$ = this.store.select(fromBeach.getTimeKeeping);

    this.numberOfTimeoutsByTeamSides$ = this.store.select(fromBeach.getNumberOfTimeoutsByTeamSides);

    this.subscription.add(
      this.store.select(fromBeach.getTeamSides)
        .subscribe(teamSides => this.teamSides = teamSides)
    );

    this.subscription.add(
      this.store.select(fromBeach.isDecidingSet).subscribe(isDecidingSet => this.isDecidingSet = isDecidingSet)
    );

    this.leftTeam$ = this.store.select(fromBeach.getLeftTeam);

    this.rightTeam$ = this.store.select(fromBeach.getRightTeam);

    this.setPoints$ = this.store.select(fromBeach.getSetPointsByTeamSides);

    this.subscription.add(
      this.store.select(fromBeach.getBeachMatchSets).subscribe(matchSets => this.matchSets = matchSets)
    );

    this.serving$ = this.store.select(fromBeach.getServing);

    this.teamSquadsByTeamSides$ = this.store.select(fromBeach.getBeachTeamSquadsByTeamSides);

    this.lineupsByTeamSides$ = this.store.select(fromBeach.getBeachLineupsByTeamSides);

    this.subscription.add(
      this.store.select(fromBeach.getBeachComments).subscribe(comments => this.comments = comments)
    )

    this.subscription.add(
      this.store.select(fromBeach.getCurrentBeachMatchSet).subscribe(currentMatchSet => this.currentMatchSet = currentMatchSet)
    );

    this.subscription.add(
      this.store.select(fromBeach.getBeachMatchState).subscribe(matchState => this.matchState = matchState)
    );

    this.subscription.add(
      this.gameHistory$.subscribe((h) => {
        // TOOD: SCORE-BEACH type BeachMatchState and BeachAction
        this.localizedHistory = h.slice(1).map(a => new LocalizedHistoryActionBuilder(this.matchState as any, a as any).build()).reverse();
      })
    );

    this.setScore$ = this.store.select(fromBeach.getCurrentSetScore);

    this.subscription.add(
      this.store.select(fromBeach.getCurrentSetScoreByTeamSides).subscribe(setScore => this.setScoreByTeamSides = setScore)
    );

    this.isMatchFinished$ = this.store.select(fromBeach.getIsMatchFinished);

    const now$ = observableTimer(0, 1000).pipe(map(_ => +moment()));

    const isSetRunning = (now: number, timeKeeping: TimeKeeping) => {
      return this.setStarted(timeKeeping) &&
        timeKeeping.currentPauseStartTime === 0 &&
        timeKeeping.timeoutStartTimeMs + this.timeoutDurationMs < now &&
        (this.technicalTimeoutDurationMs === 0 || ((timeKeeping.technicalTimeoutStartTimeMs + this.technicalTimeoutDurationMs) < now));
    };

    this.isSetRunning$ = now$.pipe(
      combineLatest(this.timeKeeping$, isSetRunning),
      combineLatest(this.isMatchFinished$, (isSetRunning, isMatchFinished) => isSetRunning && !isMatchFinished),);

    this.subscription.add(
      this.store.select(fromBeach.getCurrentSetNumber).subscribe(currentSetNumber => this.currentSetNumber = currentSetNumber)
    );

    this.subscription.add(
      this.store.select(fromBeach.getCurrentSetIndex).subscribe(currentSetIndex => this.currentSetIndex = currentSetIndex)
    );

    this.isSetFinished$ = this.store.select(fromBeach.getCurrentSetIndex).pipe(
      bufferCount(2, 1), // this prevents emitting true when undoing a finished set
      map(([previousSetNumber, currentSetIndex]) => currentSetIndex > previousSetNumber),);

    this.subscription.add(
      this.store.select(fromShared.getMatchId).subscribe(id => this.matchId = id)
    );

    this.gameStates$ = this.store.select(fromBeach.getGameStates);

    this.sanctionsByTeamSides$ = this.store.select(fromBeach.getSanctionsByTeamSides);

    this.isGameStateTouched$ = this.store.select(fromBeach.isGameStateTouched);

    this.maxTimeoutsPerSet$ = this.store.select(fromBeach.getMaxTimeoutsPerSet);

    const hasMatchStarted$ = this.store.select(fromBeach.getHasMatchStarted)

    this.subscription.add(
      this.store.select(fromBeach.getBeachScoreConfiguration).subscribe(scoreConfiguration => this.scoreConfiguration = scoreConfiguration)
    );

    this.subscription.add(
      this.store.select(fromBeach.getBeachMatchConfiguration).subscribe(matchConfig => this.matchConfiguration = matchConfig)
    );

    this.subscription.add(
      hasMatchStarted$.subscribe(hasMatchStarted => this.hasMatchStarted = hasMatchStarted)
    );

    this.subscription.add(
      this.store.select(fromBeach.getHasCurrentSetStarted).subscribe(hasSetStarted => this.hasSetStarted = hasSetStarted)
    );

    this.currentSetPauseStart$ = this.store.select(fromBeach.getCurrentSetPauseStart)

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  navigateBack() {
    this.router.navigate(['match-preparation']);
  }

  navigateHome() {
    this.router.navigate(['matches-overview']);
  }

  finalizeMatch() {
    this.router.navigate(['match-finalization']);
  }

  coinToss(teamCode: TeamCode) {
    this.dispatchService.dispatchRemoteAction(new CoinTossAction(teamCode, this.matchId));
  }

  switchSides() {
    this.dispatchService.dispatchRemoteAction(new SwitchSidesAction(this.matchId));
  }

  changeServing() {
    this.dispatchService.dispatchRemoteAction(new ChangeServingAction(this.matchId));
  }

  incrementScore(teamCode: TeamCode) {
    this.dispatchService.dispatchRemoteAction(new ScoreAction(teamCode, this.matchId));
  }

  overrideScore(score: ByTeamSide<number>) {
    const team1 = TeamCodes.team1 === this.teamSides.leftTeam ? score.leftTeam : score.rightTeam;
    const team2 = TeamCodes.team2 === this.teamSides.rightTeam ? score.rightTeam : score.leftTeam;
    this.dispatchService.dispatchRemoteAction(new ScoreOverrideAction({ team1, team2 }, this.matchId));
  }

  onTeamWarning(payload: TeamSanction) {
    this.dispatchService.dispatchRemoteAction(new TeamWarningAction(payload, this.matchId));
  }

  onTeamPenalty(payload: TeamPenalty) {
    this.dispatchService.dispatchRemoteAction(new TeamPenaltyAction(payload, this.matchId));
  }

  onImproperRequest(payload: TeamSanction) {
    this.dispatchService.dispatchRemoteAction(new TeamImproperRequestAction(payload, this.matchId));
  }

  onCancelSetPause() {
    this.dispatchService.dispatchRemoteAction(new CancelSetPauseAction(this.matchId));
  }

  onCancelTimeout() {
    this.dispatchService.dispatchRemoteAction(new CancelTimeoutAction(this.matchId));
  }

  onCancelTechnicalTimeout() {
    this.dispatchService.dispatchRemoteAction(new CancelTechnicalTimeoutAction(this.matchId));
  }

  undo(nStates: number) {
    if (!AmbientHelpers.isAnyDialogOpen()) {
      this.dispatchService.dispatchRemoteAction(new UndoAction(nStates, this.matchId));
    }
  }

  redo(nStates: number) {
    if (!AmbientHelpers.isAnyDialogOpen()) {
      this.dispatchService.dispatchRemoteAction(new RedoAction(nStates, this.matchId));
    }
  }

  onStart(timestamp: number) {
    if (this.hasMatchStarted) {
      this.startSet(timestamp);
    } else {
      this.startMatch(timestamp);
    }
  }

  startSet(timestamp: number) {
    this.dispatchService.dispatchRemoteAction(new StartSetAction(timestamp, this.matchId));
  }

  startMatch(timestamp: number) {
    const startTimeMs = +new Date()
    this.dispatchService.dispatchRemoteAction(new StartMatchAction({ startTimeMs, actualStartTimeMs: timestamp }, this.matchId));
  }

  timeout(timeout: Timeout) {
    this.dispatchService.dispatchRemoteAction(new StartTimeoutAction(timeout, this.matchId));
  }

  openCommentsDialog() {
    const data: CommentsDialogData = {
      comments: this.comments
    }
    const dialogRef: MatDialogRef<CommentsDialogComponent, string> = this.dialog.open(CommentsDialogComponent, { data });
    dialogRef.afterClosed().pipe(
      first(),
      filter(result => result !== null),)
      .subscribe(result => this.onCloseCommentsDialog(result));
  }

  openManualChangesDialog() {
    const config: ManualChangesDialogConfig = {
      pointsToWinSet: this.matchConfiguration.pointsToWinSet,
      pointsToWinDecidingSet: this.matchConfiguration.pointsToWinDecidingSet,
      minimumPointDifference: this.matchConfiguration.minimumPointDifference
    }
    const data: ManualChangesDialogData = {
      setScore: this.setScoreByTeamSides,
      leftTeamShortName: this.leftTeam.shortName,
      rightTeamShortName: this.rightTeam.shortName,
      config,
      isDecidingSet: this.isDecidingSet
    }
    const dialogRef: MatDialogRef<ManualChangesDialogComponent, ManualChangesDialogResult>
      = this.dialog.open(ManualChangesDialogComponent, { data });

    dialogRef.afterClosed().pipe(
      first(),
      filter(result => result !== null),)
      .subscribe(result => this.overrideScore(result));
  }

  openScoresheetView() {
    this.router.navigate(['/scoresheet'])
  }

  private onCloseCommentsDialog(result: string) {
    this.dispatchService.dispatchRemoteAction(new SaveCommentsAction(result, this.matchId));
  }

  get leftTeam() {
    return this.match[this.teamSides.leftTeam];
  }

  get rightTeam() {
    return this.match[this.teamSides.rightTeam];
  }

  get TeamSideValues() {
    return TeamSideValues;
  }

  get TeamCodes() {
    return TeamCodes;
  }

  setStarted(timeKeeping: TimeKeeping) {
    return timeKeeping.actualStartTimeMs !== 0;
  }

  trackActionHistory(index: number, action: LocalizedHistoryAction) {
    return action.uuid;
  }

  get technicalTimeoutDurationMs() {
    return this.scoreConfiguration.technicalTimeoutDurationSeconds * 1000;
  }

  get timeoutDurationMs() {
    return this.scoreConfiguration.timeoutDurationSeconds * 1000;
  }

  get totalPlayersOnField() {
    return this.scoreConfiguration.totalPlayersOnField;
  }

  get showCoinToss() {
    return this.currentMatchSet.coinTossWinner === null && this.currentSetNumber !== 2;
  }

  get coinTossWinner() {
    return this.currentMatchSet.coinTossWinner
  }

  isLineupComplete(teamCode: TeamCode) {
    return this.currentMatchSet.lineups[teamCode].current.filter(p => p && p.jerseyNumber !== null).length === this.totalPlayersOnField
  }


  get isStartable() {
    return this.isLineupComplete(TeamCodes.team1)
      && this.isLineupComplete(TeamCodes.team2)
      && this.matchState.matchPreparation.teamSquads.team1.captain
      && this.matchState.matchPreparation.teamSquads.team2.captain
      && this.bothTeamsSigned
  }

  get bothTeamsSigned() {
    return this.matchState.matchPreparation.teamSquads.team1.signature.signatureString
      && this.matchState.matchPreparation.teamSquads.team2.signature.signatureString
  }

}
