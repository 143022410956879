import {
  CONFIRM_TEAMSQUAD,
  ConfirmTeamSquadAction,
  TeamSquadAction,
  TOGGLE_OFFICIAL,
  UNDO_TEAMSQUAD_CONFIRMATION,
  UndoTeamsquadConfirmationAction
} from './team-squad.action.js';

import {
  TOGGLE_CAPTAIN,
  TOGGLE_LIBERO,
  TOGGLE_PLAYER,
  SELECT_ALL,
  RESET,
} from './team-squad.action.js';
import { Player } from '../../interfaces/models/player.js';
import { Official } from '../../interfaces/models/official.js';
import { ScoreConfiguration } from '../../interfaces/models/score-configuration.js';

export interface TeamSquadConfirmation {
  confirmed: boolean
  pin: string
}

interface GenericTeamSquad<T extends Player | string, O extends Official | string> extends TeamSquadConfirmation {
  captain: T;
  liberos: T[];
  players: T[];
  officials: O[];
}

/** Used inside reducers where only ids are stored */
export type StateTeamSquad = GenericTeamSquad<string, string>;

/** Used in components where we have objects */
export type TeamSquad = GenericTeamSquad<Player, Official>;

export const initialTeamSquadConfirmation: TeamSquadConfirmation = {
  confirmed: false,
  pin: null
}

export const initialState: StateTeamSquad = {
  captain: null,
  liberos: [],
  players: [],
  officials: [],
  ...initialTeamSquadConfirmation
};

const selectAll = (state: StateTeamSquad, playerIds: string[]) => {
  return {
    ...state,
    players: [ ...state.players, ...playerIds.filter(id => state.players.indexOf(id) === -1) ]
  };
};

export const toggleCaptain = <T extends { captain: string }>(state: T, playerId: string): T => {
  return {
    ...state,
    captain: state.captain === playerId ? null : playerId
  };
};

const toggleOne = (state: string[], teamMemberId: string) => 
  state.indexOf(teamMemberId) > -1
    ? state.filter(p => p !== teamMemberId)
    : [ ...state, teamMemberId ];

export const undoTeamSquadConfirmation = <T extends TeamSquadConfirmation>(state: T, _: UndoTeamsquadConfirmationAction): T => {
  return {
    ...state,
    confirmed: false,
    pin: null
  };
}

export const teamSquadConfirmation = <T extends TeamSquadConfirmation>(state: T, action: ConfirmTeamSquadAction): T => {
  return {
    ...state,
    confirmed: true,
    pin: action.payload.pin
  };
}

export const teamSquadReducer = (state: StateTeamSquad = initialState, action: TeamSquadAction) => {

  switch (action.type) {
    case TOGGLE_CAPTAIN:
      return toggleCaptain(state, action.payload.teamMemberId);

    case TOGGLE_LIBERO:
      return {
        ...state,
        liberos: toggleOne(state.liberos, action.payload.teamMemberId)
      }

    case TOGGLE_PLAYER:
      return {
        ...state,
        players: toggleOne(state.players, action.payload.teamMemberId)
      }

    case TOGGLE_OFFICIAL:
      return {
        ...state,
        officials: toggleOne(state.officials, action.payload.teamMemberId)
      }

    case SELECT_ALL:
      return selectAll(state, action.payload.teamMemberIds);

    case RESET:
      return initialState;
    
    case UNDO_TEAMSQUAD_CONFIRMATION: {
      return undoTeamSquadConfirmation(state, action)
    }

    case CONFIRM_TEAMSQUAD: {
      return teamSquadConfirmation(state, action)
    }

    default:
      return state;
  }
};

// -- selectors --
export const getCaptain = <T extends string | Player, O extends string | Official>(state: GenericTeamSquad<T, O>) => state.captain;
export const getOfficials = <T extends string | Player, O extends string | Official>(state: GenericTeamSquad<T, O>) => state.officials;
export const getPlayers = <T extends string | Player, O extends string | Official>(state: GenericTeamSquad<T, O>) => state.players;
export const getLiberos = <T extends string | Player, O extends string | Official>(state: GenericTeamSquad<T, O>) => state.liberos;
export const getPin = (state: TeamSquad | StateTeamSquad) => state.pin;
export const getConfirmed = (state: TeamSquad | StateTeamSquad) => state.confirmed;


// Ensures that we don't call any of these with (string, Object) or (Object, string) but only (string, string) or (Object, Object)
type IsPlayer = <P extends string | Player, O extends string | Official>(state: GenericTeamSquad<P, O>, p: P) => boolean;
export const isCaptain: IsPlayer = (state, p) => getCaptain(state) === p;
export const isLibero: IsPlayer = (state, p) => getLiberos(state).indexOf(p) > -1;
export const isTeamSquadPlayer: IsPlayer = (state, p) => getPlayers(state).indexOf(p) > -1;

// --- preconditions ---
type Pre = (state: TeamSquad) => boolean;
export const preTogglePlayer: Pre = state => !getConfirmed(state);
export const preToggleCaptain: Pre = state => preTogglePlayer(state);
export const preToggleLibero = (config: ScoreConfiguration, state: TeamSquad) => preTogglePlayer(state) && !isNoLiberoAllowed(state, config);
export const preChangeJerseyNumber = (isGameStateTouched: boolean, state: TeamSquad) =>
     !getConfirmed(state)
  && !isGameStateTouched;

type Poss = (state: TeamSquad, p: Player) => boolean;

export const possToggleCaptain: Poss = (state, p) =>
     preToggleCaptain(state)
  && isTeamSquadPlayer(state, p);

export const possToggleLibero = (config: ScoreConfiguration, state: TeamSquad, p: Player) =>
     preToggleLibero(config, state)
  && isTeamSquadPlayer(state, p);

export const possTogglePlayer: Poss = (state, p) =>
     preTogglePlayer(state)
  && p.eligible;

export const possChangeJerseyNumber = (isGameStateTouched: boolean, state: TeamSquad, p: Player) =>
     preChangeJerseyNumber(isGameStateTouched, state)
  && p.eligible;


const isNoLiberoAllowed = (state: TeamSquad, config: ScoreConfiguration) => {
  const noLiberosAllowedEntry = config.numberOfLiberos.find(entry => entry.minLiberos === 0 && entry.maxLiberos === 0);
  if (noLiberosAllowedEntry === undefined) {
    return false;
  }
  return state.players.length <= noLiberosAllowedEntry.toPlayers;
};
