import {timer as observableTimer,  Observable, Subscription } from 'rxjs';

import {first, filter, bufferCount, combineLatest, map, take} from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';

import { TeamSides } from '../../../../shared/reducers/game-state/match-sets/team-sides/team-sides.reducer';
import { SwitchSidesAction } from '../../../../shared/reducers/game-state/match-sets/team-sides/team-sides.action';
import { TimeKeeping } from '../../../../shared/reducers/game-state/match-sets/time-keeping/time-keeping.reducer';
import {
  StartSetAction,
  StartTimeoutAction,
  Timeout,
  StartMatchAction,
  ChangeSetPauseDurationAction,
  CancelSetPauseAction,
  CancelTimeoutAction,
  CancelTechnicalTimeoutAction
} from '../../../../shared/reducers/game-state/match-sets/time-keeping/time-keeping.action';
import { MatchSets } from '../../../../shared/reducers/game-state/match-sets/match-sets.reducer';
import { Serving } from '../../../../shared/reducers/game-state/match-sets/serving/serving.reducer';
import { ChangeServingAction } from '../../../../shared/reducers/game-state/match-sets/serving/serving.action';
import { SetScore } from '../../../../shared/reducers/game-state/match-sets/set-score/set-score.reducer';
import { ScoreAction, ScoreOverrideAction } from '../../../../shared/reducers/game-state/match-sets/set-score/set-score.action';
import { UndoAction, RedoAction } from '../../../../shared/reducers/undoable/undoable.action';
import {
  SubstitutionAction,
  NominationAction,
  Substitution,
  Nomination
} from '../../../../shared/reducers/game-state/match-sets/lineups/lineups.action';
import { GameStates, MatchState } from '../../../../shared/reducers/match-states/match-state.reducer';
import { SaveCommentsAction } from '../../../../shared/reducers/match-states/match-modification.action';
import { GameStateAction } from '../../../../shared/reducers/game-state/game-state.action';
import { MatchSet } from '../../../../shared/reducers/game-state/match-sets/match-set.reducer';

import * as fromIndoor from 'app/root/indoor.reducer';
import * as fromShared from 'app/root/shared.reducer';
import {
  Team,
  Match,
  ByTeamSide,
  TeamSideValues,
  TeamCode,
  ScoreConfiguration,
  MatchConfiguration,
  TeamCodes
} from 'app/models';

import { LocalizedHistoryAction } from 'app/models/localized-history-action';
import { LocalizedHistoryActionBuilder } from 'app/helpers/localized-history-action-builder';
import { TeamSquadsPresenter } from 'app/helpers/team-squads-presenter';
import { ScoresheetPresenter } from 'app/helpers/scoresheet-presenter';
import { AmbientHelpers } from 'app/helpers/ambient-helpers';

import { InfoDialogComponent } from 'app/match-view/dialogs/info-dialog.component';
import { CommentsDialogComponent, CommentsDialogData } from 'app/match-view/dialogs/comments-dialog.component';
import { SetFinishedDurationDialogComponent } from 'app/match-view/dialogs/set-finished-duration-dialog';
import { ManualChangesDialogComponent, ManualChangesDialogResult, ManualChangesDialogData, ManualChangesDialogConfig } from "app/match-view/dialogs/manual-changes-dialog.component";
import { LockStartingSixAction } from '../../../../shared/reducers/game-state/match-sets/starting-six.action';
import { SetFinishedSpectatorCountDialogComponent } from './dialogs/set-finished-spectator-count-dialog';
import { ChallengeDialogComponent } from './dialogs/challenge-dialog';
import { Challenge } from '../../../../shared/reducers/game-state/match-sets/challenges/challenges.reducer';
import {
  ChallengeDeclinedAction,
  ChallengeSuccessAction
} from '../../../../shared/reducers/game-state/match-sets/challenges/challenges.action';
import { isSmallField } from '../../../../shared/reducers/game-state/match-sets/lineups/lineups.reducer';
import { IndoorRoot } from '../root/indoor.reducer';
import { SanctionsByTeamSides, SetPointsByTeamSides, SetScoreByTeamSides } from 'app/root/shared.reducer';
import {
  TeamImproperRequestAction,
  TeamPenaltyAction,
  TeamSanction,
  TeamPenalty,
  TeamWarningAction
} from '../../../../shared/reducers/game-state/sanctions/team-sanction/team-sanction.action';
import { DispatchService } from 'app/connections/dispatch.service';

@Component({
  selector: 'sams-match-view',
  template: `
  <div *ngIf="matchId" class="match-view">
    <mat-sidenav-container class="history-container">
      <mat-sidenav #sidenav class="history-sidenav">
          <h3 mat-subheader>{{'app.history' | translate}}</h3>
          <table>
          <tr *ngFor="let action of localizedHistory; let idx = index; trackBy: trackActionHistory">
            <td>{{action.time}} - </td>
            <td [ngStyle]="{'font-weight': action.message.primary ? 'bold' : 'normal'}">{{action.message.key | translate:action.message.params}}</td>
          </tr>
        </table>
      </mat-sidenav>

      <sams-syncing *ngIf="!match.isTestMatch" [matchId]="matchId"></sams-syncing>

      <mat-toolbar color="secondary">
        <div (click)="navigateHome()" style="height: 100%; width: auto; cursor: pointer;">
          <sams-score-logo></sams-score-logo>
        </div>        
        <sams-listening *ngIf="!match.isTestMatch" [matchId]="matchId"></sams-listening>

        <span style="flex: 1 1 auto;"></span>
        <button mat-button [disabled]="isGameStateTouched$ | async" (click)="navigateBack()">{{'component.match-view.back_to_match_prep' | translate}}</button>
        <button mat-raised-button class="positive" *ngIf="isMatchFinished$ | async" [disabled]="!(isMvpSubmissionFinished$ | async)" (click)="finalizeMatch()">{{'component.match-view.finalize_match' | translate}}</button>
        <span style="flex: 1 1 auto;"></span>
        <sams-num-of-spectators class="num-of-spectators" [matchId]="matchId" [numOfSpectators]="numOfSpectators$ | async" [placeholder]="'app.num_of_spectators'"></sams-num-of-spectators>
        <sams-connection></sams-connection>
      </mat-toolbar>
      
      <div class="flex-container">

        <div class="left-column">
        
          <div class="team-data left-team">
            <img *ngIf="leftTeam.logoImageMax400x400?.url" [src]="leftTeam.logoImageMax400x400?.url" class="team-logo" [alt]="leftTeam.name">
            <h2>{{(leftTeam$ | async)?.name}}</h2>
            <sams-team-sanctions
              [teamSanctions] = "(sanctionsByTeamSides$ | async).leftTeam.team"
              [teamSide] = "TeamSideValues.leftTeam"
              [teamCode] = "teamSides.leftTeam"
              [matchId] = "matchId"
              [setScore] = "setScore$ | async"
              [currentSetNumber] = "currentSetNumber"
              [isMatchFinished] = "isMatchFinished$ | async"
              (improperRequest) = "onImproperRequest($event)"
              (teamWarning) = "onTeamWarning($event)"
              (teamPenalty) = "onTeamPenalty($event)">
            </sams-team-sanctions>
            <span class="set-points">{{(setPoints$ | async)?.leftTeam}}</span>
            <span *ngIf="jerseyColorSelectionEnabled" class="team-color" [ngStyle]="{'background': 'linear-gradient(' + getSelectedJersey((leftTeam$ | async))?.shirtColorHex + ' 50%, ' + getSelectedJersey((leftTeam$ | async))?.shortsColorHex + ' 50%)'}"></span>
          </div>

          <div class="player-list-container">          
            <sams-player-list
              [teamCode] = "teamSides.leftTeam"
              [teamSide] = "TeamSideValues.leftTeam"
              [team] = "leftTeam$ | async"
              [individualSanctions] = "(sanctionsByTeamSides$ | async)?.leftTeam.individuals"
              [setScore] = "setScore$ | async"
              [lineup] = "(lineupsByTeamSides$ | async)?.leftTeam"
              [serving] = "serving$ | async"
              [teamSquad] = "(teamSquadsByTeamSides$ | async)?.leftTeam"
              [currentSetNumber] = "currentSetNumber"
              [hasSetStarted] = "hasSetStarted"
              [isMatchFinished] = "isMatchFinished$ | async"
              [injuries] = "(injuriesByTeamSides$ | async)?.leftTeam"
              [liberos] = "(liberosByTeamSides$ | async)?.leftTeam"
              [mvp] = "(mvpsByTeamSides$ | async)?.leftTeam"
              [mvpSubmissionEnabled] = "mvpSubmissionEnabled"
              [isMvpSubmissionFinished] = "isMvpSubmissionFinished$ | async"
              [matchId] = "matchId"
              [isStartingSixLocked] = "isStartingSixLocked$ | async"
              [liberoRegistrationEnabled] = "liberoRegistrationEnabled$ | async"
              [wasTeamTimeoutTakenBeforeScore] = "wasTeamTimeoutTakenBeforeScore$ | async">
            </sams-player-list>
          </div>

        </div> <!-- left-column -->

        <div class="middle-column">

        <sams-countdown-display
          [matchId]="matchId"
          [timeKeeping]="timeKeeping$ | async"
          [scoreConfiguration]="scoreConfiguration"
          [hasSetStarted]="hasSetStarted"
          [isMatchFinished]="isMatchFinished$ | async"
          [currentMatchSet]="currentMatchSet"
          [currentSetPauseStart]="currentSetPauseStart$ | async"
          (cancelSetPause)="onCancelSetPause()"
          (cancelTimeout)="onCancelTimeout()"
          (cancelTechnicalTimeout)="onCancelTechnicalTimeout()">
        </sams-countdown-display>

        <div class="flex-container time-keeping">
          <div class="num-of-substitutions disabled-background" [class]="getNumOfSubstitutionsClass((lineupsByTeamSides$ | async)?.leftTeam.numOfSubstitutions)">
            <mat-icon>compare_arrows</mat-icon>
            <div>
              {{(lineupsByTeamSides$ | async)?.leftTeam.numOfSubstitutions}}
            </div>
          </div>

          <div class="flex-container challenge-timeouts">
            <sams-challenge
              *ngIf="videoChallengeAvailable"
              [matchId]="matchId"
              [teamCode]="teamSides.leftTeam"
              [teamSide]="TeamSideValues.leftTeam"
              [challengeState]="(challengesByTeamSides$ | async).leftTeam"
              [isMatchFinished]="isMatchFinished$ | async"
              [hasSetStarted]="hasSetStarted"
              [isSetRunning]="isSetRunning$ | async"
              [scoreConfiguration]="scoreConfiguration">
            </sams-challenge> 

            <sams-timeout
              (timeout)="timeout($event)"
              [hasStarted]="hasSetStarted"
              [numberOfTimeouts]="(numberOfTimeoutsByTeamSides$ | async).leftTeam"
              [maxTimeoutsPerSet]="maxTimeoutsPerSet$ | async"
              [teamCode]="teamSides.leftTeam"
              [setScore]="setScore$ | async"
              [isSetRunning]="isSetRunning$ | async">
            </sams-timeout>
          </div>

          <div class="time-keeping-container">
            <div class="set-number">
              {{'app.set' | translate}} {{currentSetNumber}}
            </div>
            <sams-time-keeping 
              (start)="onStart($event)"
              [isMatchFinished]="isMatchFinished$ | async"
              [isStartable]="areBothTeamsReady$ | async"
              [hasStarted]="hasSetStarted"
              [timeKeeping]="timeKeeping$ | async"
              [isSetRunning]="isSetRunning$ | async">
            </sams-time-keeping>
          </div>

          <div class="flex-container challenge-timeouts">
            <sams-challenge
              *ngIf="videoChallengeAvailable"
              [matchId]="matchId"
              [teamCode]="teamSides.rightTeam"
              [teamSide]="TeamSideValues.rightTeam"
              [challengeState]="(challengesByTeamSides$ | async).rightTeam"
              [isMatchFinished]="isMatchFinished$ | async"
              [hasSetStarted]="hasSetStarted"
              [isSetRunning]="isSetRunning$ | async"
              [scoreConfiguration]="scoreConfiguration">
            </sams-challenge>
            <sams-timeout
              (timeout)="timeout($event)"
              [hasStarted]="hasSetStarted"
              [numberOfTimeouts]="(numberOfTimeoutsByTeamSides$ | async).rightTeam"
              [maxTimeoutsPerSet]="maxTimeoutsPerSet$ | async"
              [teamCode]="teamSides.rightTeam"
              [setScore]="setScore$ | async"
              [isSetRunning]="isSetRunning$ | async">
            </sams-timeout>
          </div>

          <div class="num-of-substitutions disabled-background" [class]="getNumOfSubstitutionsClass((lineupsByTeamSides$ | async)?.rightTeam.numOfSubstitutions)">
            <mat-icon>compare_arrows</mat-icon>
            <div>{{(lineupsByTeamSides$ | async)?.rightTeam.numOfSubstitutions}}</div>
          </div>
        </div>
          
          <div class="vertical-spacer"></div>
          
          <sams-scoreboard
            [setScore]="setScore$ | async"
            [isSetRunning]="isSetRunning$ | async"
            [teamSides]="teamSides"
            (incrementScore)="incrementScore($event)">
          </sams-scoreboard>

          <div class="vertical-spacer"></div>

          <div *ngIf="areHighlightsEnabled" class="highlight-container">
            <sams-team-highlight [matchId]="matchId" [teamCode]="teamSides.leftTeam"></sams-team-highlight>
            <div class="highlight-spacer"></div>
            <sams-team-highlight [matchId]="matchId" [teamCode]="teamSides.rightTeam"></sams-team-highlight>
          </div>

          <div *ngIf="areHighlightsEnabled" class="vertical-spacer"></div>

          <sams-undoable
            [gameStates]="gameStates$ | async"
            [matchState]="matchState"
            (undo)="undo($event)"
            (redo)="redo($event)">
          </sams-undoable>

          <div class="vertical-spacer"></div>

          <sams-lineups [ngClass]="{ 'small-field': isSmallField }"></sams-lineups>

          <div class="vertical-spacer"></div>
          <div class="vertical-spacer"></div>

          <div *ngIf="!hasMatchStarted || (isDecidingSet && !hasSetStarted && !(isMatchFinished$ | async))" class="pre-match-actions">
            <button mat-raised-button [disabled]="isStartingSixLocked$ | async" (click)="switchSides()">
              {{'component.match-view.switch_sides' | translate}}
            </button>
            <button *ngIf="!hasMatchStarted" class="lock-starting-six-button" [ngClass]="{ 'positive': true }"
              [disabled]="(isLockStartingSixDisabled$ | async) || (isStartingSixLocked$ | async)" mat-raised-button (click)="lockStartingSix()">
                {{'component.match-view.lock_starting_six' | translate}}
            </button>
            <button class="change-serving-button" [disabled]="isStartingSixLocked$ | async" mat-raised-button (click)="changeServing()">
              {{'component.match-view.switch_serving' | translate}}
            </button>
          </div>

          <div class="vertical-spacer"></div>
          
          <div class="pre-match-actions">
            <sams-lineups-modification *ngIf="!hasSetStarted && !(isMatchFinished$ | async)" 
              [matchId]="matchId"
              [teamCode]="teamSides.leftTeam"
              [teamSide]="TeamSideValues.leftTeam"
              [currentSetNumber]="currentSetNumber"
              [isStartingSixLocked]="isStartingSixLocked$ | async">
            </sams-lineups-modification>
            <sams-lineups-modification *ngIf="!hasSetStarted && !(isMatchFinished$ | async)" 
              [matchId]="matchId"
              [teamCode]="teamSides.rightTeam"
              [teamSide]="TeamSideValues.rightTeam"
              [currentSetNumber]="currentSetNumber"
              [isStartingSixLocked]="isStartingSixLocked$ | async">
            </sams-lineups-modification>
          </div>

          <div class="vertical-spacer"></div>

          <sams-match-sets [matchSets]="matchSets" [matchState]="matchState"></sams-match-sets>

          <div class="vertical-spacer"></div>
          
          <div class="bottom-action-buttons">
            <button mat-raised-button (click)="openCommentsDialog()">{{'app.comments' | translate}} <mat-icon *ngIf="comments$ | async">content_paste</mat-icon></button>
            <button mat-raised-button (click)="openManualChangesDialog()">{{'app.manual_changes' | translate}}</button>
            <button mat-raised-button (click)="sidenav.open()">{{'app.history' | translate}}</button>
            <button mat-raised-button (click)="openScoresheetView()">{{'app.scoresheet' | translate}}</button>
            <button mat-raised-button (click)="openTeamSquadsView()">{{'component.match-view.team_squads' | translate}}</button>
          </div>
          
          <div class="vertical-spacer"></div>

        </div> <!-- middle-column -->

        <div class="right-column">
          <div class="team-data right-team">
            <img *ngIf="rightTeam.logoImageMax400x400?.url" [src]="rightTeam.logoImageMax400x400?.url" class="team-logo" [alt]="rightTeam.name">
            <h2>{{(rightTeam$ | async).name}}</h2>
            <sams-team-sanctions
              [teamSanctions] = "(sanctionsByTeamSides$ | async).rightTeam.team"
              [teamSide] = "TeamSideValues.rightTeam"
              [teamCode] = "teamSides.rightTeam"
              [matchId] = "matchId"
              [setScore] = "setScore$ | async"
              [currentSetNumber] = "currentSetNumber"
              [isMatchFinished] = "isMatchFinished$ | async"
              (improperRequest) = "onImproperRequest($event)"
              (teamWarning) = "onTeamWarning($event)"
              (teamPenalty) = "onTeamPenalty($event)">
            </sams-team-sanctions>
           <span class="set-points">{{(setPoints$ | async)?.rightTeam}}</span>
           <span *ngIf="jerseyColorSelectionEnabled" class="team-color" [ngStyle]="{'background': 'linear-gradient(' + getSelectedJersey((rightTeam$ | async))?.shirtColorHex + ' 50%, ' + getSelectedJersey((rightTeam$ | async))?.shortsColorHex + ' 50%)'}"></span>
          </div>

        <div class="player-list-container">
          <sams-player-list
            [teamCode] = "teamSides.rightTeam"
            [teamSide] = "TeamSideValues.rightTeam"
            [team] = "rightTeam$ | async"
            [individualSanctions] = "(sanctionsByTeamSides$ | async)?.rightTeam.individuals"
            [setScore] = "setScore$ | async"
            [lineup] = "(lineupsByTeamSides$ | async)?.rightTeam"
            [serving] = "serving$ | async"
            [teamSquad] = "(teamSquadsByTeamSides$ | async)?.rightTeam"
            [currentSetNumber] = "currentSetNumber"
            [hasSetStarted] = "hasSetStarted"
            [isMatchFinished] = "isMatchFinished$ | async"
            [injuries] = "(injuriesByTeamSides$ | async)?.rightTeam"
            [liberos] = "(liberosByTeamSides$ | async)?.rightTeam"
            [mvp] = "(mvpsByTeamSides$ | async)?.rightTeam"
            [mvpSubmissionEnabled] = "mvpSubmissionEnabled"
            [isMvpSubmissionFinished] = "isMvpSubmissionFinished$ | async"
            [matchId] = "matchId"
            [isStartingSixLocked] = "isStartingSixLocked$ | async"
            [liberoRegistrationEnabled] = "liberoRegistrationEnabled$ | async"
            [wasTeamTimeoutTakenBeforeScore] = "wasTeamTimeoutTakenBeforeScore$ | async">
          </sams-player-list>
        </div>
        

        </div> <!-- right-column -->

      </div> <!-- flex-container -->

    </mat-sidenav-container>
  </div>
    
  `,
  styles: [`

    .small-field {
      width: 50%;
      align-self: center;
    }

    div.flex-container.challenge-timeouts {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%
    }

    sams-challenge {
      display: flex;
    }

    sams-timeout {
      display: flex;
      height: 70%;
    }

    sams-time-keeping {
      display: flex;
      height: 70%;
    }

    .highlight-container {
      display: flex;
      justify-content: center;
    }

    .highlight-spacer {
      margin: 0 25px;
    }

    .timeout-countdown {
      text-align: center;
      width: 100%;
      font-size: 20px;
      font-weight: bold;
      color: #424242;
    }
    .set-number {
      color: #424242;
      margin: 5px;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      width: 100%;
    }
    .player-list-container {
      width: 100%;
    }
    button.hide-button {
      min-width: 0;
      padding: 0;
      width: 2.5%;
    }
    div.flex-container {
      display: flex;
      justify-content: center;
    }
    .time-keeping {
      align-items: flex-end;
    }

    .time-keeping-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    div.right-column {
      display: flex;
      flex-direction: column;
      text-align: right;
      justify-content: flex-start;
      align-items: center;
      width: 32.5%;
    }

    div.left-column {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 32.5%;
    }

    div.middle-column {
      margin-left: 1%;
      margin-right: 1%;
      width: 30.5%;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    .team-data {
      width: 100%;
      min-height: 130px;
      display: flex;
      align-items: center;
      margin-top: 15px;
      justify-content: space-between;
    }

    .team-data h2 {
      font-size: 2vw;
    }


    .team-data > * {
    }

    div.left-team {
      flex-direction: row;
    }

    div.right-team {
      flex-direction: row-reverse;
    }

    h2 {
      color: #424242;
    }
    span.team-color {
      border: 2px solid lightgrey;
      width: 3.5em;
      height: 4.4em;
    }
    span.set-points {
      background-color: lightgrey;
      padding: 0px 20px;
      font-size: 3.9em;
    }
    .history-sidenav {
      padding: 20px;
    }

    .lineup-container {
      min-width: 100%;
      display: flex;
      justify-content: space-between;
      text-align: center;
    }
    .left-half {
      border-right: 1px dashed black;
    }
    .right-half {
      border-left: 1px dashed black;
    }
    .lineup {
      width: 50%;
      padding-right: 2px; /* hack because tiles somehow overflow on right side */
    }
    .bottom-action-buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .bottom-action-buttons button {
      margin: 5px 5px;
    }

    .num-of-substitutions.exceeded {
      background-color: tomato;
      color: white;
    }
    .num-of-substitutions.warning {
      background-color: gold;
      color: white;
    }

    .num-of-substitutions {
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      width: 3vw;
      min-width: 25px;
      max-width: 100px;
      padding: 5px 0;
    }
    .num-of-substitutions .mat-icon {
      font-size: 20px;
    }

    .team-logo {
      width: calc( 40px + 4vw );
      max-width: 100px;
    }

    .pre-match-actions {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    .pre-match-actions > button {
      padding: 0px 8px;
    }

    @media (min-width: 1600px) {
      span.set-points {
        font-size: 64px;
      }
      .team-data h2 {
        font-size: 32px;
      }

    }

    .num-of-spectators {
      font-size: 16px;
      text-align: right;
      line-height: normal;
      margin-top: 18px;
      width: 100px;
    }
    .num-of-spectators label {
      font-size: 12px;
    }

  `]
})
export class MatchViewComponent implements OnDestroy, OnInit {

  readonly setNumberChangeSetPauseDuration = 2;

  scoreConfiguration: ScoreConfiguration;

  matchConfiguration: MatchConfiguration;

  matchId: string;

  match: Match;

  comments: string;

  currentMatchSet: MatchSet;

  gameHistory$: Observable<GameStateAction[]>;

  localizedHistory: LocalizedHistoryAction[];

  leftTeam$: Observable<Team>;

  rightTeam$: Observable<Team>;

  teamSides: TeamSides;

  timeKeeping$: Observable<TimeKeeping>;

  numberOfTimeoutsByTeamSides$: Observable<ByTeamSide<number>>;

  setPoints$: Observable<SetPointsByTeamSides>;

  matchSets: MatchSets;

  serving$: Observable<Serving>;

  lineupsByTeamSides$: Observable<fromIndoor.LineupsByTeamSides>;

  challengesByTeamSides$: Observable<fromIndoor.ChallengesByTeamSides>;

  areBothTeamsReady$: Observable<boolean>;

  isLockStartingSixDisabled$: Observable<boolean>;

  teamSquadsByTeamSides$: Observable<fromIndoor.TeamSquadsByTeamSides>;

  setScore$: Observable<SetScore>;

  setScoreByTeamSides: SetScoreByTeamSides;

  currentSetNumber: number;

  currentSetIndex: number;

  isMatchFinished$: Observable<boolean>;

  isSetRunning$: Observable<boolean>;

  isSetFinished$: Observable<boolean>;

  gameStates$: Observable<GameStates>;

  isDecidingSet: boolean;

  sanctionsByTeamSides$: Observable<SanctionsByTeamSides>;

  injuriesByTeamSides$: Observable<fromIndoor.InjuriesByTeamSides>;

  liberosByTeamSides$: Observable<fromIndoor.LiberosByTeamSides>;

  mvpsByTeamSides$: Observable<fromIndoor.MvpsByTeamSides>;

  isMvpSubmissionFinished$: Observable<boolean>;

  isGameStateTouched$: Observable<boolean>;

  numOfSpectators$: Observable<number>;

  maxTimeoutsPerSet$: Observable<number>;

  isStartingSixLocked$: Observable<boolean>;

  liberoRegistrationEnabled$: Observable<boolean>;

  currentSetPauseStart$: Observable<number>;

  wasTeamTimeoutTakenBeforeScore$: Observable<boolean>;

  matchState: MatchState;

  hasSetStarted: boolean;

  hasMatchStarted: boolean;

  listening: boolean;

  private subscription = new Subscription();

  constructor(
    public store: Store<IndoorRoot>,
    public dispatchService: DispatchService,
    public dialog: MatDialog,
    public router: Router,
    public translate: TranslateService) {
  }

  ngOnInit() {

    this.subscription.add(this.store.select(fromShared.getListening).subscribe(listening => this.listening = listening));

    this.subscription.add(this.store.select(fromIndoor.getMatch).subscribe(match => this.match = match));
    
    this.gameHistory$ = this.store.select(fromIndoor.getGameHistory);

    this.timeKeeping$ = this.store.select(fromIndoor.getTimeKeeping);

    this.numberOfTimeoutsByTeamSides$ = this.store.select(fromIndoor.getNumberOfTimeoutsByTeamSides);

    this.subscription.add(
      this.store.select(fromIndoor.getTeamSides)
        .subscribe(teamSides => this.teamSides = teamSides)
    );

    this.subscription.add(
      this.store.select(fromIndoor.isDecidingSet).subscribe(isDecidingSet => this.isDecidingSet = isDecidingSet)
    );

    this.leftTeam$ = this.store.select(fromIndoor.getLeftTeam);

    this.rightTeam$ = this.store.select(fromIndoor.getRightTeam);

    this.setPoints$ = this.store.select(fromIndoor.getSetPointsByTeamSides);

    this.subscription.add(
      this.store.select(fromIndoor.getMatchSets).subscribe(matchSets => this.matchSets = matchSets)
    );

    this.serving$ = this.store.select(fromIndoor.getServing);

    this.teamSquadsByTeamSides$ = this.store.select(fromIndoor.getTeamSquadsByTeamSides);

    this.lineupsByTeamSides$ = this.store.select(fromIndoor.getLineupsByTeamSides);

    this.challengesByTeamSides$ = this.store.select(fromIndoor.getChallengesByTeamSides);

    this.subscription.add(this.store.select(fromIndoor.getComments).subscribe(comments => this.comments = comments));

    this.subscription.add(
      this.store.select(fromIndoor.getCurrentMatchSet).subscribe(currentMatchSet => this.currentMatchSet = currentMatchSet)
    );

    this.subscription.add(
      this.store.select(fromIndoor.getMatchState).subscribe(matchState => this.matchState = matchState)
    );

    this.subscription.add(
      this.gameHistory$.subscribe((h) => {
        this.localizedHistory = h.map(a => new LocalizedHistoryActionBuilder(this.matchState, a).build()).reverse();
      })
    );

    const isLeftTeamReady$ = this.lineupsByTeamSides$.pipe(
      map(lineups => lineups.leftTeam.current.filter(x => x).length === this.totalPlayersOnField));

    const isRightTeamReady$ = this.lineupsByTeamSides$.pipe(
      map(lineups => lineups.rightTeam.current.filter(x => x).length === this.totalPlayersOnField));

    this.areBothTeamsReady$ = isLeftTeamReady$.pipe(combineLatest(isRightTeamReady$, (x, y) => x && y));

    this.isLockStartingSixDisabled$ = this.store.select(fromIndoor.getAreDefaultLiberosSet)
      .pipe(combineLatest(this.areBothTeamsReady$,
        (defaultLiberosSet, bothTeamsReady) => !defaultLiberosSet || !bothTeamsReady))

    this.setScore$ = this.store.select(fromIndoor.getCurrentSetScore);

    this.subscription.add(
      this.store.select(fromIndoor.getCurrentSetScoreByTeamSides).subscribe(setScore => this.setScoreByTeamSides = setScore)
    );

    this.isMatchFinished$ = this.store.select(fromIndoor.getIsMatchFinished);

    const now$ = observableTimer(0, 1000).pipe(map(_ => +moment()));

    const isSetRunning = (now: number, timeKeeping: TimeKeeping) => {
      return this.setStarted(timeKeeping) &&
        timeKeeping.currentPauseStartTime === 0 &&
        timeKeeping.timeoutStartTimeMs + this.timeoutDurationMs < now &&
        (this.technicalTimeoutDurationMs === 0 || ((timeKeeping.technicalTimeoutStartTimeMs + this.technicalTimeoutDurationMs) < now));
    };

    this.isSetRunning$ = now$.pipe(
      combineLatest(this.timeKeeping$, isSetRunning),
      combineLatest(this.isMatchFinished$, (isSetRunning, isMatchFinished) => isSetRunning && !isMatchFinished),);

    this.subscription.add(
      this.store.select(fromIndoor.getCurrentSetNumber).subscribe(currentSetNumber => this.currentSetNumber = currentSetNumber)
    );

    this.subscription.add(
      this.store.select(fromIndoor.getCurrentSetIndex).subscribe(currentSetIndex => this.currentSetIndex = currentSetIndex)
    );

    this.isSetFinished$ = this.store.select(fromIndoor.getCurrentSetIndex).pipe(
      bufferCount(2, 1), // this prevents emitting true when undoing a finished set
      map(([previousSetNumber, currentSetIndex]) => currentSetIndex > previousSetNumber),);


    // Promise workaround see: https://github.com/angular/angular/issues/15634
    Promise.resolve().then(() => {
      this.subscription.add(
        this.isSetFinished$.pipe(filter(x => x)).subscribe(_ => this.openSetFinishDialog())
      );
  
      this.subscription.add(
        this.isMatchFinished$.pipe(filter(x => x)).subscribe(_ => this.openInfoDialog(
          this.translate.get('component.match-view.match_finished'),
            this.mvpSubmissionEnabled
              ? this.singleTeamMvpSubmissionEnabled
                ? this.translate.get('component.match-view.match_finished_single_mvp_dialog_message')
                : this.translate.get('component.match-view.match_finished_mvps_dialog_message')
              : this.translate.get('component.match-view.match_finished_dialog_message')
        , this.translate.get('app.ok')))
      );

      this.subscription.add(
        this.challengesByTeamSides$.pipe(filter(x => !!x)).subscribe(challenges => {
          if (challenges.leftTeam.pendingChallenge) {
            this.openChallengeDialog(challenges.leftTeam.pendingChallenge, this.leftTeam);
          } else if (challenges.rightTeam.pendingChallenge) {
            this.openChallengeDialog(challenges.rightTeam.pendingChallenge, this.rightTeam);
          }
        })
      )
    })

    this.subscription.add(
      this.store.select(fromShared.getMatchId).subscribe(id => this.matchId = id)
    );

    this.gameStates$ = this.store.select(fromIndoor.getGameStates);

    this.sanctionsByTeamSides$ = this.store.select(fromIndoor.getSanctionsByTeamSides);

    this.injuriesByTeamSides$ = this.store.select(fromIndoor.getInjuriesByTeamSides);

    this.liberosByTeamSides$ = this.store.select(fromIndoor.getLiberosByTeamSides);

    this.mvpsByTeamSides$ = this.store.select(fromIndoor.getMvpsByTeamSides);

    this.isMvpSubmissionFinished$ = this.store.select(fromIndoor.getIsMvpsSubmissionFinished);

    this.isGameStateTouched$ = this.store.select(fromIndoor.isGameStateTouched);

    this.numOfSpectators$ = this.store.select(fromIndoor.getNumOfSpectators);

    this.maxTimeoutsPerSet$ = this.store.select(fromIndoor.getMaxTimeoutsPerSet);

    const hasMatchStarted$ = this.store.select(fromIndoor.getHasMatchStarted)

    this.isStartingSixLocked$ = this.store.select(fromIndoor.isStartingSixLocked);

    this.liberoRegistrationEnabled$ = this.store.select(fromIndoor.isLiberoRegistrationEnabled);

    this.wasTeamTimeoutTakenBeforeScore$ = this.store.select(fromIndoor.wasTeamTimeoutTakenBeforeScore);

    this.subscription.add(
      this.store.select(fromIndoor.getScoreConfiguration).subscribe(scoreConfiguration => this.scoreConfiguration = scoreConfiguration)
    );

    this.subscription.add(
      this.store.select(fromIndoor.getMatchConfiguration).subscribe(matchConfig => this.matchConfiguration = matchConfig)
    );
    
    this.subscription.add(
      hasMatchStarted$.subscribe(hasMatchStarted => this.hasMatchStarted = hasMatchStarted)
    );

    this.subscription.add(
      this.store.select(fromIndoor.getHasCurrentSetStarted).subscribe(hasSetStarted => this.hasSetStarted = hasSetStarted)
    );

    this.currentSetPauseStart$ = this.store.select(fromIndoor.getCurrentSetPauseStart)

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  navigateBack() {
    this.router.navigate(['match-preparation']);
  }

  navigateHome() {
    this.router.navigate(['matches-overview']);
  }

  finalizeMatch() {
    this.router.navigate(['match-finalization']);
  }

  switchSides() {
    this.dispatchService.dispatchRemoteAction(new SwitchSidesAction(this.matchId));
  }

  changeServing() {
    this.dispatchService.dispatchRemoteAction(new ChangeServingAction(this.matchId));
  }

  lockStartingSix() {
    this.dispatchService.dispatchRemoteAction(new LockStartingSixAction(this.matchId))
  }

  incrementScore(teamCode: TeamCode) {
    this.dispatchService.dispatchRemoteAction(new ScoreAction(teamCode, this.matchId));
  }

  overrideScore(score: ByTeamSide<number>) {
    const team1 = TeamCodes.team1 === this.teamSides.leftTeam ? score.leftTeam : score.rightTeam;
    const team2 = TeamCodes.team2 === this.teamSides.rightTeam ? score.rightTeam : score.leftTeam;
    this.dispatchService.dispatchRemoteAction(new ScoreOverrideAction({ team1, team2 }, this.matchId));
  }

  undo(nStates: number) {
    if (!AmbientHelpers.isAnyDialogOpen()) {
      this.dispatchService.dispatchRemoteAction(new UndoAction(nStates, this.matchId));
    }
  }

  redo(nStates: number) {
    if (!AmbientHelpers.isAnyDialogOpen()) {
      this.dispatchService.dispatchRemoteAction(new RedoAction(nStates, this.matchId));
    }
  }

  onStart(timestamp: number) {
    if (this.hasMatchStarted) {
      this.startSet(timestamp);
    } else {
      this.startMatch(timestamp);
    }
  }

  startSet(timestamp: number) {
    this.dispatchService.dispatchRemoteAction(new StartSetAction(timestamp, this.matchId));
  }

  startMatch(timestamp: number) {
    const startTimeMs = +moment(this.match.date);
    this.dispatchService.dispatchRemoteAction(new StartMatchAction({ startTimeMs, actualStartTimeMs: timestamp }, this.matchId));
  }

  onSubstitution(substitution: Substitution) {
    this.dispatchService.dispatchRemoteAction(new SubstitutionAction(substitution, this.matchId));
  }

  onNomination(nomination: Nomination) {
    this.dispatchService.dispatchRemoteAction(new NominationAction(nomination, this.matchId));
  }

  timeout(timeout: Timeout) {
    this.dispatchService.dispatchRemoteAction(new StartTimeoutAction(timeout, this.matchId));
  }

  onTeamWarning(payload: TeamSanction) {
    this.dispatchService.dispatchRemoteAction(new TeamWarningAction(payload, this.matchId));
  }

  onTeamPenalty(payload: TeamPenalty) {
    this.dispatchService.dispatchRemoteAction(new TeamPenaltyAction(payload, this.matchId));
  }

  onImproperRequest(payload: TeamSanction) {
    this.dispatchService.dispatchRemoteAction(new TeamImproperRequestAction(payload, this.matchId));
  }

  onCancelSetPause() {
    this.dispatchService.dispatchRemoteAction(new CancelSetPauseAction(this.matchId));
  }

  onCancelTimeout() {
    this.dispatchService.dispatchRemoteAction(new CancelTimeoutAction(this.matchId));
  }

  onCancelTechnicalTimeout() {
    this.dispatchService.dispatchRemoteAction(new CancelTechnicalTimeoutAction(this.matchId));
  }

  private openInfoDialog(title: Observable<string>, content: Observable<string>, buttonText: Observable<string>) {
    if (this.listening) {
      return;
    }
    const dialogRef = this.dialog.open(InfoDialogComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.content = content;
    dialogRef.componentInstance.buttonText = buttonText;
    return dialogRef;
  }

  openCommentsDialog() {
    const data: CommentsDialogData = {
      comments: this.comments
    }
    const dialogRef: MatDialogRef<CommentsDialogComponent, string> = this.dialog.open(CommentsDialogComponent, { data });
    dialogRef.afterClosed().pipe(
      first(),
      filter(result => result !== null),)
      .subscribe(result => this.onCloseCommentsDialog(result));
  }

  openManualChangesDialog() {
    const config: ManualChangesDialogConfig = {
      pointsToWinSet: this.matchConfiguration.pointsToWinSet,
      pointsToWinDecidingSet: this.matchConfiguration.pointsToWinDecidingSet,
      minimumPointDifference: this.matchConfiguration.minimumPointDifference
    }
    const data: ManualChangesDialogData = {
      setScore: this.setScoreByTeamSides,
      leftTeamShortName: this.leftTeam.shortName,
      rightTeamShortName: this.rightTeam.shortName,
      config,
      isDecidingSet: this.isDecidingSet
    }
    const dialogRef: MatDialogRef<ManualChangesDialogComponent, ManualChangesDialogResult>
      = this.dialog.open(ManualChangesDialogComponent, { data });

    dialogRef.afterClosed().pipe(
      first(),
      filter(result => result !== null),)
      .subscribe(result => this.overrideScore(result));
  }

  private openChallengeDialog(challenge: Challenge, team: Team) {
    if (this.listening) {
      return;
    }
    const dialogRef = this.dialog.open(ChallengeDialogComponent);
    dialogRef.componentInstance.challenge = challenge
    dialogRef.componentInstance.team = team
    dialogRef.afterClosed().pipe(
      first())
      .subscribe(result => this.onCloseChallengeDialog(result));
    return dialogRef;
  }

  private onCloseChallengeDialog(challenge: Challenge) {
    if (!challenge) {
      this.undo(1)
    } else if (challenge.successful) {
      this.onChallengeSuccess(challenge);
    } else if (!challenge.successful) {    
      this.onChallengeDeclined(challenge);
    }
  }

  onChallengeSuccess(challenge: Challenge) {
    this.dispatchService.dispatchRemoteAction(new ChallengeSuccessAction(challenge, this.matchId))
  }

  onChallengeDeclined(challenge: Challenge) {
    this.dispatchService.dispatchRemoteAction(new ChallengeDeclinedAction(challenge, this.matchId))
  }


  openScoresheetView() {
    new ScoresheetPresenter(this.matchState).openScoresheetView();
  }

  openTeamSquadsView() {
    new TeamSquadsPresenter(this.matchState).openTeamSquadsView();
  }

  private onCloseCommentsDialog(result: string) {
    this.dispatchService.dispatchRemoteAction(new SaveCommentsAction(result, this.matchId));
  }

  get leftTeam() {
    return this.match[this.teamSides.leftTeam];
  }

  get rightTeam() {
    return this.match[this.teamSides.rightTeam];
  }

  get TeamSideValues() {
    return TeamSideValues;
  }

  setStarted(timeKeeping: TimeKeeping) {
    return timeKeeping.actualStartTimeMs !== 0;
  }

  // TODO: refactor this subscription is never unsubbed
  private openSetFinishDialog() {
    if (this.listening) {
      return;
    }
    let dialog;
    if (this.currentSetIndex === this.setNumberChangeSetPauseDuration &&
      (this.scoreConfiguration.pauseDurationAfterSecondSet.valueOne || this.scoreConfiguration.pauseDurationAfterSecondSet.valueTwo)) {

      dialog = this.dialog.open(SetFinishedDurationDialogComponent, { data: this.scoreConfiguration });
      dialog.componentInstance.onDurationChange.subscribe(
        duration => this.dispatchService.dispatchRemoteAction(new ChangeSetPauseDurationAction({ newDuration: duration }, this.matchId))
      );
    } else {
      if (this.currentSetIndex === 1 && this.scoreConfiguration.numberOfSpectatorsSubmissionMandatory) {
        dialog = this.dialog.open(SetFinishedSpectatorCountDialogComponent, { data: this.scoreConfiguration });
      } else {
        dialog = this.openInfoDialog(this.translate.get('app.set_finished'),
          this.translate.get('component.match-view.set_finished_dialog_message'),
          this.translate.get('app.ok'));
      }

    }
    return dialog;
  }

  trackActionHistory(index: number, action: LocalizedHistoryAction) {
    return action.uuid;
  }

  get mvpSubmissionEnabled() {
    return this.matchConfiguration.mvpSubmissionEnabled;
  }

  get singleTeamMvpSubmissionEnabled() {
    return this.matchConfiguration.singleTeamMvpSubmissionEnabled;
  }

  get technicalTimeoutDurationMs() {
    return this.scoreConfiguration.technicalTimeoutDurationSeconds * 1000;
  }

  get timeoutDurationMs() {
    return this.scoreConfiguration.timeoutDurationSeconds * 1000;
  }

  get totalPlayersOnField() {
    return this.scoreConfiguration.totalPlayersOnField;
  }

  get areHighlightsEnabled() {
    return this.scoreConfiguration.additionalManualEventLog;
  }

  get jerseyColorSelectionEnabled() {
    return this.scoreConfiguration.jerseyColorSelectionEnabled;
  }

  get videoChallengeAvailable() {
    return this.scoreConfiguration.videoChallengeAvailable;
  }

  getSelectedJersey(team: Team) {
    return team.jerseys[team.selectedJersey]
  }

  getNumOfSubstitutionsClass(numOfSubstitutions: number) {
    switch(numOfSubstitutions) {
      case this.scoreConfiguration.maxRegularSubstitutionsPerSet:
        return 'exceeded'
      case this.scoreConfiguration.maxRegularSubstitutionsPerSet - 1:
        return 'warning'
    }
  }

  get isSmallField() {
    return isSmallField(this.scoreConfiguration)
  }

}
