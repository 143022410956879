export interface Jersey {
  shirtColorHex: string
  shortsColorHex: string
}

export interface Jerseys {
  home?: Jersey
  away?: Jersey
  custom: Jersey
}

export type SelectedJersey = "home" | "away" | "custom"

export const JERSEY_TYPE = {
  home: "home",
  away: "away",
  custom: "custom"
}
