import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { Player, Team } from 'app/models';

export interface LiberoInjuredDialogData {
  team: Team;
  title: Observable<string>;
  availablePlayers: Player[];
  availableLiberos: Player[];
}

export interface LiberoInjuredDialogSelect {
  player: Player;
  isLibero: boolean;
}

export type LiberoInjuredDialogResult = LiberoInjuredDialogSelect | "UNDO";

@Component({
  selector: 'sams-libero-injured-dialog',
  template: `
    <div class="libero-injured-dialog">

      <div class="dialog-header">
        <sams-logo [src]="team.logoImageMax400x400?.url" [alt]="team.name" [letter]="team.singleLetterName" size="80px"></sams-logo>
        <div>
          <h1 mat-dialog-title>{{team.name}}</h1>
          <h1 mat-dialog-title>{{title | async}}</h1>
        </div>
      </div>

      <div class="player-selections">
        <div class="player-select-col">
          <sams-player-select
            availablePlayersTitle="component.libero-injured-dialog.available_players"
            [availablePlayers]="availablePlayers"
            (playerSelected)="onPlayerSelected($event)">
          </sams-player-select>
          <button mat-raised-button
            [ngClass]="{ 'positive': selectedPlayer }"
            [disabled]="!selectedPlayer"
            (click)="onConfirmSubstitution()">
            {{'component.libero-injured-dialog.substitute' | translate}}
          </button>
        </div>

        <div class="player-select-col">
          <sams-player-select
            availablePlayersTitle="component.libero-injured-dialog.available_liberos"
            [availablePlayers]="availableLiberos"
            (playerSelected)="onNewLiberoSelected($event)">
          </sams-player-select>
          <button mat-raised-button
            [ngClass]="{ 'new-libero': selectedLibero }"
            [disabled]="!selectedLibero"
            (click)="onConfirmNewLibero()">
            {{'component.libero-injured-dialog.new_libero' | translate}}
          </button>
        </div>

        <div class="player-select-col">
          <div></div>
          <button mat-raised-button class="negative" (click)="onUndo()">{{'app.undo' | translate}}</button>
        </div>

      </div>

    </div>
  `,
  styles: [`

    .dialog-header {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    h1 {
      margin: 0;
      margin-left: 10px;
    }

    .player-select-col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 50px;
    }

    button {
      margin-top: 15px;
    }

    .player-selections {
      display: flex;
      justify-content: space-around;
    }

    .new-libero {
      background-color: gold;
    }

  `]
})
export class LiberoInjuredDialogComponent implements OnInit {

  team: Team;

  title: Observable<string>;

  availablePlayers: Player[];

  availableLiberos: Player[];

  selectedPlayer: Player;

  selectedLibero: Player;

  constructor(public dialogRef: MatDialogRef<LiberoInjuredDialogComponent, LiberoInjuredDialogResult>, @Inject(MAT_DIALOG_DATA) private data: LiberoInjuredDialogData) {
    this.availablePlayers = data.availablePlayers;
    this.title = data.title;
    this.team = data.team;
    this.availableLiberos = data.availableLiberos;
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
  }

  onConfirmSubstitution() {
    const result: LiberoInjuredDialogResult = { player: this.selectedPlayer, isLibero: false };
    this.dialogRef.close(result);
  }

  onConfirmNewLibero() {
    const result: LiberoInjuredDialogResult = { player: this.selectedLibero, isLibero: true };
    this.dialogRef.close(result);
  }

  onUndo() {
    this.dialogRef.close("UNDO");
  }

  get isConfirmable() {
    return (this.selectedLibero || this.selectedPlayer) && !(this.selectedLibero && this.selectedPlayer);
  }

  onPlayerSelected(p: Player) {
    this.selectedPlayer = p;
  }

  onNewLiberoSelected(p: Player) {
    this.selectedLibero = p;
  }

}
