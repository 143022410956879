import { Nationality } from './nationality';
import { UploadedImage } from './uploaded-image';

export type TeamOfficialFunction = 'TRAINER' | 'TRAINER_ASSISTANT' | 'TRAINER_ASSISTANT_SCOUT' | 'PHYSICIAN' | 'PHYSIOTHERAPIST'

export const TeamOfficialFunctions = {
  TRAINER                 : 'TRAINER',
  TRAINER_ASSISTANT       : 'TRAINER_ASSISTANT',
  TRAINER_ASSISTANT_SCOUT : 'TRAINER_ASSISTANT_SCOUT',
  PHYSICIAN               : 'PHYSICIAN',
  PHYSIOTHERAPIST         : 'PHYSIOTHERAPIST'
}

export interface Official {
  uuid: string;
  personUuid: string;
  firstName: string;
  lastName: string;
  nationality: Nationality;
  function: TeamOfficialFunction;
  portraitPhoto: UploadedImage;
  portraitPhotoMax600x600: UploadedImage;
  // score TODO: dont store in model
  isNewOfficial?: boolean;
  approved?: boolean;
}

export interface ManuallyCreatedOfficial {
  uuid: string; // throw away uuid
  personUuid: string; // throw away personUuid
  firstName: string;
  lastName: string;
  function: TeamOfficialFunction;
}
