import { ErrorComponent } from './error.component';
import { ErrorService } from './error.service';

export const ERRROR_COMPONENTS = [
  ErrorComponent
];

export const ERROR_SERVICES = [
  ErrorService
];
