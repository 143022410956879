import { MatchAction } from "../../../../interfaces/interfaces.js";
import { TeamCode } from "../../../../interfaces/models/team-codes.js";
import { SetScore } from "../../match-sets/set-score/set-score.reducer.js";

export const TEAM_WARNING = '[Sanction] Team Warning';
export const TEAM_PENALTY = '[Sanction] Team Penalty';
export const TEAM_IMPROPER_REQUEST = '[Sanction] Team Improper Request';

export interface TeamSanction {
  teamCode: TeamCode;
  setScore: SetScore;
  currentSetNumber: number;
}

export interface TeamPenalty extends TeamSanction {
  isMatchFinished: boolean;
}

export class TeamWarningAction extends MatchAction {
  readonly type = TEAM_WARNING;
  constructor(public payload: TeamSanction, matchId: string) {
    super(matchId);
  }
}

export class TeamImproperRequestAction extends MatchAction {
  readonly type = TEAM_IMPROPER_REQUEST;
  constructor(public payload: TeamSanction, matchId: string) {
    super(matchId);
  }
}

export class TeamPenaltyAction extends MatchAction {
  readonly type = TEAM_PENALTY;
  constructor(public payload: TeamPenalty, matchId: string) {
    super(matchId);
  }
}

export type TeamSanctionAction
  = TeamWarningAction
  | TeamImproperRequestAction
  | TeamPenaltyAction;
