import { MatchAction } from '../../../../interfaces/interfaces.js';
import { BeachMatchSignatureAction } from '../signature/beach-signature.action.js';

export const BEACH_CONFIRM_FINALIZATION         = '[Match] finalization confirmation';
export const BEACH_CONFIRM_FINALIZATION_SUCCESS = '[Match] finalization success';
export const BEACH_CONFIRM_FINALIZATION_FAIL    = '[Match] finalization fail';

export interface BeachConfirmFinalizationPayload {
}

export interface BeachConfirmFinalizationFailPayload {
  status: number
  message: string
}

export class BeachConfirmFinalizationAction extends MatchAction {
  readonly type = BEACH_CONFIRM_FINALIZATION;
  constructor(public payload: BeachConfirmFinalizationPayload, matchId: string) {
    super(matchId);
  }
}

export class BeachConfirmFinalizationSuccessAction extends MatchAction {
  readonly type = BEACH_CONFIRM_FINALIZATION_SUCCESS;
  constructor(matchId: string) {
    super(matchId);
  }
}

export class BeachConfirmFinalizationFailAction extends MatchAction {
  readonly type = BEACH_CONFIRM_FINALIZATION_FAIL;
  constructor(public payload: BeachConfirmFinalizationFailPayload, matchId: string) {
    super(matchId);
  }
}


export type BeachMatchFinalizationAction =
    BeachConfirmFinalizationAction
  | BeachConfirmFinalizationSuccessAction
  | BeachConfirmFinalizationFailAction
  | BeachMatchSignatureAction;
