import { MatchAction } from '../../../../interfaces/interfaces.js';
import { TeamCode } from '../../../../interfaces/models/team-codes.js';

export const SUBSTITUTION             = '[Lineups] Substitution';
export const DENOMINATION             = '[Lineups] Cancel nomination';
export const NOMINATION               = '[Lineups] Nominate';
export const LIBERO_IN                = '[Lineups] Libero in';
export const LIBERO_OUT               = '[Lineups] Libero out';
export const LIBERO_SWITCH            = '[Lineups] Libero switch';
export const EXCEPTIONAL_SUBSTITUTION = '[Lineups] Exceptional Substitution';
export const ROTATE_CLOCKWISE         = '[Lineups] Rotate clockwise';
export const ROTATE_ANTICLOCKWISE     = '[Lineups] Rotate anticlockwise';

export interface Substitution {
  teamCode: TeamCode;
  playerOutIndex: number;
  playerOutId: string;
  playerInId: string;
}

export interface ExceptionalSubstitution extends Substitution {
  isAllowedToPlayNextSets: boolean;
}

export interface LiberoIn {
  teamCode: TeamCode;
  liberoId: string;
  liberoSubstitute: string;
}

export interface LiberoOut {
  liberoId: string;
  playerInId: string;
  teamCode: TeamCode;
}

export interface LiberoSwitch {
  teamCode: TeamCode;
  liberoOutId: string;
  liberoInId: string;
}

export interface Denomination {
  teamCode: TeamCode;
  playerIndex: number;
}

export interface Nomination {
  teamCode: TeamCode;
  playerOutIndex: number;
  playerInId: string;
}

export class ExceptionalSubstitutionAction extends MatchAction {
  readonly type = EXCEPTIONAL_SUBSTITUTION;
  constructor(public payload: ExceptionalSubstitution, matchId: string) {
    super(matchId);
  }
}

export class SubstitutionAction extends MatchAction {
  readonly type = SUBSTITUTION;
  constructor(public payload: Substitution, matchId: string) {
    super(matchId);
  }
}

export class LiberoInAction extends MatchAction {
  readonly type = LIBERO_IN;
  constructor(public payload: LiberoIn, matchId: string) {
    super(matchId);
  }
}

export class LiberoOutAction extends MatchAction {
  readonly type = LIBERO_OUT;
  constructor(public payload: LiberoOut, matchId: string) {
    super(matchId);
  }
}

export class LiberoSwitchAction extends MatchAction {
  readonly type = LIBERO_SWITCH;
  constructor(public payload: LiberoSwitch, matchId: string) {
    super(matchId);
  }
}

export class NominationAction extends MatchAction {
  readonly type = NOMINATION;
  constructor(public payload: Nomination, matchId: string) {
    super(matchId);
  }
}

export class DenominationAction extends MatchAction {
  readonly type = DENOMINATION;
  constructor(public payload: Denomination, matchId: string) {
    super(matchId);
  }
}

export class RotateClockwiseAction extends MatchAction {
  readonly type = ROTATE_CLOCKWISE;
  constructor(public payload: TeamCode, matchId: string) {
    super(matchId);
  }
}

export class RotateAntiClockwiseAction extends MatchAction {
  readonly type = ROTATE_ANTICLOCKWISE;
  constructor(public payload: TeamCode, matchId: string) {
    super(matchId);
  }
}

export type LineupsAction
  = SubstitutionAction
  | ExceptionalSubstitutionAction
  | DenominationAction
  | NominationAction
  | LiberoInAction
  | LiberoOutAction
  | LiberoSwitchAction
  | RotateClockwiseAction
  | RotateAntiClockwiseAction;
