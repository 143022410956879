
import moment from 'moment';

import {
  TimeKeepingAction,
  START_SET,
  END_SET,
  START_TIMEOUT,
  SET_TIME_KEEPING
} from './time-keeping.action.js';
import { ByTeamCode } from "../../../../interfaces/models/team-codes.js";
import {
  START_TECHNICAL_TIMEOUT,
  SET_START_TIME,
  START_MATCH,
  CANCEL_TECHNICAL_TIMEOUT,
  CANCEL_TIMEOUT,
  CANCEL_SET_PAUSE,
  CHANGE_SET_PAUSE_DURATION
} from './time-keeping.action.js';
import { INITIALIZE_MATCH, InitializeMatchAction } from '../../../match-states/match-state.action.js';

export interface TimeoutHistory {
  scoreSelf: number,
  scoreOther: number,
  startTimeMs: number
}

export interface TimeKeeping {
  startTimeMs: number;
  actualStartTimeMs: number;
  pausedTimeMs: number;
  currentPauseStartTime: number;
  endTimeMs: number;
  timeoutStartTimeMs: number;
  technicalTimeoutStartTimeMs: number;
  numberOfTimeouts: ByTeamCode<number>;
  timeoutHistory: ByTeamCode<TimeoutHistory[]>;
  setPauseDurationMs: number;
  setPauseCancelled: boolean;
}


export interface TimeKeepingAdditionalState {
  setPauseDurationSeconds: number
}

export const initialState: TimeKeeping = {
  startTimeMs: 0,
  actualStartTimeMs: 0,
  pausedTimeMs: 0,
  currentPauseStartTime: 0,
  endTimeMs: 0,
  timeoutStartTimeMs: 0,
  technicalTimeoutStartTimeMs: 0,
  numberOfTimeouts: { team1: 0, team2: 0 },
  timeoutHistory: { team1: [], team2: [] },
  setPauseDurationMs: 0,
  setPauseCancelled: false
}

export const timeKeepingReducer = (state: TimeKeeping = initialState, action: TimeKeepingAction | InitializeMatchAction): TimeKeeping => {

  switch (action.type) {

    // TODO: BEACH-SCORE does this work???
    case INITIALIZE_MATCH: {
      const { setPauseDurationSeconds } = action.payload.scoreConfiguration
      return {
        ...state,
        setPauseDurationMs: setPauseDurationSeconds * 1000,
      }
    }

    case SET_TIME_KEEPING: {
      const { setPauseDurationSeconds } = action.payload
      return {
        ...state,
        setPauseDurationMs: setPauseDurationSeconds * 1000,
      }
    }

    case START_MATCH: {
      return {
        ...state,
        startTimeMs: action.payload.startTimeMs,
        actualStartTimeMs: action.payload.actualStartTimeMs
      };
    }
    case START_SET:
      return {
        ...state,
        actualStartTimeMs: action.payload
      };
    case SET_START_TIME:
      return {
        ...state,
        startTimeMs: action.payload
      };
    case END_SET:
      return {
        ...state,
        endTimeMs: action.payload
      };
    case CHANGE_SET_PAUSE_DURATION:
      return {
        ...state,
        startTimeMs: state.startTimeMs + action.payload.newDuration - state.setPauseDurationMs,
        setPauseDurationMs: action.payload.newDuration,
      };
    case CANCEL_SET_PAUSE:
      return {
        ...state,
        setPauseCancelled: true
      };
    case START_TIMEOUT:
      const teamCode = action.payload.teamCode;
      const { scoreSelf, scoreOther, timestamp } = action.payload;
      const historyEntry: TimeoutHistory = {
        scoreSelf, scoreOther, startTimeMs: timestamp
      };
      const newHistory = [...state.timeoutHistory[teamCode], historyEntry];
      return {
        ...state,
        numberOfTimeouts: {
          ...state.numberOfTimeouts,
          [teamCode]: state.numberOfTimeouts[teamCode] + 1
        },
        timeoutHistory: {
          ...state.timeoutHistory,
          [teamCode]: newHistory,
        },
        timeoutStartTimeMs: action.payload.timestamp
      };
    case CANCEL_TIMEOUT:
      return {
        ...state,
        timeoutStartTimeMs: 0
      };
    case START_TECHNICAL_TIMEOUT:
      return {
        ...state,
        technicalTimeoutStartTimeMs: action.payload
      };
    case CANCEL_TECHNICAL_TIMEOUT:
      return {
        ...state,
        technicalTimeoutStartTimeMs: 0
      };
    default:
      return state;
  }
};

// --- selectors ---
export const getTotalDurationMs = (state: TimeKeeping) => {
  if (state.startTimeMs === 0) {
    return 0;
  }
  const duration = ((state.endTimeMs ? state.endTimeMs : new Date().getTime()) - state.startTimeMs - state.pausedTimeMs);
  if (duration < 0) {
    return 0;
  }
  return duration;
}
export const getTotalDurationSeconds = (state: TimeKeeping) => Math.round(getTotalDurationMs(state) / 1000);
export const getStartTimeMs = (state: TimeKeeping) => state.startTimeMs;
export const getStartTimeMoment = (state: TimeKeeping) => moment.unix(state.startTimeMs / 1000);
export const getEndTimeMoment = (state: TimeKeeping) => moment.unix(state.endTimeMs / 1000);

export const getCurrentPauseStartTime = (state: TimeKeeping) => state.currentPauseStartTime;
export const getPausedTimeMs = (state: TimeKeeping) => state.pausedTimeMs;
export const getNumberOfTimeouts = (state: TimeKeeping) => state.numberOfTimeouts;
