import { StartingSixAction, LOCK_STARTING_SIX } from './starting-six.action.js';

export interface StartingSix {
  locked: boolean
}

export const initialState: StartingSix = {
  locked: false
};

export const startingSixReducer = (state = initialState, action: StartingSixAction) => {
  switch (action.type) {
    case LOCK_STARTING_SIX:
      return {
        ...state,
        locked: true
      }
    default:
      return state;
  }
};

export const isStartingSixLocked = (state: StartingSix) => state.locked;
