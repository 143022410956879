import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sams-match-finalized-warning-dialog',
  template: `
    <h2 mat-dialog-title style="text-align: center;">{{'component.matches-overview.match_finalized_warning' | translate}}</h2>
    <p style="text-align: center;">{{'component.matches-overview.match_finalized_warning_info' | translate}}</p>
    <div mat-dialog-actions>
      <button mat-raised-button color="warn" (click)="onClick(true)">{{'app.confirm' | translate}}</button>
      <button mat-raised-button (click)="onClick(false)">{{'app.cancel' | translate}}</button>
    </div>
  `,
  styles: [`
    .mat-dialog-actions {
      display: flex;
      justify-content: center;
    }
  `]
})
export class MatchFinalizedWarningDialogComponent {

  constructor(public dialogRef: MatDialogRef<MatchFinalizedWarningDialogComponent>) {
    dialogRef.disableClose = true;
  }
  onClick = (decision: boolean) => {
    this.dialogRef.close(decision);
  }
}
