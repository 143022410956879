import { Component, Input } from '@angular/core';

@Component({
  selector: 'sams-logo',
  template: `
    <div class="logo" mat-card-sm-image *ngIf="src" [ngStyle]="{'width': size, 'height': size, 'background-image': getUrl(src)}">
    </div>
    <div *ngIf="!src"
      [ngStyle]="{'width': size, 'height': size, 'font-size': size, 'line-height': size}"
      class="avatar-letter">
      {{letter ? letter : '?'}}
    </div>
  `,
  styles: [`
    div.logo {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    div.avatar-letter {
      max-width: auto;
      overflow: hidden;
      text-align: center;
      border-radius: 50%;
      background-color: #aaa;
      color: white;
      font-weight: bold;
      vertical-align: center;
    }
  `]
})
export class LogoComponent {

  @Input() src;

  @Input() alt;

  @Input() letter;

  @Input() size: string;

  constructor() {
  }

  getUrl(value: string): string {
    return `url(${value})`
  }

}
