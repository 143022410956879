import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sams-select-mvp',
  template: `
    <button [hidden]="hidden" [disabled]="disabled" (click)="selectMvp.emit(playerUuid)" mat-menu-item>
        <mat-icon class="mvp">star_rate</mat-icon>
        <span>{{'app.select_mvp' | translate}}</span>
    </button>
  `
})
export class SelectMvpComponent {

  @Input() playerUuid: string;

  @Input() hidden: boolean;

  @Input() disabled: boolean;

  @Output() selectMvp = new EventEmitter<string>();

}
