import {
  TeamSidesAction,
  SWITCH_SIDES,
  SwitchSidesAction,
  SET_SIDES
} from './team-sides.action.js';
import { ByTeamSide } from '../../../../interfaces/models/team-sides.js';
import { TeamCodes, TeamCode } from '../../../../interfaces/models/team-codes.js';

export type TeamSides = ByTeamSide<TeamCode>;

export const initialState: TeamSides = {
  leftTeam: TeamCodes.team1,
  rightTeam: TeamCodes.team2
};

export const switchSides = (state: TeamSides, action: SwitchSidesAction) => {
  return {
    ...state,
    leftTeam: state.rightTeam,
    rightTeam: state.leftTeam
  };
};

export const teamSidesReducer = (state = initialState, action: TeamSidesAction) => {
  switch (action.type) {
    case SWITCH_SIDES:
      return switchSides(state, action);
    case SET_SIDES: {
      return {
        ...state,
        ...action.payload.teamSides
      }
    }
    default:
      return state;
  }
};

// --- selectors ---
export const getLeftTeamCode = (state: TeamSides) => state.leftTeam;
export const getRightTeamCode = (state: TeamSides) => state.rightTeam;
