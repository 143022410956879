import { setScoreReducer } from './set-score/set-score.reducer.js';
import { MatchSetsAction } from './match-sets.action.js';
import { MatchSet, matchSetReducer, AdditionalMatchSetState, isSetStarted, isSetFinished, decidingSet } from './match-set.reducer.js';
import { teamSidesReducer } from './team-sides/team-sides.reducer.js';
import { SwitchSidesAction } from './team-sides/team-sides.action.js';
import { timeKeepingReducer } from './time-keeping/time-keeping.reducer.js';
import { EndSetAction, SetStartTimeAction } from './time-keeping/time-keeping.action.js';
import { servingReducer } from './serving/serving.reducer.js';
import { ChangeServingAction } from './serving/serving.action.js';
import { SCORE, SCORE_OVERRIDE, SetScoreAction, PENALTY_SCORE, ScoreAction } from './set-score/set-score.action.js';
import { GameStateAction } from '../game-state.action.js';
import { MatchConfiguration } from '../../../interfaces/models/match-configuration.js';
import { AdditionalGameState } from '../game-state.reducer.js';
import { TeamCodes } from '../../../interfaces/models/team-codes.js';

export interface SetPoints {
  team1: number
  team2: number
};

export type MatchSets = MatchSet[];

export const matchSetsReducer = (state: MatchSets, action: GameStateAction, additionalGameState: AdditionalGameState) => {

  const currentSetNumber = getCurrentSetNumber(state);
  const setScore = setScoreReducer(undefined, {} as ScoreAction)
  const newSetScore = setScoreReducer(setScore, action as SetScoreAction);

  const additionalMatchSetState: AdditionalMatchSetState = {
    isDecidingSet: state ? decidingSet(currentSetNumber, additionalGameState.matchConfig) : false,
    currentSetNumber,
    newSetScore,
    scoreConfig: additionalGameState.scoreConfig,
    matchConfig: additionalGameState.matchConfig
  };

  if (!state) {
    state = [matchSetReducer(undefined, {} as GameStateAction, additionalMatchSetState)];
  }

  if (action.type) {
    const currentMatchSet = state[state.length - 1];
    const currentSetIndex = state.length - 1;

    const nextState = [
      ...state.slice(0, currentSetIndex),
      matchSetReducer(currentMatchSet, action, additionalMatchSetState),
      ...state.slice(currentSetIndex + 1)
    ];

    return addSetReducer(nextState, action, additionalMatchSetState, additionalGameState.matchConfig);
  }

  return state;

};
  
// ==== HELPER FUNCTIONS ====
const matchDecided = (state: MatchSets, config: MatchConfiguration) => {
  const setPoints = getSetPoints(state, config)
  const setNumber = getCurrentSetNumber(state)
  const setScore = getCurrentMatchSet(state).setScore;
  const matchDecidedBySetsToWin = setPoints.team1 >= config.setsToWin || setPoints.team2 >= config.setsToWin
  const setIsFinished = isSetFinished(setNumber, config, setScore);
  if (config.alwaysPlayMaximumNumberOfSets) {
    return (setNumber === config.maximumSets) && setIsFinished
  }
  return matchDecidedBySetsToWin || setNumber === config.maximumSets && setIsFinished
}

const continuePlaying = (setPoints: SetPoints, config: MatchConfiguration) =>
  (setPoints.team1 + setPoints.team2 < config.maximumSets) && config.alwaysPlayMaximumNumberOfSets;

// ==== Selectors ====

// reduces all set scores: [{ leftTeam: 25, rightTeam: 23 }, { leftTeam: 21,rightTeam: 25 }]
// to set points:           { leftTeam: 1, rightTeam: 1 }
export const getSetPoints = (state: MatchSets, config: MatchConfiguration): SetPoints => {

  const initial = { team1: 0, team2: 0 }

  if (!state) {
    return initial 
  }

  return state.reduce((setPoints: SetPoints, { setScore }) => {

    const currentSetNumber = getCurrentSetNumber(state)
    const setIsFinished = isSetFinished(currentSetNumber, config, setScore);
    if (setIsFinished) {
      if (setScore.team1 > setScore.team2) {
        setPoints.team1++;
      }
      if (setScore.team1 < setScore.team2) {
        setPoints.team2++;
      }
    }

    return setPoints;
  }, initial);

};
export const getCurrentSetNumber = (state: MatchSets) => state ? state.length : 1;
export const getCurrentSetIndex = (state: MatchSets) => state.length - 1;
export const getCurrentMatchSet = (state: MatchSets) => state[getCurrentSetIndex(state)];
export const getPreviousMatchSet = (state: MatchSets) => state[getCurrentSetIndex(state) - 1];
export const getMatchSetForSetNumber = (state: MatchSets, setNumber: number) => state[setNumber - 1];
export const isMatchStarted = (state: MatchSets) => isSetStarted(state[0]);
export const isMatchFinished = matchDecided;
export const isDecidingSet = (state: MatchSets, config: MatchConfiguration) => decidingSet(getCurrentSetNumber(state), config)
export const getWinningTeamCode = (state: MatchSets, config: MatchConfiguration) => {
  const points = getSetPoints(state, config);

  if (!isMatchFinished(state, config)) {
    return undefined;
  }
  if (points.team1 > points.team2)
    return TeamCodes.team1;
  if (points.team2 > points.team1)
    return TeamCodes.team2;

  if (config.determineDrawWinnerByBallPoints) {
    const ballPoints = calculateBallPoints(state)
    if (ballPoints.team1 > ballPoints.team2)
      return TeamCodes.team1;
    if (ballPoints.team2 > ballPoints.team1)
      return TeamCodes.team2;
  }
  return undefined;
}
export const getHasCurrentSetStarted = (state: MatchSets, config: MatchConfiguration) => 
  !isMatchFinished(state, config) && isSetStarted(getCurrentMatchSet(state));
// ==== Selectors ====


// ==== Case reducers ====

const createNewSet = (state: MatchSet, action: SetScoreAction, additionalMatchSetState: AdditionalMatchSetState, isDecidingSet: boolean) => {
  const { scoreConfig } = additionalMatchSetState
  const decidingSetPauseDurationSeconds = scoreConfig ? scoreConfig.decidingSetPauseDurationSeconds : 180
  const setPauseDurationSeconds = scoreConfig ? scoreConfig.setPauseDurationSeconds : 180

  const setPauseDurationMs = (isDecidingSet ? decidingSetPauseDurationSeconds : setPauseDurationSeconds) * 1000
  return {
    ...matchSetReducer(undefined, {} as MatchSetsAction, additionalMatchSetState),
    timeKeeping: {
      ...timeKeepingReducer(undefined, new SetStartTimeAction(action.timestamp + setPauseDurationMs, action.matchId)),
      setPauseDurationMs
    },
    teamSides: teamSidesReducer(state.teamSides, new SwitchSidesAction(action.matchId)),
    serving: servingReducer({ ...state.serving, currentServings: 0 }, new ChangeServingAction(action.matchId))
  };
};

const endCurrentSet = (state: MatchSet, action: SetScoreAction) => {
  return {
    ...state,
    timeKeeping: timeKeepingReducer(state.timeKeeping, new EndSetAction(action.timestamp, action.matchId))
  };
};

const calculateBallPoints = (state: MatchSets) => {
  return state.reduce((prev, curr) => {
    return {
      team1: prev.team1 + curr.setScore.team1,
      team2: prev.team2 + curr.setScore.team2
    }
  }, { team1: 0, team2: 0 })
}


function addSetReducer (state: MatchSets, action: GameStateAction, additionalMatchSetState: AdditionalMatchSetState, config: MatchConfiguration) {
  switch (action.type) {
    case SCORE_OVERRIDE:
    case PENALTY_SCORE:
    case SCORE: {

      const setScore = getCurrentMatchSet(state).setScore;
      const setPoints = getSetPoints(state, config);
      const currentSetNumber = getCurrentSetNumber(state);
      const currentSetIndex = getCurrentSetIndex(state);
      const currentMatchSet = getCurrentMatchSet(state);

      const setIsFinished = isSetFinished(currentSetNumber, config, setScore);
      const isMatchDecided = matchDecided(state, config);
      const isMatchUndecided = !isMatchDecided;
      const shouldContinuePlaying = continuePlaying(setPoints, config);

      const shouldAddSet = (isMatchUndecided || shouldContinuePlaying) && setIsFinished

      if (shouldAddSet) {
        const isDecidingSet = currentSetNumber + 1 === config.maximumSets
        return [
          ...state.slice(0, currentSetIndex),
          endCurrentSet(currentMatchSet, action),
          ...state.slice(currentSetIndex + 1),
          createNewSet(currentMatchSet, action, additionalMatchSetState, isDecidingSet)
        ];
      }

      if (isMatchDecided && !shouldContinuePlaying) {
        return [
          ...state.slice(0, currentSetIndex),
          endCurrentSet(currentMatchSet, action),
          ...state.slice(currentSetIndex + 1)
        ];
      }

      return state;
    }
    default: {
      return state;
    }
  }

}

// ==== Case reducers ====
