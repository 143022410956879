import { InfoDialogComponent } from "./info-dialog.component";
import { SetFinishedDurationDialogComponent } from "./set-finished-duration-dialog";
import { ManualChangesDialogComponent } from "./manual-changes-dialog.component";
import { CommentsDialogComponent } from "./comments-dialog.component";
import { NoLiberoOnFieldDialogComponent } from "./no-libero-on-field-dialog.component";
import { LiberoInjuredDialogComponent } from "./libero-injured-dialog.component";
import { SubstitutionDialogComponent } from "./substitution-dialog.component";
import { SetFinishedSpectatorCountDialogComponent } from './set-finished-spectator-count-dialog';
import { LiberoRotatedOutDialogComponent } from './libero-rotated-out-dialog';
import { SubstitutionsExceededDialogComponent } from "./substitutions-exceeded-dialog.component";
import { ChallengeDialogComponent } from "./challenge-dialog";

export const MATCH_VIEW_DIALOGS = [
  InfoDialogComponent,
  SetFinishedDurationDialogComponent,
  SetFinishedSpectatorCountDialogComponent,
  CommentsDialogComponent,
  ChallengeDialogComponent,
  ManualChangesDialogComponent,
  NoLiberoOnFieldDialogComponent,
  SubstitutionDialogComponent,
  LiberoInjuredDialogComponent,
  LiberoRotatedOutDialogComponent,
  SubstitutionsExceededDialogComponent
];
