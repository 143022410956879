import { Component, Input } from '@angular/core';
import { Mvp } from '../../../../../shared/reducers/game-state/mvps/mvp.reducer';
import { Player } from 'app/models';

@Component({
  selector: 'sams-mvp-display',
  template: `
    <mat-icon *ngIf="isMvp" class="mvp">star_rate</mat-icon>
  `
})
export class MvpDisplayComponent {

  @Input() player: Player;

  @Input() mvp: Mvp<Player>;

  get isMvp() {
    if (!this.mvp || !this.mvp.player) return false;
    return this.player.uuid === this.mvp.player.uuid;
  }

}
