import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Team } from 'app/models';
import { ScoreConfiguration } from '../../../../../shared/interfaces/models/score-configuration';
import {
  Challenge
} from '../../../../../shared/reducers/game-state/match-sets/challenges/challenges.reducer';


export type ChallengeDialogResult = Challenge | null;

@Component({
  selector: 'sams-challenge-dialog',
  template: `
    <h1 mat-dialog-title>{{'component.challenge-dialog.title' | translate:team}}</h1>
    <p>{{'component.challenge.' + challengeType | translate}}</p>
    <div mat-dialog-actions>
      <button mat-raised-button class="positive"
        (click)="onChallengeSuccess()">
        {{'component.challenge-dialog.success' | translate}}
      </button>
      <button
        mat-raised-button color="warn"
        (click)="onChallengeDeclined()">
        {{'component.challenge-dialog.failure' | translate}}
      </button>
      <button
        mat-raised-button color="primary"
        (click)="onUndoChallenge()">
        {{'component.challenge-dialog.undo' | translate}}
      </button>
    </div>
  `,
})
export class ChallengeDialogComponent {

  public challenge: Challenge

  public team: Team

  constructor(public dialogRef: MatDialogRef<ChallengeDialogComponent, ChallengeDialogResult>,
    @Inject(MAT_DIALOG_DATA) public scoreConfiguration: ScoreConfiguration) {
    dialogRef.disableClose = true;
  }

  onChallengeSuccess = () => {
    this.dialogRef.close({
      ...this.challenge,
      successful: true,
      challengesLeft: this.challenge.challengesLeft,
      durationMs: +new Date() - this.challenge.timestamp
    });
  }

  onChallengeDeclined = () => {
    this.dialogRef.close({
      ...this.challenge,
      challengesLeft: this.challenge.challengesLeft - 1,
      successful: false,
      durationMs: +new Date() - this.challenge.timestamp
    });
  }

  onUndoChallenge = () => {
    this.dialogRef.close();
  }

  get challengeType() {
    return this.challenge.challengeType.toLocaleLowerCase();
  }

}
