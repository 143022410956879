import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

export interface SyncState {
  timestamp: string
  numberOfMatchActions: number 
}

export interface SyncingDialogData {
  clientSyncState: SyncState
  serverSyncState: SyncState
}

export type SyncingDialogResult = 'PUSH' | 'PULL';

@Component({
  selector: 'sams-syncing-dialog',
  template: `
    <h2 mat-dialog-title>{{'component.syncing-dialog.diverging_histories_dialog_heading' | translate}}</h2>
    <div class="action-buttons" mat-dialog-actions>

      <button *ngIf="isClientStateLessThanServerState && !isClientStateEmpty" mat-raised-button class="warn" (click)="onPush()">
        <p>{{'component.syncing-dialog.diverging_histories_dialog_overwrite_caution' | translate:clientState}}</p>
      </button>

      <button *ngIf="isClientStateEmpty" mat-raised-button class="negative" (click)="onPush()">
        <p>{{'component.syncing-dialog.diverging_histories_dialog_overwrite_warning' | translate:serverState}}</p>
      </button>

      <button *ngIf="!isClientStateEmpty && !isClientStateLessThanServerState" mat-raised-button class="positive" (click)="onPush()">
        <p>{{'component.syncing-dialog.diverging_histories_dialog_overwrite' | translate:clientState}}</p>
      </button>

      <button mat-raised-button color="primary" (click)="onPull()">
        <p>{{'component.syncing-dialog.diverging_histories_dialog_load' | translate:serverState}}</p>
      </button>

    </div>
  `,
  styles: [`
    .action-buttons {
      display: flex;
      justify-content: center;
    }
    .action-buttons button {
      margin: 15px 15px 15px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 2;
    }

    .action-buttons button.warn {
      background-color: gold;
    }
  `]
})
export class SyncingDialogComponent {

  syncData: SyncingDialogData

  constructor(public dialogRef: MatDialogRef<SyncingDialogComponent, SyncingDialogResult>, @Inject(MAT_DIALOG_DATA) private data: SyncingDialogData) {
    this.syncData = data
    dialogRef.disableClose = true;
  }

  get clientState() {
    return this.syncData.clientSyncState
  }

  get serverState() {
    return this.syncData.serverSyncState
  }

  get isClientStateEmpty() {
    return this.syncData.clientSyncState.numberOfMatchActions === 0
  }

  get isClientStateLessThanServerState() {
    return this.syncData.clientSyncState.numberOfMatchActions < this.syncData.serverSyncState.numberOfMatchActions
  }

  onPush() {
    this.dialogRef.close('PUSH');
  }

  onPull() {
    this.dialogRef.close('PULL');
  }

}
