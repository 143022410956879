import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Team } from '../../../../../shared/interfaces/models/team';

@Component({
  selector: 'sams-libero-rotated-out-dialog',
  template: `
    <div class="dialog-header">
      <sams-logo [src]="team.logoImageMax400x400?.url" [alt]="team.name" [letter]="team.singleLetterName" size="80px"></sams-logo>
      <div>
        <h1 mat-dialog-title>{{team.name}}</h1>
        <h1 mat-dialog-title>{{title | async}}</h1>
      </div>
    </div>
    <p style="line-height: 22px;" *ngIf="content">{{content | async}}</p>
    <div class="action-buttons" mat-dialog-actions>
      <button mat-raised-button color="primary" mat-dialog-close="Ok">{{buttonText | async}}</button>
    </div>
  `,
  styles: [`
    .action-buttons {
      display: flex;
      justify-content: center;
    }
    .dialog-header {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    h1 {
      margin: 0;
      margin-left: 10px;
    }
  `]
})
export class LiberoRotatedOutDialogComponent {

  title: Observable<string>;

  content?: Observable<string>;

  buttonText: Observable<string>;

  team: Team

  constructor(public dialogRef: MatDialogRef<LiberoRotatedOutDialogComponent>) {
    dialogRef.disableClose = true;
  }
}
