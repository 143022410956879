import { MatchSetsAction } from './match-sets/match-sets.action.js';
import { SanctionAction } from './sanctions/sanctions.action.js';
import { InjuryAction } from './injuries/injuries.action.js';
import { LiberosAction } from './liberos/liberos.action.js';
import { MvpAction } from './mvps/mvp.action.js';
import { TeamCode } from '../../interfaces/models/team-codes.js';
import { MatchAction } from '../../interfaces/interfaces.js';

export const REPLACE_LIBERO = '[Liberos] Replace libero';

export interface ReplaceLibero {
  teamCode: TeamCode;
  oldLiberoId: string;
  newLiberoId: string;
}

export class ReplaceLiberoAction extends MatchAction {
  readonly type = REPLACE_LIBERO;
  constructor(public payload: ReplaceLibero, matchId: string) {
    super(matchId);
  }
}

export type GameStateAction
  = MatchSetsAction
  | SanctionAction
  | MvpAction
  | InjuryAction
  | LiberosAction
  | ReplaceLiberoAction;
