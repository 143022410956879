import { SetScore } from '../match-sets/set-score/set-score.reducer.js';
import { IndividualSanctions, individualSanctionsReducer } from './individual-sanction/individual-sanction.reducer.js';
import { TeamSanctions, teamSanctionsReducer } from './team-sanction/team-sanction.reducer.js';
import { SanctionAction } from './sanctions.action.js';
import {
  INDIVIDUAL_PENALTY,
  INDIVIDUAL_EXPULSION,
  INDIVIDUAL_DISQUALIFICATION,
  INDIVIDUAL_WARNING
} from './individual-sanction/individual-sanction.action.js';
import { TEAM_IMPROPER_REQUEST, TEAM_WARNING, TEAM_PENALTY } from './team-sanction/team-sanction.action.js';
import * as fromIndividualSanction from './individual-sanction/individual-sanction.reducer.js';
import * as fromTeamSanction from './team-sanction/team-sanction.reducer.js';
import { ExceptionalSubstitutionAction, EXCEPTIONAL_SUBSTITUTION } from '../match-sets/lineups/lineups.action.js';

export interface AdditionalSanctionsState {
  setScore: SetScore;
  currentSetNumber: number;
}

export interface Sanction {
  setScore: SetScore;
  currentSetNumber: number;
  timestamp: number;
}

export interface Sanctions {
  individuals: IndividualSanctions;
  team: TeamSanctions;
}

export const initialState: Sanctions = {
  individuals: fromIndividualSanction.initialState, 
  team: fromTeamSanction.initialState 
}

export const sanctionsReducer =
  (state = initialState, action: SanctionAction | ExceptionalSubstitutionAction, additionalSanctionsState: AdditionalSanctionsState) => {

  switch (action.type) {
    case EXCEPTIONAL_SUBSTITUTION: {
      const isAllowedToPlayNextSets = action.payload.isAllowedToPlayNextSets
      const playerOutId = action.payload.playerOutId
      const expulsion = state.individuals.expulsions[playerOutId]

      // TODO: exceptional substitution due to injury, handle more gracefully
      if (!expulsion) return state
      return {
        ...state,
        individuals: {
          ...state.individuals,
          expulsions: {
            ...state.individuals.expulsions,
            [playerOutId]: {
              ...expulsion,
              isAllowedToPlayNextSets
            }
          }
        }
      }
    }
    case INDIVIDUAL_WARNING:
    case INDIVIDUAL_PENALTY:
    case INDIVIDUAL_EXPULSION:
    case INDIVIDUAL_DISQUALIFICATION: {
      const { setScore, currentSetNumber } = additionalSanctionsState;
      return {
        ...state,
        individuals: individualSanctionsReducer(state.individuals, action, setScore, currentSetNumber),
      };
    }
    case TEAM_IMPROPER_REQUEST:
    case TEAM_WARNING:
    case TEAM_PENALTY: {
     const { setScore, currentSetNumber } = additionalSanctionsState;
      return {
        ...state,
        team: teamSanctionsReducer(state.team, action, setScore, currentSetNumber)
      };
    }
   default:
      return state;
  }
};

export const getTeamSanctions = (state: Sanctions) => state.team;
export const getIndividualSanctions = (state: Sanctions) => state.individuals;
export const getTeamImproperRequest = (state: Sanctions) => fromTeamSanction.getTeamImproperRequest(getTeamSanctions(state));
export const getTeamPenalties = (state: Sanctions) => fromTeamSanction.getTeamPenalties(getTeamSanctions(state));
export const getTeamWarning = (state: Sanctions) => fromTeamSanction.getTeamWarning(getTeamSanctions(state));
export const getIndividualDisqualifications = (state: Sanctions) =>
  fromIndividualSanction.getIndividualDisqualifications(getIndividualSanctions(state));
export const getIndividualExpulsions = (state: Sanctions) =>
  fromIndividualSanction.getIndividualExpulsions(getIndividualSanctions(state)); 
export const getIndividualPenalties = (state: Sanctions) =>
  fromIndividualSanction.getIndividualPenalties(getIndividualSanctions(state));
export const getIndividualWarnings = (state: Sanctions) =>
  fromIndividualSanction.getIndividualWarnings(getIndividualSanctions(state)); 
