import {timer as observableTimer,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter
} from '@angular/core';
import * as moment from 'moment';

import { TimeKeeping } from '../../../../shared/reducers/game-state/match-sets/time-keeping/time-keeping.reducer';

@Component({
  selector: 'sams-time-keeping',
  template: `
    <div class="flex-container-row">
      <div class="flex-container">
        <div class="time-keeping-wrapper">
          <div class="time">{{ time | async }}</div>
          <button *ngIf="!hasStarted" [disabled]="!isStartable || isMatchFinished" mat-raised-button (click)="start.emit(now)">
            <mat-icon>play_circle_outline</mat-icon>
          </button>
          <button mat-raised-button *ngIf="hasStarted" disabled="true">
            <mat-icon>play_circle_filled</mat-icon>
          </button>
        </div>
      </div>
    </div>
  `,
  styles: [`
    button {
      width: 3.5vw;
      min-width: 40px;
      max-width: 100px;
      padding: 0;
    }
    div.flex-container {
      display: flex;
      justify-content: center;
      text-align: center;
    }
    div.flex-container-row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
    div.time-keeping-wrapper {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      min-height: 85%;
      min-width: 100px;
    }
    div.time-keeping-wrapper button {
      width: 100%;
      max-width: 100%;
    }
    .time {
      font-family: 'Dosis', sans-serif;
      background: #4d4d4d;
      padding: 5px 10px;
      box-sizing: border-box;
      color: white;
      font-size: 20px;
      width: 100%;
      letter-spacing: 10px;
    }
  `]
})
export class TimeKeepingComponent implements OnInit {

  time: Observable<string>;

  @Input() timeKeeping: TimeKeeping;

  @Input() isStartable = false;

  @Input() isSetRunning: boolean;

  @Input() isMatchFinished: boolean;

  @Input() hasStarted: boolean;

  @Output() start = new EventEmitter<number>();

  ngOnInit() {
    this.time = observableTimer(0, 1000).pipe(
      map(_ => moment()),
      map(date => date.format('HH:mm')),);
  }

  get totalDurationMs(): number {
    if (!this.hasStarted || this.isMatchFinished) {
      return 0;
    } else if (this.isPaused) {
      return this.currentPauseStartTime - this.startTimeMs - this.pausedTimeMs;
    } else {
      return this.now - this.startTimeMs - this.pausedTimeMs;
    }
  }

  get isPaused(): boolean {
    return this.currentPauseStartTime !== 0;
  }

  get startTimeMs(): number {
    return this.timeKeeping.startTimeMs;
  }

  get pausedTimeMs(): number {
    return this.timeKeeping.pausedTimeMs;
  }

  get currentPauseStartTime(): number {
    return this.timeKeeping.currentPauseStartTime;
  }

  get now(): number {
    return +moment();
  }

}
