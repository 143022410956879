import { Component, Input, EventEmitter, Output } from '@angular/core';
import * as bcrypt from 'bcryptjs';
import { environment } from 'environments/environment';

@Component({
  selector: 'sams-pin',
  template: `
    <mat-form-field>
    <input *ngIf="!isFirefox"
        class="pin"
        [(ngModel)]="enteredPin"
        autocomplete="off"
        [disabled]="disabled"
        matInput
        required
        [placeholder]="placeholder">
    <input *ngIf="isFirefox"
        type="password"
        class="pin"
        [(ngModel)]="enteredPin"
        autocomplete="off"
        [disabled]="disabled"
        matInput
        required
        [placeholder]="placeholder">
    </mat-form-field>
    <br>
    <button mat-raised-button (click)="onConfirm()" [ngClass]="buttonClass" [disabled]="disabled">
      <mat-icon>{{buttonIcon}}</mat-icon> {{buttonLabel}}
    </button>
    <p *ngIf="!hidePinWrongMsg" class="warn">{{'component.pin.wrong_pin' | translate}}</p>
  `,
  styles: [`
    .warn {
      color: coral;
    }
    .pin {
      text-security: disc;
      -webkit-text-security: disc;
      font-size: 20px;
      font-weight: bold;
    }
  `]
})
export class PinComponent {

  @Input() placeholder: string;

  @Input() buttonLabel: string;

  @Input() buttonClass = 'positive';

  @Input() buttonIcon = 'send';

  @Input() pin: string;

  @Input() disabled: boolean;

  @Output() success = new EventEmitter<string>();

  @Output() failure = new EventEmitter<string>();

  hidePinWrongMsg = true;

  enteredPin = '';

  onConfirm() {
    if (!this.pin) {
      this.hidePinWrongMsg = false
      this.failure.emit()
      return false;
    }
    bcrypt.compare(this.enteredPin, this.pin).then(result => {
      if (!environment.production) {
        result = true;
      }
      this.hidePinWrongMsg = result;
      if (result) {
        const rounds = bcrypt.getRounds(this.pin);
        const newHash = bcrypt.hashSync(this.pin, rounds);
        this.success.emit(newHash);
      } else {
        this.failure.emit()
      }
    });
  }

  get isFirefox() {
    return navigator.userAgent.toLowerCase().indexOf('firefox') > -1
  }

}
