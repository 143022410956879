
import {timer as observableTimer,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'sams-countdown',
  template: `
  <div class="flex" *ngIf="timer$ | async">
    <span class="timer">{{title | async}} {{ timer$ | async }}</span>
    <button *ngIf="isCancelAvailable" mat-stroked-button (click)="cancelTimer()">
      <mat-icon class="icon" >clear</mat-icon>
    </button>
  </div>
  `,
  styles: [`
    .timer {
      width: 85px;
      font-size: 14px;
      white-space: pre-wrap;
      word-break: break-word;
    }
    .flex {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    button {
      margin-left: 5px;
    }

    .icon {
      color: tomato;
    }

  `]
})
export class CountdownComponent implements OnInit {
  
  isCancelAvailable: boolean;

  @Input() readMode: boolean;

  @Input() title: Observable<string>;

  @Input() startTimeMs: number;

  @Input() durationMs: number;

  @Output() cancel = new EventEmitter<void>();

  timer$: Observable<string>;

  constructor() {

    this.timer$ = observableTimer(0, 100).pipe(
      map(_ => {
        const now = +moment();
        const then = this.startTimeMs + this.durationMs;

        const diffTime = then - now;
        const durationSeconds = Math.round(diffTime / 1000);

        if (now < then) {
          const minutes = Math.floor(durationSeconds / 60);
          if (!minutes) {
            return durationSeconds.toString();
          }
          const seconds = durationSeconds % 60;
          return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        }
      }));
  }

  ngOnInit() {
    this.isCancelAvailable = this.cancel.observers.length > 0 && !this.readMode;
  }

  cancelTimer = () => {
    this.cancel.emit();
  }

}
