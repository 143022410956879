import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import * as StackTrace from 'stacktrace-js';

import { ErrorService } from './error.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {


  constructor(private injector: Injector) {

  }

  handleError(error: Error) {
    const router = this.injector.get(Router);
    const errorService = this.injector.get(ErrorService);

    const message = error.message ? error.message : error.toString();
    // get the stack trace, lets grab the last 10 stacks only
    StackTrace.fromError(error).then(stackframes => {
      const stack = stackframes
        .splice(0, 20)
        .map(function(sf) {
          return sf.toString();
        }).join('\n');

      errorService.handleError({ message, stack });

    });

    console.error(error);

    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    throw error;
  }

}
