
import { combineLatest as observableCombineLatest, Subscription } from 'rxjs';

import { first, distinctUntilChanged, filter, debounceTime } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { TeamSquad } from '../../../../../shared/reducers/match-preparation/team-squad.reducer';
import {
  Substitution,
  Nomination,
  LiberoIn,
  LiberoOut,
  LiberoSwitch,
  Denomination,
  ExceptionalSubstitution
} from '../../../../../shared/reducers/game-state/match-sets/lineups/lineups.action';
import { Lineup, BACKROW_INDICES, isSmallField } from '../../../../../shared/reducers/game-state/match-sets/lineups/lineups.reducer';
import { Injuries } from '../../../../../shared/reducers/game-state/injuries/injuries.reducer';
import { UndoAction } from '../../../../../shared/reducers/undoable/undoable.action';
import { Liberos } from '../../../../../shared/reducers/game-state/liberos/liberos.reducer';
import { isPlayerUnallowedToPlay, isLiberoUnallowedToPlay } from '../../../../../shared/reducers/game-state/game-state.reducer';
import { IndividualExpulsions, IndividualSanctions } from '../../../../../shared/reducers/game-state/sanctions/individual-sanction/individual-sanction.reducer';
import { ReplaceLiberoAction } from '../../../../../shared/reducers/game-state/game-state.action';
import { isPlayerServing } from '../../../../../shared/reducers/game-state/match-sets/match-set.reducer';
import { Serving } from '../../../../../shared/reducers/game-state/match-sets/serving/serving.reducer';

import { LiberoInjuredDialogData, LiberoInjuredDialogComponent, LiberoInjuredDialogResult } from 'app/match-view/dialogs/libero-injured-dialog.component';
import { InfoDialogComponent } from 'app/match-view/dialogs/info-dialog.component';
import { SubstitutionDialogComponent, SubstitutionDialogData } from 'app/match-view/dialogs/substitution-dialog.component';
import { NoLiberoOnFieldDialogComponent, NoLiberoOnFieldDialogResult } from 'app/match-view/dialogs/no-libero-on-field-dialog.component';
import { SubstitutionDialogResult } from 'app/match-view/dialogs/substitution-dialog.component';
import * as fromIndoor from 'app/root/indoor.reducer';
import * as fromShared from 'app/root/shared.reducer';
import { Dragged, DraggableService } from 'app/match-view/draggable.service';
import { Player, TeamCode, TeamSide, Team, TeamSideValues } from 'app/models';
import { ScoreConfiguration } from '../../../../../shared/interfaces/models/score-configuration';
import {
  SubstitutionsExceededDialogComponent,
  SubstitutionsExceededDialogData,
  SubstitutionsExceededDialogResult
} from '../dialogs/substitutions-exceeded-dialog.component';
import { DispatchService } from 'app/connections/dispatch.service';

@Component({
  selector: 'sams-lineup',
  template: `
    <div class="tile-container" rowHeight="1:1">
      <mat-grid-list [cols]="cols">
        <ng-template ngFor let-p let-i="index" [ngForOf]="fieldPlayers">

          <mat-grid-tile *ngIf="p === undefined"></mat-grid-tile>

          <mat-grid-tile
            *ngIf="p !== undefined"
            class="position"
            [ngClass]="{ 'libero': isLibero(p), 'not-droppable': isNotDroppable(p, positions[i] - 1), 'droppable': isDroppable(p, positions[i] - 1) }"
            (dragover)="onDragOver($event, p, positions[i] - 1)"
            (drop)="onDrop($event, positions[i] - 1, p)"
            (dragenter)="onDragEnter($event)">
            <div *ngIf="isServing(positions[i] - 1)" [ngClass]="{ 'ball': true, 'ball-left-team': isLeftTeam, 'ball-right-team': !isLeftTeam}"></div>
            <div class="position-index">
              {{positions[i] | position}}
            </div>
            <div>
              <span [ngClass]="{'huge': readMode}" class="jersey-number">{{p?.jerseyNumber}}</span>
              <span [ngClass]="{'huge': readMode}" *ngIf="isLibero(p)" class="sub-icon">L</span>
              <span [ngClass]="{'huge': readMode}" *ngIf="isCaptain(p)" class="sub-icon">C</span>
              <div *ngIf="!readMode">{{p?.lastName}}</div>
            </div>
            <div class="remove" *ngIf="isPossibleToRemovePlayer(p) && !readMode">
              <mat-icon (click)="onDenominate(positions[i] - 1)" matTooltip="{{'app.remove' | translate}}">clear</mat-icon>
            </div>
          </mat-grid-tile>
        </ng-template>
      </mat-grid-list>
    </div>
  `,
  styles: [`
    .ball {
      border: 1px solid grey;
      border-radius: 50%;
      width: 35%;
      height: 35%;
      background: repeating-linear-gradient(
        15deg,
        rgb(250,220,0),
        rgb(255,220,0) 5px,
        rgb(62,69,145) 6px,
        rgb(62,69,145) 10px
      );
      position: absolute;
      /* width and height can be anything, as long as they're equal */
    }
    .ball-left-team {
      left: -1vw;
      bottom: -1vw;
    }
    .ball-right-team {
      right: -1vw;
      top: -1vw;
    }
    .position {
      overflow: visible;
      background-color: lightblue;
      border: 1px solid white;
    }
    div.remove {
      position: absolute;
      color: tomato;
      cursor: pointer;
      right: 5px;
      top: 5px;
    }
    div.position-index {
      position: absolute;
      left: 5px;
      top: 5px;
    }
    .jersey-number {
      font-size: 3vw;
      font-weight: bold;
      margin: 10px;
    }
    .jersey-number.huge {
      font-size: 12vw;
    }
    span.huge {
      font-size: 8vw;
    }
    .draggable {
      cursor: move;
    }
    .position.droppable {
      background-color: lightgreen;
    }
    .position.not-droppable {
      background-color: tomato;
    }
    .sub-icon {
      font-weight: bold;
      // margin-left: -6px;
      font-size: 16px;
    }
    .libero {
      background-color: gold;
    }


  `],
})
export class LineupComponent implements OnInit, OnDestroy {

  maxRegularSubstitutionsPerSet: number;

  dragged: Dragged = null;

  @Input() readMode: boolean;

  @Input() fieldPlayers: Player[];

  @Input() lineup: Lineup<Player>;

  @Input() teamCode: TeamCode;

  @Input() teamSquad: TeamSquad;

  @Input() teamSide: TeamSide;

  @Input() team: Team;

  @Input() serving: Serving;

  @Input() isListening: boolean;

  @Input() hasSetStarted: boolean;

  @Input() isSetRunning: boolean;

  @Input() isMatchFinished: boolean;

  @Input() positions: number[];

  @Input() individualSanctions: IndividualSanctions;

  @Input() injuries: Injuries<Player>;

  @Input() liberos: Liberos<Player>;

  @Input() scoreConfig: ScoreConfiguration;

  @Input() isStartingSixLocked: boolean;

  @Input() liberoRegistrationEnabled: boolean;

  @Output() substitution = new EventEmitter<Substitution>();

  @Output() exceptionalSubstitution = new EventEmitter<ExceptionalSubstitution>();

  @Output() nomination = new EventEmitter<Nomination>();

  @Output() denomination = new EventEmitter<Denomination>();

  @Output() liberoIn = new EventEmitter<LiberoIn>();

  @Output() liberoOut = new EventEmitter<LiberoOut>();

  @Output() liberoSwitch = new EventEmitter<LiberoSwitch>();

  @Output() dropped = new EventEmitter<null>();

  currentSetNumber: number;

  matchId: string;

  playersOnBench: Player[];

  possibleSubstitutesForDraggedPlayer: Player[] = [];

  subscription = new Subscription();

  constructor(
    private dialog: MatDialog,
    private store: Store<fromIndoor.IndoorRoot>,
    private dispatchService: DispatchService,
    private draggableService: DraggableService,
    private translate: TranslateService
  ) {

  }

  ngOnInit() {

    this.subscription.add(
      this.store.select(root => fromIndoor.getFieldPlayers(root, this.teamSide))
        .subscribe(fieldPlayers => this.fieldPlayers = fieldPlayers)
    );

    this.subscription.add(
      this.store.select(fromIndoor.getMaxRegularSubstitutionsPerSet)
        .subscribe(maxRegularSubstitutionsPerSet => this.maxRegularSubstitutionsPerSet = maxRegularSubstitutionsPerSet)
    );

    this.subscription.add(
      this.draggableService.dragged
        .subscribe(dragged => this.updatePossibleSubstitutesForDraggedPlayer(dragged))
    );

    this.subscription.add(
      this.store.select(fromShared.getMatchId).subscribe(matchId => this.matchId = matchId)
    );

    this.subscription.add(
      this.store.select(fromIndoor.getCurrentSetNumber).subscribe(currentSetNumber => this.currentSetNumber = currentSetNumber)
    );

    this.subscription.add(
      this.store.select(root => fromIndoor.getPlayersOnBench(root, this.teamSide))
        .subscribe(playersOnBench => this.playersOnBench = playersOnBench)
    );

    // Promise workaround see: https://github.com/angular/angular/issues/15634
    Promise.resolve().then(_ => {

      this.subscription.add(
        observableCombineLatest(
          this.store.select(fromIndoor.getHasCurrentSetStarted).pipe(distinctUntilChanged()),
          this.store.select(root => fromIndoor.getSanctionsOfTeam(root, this.teamSide)).pipe(
            distinctUntilChanged((x, y) => {
              return Object.keys(x.individuals.disqualifications).length === Object.keys(y.individuals.disqualifications).length
                && Object.keys(x.individuals.expulsions).length === Object.keys(y.individuals.expulsions).length;
            })),
          this.store.select(root => fromIndoor.getTeamLineup(root, this.teamSide)).pipe(
            distinctUntilChanged((x, y) => {
              return y.current.every(p => x.current.indexOf(p) > -1)
                && x.liberoOnField === y.liberoOnField
                && x.liberoSubstitute === y.liberoSubstitute;
            })),
          this.store.select(root => fromIndoor.getTeamInjuries(root, this.teamSide)).pipe(
            distinctUntilChanged((x, y) => x.length === y.length))
        ).pipe(
        debounceTime(0),
        filter(_ => this.hasSetStarted),
        filter(_ => !this.isListening),)
        .subscribe(_ => {
          this.checkInjuries();
          this.checkSanctions();
        })
      );

      const noLiberoOnFieldSub = this.store.select(fromIndoor.getServing).pipe(
        distinctUntilChanged((x, y) => x.currentlyServing === y.currentlyServing && x.changedByScore === y.changedByScore),
        debounceTime(0), // don't remove see #236
        filter(serving => serving.changedByScore),
        filter(serving => serving.currentlyServing !== this.teamCode),
        filter(_ => this.hasSetStarted && !this.isListening && !this.readMode && !this.lineup.liberoOnField && !this.areAllLiberosUnableToPlay),)
        .subscribe(_ => this.liberoRegistrationEnabled ? null : this.openNoLiberoOnFieldDialog());

      this.subscription.add(noLiberoOnFieldSub);

    });

  }

  openNoLiberoOnFieldDialog() {

    const dialogRef: MatDialogRef<NoLiberoOnFieldDialogComponent, NoLiberoOnFieldDialogResult> = 
      this.dialog.open(NoLiberoOnFieldDialogComponent);

    dialogRef.componentInstance.title = this.translate.get('component.lineup.no_libero_on_field', { name: this.team.shortName });
    dialogRef.componentInstance.availableLiberos = this.availableLiberos;
    dialogRef.componentInstance.defaultLibero = this.defaultLibero;
    dialogRef.componentInstance.team = this.team;

    // we don't care for which libero we check here
    dialogRef.componentInstance.availablePlayers = 
      this.fieldPlayers.filter(p => this.isPossibleSubstitute(p, this.lineup.current.indexOf(p), this.availableLiberos[0]));

    dialogRef.componentInstance.lineup = this.lineup;

    dialogRef.afterClosed().pipe(
      first(),
      filter(result => result != null),)
      .subscribe(result => {
        const substitution: LiberoIn = {
          teamCode: this.teamCode,
          liberoId: result.selectedLibero.uuid,
          liberoSubstitute: result.selectedPlayer.uuid
        };
        this.liberoIn.emit(substitution);
      });
  }

  checkSanctions() {

    const currentExpulsions = this.getCurrentSetExpulsions();
    const disqualifications = this.individualSanctions.disqualifications;

    const sanctionsThatRequireSubstitution = { ...currentExpulsions, ...disqualifications };

    let player: Player;

    // fullLineup meaning including the liberoOnField + liberoSubstitute
    const fullLineup = this.fieldPlayers.concat(this.lineup.current);

    for (let playerId in sanctionsThatRequireSubstitution) {
      let result = fullLineup.find(p => p ? p.uuid === playerId : false);
      player = result ? result : player;
    }

    if (!player) {
      return;
    }

    let i: number;
    if (player === this.liberoSubstitute) {
      i = this.fieldPlayers.indexOf(this.liberoOnField)
    } else {
      i = this.fieldPlayers.indexOf(player);
    }

    const playerIndex = this.positions[i] - 1;

    const isDisqualified = !!disqualifications[player.uuid]
    this.onSanctionedPlayerOnField(player, playerIndex, isDisqualified);
  }

  onSanctionedPlayerOnField(player: Player, playerIndex: number, isDisqualified: boolean) {
    const possibleSubstitutes = this.getPossibleSubstitutes(player, playerIndex);
    if (this.isNumOfSubstitutionsExceeded) {
        const possibleExceptionalSubstitutes = this.getPossibleExceptionalSubstitutes(player);
        if (possibleExceptionalSubstitutes.length > 0) {
          isDisqualified ?
            this.openExceptionalSubstitutionDialog(player, playerIndex, possibleExceptionalSubstitutes)
            : this.openNumOfSubstitutionsExceededDialog(player, playerIndex, possibleExceptionalSubstitutes)
        } else {
          this.openNoSubstitutesAvailableDialog();
        }
    } else {
      if (possibleSubstitutes.length > 0) {
        this.openSubstitutionDialog(player, playerIndex, possibleSubstitutes);
      } else {
        const isPlayerLocked = this.isPlayerLocked(player.uuid)
        if (isPlayerLocked) {
          const possibleExceptionalSubstitutes = this.getPossibleExceptionalSubstitutes(player);
          if (possibleExceptionalSubstitutes.length > 0) {
            this.openExceptionalSubstitutionDialog(player, playerIndex, possibleExceptionalSubstitutes)
            return
          }
        } 
        this.openNoSubstitutesAvailableDialog();
      }
    }
  }

  checkInjuries() {

    const player = this.injuries.find(p => this.lineup.current.indexOf(p) > -1 || this.liberoOnField === p);
    if (!player) {
      return;
    }

    let playerIndex = this.lineup.current.indexOf(player);
    if (player === this.liberoOnField) {
      playerIndex = this.lineup.current.indexOf(this.liberoSubstitute);
    }
    this.onInjuredPlayerOnField(player, playerIndex);
  }

  getCurrentSetExpulsions() {
    let result: IndividualExpulsions = {};
    Object.keys(this.individualSanctions.expulsions).forEach(teamMemberId => {
      const sanction = this.individualSanctions.expulsions[teamMemberId];
      if (sanction.currentSetNumber === this.currentSetNumber || !sanction.isAllowedToPlayNextSets) {
        result[teamMemberId] = sanction;
      }
    });
    return result;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  isPlayerLocked(playerId: string) {
    if (typeof this.lineup.substitutedInFor[playerId] !== 'undefined' &&
        typeof this.lineup.substitutedOutFor[playerId] !== 'undefined') {
      return true;
    }
    return false;
  }

  get areAllLiberosUnableToPlay() {
    if (this.lineup.liberos.length === 0) {
      return true;
    }
    return this.lineup.liberos.every(p => this.isLiberoUnallowedToPlay(p))
  }

  getPossibleSubstitutes(player: Player, playerIndex: number) {

    let candidates = this.playersOnBench;

    // if there exists a libero substitute hes a possible substitute
    const liberoSubstitute = this.teamSquad.players.find(p => p === this.liberoSubstitute);
    if (liberoSubstitute) {
      candidates = [...candidates, liberoSubstitute];
    }

    // all liberos that are not on the field are possible substitutes
    if (this.liberoOnField) {
      const liberosNotOnField = this.lineup.liberos.filter(p => p !== this.liberoOnField);
      if (liberosNotOnField.length > 0) {
        candidates = [...candidates, ...liberosNotOnField];
      }
    }

    return candidates.filter(benchPlayer => this.isPossibleSubstitute(player, playerIndex, benchPlayer));

  }

  getPossibleExceptionalSubstitutes(player: Player) {
    return this.playersOnBench.filter(p => !this.isPlayerUnallowedToPlay(p));
  }

  onInjuredPlayerOnField(player: Player, playerIndex: number) {

    const possibleSubstitutes = this.getPossibleSubstitutes(player, playerIndex);

    if (this.isLibero(player) && this.areAllLiberosUnableToPlay) {
      const possibleLiberos = this.liberoCandidates;
      this.openLiberoInjuredDialog(player, playerIndex, possibleSubstitutes, possibleLiberos);
    } else {

      if (possibleSubstitutes.length === 0 || this.isNumOfSubstitutionsExceeded) {
        const possibleExceptionalSubstitutes = this.getPossibleExceptionalSubstitutes(player);
        if (possibleExceptionalSubstitutes.length > 0) {
          this.openExceptionalSubstitutionDialog(player, playerIndex, possibleExceptionalSubstitutes);
        } else {
          this.openNoSubstitutesAvailableDialog();
        }
      } else {
        this.openSubstitutionDialog(player, playerIndex, possibleSubstitutes);
      }

    }

  }

  openLiberoInjuredDialog(player: Player, playerIndex, availablePlayers: Player[], availableLiberos: Player[]) {

    const data: LiberoInjuredDialogData = {
      team: this.team,
      title: this.translate.get('component.libero-injured-dialog.title', { player }),
      availablePlayers,
      availableLiberos
    };

    const dialogRef: MatDialogRef<LiberoInjuredDialogComponent, LiberoInjuredDialogResult> =
      this.dialog.open(LiberoInjuredDialogComponent, { data });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result === "UNDO") {
        this.undoLastAction();
      } else {
        if (result.isLibero) {
          this.onReplaceLibero(playerIndex, player, result.player);
        } else {
          this.emitSubstitution(playerIndex, player, result.player);
        }
      }
    });

  }

  openExceptionalSubstitutionDialog(player: Player, playerOutIndex: number, availablePlayers: Player[]) {

    const data: SubstitutionDialogData = {
      team: this.team,
      title: this.translate.get('component.lineup.exceptional_substitution', { player }),
      availablePlayers,
      isUndoable: true
    };

    const dialogRef: MatDialogRef<SubstitutionDialogComponent, SubstitutionDialogResult> =
      this.dialog.open(SubstitutionDialogComponent, { data });

    dialogRef.afterClosed().pipe(first()).subscribe(substitute => {
      if (substitute === "UNDO") {
        this.undoLastAction();
     } else {
        const substitution: ExceptionalSubstitution = {
          teamCode: this.teamCode,
          playerInId: substitute.uuid,
          playerOutId: player.uuid,
          playerOutIndex,
          isAllowedToPlayNextSets: true
        };
        this.exceptionalSubstitution.emit(substitution);
      }
    });

  }

  openSubstitutionDialog(player: Player, playerIndex: number, availablePlayers: Player[]) {

    const data: SubstitutionDialogData = {
      team: this.team,
      title: this.isLibero(player)
        ? this.translate.get('component.lineup.regular_libero_change', { player })
        : this.translate.get('component.lineup.regular_substitution', { player }),
      availablePlayers,
      isUndoable: true
    };

    const dialogRef: MatDialogRef<SubstitutionDialogComponent, SubstitutionDialogResult> =
      this.dialog.open(SubstitutionDialogComponent, { data });

    dialogRef.afterClosed().pipe(first()).subscribe(substitute => {
      if (substitute === "UNDO") {
        this.undoLastAction();
      } else {
        this.emitSubstitution(playerIndex, player, substitute);
      }
    });

  }

  openNoSubstitutesAvailableDialog() {
    const dialogRef = this.dialog.open(InfoDialogComponent);
    dialogRef.componentInstance.title = this.translate.get('component.lineup.no_legal_substitutes_available');
    dialogRef.componentInstance.content = this.translate.get('component.lineup.no_legal_substitutes_available_hint');
    dialogRef.componentInstance.buttonText = this.translate.get('app.confirm');
  }

  openNumOfSubstitutionsExceededDialog(player: Player, playerOutIndex: number, availablePlayers: Player[]) {

    const data: SubstitutionsExceededDialogData = {
      team: this.team,
      title: this.translate.get('component.substitutions-exceeded-dialog.num_of_substitutions_exceeded', { player }),
      availablePlayers,
      isUndoable: true
    };

    const dialogRef: MatDialogRef<SubstitutionsExceededDialogComponent, SubstitutionsExceededDialogResult> =
      this.dialog.open(SubstitutionsExceededDialogComponent, { data });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result === "UNDO") {
        this.undoLastAction();
     } else if (result) {
        const substitution: ExceptionalSubstitution = {
          teamCode: this.teamCode,
          playerInId: result.uuid,
          playerOutId: player.uuid,
          playerOutIndex,
          isAllowedToPlayNextSets: false
        };
        this.exceptionalSubstitution.emit(substitution);
      }
    });

  }

  onDenominate(fieldPlayerIndex: number) {
    this.denomination.emit({
      teamCode: this.teamCode,
      playerIndex: fieldPlayerIndex
    });
  }

  onDrop(event: DragEvent, fieldPlayerIndex: number, fieldPlayer: Player) {

    if (this.isDroppable(fieldPlayer, fieldPlayerIndex)) {
      const benchPlayer = this.dragged.benchPlayer;

      this.emitSubstitution(fieldPlayerIndex, fieldPlayer, benchPlayer);

      this.dropped.emit(null);

      event.preventDefault();
    }

  }

  private undoLastAction() {
    this.dispatchService.dispatchRemoteAction(new UndoAction(1, this.matchId));
  }

  emitSubstitution(fieldPlayerIndex: number, fieldPlayer: Player, benchPlayer: Player) {
    if (this.hasSetStarted) {

      if (this.isLibero(benchPlayer)) {

        if (this.isLibero(fieldPlayer)) {

          this.liberoSwitch.emit({
            teamCode: this.teamCode,
            liberoInId: benchPlayer.uuid,
            liberoOutId: fieldPlayer.uuid
          });

        } else {
          this.liberoIn.emit({
            teamCode: this.teamCode,
            liberoId: benchPlayer.uuid,
            liberoSubstitute: fieldPlayer.uuid
          });
        }

      } else if (this.isLibero(fieldPlayer)) {

        this.liberoOut.emit({
          teamCode: this.teamCode,
          liberoId: fieldPlayer.uuid,
          playerInId: benchPlayer.uuid
        });

      } else {

        const substitution: Substitution = {
          teamCode: this.teamCode,
          playerInId: benchPlayer.uuid,
          playerOutId: fieldPlayer.uuid,
          playerOutIndex: fieldPlayerIndex
        };
        this.substitution.emit(substitution);

      }

    } else {

      const nomination: Nomination = {
        teamCode: this.teamCode,
        playerInId: benchPlayer.uuid,
        playerOutIndex: fieldPlayerIndex
      };
      this.nomination.emit(nomination);

    }
  }

  onDragOver(event: DragEvent, fieldPlayer: Player, fieldIndex: number) {
    if (this.isDroppable(fieldPlayer, fieldIndex)) {
      event.dataTransfer.dropEffect = 'copy';
      event.preventDefault();
    }
  }

  onDragEnter(event: DragEvent, fieldPlayer: Player, fieldIndex: number) {
    event.preventDefault();
  }


  isPossibleSubstitute(
    fieldPlayer: Player,
    fieldIndex: number,
    benchPlayer: Player,
    debug: boolean = false
  ) {

    const isPlayerUnallowedToPlay = this.isPlayerUnallowedToPlay(benchPlayer);

    if (isPlayerUnallowedToPlay) {
      if (debug) console.log(benchPlayer.lastName, 'is unable to play')
      return false;
    }

    const isFieldPlayerLibero = this.isLibero(fieldPlayer);
    const isBenchPlayerLibero = this.isLibero(benchPlayer);

    const isLiberoSub = isFieldPlayerLibero || isBenchPlayerLibero;

    if (isLiberoSub) {

      const liberoOnField = this.liberoOnField;
      const isLiberoOnField = this.isLiberoOnField;

      // prevents substitution of libero before match has started
      if (!this.hasSetStarted) {
        if (debug) console.log('prevents substitution of libero before match has started')
        return false;
      }

      // prevents substituting in liberos that are marked as unable to play
      if (this.isLiberoUnallowedToPlay(benchPlayer)) {
        if (debug) console.log('prevents substituting liberos that are marked as unable to play', this.isLiberoUnallowedToPlay(benchPlayer), this.isLiberoUnallowedToPlay(fieldPlayer), benchPlayer.lastName)
        return false;
      }

      // prevents two liberos on field
      // if a libero is on the field the fieldPlayer or benchPlayer has to be that libero - so the libero is substituted out
      if (isLiberoOnField && !(liberoOnField === fieldPlayer)) {
        if (debug) console.log('prevents two liberos on field', isLiberoOnField, !(liberoOnField === fieldPlayer || liberoOnField === benchPlayer))
        return false;
      }

      // the libero is only allowed to replace players in a backrow position and may not serve
      if (BACKROW_INDICES.indexOf(fieldIndex) < 0 || this.isServing(fieldIndex)) {
        if (debug) console.log(`the libero is only allowed to replace players in a backrow position ${fieldIndex + 1} and may not serve`, fieldPlayer.lastName, BACKROW_INDICES.indexOf(fieldIndex) < 0, this.isServing(fieldIndex))
        return false;
      }

      // allow libero for libero but not player for libero
      // player for libero only in libero out function
      if (!isBenchPlayerLibero && benchPlayer !== this.liberoSubstitute) {
        if (debug) console.log(`allow libero for libero but not player for libero`, isFieldPlayerLibero, !isBenchPlayerLibero, benchPlayer !== this.liberoSubstitute)
        return false;
      }

    } else {

      if (fieldPlayer) {

        const { starting, substitutedInFor, substitutedOutFor } = this.lineup;

        // we cannot substitute a player for the libero substitute
        if (this.liberoSubstitute === benchPlayer) {
          if (debug && this.liberoSubstitute === benchPlayer) console.log(benchPlayer.lastName, 'is the liberoSubstitute')
          return false;
        }

        // player in the starting lineup can only be substituted out once
        if (starting.indexOf(fieldPlayer.uuid) > -1) {
          if (typeof substitutedOutFor[fieldPlayer.uuid] !== 'undefined') {
            if (debug) console.log(fieldPlayer.lastName, 'is a player in the starting lineup and therefore can only be substituted out once')
            return false;
          }
        }

        if (typeof substitutedOutFor[benchPlayer.uuid] !== 'undefined') {
          // substitutes can only be substituted out for the player they came in for
          const substitutedInId = substitutedOutFor[benchPlayer.uuid].playerInId;
          if (substitutedInId !== fieldPlayer.uuid) {
            const sub = this.teamSquad.players.find(p => p.uuid === substitutedInId);
            if (debug) console.log(benchPlayer.lastName, 'is a substitute and can only be substituted out for the player he came in for', sub.lastName, 'not', fieldPlayer.lastName)
            return false;
          }
        }

        // player in the starting lineup can only be substituted in again for the player they came out
        if (starting.indexOf(benchPlayer.uuid) < 0) {
          // substitutes can only be substituted in once for a starting player
          if (typeof substitutedInFor[benchPlayer.uuid] !== 'undefined') {
            if (debug) console.log(benchPlayer.lastName, 'is a substitute and can only be substituted in once')
            return false;
          }
          if (starting.indexOf(fieldPlayer.uuid) < 0) {
            if (debug) console.log(benchPlayer.lastName, 'is a substitute and can only be substituted for a starting player', fieldPlayer.lastName, 'is not a starting player')
            return false;
          }
        }

        // if the player on the field was substituted in, he can only be substituted out against the player he was substituted in
        if (typeof substitutedInFor[fieldPlayer.uuid] !== 'undefined') {
          if (substitutedInFor[fieldPlayer.uuid].playerOutId !== benchPlayer.uuid) {
            if (debug) {
              const substituteId = substitutedInFor[fieldPlayer.uuid].playerOutId;
              const sub = this.teamSquad.players.find(p => p.uuid === substituteId);
              if (debug) console.log(fieldPlayer.lastName, 'was substituted in, so he can only be substituted out against the player he was substituted in', sub.lastName, 'not', benchPlayer.lastName)
            }
            return false;
          }
        }
      }


    }

    if (debug) console.log('all checks passed', benchPlayer.lastName, 'is possible sub for', fieldPlayer.lastName)

    return true;

  }

  get liberoCandidates() {
    return this.playersOnBench.filter(p => !this.isPlayerUnallowedToPlay(p));
  }

  onReplaceLibero(oldLiberoIndex: number, oldLibero: Player, newLibero: Player) {
    this.dispatchService.dispatchRemoteAction(new ReplaceLiberoAction({
      oldLiberoId: oldLibero.uuid, newLiberoId: newLibero.uuid, teamCode: this.teamCode
    }, this.matchId));
  }

  updatePossibleSubstitutesForDraggedPlayer(dragged: Dragged) {
    this.dragged = dragged;
    if (!dragged) {
      this.possibleSubstitutesForDraggedPlayer = [];
    } else {
      const { benchPlayer, teamCode } = dragged;
      this.possibleSubstitutesForDraggedPlayer = this.fieldPlayers.filter((fieldPlayer, i) => {
        const fieldPlayerIndex = this.positions[i] - 1;
        return this.isPossibleSubstitute(fieldPlayer, fieldPlayerIndex, benchPlayer);
      });
    }
  }

  isDroppable(fieldPlayer: Player, fieldIndex: number) {

    if (!this.dragged) {
      return false;
    }

    const { benchPlayer, teamCode } = this.dragged;

    // prevents dropping player on other teams player
    if (teamCode !== this.teamCode) {
      return false;
    }

    return this.possibleSubstitutesForDraggedPlayer.indexOf(fieldPlayer) > -1;
  }

  isPlayerUnallowedToPlay(p: Player) {
    return isPlayerUnallowedToPlay(this.injuries, this.individualSanctions, this.currentSetNumber, p.uuid);
  }

  isLiberoUnallowedToPlay(l: Player) {
    return isLiberoUnallowedToPlay(this.liberos, this.injuries, this.individualSanctions, this.currentSetNumber, l.uuid);
  }

  isPlayerOnField(p: Player) {
    return this.fieldPlayers.indexOf(p) > -1;
  }

  isCaptain(p: Player) {
    return p ? this.teamSquad.captain === p : false;
  }

  isLibero(p: Player) {
    return this.lineup.liberos.indexOf(p) > -1;
  }

  isServing(fieldIndex: number) {
    return isPlayerServing(this.teamCode, this.serving.currentlyServing, fieldIndex);
  }

  get liberoOnField() {
    return this.lineup.liberoOnField;
  }

  get liberoSubstitute() {
    return this.lineup.liberoSubstitute;
  }

  get isNumOfSubstitutionsExceeded() {
    return this.lineup.numOfSubstitutions >= this.maxRegularSubstitutionsPerSet;
  }

  get isLiberoOnField() {
    return !!this.liberoOnField;
  }

  get availableLiberos() {
    return this.lineup.liberos.filter(libero => !this.isLiberoUnallowedToPlay(libero));
  }

  get isLeftTeam() {
    return this.teamSide === TeamSideValues.leftTeam;
  }

  get cols() {
    return isSmallField(this.scoreConfig) ? 1 : 2
  }

  isPossibleToRemovePlayer(p: Player) {
    return !this.hasSetStarted && p && !this.isMatchFinished && !this.isStartingSixLocked
  }

  isNotDroppable(p: Player, fieldIndex: number) {
    if (this.dragged) {
      if (this.dragged.teamCode === this.teamCode && !this.isDroppable(p, fieldIndex)) {
        return true;
      }
    }
  }

  get defaultLibero() {
    return this.liberos.defaultLibero
  }

}
