import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sams-maximum-matches-warning-dialog',
  template: `
    <h1 mat-dialog-title style="text-align: center;">{{'component.matches-overview.maximum_matches_warning' | translate}}</h1>
    <p style="text-align: center;">{{'component.matches-overview.maximum_matches_warning_info' | translate}}</p>
    <div mat-dialog-actions>
      <button mat-raised-button color="success" (click)="onClick()">{{'app.confirm' | translate}}</button>
    </div>
  `,
  styles: [`
    .mat-dialog-actions {
      display: flex;
      justify-content: center;
    }
  `]
})
export class MaximumMatchesWarningDialogComponent {

  constructor(public dialogRef: MatDialogRef<MaximumMatchesWarningDialogComponent>) {
    dialogRef.disableClose = true;
  }
  onClick = () => {
    this.dialogRef.close();
  }
}
