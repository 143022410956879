import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

import { Player } from 'app/models';

@Component({
  selector: 'sams-player-select',
  template: `
    <div class="player-select-wrapper">
      <h4>{{availablePlayersTitle | translate}}</h4>
      <mat-radio-group class="player-selection" (change)="onPlayerSelected($event.value)">
        <mat-radio-button class="player-select" *ngFor="let p of availablePlayers" [value]="p">
          {{p.jerseyNumber}} {{p.lastName}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  `,
  styles: [`

    .player-selection {
      display: inline-flex;
      flex-direction: column;
    }

    .player-select {
      margin: 5px;
    }

    .selected-player {
      margin: 15px 0;
    }

  `]
})
export class PlayerSelectComponent implements OnInit {

  @Input() availablePlayersTitle: string;

  @Input() availablePlayers: Player[];

  @Output() playerSelected = new EventEmitter<Player>();

  constructor() {
  }

  ngOnInit() {
  }

  onPlayerSelected(p: Player) {
    this.playerSelected.emit(p);
  }

}
