import { LineupsComponent } from './lineups.component';
import { LineupComponent } from './lineup.component';
import { LineupsModificationComponent } from './lineups-modification.component';
import { LineupModificationComponent } from './lineup-modification.component';

export const LINEUPS_COMPONENTS = [
  LineupsComponent,
  LineupComponent,
  LineupModificationComponent,
  LineupsModificationComponent
];
