import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import * as moment from 'moment';

import { Match } from 'app/models';
import { MatchIdentifiers } from '../../../../shared/reducers/matches-overview/matches-overview.action';

@Component({
  selector: 'sams-match-preview',
  template: `
      <mat-card class="match-card" [ngClass]="{'selected': selected}">

        <div class="association">
          <sams-logo [src]="association?.logoImage?.url" [alt]="association?.name" [letter]="association?.name?.charAt(0)" size="55px"></sams-logo>
          <div class="association-name">
            <p>{{association?.name}}</p>
            <p>{{matchSeries?.name}}</p>
          </div>
        </div>

        <mat-card-header>
          <mat-card-title>
            {{'component.match-preview.match_number' | translate}}: <b>{{match.matchNumber}}</b> <br>
            {{match.team1Description}} <br>
            vs. <br>
            {{match.team2Description}}
          </mat-card-title>
          <sams-logo [src]="team1.logoImageMax400x400?.url" [alt]="team1.name" [letter]="team1.singleLetterName" size="80px"></sams-logo>
          <div style="margin: 0px 5px;"></div>
          <sams-logo [src]="team2.logoImageMax400x400?.url" [alt]="team2.name" [letter]="team2.singleLetterName" size="80px"></sams-logo>
        </mat-card-header>

        <mat-card-content>
          <p>{{matchDate}}</p>
          <p>
            {{location? location.name : 'component.match-preview.unknown_location' | translate}}
            <br/>
            <span class="hint">{{locationSingleLineAddress ? locationSingleLineAddress : 'component.match-preview.unknown_address' | translate}}</span>
          </p>
        </mat-card-content>

        <mat-card-actions>
          <button mat-raised-button (click)="removeMatch.emit(matchId)" color="warn">{{'app.delete' | translate}}</button>
          <button *ngIf="!isTestMatch" mat-raised-button (click)="refreshMatch.emit(matchIdentifiers)">{{'app.reload' | translate}}</button>
          <button mat-raised-button (click)="selectMatch.emit(matchId)">{{'app.view' | translate}}</button>
          <button *ngIf="refereeViewEnabled" mat-raised-button (click)="selectMatchReferee.emit(matchId)">{{'app.view_referee' | translate}}</button>
        </mat-card-actions>
      </mat-card>
    `,
    styles: [`
      .mat-raised-button {
        margin: 5px 0;
        margin-right: 5px!important;
      }
      .association {
        display: flex;
        justify-content: flex-start;
        padding-bottom: 15px;
      }
      .association-name {
        padding-left: 15px;
      }
      .association-name p {
        margin: 2px;
        padding: 0;
      }
      .match-card {
        margin: 15px;
        max-width: 350px;
      }
      .mat-card-title {
        font-weight: normal;
        font-size: 14px;
        text-align: center;
      }
      .match-card.selected {
        background-color: RoyalBlue;
        color: white;
      }

    `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatchPreviewComponent {

  @Input() match: Match;

  @Input() selected: boolean;

  @Output() removeMatch = new EventEmitter<string>();

  @Output() refreshMatch = new EventEmitter<MatchIdentifiers>();

  @Output() selectMatch = new EventEmitter<string>();

  @Output() selectMatchReferee = new EventEmitter<string>();

  constructor() {
  }

  get isTestMatch() {
    return this.match.isTestMatch;
  }

  get matchTitle() {
    return `${this.match.team1Description} vs. ${this.match.team2Description}`;
  }

  get matchDate() {
    return moment(this.match.date).format('dddd, D. MMMM YYYY, HH:mm');
  }

  get team1() {
    return this.match.team1;
  }

  get team2() {
    return this.match.team2;
  }

  get location(){
    return this.match.location;
  }

  get association() {
    return this.match.association;
  }

  get matchSeries() {
    return this.match.matchSeries;
  }

  get fetchToken() {
    return this.match.fetchToken;
  }

  get matchIdentifiers() {
      return { matchId: this.matchId, fetchToken: this.fetchToken };
  }

  get matchId() {
    return this.match.uuid;
  }
  
  get locationSingleLineAddress() {
    const location = this.match.location;
    if (!location) {
      return null
    }
    const address = location.address;
    if (address) {
      return [address.street, address.postcode, address.city].join(', ');
    }
    return null;
  }

  get refereeViewEnabled() {
    return this.match.scoreConfiguration.refereeViewEnabled;
  }

}
