import { MatchAction } from '../../../interfaces/interfaces.js';

export const LOCK_STARTING_SIX = '[MatchSet] Lock starting six';

export class LockStartingSixAction extends MatchAction {
  readonly type = LOCK_STARTING_SIX;
  constructor(matchId: string) {
    super(matchId);
  }
}

export type StartingSixAction
  = LockStartingSixAction;
