import {
  merge as observableMerge,
  fromEvent as observableFromEvent,
  Observable,
  Subscription
} from 'rxjs';
import { mapTo, debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { Store } from '@ngrx/store';

import {
  RemoteAction,
  RoomJoinRequest
} from '../../../../shared/interfaces/interfaces';
import {
  SocketEvents,
  CheckHistoryRequest,
  PushActionHistoryRequest,
  PullActionHistoryResponse,
  OutOfSyncResponse,
} from '../../../../shared/interfaces/interfaces';

import { config } from '../config/config';
import { ConnectionsService } from './connections.service';
import {
  ReceiveAction
} from '../../../../shared/reducers/connections/connections.action';

import * as fromShared from '../root/shared.reducer';
import { ConnectionAuth } from '../../../../shared/reducers/connections/connections.reducer';

@Injectable()
export class SocketService implements OnDestroy {

  socket: Socket;

  matchesHttpUrl: string;

  receiveAction$: Observable<RemoteAction>;

  isConnected$: Observable<boolean>;

  isOnline$: Observable<boolean>;

  outOfSync$: Observable<OutOfSyncResponse>;

  inSync$: Observable<string>;

  private subscription = new Subscription();

  constructor(
    public connectionsService: ConnectionsService,
    public store: Store<fromShared.Root>,
  ) {

    this.matchesHttpUrl = `${config.apiHttpServerUrl}/matches`;

    const auths: { [matchId: string]: ConnectionAuth } = Object.keys(this.connectionsService.connections).reduce((prev, matchId) => {
      return {
        ...prev,
        [matchId]: this.connectionsService.connections[matchId].auth
      }
    }, {})

    this.socket = io(this.matchesHttpUrl, { autoConnect: true, auth: auths });

    this.receiveAction$ = observableFromEvent(this.socket, SocketEvents.BROADCAST_ACTION);
    this.outOfSync$ = observableFromEvent(this.socket, SocketEvents.OUT_OF_SYNC);
    this.inSync$ = observableFromEvent(this.socket, SocketEvents.IN_SYNC);

    this.subscription.add(
      this.store.select(fromShared.getMatchId).pipe(
        distinctUntilChanged(),
        filter(id => id != null)) // when deselecting a match matchId is null, we do not want to join null rooms, so filter those!
        .subscribe(matchId => this.joinRoom(matchId, this.connectionsService.connections[matchId]?.auth)
      )
    )

    this.subscription.add(
      this.receiveAction$.subscribe((action: RemoteAction) => {
        this.receiveAction(action)
      })
    );

    this.subscription.add(
      observableFromEvent(this.socket, SocketEvents.PULL_ACTION_HISTORY_RESPONSE)
        .subscribe((response: PullActionHistoryResponse) => this.pullActionHistoryResponse(response))
    );

    this.subscription.add(
      observableFromEvent(this.socket, SocketEvents.PULL_ACTION_HISTORY)
        .pipe(debounceTime(500))
        .subscribe((matchId: string) => {
          this.pushActionHistory(matchId, this.connectionsService.getActionHistory(matchId))
        })
    );

    this.isConnected$ = observableMerge(
      observableFromEvent(this.socket, 'connect').pipe(
        mapTo(true)),
      observableFromEvent(this.socket, 'disconnect').pipe(
        mapTo(false))
    );

    this.isOnline$ = observableMerge(
      observableFromEvent(window, 'online').pipe(
        mapTo(true)),
      observableFromEvent(window, 'offline').pipe(
        mapTo(false))
    );

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  joinRoom(matchId: string, auth?: ConnectionAuth) {
    const roomJoinRequest: RoomJoinRequest = { matchId, auth }
    this.socket.emit(SocketEvents.ROOM_JOIN_REQUEST, roomJoinRequest);
  }

  public receiveAction(action: RemoteAction) {
    // This check is vital, since otherwise we add duplicate actions if we pulled the actionhistory 
    // while another socket keeps sending pending actions which the server broadcasts
    if (!this.connectionsService.getActionHistory(action.matchId).find(a => a.uuid === action.uuid)) {
      this.store.dispatch(new ReceiveAction(action.matchId));
      this.store.dispatch(action);
    }
  }

  public sendRemoteAction(action: RemoteAction) {
    this.socket.emit(SocketEvents.SEND_ACTION, action);
  }

  sendHistoryChecksum(matchId: string) {
    // we skip checksum comparison if we have actions in buffer since
    // the checksum is checked on every action sent and otherwise we would get out of sync
    // even though we are in sync since the server has to catch up with the client before this check is done.
    // TODO: SCORE-343 the match join action is always a
    // pending action and prevents checking the history, therefore timeout in syncing component is needed
    if (this.hasPendingActions) return
    const historyChecksum = this.connectionsService.createHistoryChecksum(matchId);
    const historyChecksumRequest: CheckHistoryRequest = { historyChecksum, matchId };
    this.socket.emit(SocketEvents.CHECK_HISTORY_REQUEST, historyChecksumRequest);
  }

  public pullActionHistoryResponse({ actionHistory, matchId }: PullActionHistoryResponse) {
    if (actionHistory.length === 0) {
      return
    }
    this.connectionsService.replaceActionHistory(matchId, actionHistory);
  }

  pushActionHistory(matchId: string, actionHistory: RemoteAction[]) {
    this.socket.emit(SocketEvents.PUSH_ACTION_HISTORY_REQUEST,
      { matchId, actionHistory } as PushActionHistoryRequest);
  }

  pullActionHistory(matchId: string) {
    this.socket.emit(SocketEvents.PULL_ACTION_HISTORY_REQUEST, matchId);
  }

  public get hasPendingActions() {
    return this.socket.sendBuffer.length > 0
  }

}
