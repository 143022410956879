import handlebars from "handlebars";
import moment from "moment";

import { MatchState } from "../../../shared/reducers/match-states/match-state.reducer.js";
import { ScoresheetModel } from "../model/scoresheet-model.js";
import { SCORE_LOGO } from "../../media/svg.js";
import { getMessage } from "./i18n.js";

export class ScoresheetGenerator {

  private template: HandlebarsTemplateDelegate;

  public model: ScoresheetModel;

  public error: Error;

  constructor(public state: MatchState, templateHbs: string, templateCss: string) {
    try {
      this.model = ScoresheetModel.of(state);
      this.registerHelpers(templateCss);
      
      this.template = handlebars.compile(templateHbs);
    } catch (e) {
      this.error = e;
    }
  }

  private registerHelpers = (templateCss: string) => {
    handlebars.registerHelper('times', function(n: number, block: any) {
      var accum = '';
      for(var i = 0; i < n; ++i) {
          block.data.index = i;
          block.data.first = i === 0;
          block.data.last = i === (n - 1);
          accum += block.fn(this);
      }
      return accum;
  });
    handlebars.registerHelper('ifIn', function(elem: any, list: any, options: any) {
      if(list.indexOf(elem) > -1) {
        return options.fn(this);
      }
      return options.inverse(this);
    });
    handlebars.registerHelper('msg', (s: string, block: any) => {
      return getMessage(s);
    });
    handlebars.registerHelper('concat', (a: string, b: string): string => {
      return a.concat(b);
    });
    handlebars.registerHelper('add', (a: number, b: number): number => {
      return a + b;
    });
    handlebars.registerHelper('odd', (n: number, options: any): boolean => {
      return n % 2 == 1 ? options.fn(this) : options.inverse(this);
    });
    handlebars.registerHelper('even', (n: number, options: any): boolean => {
      return n % 2 == 0 ? options.fn(this) : options.inverse(this);
    });
    handlebars.registerHelper('moment', (n: moment.Moment, pattern: string): string => {
      return n ? n.format(pattern) : "";
    });
    handlebars.registerHelper('asMoment', (n: string, pattern: string) => {
      return n ? moment(n).format(pattern) : ""
    });
    handlebars.registerHelper('compare', (a: any, operator: string, b: any, options: any): any => {
      var result: boolean;
      switch (operator) {
        case '==':
          result = a == b;
          break;
        case '===':
          result = a === b;
          break;
        case '!=':
          result = a != b;
          break;
        case '!==':
          result = a !== b;
          break;
        case '<':
          result = a < b;
          break;
        case '>':
          result = a > b;
          break;
        case '<=':
          result = a <= b;
          break;
        case '>=':
          result = a >= b;
          break;
        case 'typeof':
          result = typeof a === b;
          break;
        default: {
          throw new Error('helper {{compare}}: invalid operator: `' + operator + '`');
        }
      }
      return result ? options.fn(this) : options.inverse(this);
    });

    handlebars.registerHelper('style', (block: any) => {
      return '<style>' + templateCss + '</style>';
    });

    handlebars.registerHelper('repeat', (n: number, block: any) => {
      var accum = '';
      for (var i = 0; i < n; ++i) {
        block.data.index = i;
        block.data.first = i === 0;
        block.data.last = i === (n - 1);
        accum += block.fn(this);
      }
      return accum;
    });
  }

  public toHtmlString = (): string => {
    if (this.error != null) {
      return `<html>
<head><title>Error</title></head>
<body><h1>${this.error}</h1>
<br/>
<pre>${this.error.stack}</pre>
</body></html>`;
    }

    return this.template({ 'model': this.model, 'scoreLogo': SCORE_LOGO, 'now': moment() });
  }

}