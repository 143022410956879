import teamSquadsCss from '../../../../scoresheet-generator/src/scss/team-squads.css';
import teamSquadsTemplate from 'raw-loader!../../../../scoresheet-generator/src/hbs/team-squads.hbs';

import { ScoresheetGenerator } from '../../../../scoresheet-generator/src/generator/generator';
import { MatchState } from '../../../../shared/reducers/match-states/match-state.reducer';

export class TeamSquadsPresenter {

  constructor(private matchState: MatchState) {
  }
  
  openTeamSquadsView() {
    const w = window.open();
    const html = new ScoresheetGenerator(this.matchState, teamSquadsTemplate, teamSquadsCss).toHtmlString();
    w.document.write(html);
  }
}