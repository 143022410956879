import { ActionHistory } from './connections.reducer.js';
import { Action } from '../../interfaces/interfaces.js';
import { StartMatchAction } from '../game-state/match-sets/time-keeping/time-keeping.action.js';
import { AddMatchSuccessAction } from '../matches-overview/matches-overview.action.js';
import { AuthTokenRefreshAction } from '../auth/auth.action.js';

export const SEND = '[Connection] send last action';
export const RECEIVE = '[Connection] receive action';
export const REPLACE_HISTORY = '[Connection] replace history';

export class SendAction implements Action {
  readonly type = SEND;

  constructor(public matchId: string) {
  }
}

export class ReceiveAction implements Action {
  readonly type = RECEIVE;

  constructor(public matchId: string) {
  }
}

export class ReplaceHistoryAction implements Action {
  readonly type = REPLACE_HISTORY;

  constructor(public payload: ActionHistory, public matchId: string) {
  }
}

export type ConnectionsAction
  = SendAction
  | StartMatchAction
  | AddMatchSuccessAction
  | ReceiveAction
  | ReplaceHistoryAction
  | AuthTokenRefreshAction;
