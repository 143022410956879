import moment from 'moment';
import { v4 } from 'uuid';

import { MatchState } from '../reducers/match-states/match-state.reducer.js';
import { MatchStateAction } from '../reducers/match-states/match-state.action.js';
import { ConnectionsAction } from '../reducers/connections/connections.action.js';
import { BeachMatchStateAction } from '../beach/reducers/match-states/beach-match-state.action.js';
import { BeachMatchState } from '../beach/reducers/match-states/beach-match-state.reducer.js';
import { ConnectionAuth } from '../reducers/connections/connections.reducer.js';

export abstract class MatchAction implements Action {
  type: string;
  payload?: any;
  timestamp = +moment();
  uuid = v4();
  constructor(public matchId: string) { }
}

export type ExtendedReducer<T, S> = (state: T, action: Action, additionalState?: S) => T;

export interface MatchUpdatePayload {
  matchState: MatchState | BeachMatchState
  action: MatchStateAction | BeachMatchStateAction
}

export interface MatchStateReplacementPayload {
  matchState: MatchState | BeachMatchState
}

export interface Action {
  type: string;
  payload?: any;
}

export interface MatchAction extends Action {
  matchId: string;
  uuid: string;
  timestamp: number;
  orderRank: number;
}

export interface RemoteAction extends MatchAction {
  uuidHistoryChecksum: string;
}

export const isRemoteAction = (action: RemoteAction | ConnectionsAction): action is RemoteAction => {
  return (action as RemoteAction).uuidHistoryChecksum !== undefined;
}

export interface ErrorInfo {
  message: string;
  stack: string;
}

export interface CheckHistoryRequest {
  matchId: string;
  historyChecksum: string;
}

export interface RoomJoinRequest {
  matchId: string
  auth: ConnectionAuth
}

export interface PushActionHistoryRequest {
  matchId: string;
  actionHistory: RemoteAction[];
}

export interface PullActionHistoryResponse {
  matchId: string;
  actionHistory: RemoteAction[];
}

export interface OutOfSyncResponse {
  matchId: string;
  lastActionTimestamp: number;
  checksum: string;
  numberOfMatchActions: number;
}

export const SocketEvents = {
  BROADCAST_ACTION:             '[SERVER] BROADCAST ACTION',
  IN_SYNC:                      '[SERVER] IN SYNC',
  OUT_OF_SYNC:                  '[SERVER] OUT OF SYNC',
  ROOM_JOIN_REQUEST:            '[CLIENT] ROOM JOIN REQUEST',
  ROOM_JOIN_RESPONSE:           '[SERVER] ROOM JOIN RESPONSE',
  SEND_ACTION:                  '[CLIENT] SEND ACTION',
  PUSH_ACTION_HISTORY_REQUEST:  '[CLIENT] PUSH ACTION HISTORY REQUEST',
  PULL_ACTION_HISTORY_REQUEST:  '[CLIENT] PULL ACTION HISTORY REQUEST',
  PULL_ACTION_HISTORY_RESPONSE: '[SERVER] PULL ACTION HISTORY RESPONSE',
  CHECK_HISTORY_REQUEST:        '[CLIENT] CHECK_HISTORY_REQUEST',
  PULL_ACTION_HISTORY:          '[SERVER] PULL ACTION HISTORY'
};
