import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';

import * as fromBeach from 'app/root/beach.reducer';
import { TranslateService } from '@ngx-translate/core';
import { TeamSides } from '../../../../../shared/reducers/game-state/match-sets/team-sides/team-sides.reducer';
import { BeachMatchState } from '../../../../../shared/beach/reducers/match-states/beach-match-state.reducer';
import { BeachMatch } from '../../../../../shared/beach/model/beach-match';
import {
  RevertRefereeMatchSignatureAction,
  RevertTeamMatchSignatureAction,
  SignOffMatchRefereeAction,
  SignOffMatchTeamAction,
  SignOffTeamPayload } from '../../../../../shared/beach/reducers/match-states/signature/beach-signature.action';
import { TeamCode } from '../../../../../shared/interfaces/models';
import { BeachTeamSquads } from '../../../../../shared/beach/reducers/match-states/team-squads/beach-team-squads.reducer';
import { MATCH_FINALIZE_STATUS } from '../../../../../shared/beach/reducers/match-states/match-finalization/beach-match-finalization.reducer';
import { BeachFinalizationService } from './beach-finalization.service';
import { DispatchService } from '../../connections/dispatch.service';

@Component({
  selector: 'sams-beach-match-finalization',
  template: `
    <sams-syncing *ngIf="!match.isTestMatch" [matchId]="matchId"></sams-syncing>

    <mat-toolbar color="secondary">
      <div (click)="navigateHome()" style="height: 100%; width: auto; cursor: pointer;">
        <sams-score-logo></sams-score-logo>
      </div>
      <sams-listening *ngIf="!match.isTestMatch" [matchId]="matchId"></sams-listening>
      <sams-connection></sams-connection>
    </mat-toolbar>

    <div class="match-finalization">

      <mat-card>
        <h2>{{'component.match-finalization.finalize_match' | translate}}</h2>

        <mat-card-content>
          <div class="flex-container">
            <div class="match-container">
              <div class="match-info">
                <div>
                  {{match.matchSeries.name}}
                </div>
                <div>
                  {{formatDate()}}
                </div>
                <div>
                  {{formatTime()}} Uhr
                </div>
              </div>
              <div class="match-header">
                <div class="team-name">
                  {{leftTeam.name}}
                  <sams-beach-signature
                    [matchId]="matchId"
                    [disabled]="isSignatureDisabled"
                    [signature]="leftTeamSignature"
                    (signOff)="onTeamSignOff($event, teamSides.leftTeam)"
                    (revert)="onTeamRevert(teamSides.leftTeam)">
                  </sams-beach-signature>
                </div>
                <div class="team-name">
                  {{rightTeam.name}}
                  <sams-beach-signature
                    [matchId]="matchId"
                    [disabled]="isSignatureDisabled"
                    [signature]="rightTeamSignature"
                    (signOff)="onTeamSignOff($event, teamSides.rightTeam)"
                    (revert)="onTeamRevert(teamSides.rightTeam)">
                  </sams-beach-signature>
                </div>
              </div>
            </div>

          </div>

          <div class="referee-signature">
            <h3>{{'app.first_referee' | translate}}: {{firstReferee.firstName}} {{firstReferee.lastName}}</h3>
            <sams-beach-signature
              [matchId]="matchId"
              [disabled]="isSignatureDisabled"
              [signature]="refereeSignature"
              (signOff)="onRefereeSignOff($event)"
              (revert)="onRefereeRevert()">
            </sams-beach-signature>
          </div>

          <div class="comments">
            <h2>{{'app.comments' | translate}}</h2>
            <p>{{comments$ | async}}</p>
            <p class="no-comments" *ngIf="!(comments$ | async)">keine Bemerkungen</p>
          </div>

          <div class="action-buttons">
            <button mat-stroked-button (click)="openScoresheetView()">
              {{'app.scoresheet' | translate}}
            </button>

            <button mat-raised-button class="positive" *ngIf="!isMatchSuccessfullyFinalized" [disabled]="!isMatchSigned" (click)="onConfirm()">
              {{'app.confirm' | translate}}
              <mat-icon>send</mat-icon>
            </button>
          </div>

        </mat-card-content>

        <div class="result-message">
          <mat-card class="warn" *ngIf="hasMatchFinalizationFailed">
            <mat-card-content style="font-size: 12px; text-align: center;">{{'component.match-finalization.match_finalization_failed_message' | translate}}</mat-card-content>
            <mat-card-actions>
              <button style="color: black;" mat-raised-button (click)="openScoresheetView()">
                {{'component.match-finalization.save_scoresheet' | translate}}
              </button>
            </mat-card-actions>
          </mat-card>

          <mat-card class="success" *ngIf="isMatchSuccessfullyFinalized">
            <mat-card-content style="font-size: 12px; text-align: center;">{{'component.match-finalization.match_finalization_success_message' | translate}}</mat-card-content>
          </mat-card>
        </div>

      </mat-card>

    </div>

  `,
  styles: [`

    .result-message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    h2 {
      font-weight: normal;
      padding: 15px;
      padding-bottom: 20px;
    }

    .referee-signature {
      text-align: center;
    }

    .team-signatures {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .action-buttons {
      display: flex;
      button {
        width: 50%;
        margin: 15px;
      }
    }

    button {
      max-height: 36px;
    }

    .pins {
      padding-bottom: 30px;
    }

    .mat-card-header {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: auto auto;
      position: relative;
      margin-bottom: 20px;
    }
    
    .mat-card-content {
      display: grid;
      grid-template-rows: auto auto;
      grid-template-columns: auto;
    }

    .mat-card-content .flex-container {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-around;
    }

    .match-container {
      display: flex;
      flex-direction: column;
    }

    .trivia-container {
      display: flex;
      flex-direction: column;
    }

    .match-header {
      display: flex;
      align-items: center;
    }

    .match-body {
      display: grid;
      grid-column: 1 / 10;
      grid-row: 2 / 3;
      grid-template-columns: repeat(9, 1fr);
      align-items: center;
    }
    sams-match-final-scores {
      display: grid;
      grid-column: 1 / 10;
    }
    .match-info {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      grid-column: 4 / 7;
      grid-row: 1 / 2;
    }
    .match-info div {
      margin: 2px;
    }
    .match-info div:first-child {
      width: 100%;
    }

    .team-name {
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 5px;
    }
    .team-name:first-child {
      grid-column: 1 / 4;
      grid-row: 1 / 2;
    }
    .team-name:last-child {
      display: flex;
      align-items: center;
      flex-direction: column;
      grid-column: 7 / 10;
      grid-row: 1 / 2;
    }

    .team-name >* {
      margin: 5px;
    }

    .no-comments {
      font-style: italic;
      color: grey; 
    }

    .comments {
      white-space: pre-wrap;
      max-width: 600px;
      align-self: flex-start;
      margin-left: 15px;
    }

    .yellow {
      background-color: #feda56;
    }

    .reconnect {
      position: absolute;
      top: 140px;
      color: white;
      z-index: 1;
    }

    mat-card-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    mat-card-actions {
      display: flex;
      justify-content: center;
    }

    mat-card.success {
      background-color: lightgreen;
      font-weight: bold;
      font-size: 1.1rem;
      max-width: 250px;
    }

    .warn {
      background-color: tomato;
      color: white;
    }

    h3 {
      font-weight: normal;
    }

    h2 {
      color: #424242;
      margin: 0;
      margin-top: 15px;
    }

    div.match-finalization {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 15px 0;
    }

    .flex-container {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
    }

    .flex-container > * {
      margin: 0 15px;
    }

  `]
})
export class BeachMatchFinalizationComponent implements OnInit, OnDestroy {

  match: BeachMatch;

  teamSides: TeamSides;

  matchDate: Date;

  matchState: BeachMatchState;

  teamSquads: BeachTeamSquads;

  comments$: Observable<string>;

  isMatchFinished: boolean;

  private subscription = new Subscription();

  constructor(private store: Store<fromBeach.BeachRoot>,
    private router: Router,
    private dispatchService: DispatchService,
    private finalizationService: BeachFinalizationService,
    public translate: TranslateService) {
  }

  openScoresheetView() {
    this.router.navigate(['/scoresheet'])
  }

  ngOnInit() {

    this.subscription.add(
      this.store.select(fromBeach.getBeachMatchState).subscribe(matchState => this.matchState = matchState)
    );

    this.subscription.add(
      this.store.select(fromBeach.getBeachMatch).subscribe(match => {
        this.match = match
        this.matchDate = new Date(match.date)
      })
    );

    this.comments$ = this.store.select(fromBeach.getBeachComments);

    this.subscription.add(
      this.store.select(fromBeach.getBeachTeamSquads).subscribe(teamSquads => this.teamSquads = teamSquads)
    )

    this.subscription.add(
      this.store.select(fromBeach.getTeamSides)
        .subscribe(teamSides => this.teamSides = teamSides)
    );

    this.subscription.add(
      this.store.select(fromBeach.getIsMatchFinished)
        .subscribe(isMatchFinished => this.isMatchFinished = isMatchFinished)
    )

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  navigateHome() {
    this.router.navigate(['/matches-overview'])
  }

  onTeamRevert(teamCode: TeamCode) {
    const revertAction = new RevertTeamMatchSignatureAction({ teamCode }, this.matchId)
    return this.dispatchService.dispatchRemoteAction(revertAction)
  }

  onTeamSignOff(signatureString: string, teamCode: TeamCode) {
    const playerId = this.teamSquads[teamCode].captain.uuid
    const payload: SignOffTeamPayload = {
      teamCode,
      signatureString,
      playerId
    }
    const signOffAction = new SignOffMatchTeamAction(payload, this.matchId)
    this.dispatchService.dispatchRemoteAction(signOffAction)
  }

  onRefereeSignOff(signatureString: string) {
    this.dispatchService.dispatchRemoteAction(
      new SignOffMatchRefereeAction({ refereeId: this.refereeId, signatureString }, this.matchId)
    )
  }

  onRefereeRevert() {
    this.dispatchService.dispatchRemoteAction(
      new RevertRefereeMatchSignatureAction({ refereeId: this.refereeId }, this.matchId)
    )
  }

  get isSignatureDisabled() {
    return this.isMatchSuccessfullyFinalized || !this.isMatchFinished
  }

  onConfirm() {
    this.finalizationService.finalizeMatch(this.matchState)
  }

  get signatures() {
    return this.matchFinalization.signatures
  }

  get isMatchSigned() {
    return this.signatures.referee.signatureString !== null
      && this.signatures.team1.signatureString !== null
      && this.signatures.team2.signatureString !== null
  }

  get matchFinalization() {
    return this.matchState.matchFinalization
  }

  get arbitration() {
    return this.matchState.matchPreparation.arbitration
  }

  get refereeId() {
    return this.firstReferee.personUuid
  }

  get firstReferee() {
    return this.arbitration.firstReferee
  }

  get refereeSignature() {
    return this.matchFinalization.signatures.referee.signatureString
  }

  get leftTeamSignature() {
    const leftTeamCode = this.teamSides.leftTeam
    return this.matchFinalization.signatures[leftTeamCode].signatureString
  }

  get rightTeamSignature() {
    const rightTeamCode = this.teamSides.rightTeam
    return this.matchFinalization.signatures[rightTeamCode].signatureString
  }

  get leftTeam() {
    return this.match[this.teamSides.leftTeam]
  }

  get rightTeam() {
    return this.match[this.teamSides.rightTeam]
  }

  get matchId() {
    return this.match.uuid;
  }

  get hasMatchFinalizationFailed() {
    return this.matchFinalization.status === MATCH_FINALIZE_STATUS.FAILED
  }

  get isMatchSuccessfullyFinalized() {
    return this.matchFinalization.status === MATCH_FINALIZE_STATUS.SUCCESS
  }

  formatDate = (): string => {
    const dateOpts = { day: '2-digit', weekday: 'short', month: '2-digit' }
    return this.matchDate.toLocaleString('de', dateOpts as any).replace(',', '')
  }

  formatTime = (): string => {
    const timeOpts = { hour: '2-digit', minute:'2-digit' }
    return this.matchDate.toLocaleString('de', timeOpts as any)
  }

}
