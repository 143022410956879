
import {of as observableOf } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

import { ErrorInfo } from '../../../../shared/interfaces/interfaces';

import { config } from 'app/config/config';
import * as fromShared from 'app/root/shared.reducer';
import { AuthErrorType, AuthFailureAction } from '../../../../shared/reducers/auth/auth.action';
import { AddMatchFailAction } from '../../../../shared/reducers/matches-overview/matches-overview.action';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorService implements OnInit {

  errorsHttpUrl = `${config.apiHttpServerUrl}/error`;

  constructor(private http: HttpClient, public store: Store<fromShared.Root>, public translate: TranslateService) {
  }

  ngOnInit() {
  }

  handleError(errorInfo: ErrorInfo) {

  // TODO: store errors in state and send them when online?
  this.http.post(this.errorsHttpUrl, errorInfo).pipe(
    catchError((err, caught) => observableOf(err)))
    .subscribe(res => {
      // window.location.href = "#/error";
      // window.location.reload(true);
    });

  }

  async localizeAddMatchFailAction(action: AddMatchFailAction) {
    let localizedMessage;
    switch (action.payload.status) {
      case 500: {
        localizedMessage = await this.translate.get('component.matches-overview.server_error').toPromise()
        break;
      }
      case 400: {
        localizedMessage = await this.translate.get('component.matches-overview.bad_request').toPromise()
        break;
      }
      case 404: {
        localizedMessage = await this.translate.get('component.matches-overview.fetch_match_not_found').toPromise()
        break;
      }
      case 401: {
        localizedMessage = await this.translate.get('component.matches-overview.fetch_match_forbidden').toPromise()
        break;
      }
      case 403: {
        localizedMessage = await this.translate.get('component.matches-overview.unauthorized').toPromise()
        break;
      }
      default: {
        localizedMessage = await this.translate.get('component.matches-overview.fetch_match_unknown_error').toPromise()
        break;
      }
    }
    return {
      ...action,
      payload: {
        ...action.payload,
        message: localizedMessage
      }
    } as AddMatchFailAction
  }

  async localizeAuthErrorAction(action: AuthFailureAction) {
    let localizedMessage: string;
    switch(action.payload?.error) {
      case AuthErrorType.UNAUTHORIZED:
        localizedMessage = await this.translate.get('component.matches-overview.unauthorized').toPromise()
        break;
      case AuthErrorType.JSON_WEBTOKEN_ERROR:
        localizedMessage = await this.translate.get('component.matches-overview.token_validation_error').toPromise()
        break;
      case AuthErrorType.TOKEN_EXPIRED:
        localizedMessage = await this.translate.get('component.matches-overview.token_expired').toPromise()
        break;
      case AuthErrorType.TOKEN_NOT_BEFORE_ERROR:
        localizedMessage = await this.translate.get('component.matches-overview.token_not_before').toPromise()
        break;
      case AuthErrorType.BAD_REQUEST: 
        localizedMessage = await this.translate.get('component.matches-overview.bad_request').toPromise()
        break;
      case AuthErrorType.HTTP_ERROR:
        localizedMessage = await this.translate.get('component.matches-overview.http_error').toPromise()
        break;
      case AuthErrorType.SERVER_ERROR:
        localizedMessage = await this.translate.get('component.matches-overview.server_error').toPromise()
        break;
      default: {
        localizedMessage = await this.translate.get('component.matches-overview.unknown_error').toPromise()
      }
    }
    return {
      ...action,
      payload: {
        ...action.payload,
        message: localizedMessage
      }
    } as AuthFailureAction
  }

}
