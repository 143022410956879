import {
  BeachSignatureAction,
  REVERT_REFEREE_MATCH_SIGNATURE,
  REVERT_TEAM_MATCH_SIGNATURE,
  SIGN_OFF_MATCH_REFEREE,
  SIGN_OFF_MATCH_TEAM
} from "../signature/beach-signature.action.js";
import {
  BeachSignatures,
  beachSignaturesReducer
} from "../signature/beach-signatures.reducer.js";
import {
  BEACH_CONFIRM_FINALIZATION,
  BEACH_CONFIRM_FINALIZATION_FAIL,
  BEACH_CONFIRM_FINALIZATION_SUCCESS,
  BeachMatchFinalizationAction
} from "./beach-match-finalization.action.js";

export enum MATCH_FINALIZE_STATUS {
  IDLE = 'IDLE',
  SUBMITTING = 'SUBMITTING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}

interface FinalizationState {
  signatures: BeachSignatures
}

interface FinalizeSuccessState extends FinalizationState {
  status: MATCH_FINALIZE_STATUS.SUCCESS
}

interface FinalizeFailedState extends FinalizationState {
  status: MATCH_FINALIZE_STATUS.FAILED
  errorMsg: string
}

interface FinalizeIdleState extends FinalizationState {
  status: MATCH_FINALIZE_STATUS.IDLE
}

interface FinalizeSubmittingState extends FinalizationState {
  status: MATCH_FINALIZE_STATUS.SUBMITTING
}

export type BeachMatchFinalization =
    FinalizeIdleState
  | FinalizeSubmittingState
  | FinalizeSuccessState
  | FinalizeFailedState

const initialState: BeachMatchFinalization = {
  status: MATCH_FINALIZE_STATUS.IDLE,
  signatures: beachSignaturesReducer(undefined, {} as BeachSignatureAction)
}

const idleFinalizationReducer = (state: FinalizeIdleState, action: BeachMatchFinalizationAction): BeachMatchFinalization => {

  switch (action.type) {

    case REVERT_TEAM_MATCH_SIGNATURE:
    case REVERT_REFEREE_MATCH_SIGNATURE:
    case SIGN_OFF_MATCH_REFEREE:
    case SIGN_OFF_MATCH_TEAM: {
      return {
        ...state,
        signatures: beachSignaturesReducer(state.signatures, action)
      }
    }
    case BEACH_CONFIRM_FINALIZATION: 
      return {
        ...state,
        status: MATCH_FINALIZE_STATUS.SUBMITTING
      }
    default:
      return state;
  }
}

const failedFinalizationReducer = (state: FinalizeFailedState, action: BeachMatchFinalizationAction): BeachMatchFinalization => {
  switch (action.type) {
    case BEACH_CONFIRM_FINALIZATION: 
      return {
        ...state,
        status: MATCH_FINALIZE_STATUS.SUBMITTING
      }
    default:
      return state;
  }
}

const submittingFinalizationReducer = (state: FinalizeSubmittingState, action: BeachMatchFinalizationAction): BeachMatchFinalization => {
  switch (action.type) {
    case BEACH_CONFIRM_FINALIZATION: {
      return {
        ...state,
        status: MATCH_FINALIZE_STATUS.SUBMITTING
      }
    }
    case BEACH_CONFIRM_FINALIZATION_SUCCESS: {
      return {
        ...state,
        status: MATCH_FINALIZE_STATUS.SUCCESS,
      }
    }
    case BEACH_CONFIRM_FINALIZATION_FAIL: {
      const { message } = action.payload
      return {
        ...state,
        status: MATCH_FINALIZE_STATUS.FAILED,
        errorMsg: message
      }
    }

    default:
      return state;
  }
}

export const beachMatchFinalizationReducer = (state: BeachMatchFinalization = initialState, action: BeachMatchFinalizationAction): BeachMatchFinalization => {
  switch (state.status) {
    case MATCH_FINALIZE_STATUS.FAILED:
      return failedFinalizationReducer(state, action)
    case MATCH_FINALIZE_STATUS.IDLE:
      return idleFinalizationReducer(state, action);
    case MATCH_FINALIZE_STATUS.SUBMITTING:
      return submittingFinalizationReducer(state, action);
    default:
      return state;
  }
}