import { RemoteAction, MatchAction } from '../../../interfaces/interfaces.js';

export const CONFIRM_FINALIZATION         = '[Match] Confirm finalization';
export const CONFIRM_FINALIZATION_SUCCESS = '[Match] Confirm finalization success';
export const CONFIRM_FINALIZATION_FAIL    = '[Match] Confirm finalization fail';

export interface ConfirmFinalizationPayload {
  team1Pin: string;
  team2Pin: string;
  refereePin: string;
}

export interface ConfirmFinalizationFailPayload {
  status: number
  message: string
}

export class ConfirmFinalizationAction extends MatchAction {
  readonly type = CONFIRM_FINALIZATION;
  constructor(public payload: ConfirmFinalizationPayload, matchId: string) {
    super(matchId);
  }
}

export class ConfirmFinalizationSuccessAction extends MatchAction implements RemoteAction {
  readonly type = CONFIRM_FINALIZATION_SUCCESS;
  constructor(matchId: string, public uuidHistoryChecksum: string) {
    super(matchId);
  }
}

export class ConfirmFinalizationFailAction extends MatchAction implements RemoteAction {
  readonly type = CONFIRM_FINALIZATION_FAIL;
  constructor(public payload: ConfirmFinalizationFailPayload, matchId: string, public uuidHistoryChecksum: string) {
    super(matchId);
  }
}


export type MatchFinalizationAction =
    ConfirmFinalizationAction
  | ConfirmFinalizationSuccessAction
  | ConfirmFinalizationFailAction;
