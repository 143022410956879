import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromShared from '../root/shared.reducer';

@Component({
  selector: 'sams-listening',
  template: `
    <mat-icon *ngIf="listening">hearing</mat-icon>
    <!-- <mat-icon *ngIf="!listening">wifi</mat-icon> -->
  `,
  styles: [``]
})
export class ListeningComponent implements OnInit, OnDestroy {

  @Input() matchId: string;

  listening: boolean;

  private subscription = new Subscription();

  constructor(public store: Store<fromShared.Root>) {

  }

  ngOnInit() {
    this.subscription.add(
      this.store.select(fromShared.getConnections)
        .subscribe(connections => this.listening = connections[this.matchId].listening)
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
