import { BeachMatch } from '../../beach/model/beach-match.js';
import { Action } from '../../interfaces/interfaces.js';
import { Match } from '../../interfaces/models/match.js';
import { AuthAction } from '../auth/auth.action.js';
import { ConnectionAuth } from '../connections/connections.reducer.js';

export const ADD_MATCH         = '[Match-Overview] Add Match';
export const ADD_MATCH_SUCCESS = '[Match-Overview] Add Match Success';
export const ADD_MATCH_FAIL    = '[Match-Overview] Add Match Fail';
export const REMOVE_MATCH      = '[Match-Overview] Remove Match';
export const REFRESH_MATCH     = '[Match-Overview] Refresh Match';
export const SELECT_MATCH      = '[Match-Overview] Select Match';
export const DESELECT_MATCH    = '[Match-Overview] Deselect Match';
export const SET_ERR_MSG       = '[Match-Overview] Set Error Message';

export interface AddMatchSuccessPayload {
  match: Match | BeachMatch
  auth?: ConnectionAuth
}

export interface AddMatchFailPayload {
  status: number
  message: string
}

export interface MatchIdentifiers {
  matchId: string
  fetchToken: string
}

export class AddMatchAction implements Action {
  readonly type = ADD_MATCH;
  constructor(public payload: string) { }
}

export class AddMatchSuccessAction implements Action {
  readonly type = ADD_MATCH_SUCCESS;
  constructor(public payload: AddMatchSuccessPayload) { }
}

export class AddMatchFailAction implements Action {
  readonly type = ADD_MATCH_FAIL;
  constructor(public payload: AddMatchFailPayload) { }
}

export class RemoveMatchAction implements Action {
  readonly type = REMOVE_MATCH;
  constructor(public payload: string) { }
}

export class RefreshMatchAction implements Action {
  readonly type = REFRESH_MATCH;
  constructor(public payload: MatchIdentifiers) { }
}

export class SelectMatchAction implements Action {
  readonly type = SELECT_MATCH;
  constructor(public payload: string) { }
}

export class DeselectMatchAction implements Action {
  readonly type = DESELECT_MATCH;
  constructor() { }
}

export class SetErrMsgAction implements Action {
  readonly type = SET_ERR_MSG;
  constructor(public payload: string) { }
}

export type MatchesOverviewAction
  = AddMatchAction
  | AddMatchSuccessAction
  | AddMatchFailAction
  | RemoveMatchAction
  | RefreshMatchAction
  | SelectMatchAction
  | DeselectMatchAction
  | SetErrMsgAction
  | AuthAction;
