
import {withLatestFrom, distinctUntilChanged} from 'rxjs/operators';
import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { TeamSides } from '../../../../../shared/reducers/game-state/match-sets/team-sides/team-sides.reducer';
import { Serving } from '../../../../../shared/reducers/game-state/match-sets/serving/serving.reducer';
import {
  SubstitutionAction,
  NominationAction,
  Substitution,
  Nomination,
  ExceptionalSubstitution
} from '../../../../../shared/reducers/game-state/match-sets/lineups/lineups.action';
import {
  LiberoOutAction,
  LiberoIn,
  LiberoInAction,
  LiberoOut,
  LiberoSwitch,
  LiberoSwitchAction,
  ExceptionalSubstitutionAction,
  Denomination,
  DenominationAction
} from '../../../../../shared/reducers/game-state/match-sets/lineups/lineups.action';
import { Lineup } from '../../../../../shared/reducers/game-state/match-sets/lineups/lineups.reducer';

import { DraggableService } from 'app/match-view/draggable.service';
import { ByTeamSide, Team, Player, TeamSide, Match, TeamSideValues, ScoreConfiguration } from 'app/models';
import * as fromIndoor from 'app/root/indoor.reducer';
import * as fromShared from 'app/root/shared.reducer';
import { LiberoRotatedOutDialogComponent } from '../dialogs/libero-rotated-out-dialog';
import {
  LineupsByTeamSides,
  TeamSquadsByTeamSides,
  InjuriesByTeamSides,
  LiberosByTeamSides,
} from 'app/root/indoor.reducer';
import { PositionsByTeamSides, SanctionsByTeamSides } from 'app/root/shared.reducer';
import { DispatchService } from 'app/connections/dispatch.service';

@Component({
  selector: 'sams-lineups',
  template: `
    <div class="lineups-container">
      <div class="lineup left-half">
        <sams-lineup
          [fieldPlayers] = "fieldPlayersLeftTeam$ | async"
          [lineup] = "(lineupsByTeamSides$ | async)?.leftTeam"
          [teamSquad] = "(teamSquadsByTeamSides$ | async)?.leftTeam"
          [serving] = "serving$ | async"
          [isListening] = "isListening$ | async"
          [teamCode] = "teamSides.leftTeam"
          [teamSide] = "TeamSideValues.leftTeam"
          [team] = "leftTeam$ | async"
          [positions] = "(positionsByTeamSides$ | async)?.leftTeam"
          [hasSetStarted] = "hasSetStarted$ | async"
          [isSetRunning] = "isSetRunning$ | async"
          [isMatchFinished] = "isMatchFinished$ | async"
          [individualSanctions] = "(sanctionsByTeamSides$ | async)?.leftTeam.individuals"
          [injuries] = "(injuriesByTeamSides$ | async)?.leftTeam"
          [liberos] = "(liberosByTeamSides$ | async)?.leftTeam"
          [scoreConfig] = "scoreConfig$ | async"
          [isStartingSixLocked] = "isStartingSixLocked$ | async"
          [readMode]="readMode"
          [liberoRegistrationEnabled]="liberoRegistrationEnabled$ | async"
          (liberoIn) = "onLiberoIn($event)"
          (liberoOut) = "onLiberOut($event)"
          (liberoSwitch) = "onLiberoSwitch($event)"
          (substitution) = "onSubstitution($event)"
          (exceptionalSubstitution) = "onExceptionalSubstitution($event)"
          (dropped) = "onDropped()"
          (nomination) = "onNomination($event)"
          (denomination) = "onDenomination($event)">
        </sams-lineup>
      </div>
      <div class="lineup right-half">
        <sams-lineup
          [fieldPlayers] = "fieldPlayersRightTeam$ | async"
          [lineup] = "(lineupsByTeamSides$ | async)?.rightTeam"
          [serving] = "serving$ | async"
          [isListening] = "isListening$ | async"
          [teamSquad] = "(teamSquadsByTeamSides$ | async)?.rightTeam"
          [teamCode] = "teamSides.rightTeam"
          [teamSide] = "TeamSideValues.rightTeam"
          [team] = "rightTeam$ | async"
          [positions] = "(positionsByTeamSides$ | async)?.rightTeam"
          [hasSetStarted] = "hasSetStarted$ | async"
          [isSetRunning] = "isSetRunning$ | async"
          [isMatchFinished] = "isMatchFinished$ | async"
          [individualSanctions] = "(sanctionsByTeamSides$ | async)?.rightTeam.individuals"
          [injuries] = "(injuriesByTeamSides$ | async)?.rightTeam"
          [liberos] = "(liberosByTeamSides$ | async)?.rightTeam"
          [scoreConfig] = "scoreConfig$ | async"
          [isStartingSixLocked] = "isStartingSixLocked$ | async"
          [readMode]="readMode"
          [liberoRegistrationEnabled]="liberoRegistrationEnabled$ | async"
          (liberoIn) = "onLiberoIn($event)"
          (liberoOut) = "onLiberOut($event)"
          (liberoSwitch) = "onLiberoSwitch($event)"
          (substitution) = "onSubstitution($event)"
          (exceptionalSubstitution) = "onExceptionalSubstitution($event)"
          (dropped) = "onDropped()"
          (nomination) = "onNomination($event)"
          (denomination) = "onDenomination($event)">
        </sams-lineup>
      </div>
    </div>
  `,
  styles: [`
    .lineups-container {
      min-width: 100%;
      display: flex;
      justify-content: space-between;
      text-align: center;
    }
    .lineup {
      width: 50%;
      padding-right: 2px; /* hack because tiles somehow overflow on right side */
    }
    .left-half {
      border-right: 3px dashed #4c4c4c;
    }
    .right-half {
      border-left: 3px dashed #4c4c4c;
    }
  `]
})
export class LineupsComponent implements OnDestroy, OnInit {

  @Input() readMode = false;

  matchId: string;

  match: Match;

  leftTeam$: Observable<Team>;

  rightTeam$: Observable<Team>;

  teamSides: TeamSides;

  positionsByTeamSides$: Observable<PositionsByTeamSides>;

  lineupsByTeamSides$: Observable<LineupsByTeamSides>;

  fieldPlayersLeftTeam$: Observable<Player[]>;

  fieldPlayersRightTeam$: Observable<Player[]>;

  serving$: Observable<Serving>;

  isListening$: Observable<boolean>;

  teamSquadsByTeamSides$: Observable<TeamSquadsByTeamSides>;

  hasSetStarted$: Observable<boolean>;

  isSetRunning$: Observable<boolean>;

  isMatchFinished$: Observable<boolean>;

  liberosByTeamSides$: Observable<LiberosByTeamSides>;

  injuriesByTeamSides$: Observable<InjuriesByTeamSides>;

  sanctionsByTeamSides$: Observable<SanctionsByTeamSides>;

  scoreConfig$: Observable<ScoreConfiguration>;

  isStartingSixLocked$: Observable<boolean>;

  liberoRegistrationEnabled$: Observable<boolean>;

  private subscription = new Subscription();

  constructor(
    public dialog: MatDialog,
    public store: Store<fromIndoor.IndoorRoot>,
    public dispatchService: DispatchService,
    public draggableService: DraggableService,
    public translate: TranslateService) {
  }

  ngOnInit() {

    this.positionsByTeamSides$ = this.store.select(fromIndoor.getPositionsByTeamSide);

    this.sanctionsByTeamSides$ = this.store.select(fromIndoor.getSanctionsByTeamSides);

    this.subscription.add(
      this.store.select(fromIndoor.getTeamSides)
        .subscribe(teamSides => this.teamSides = teamSides)
    );

    this.teamSquadsByTeamSides$ = this.store.select(fromIndoor.getTeamSquadsByTeamSides);

    this.serving$ = this.store.select(fromIndoor.getServing);

    this.isListening$ = this.store.select(fromShared.getListening);

    this.lineupsByTeamSides$ = this.store.select(fromIndoor.getLineupsByTeamSides);

    this.injuriesByTeamSides$ = this.store.select(fromIndoor.getInjuriesByTeamSides);

    this.liberosByTeamSides$ = this.store.select(fromIndoor.getLiberosByTeamSides);

    this.hasSetStarted$ = this.store.select(fromIndoor.getHasCurrentSetStarted);

    this.isSetRunning$ = this.store.select(fromIndoor.getIsSetRunning);
    
    this.isMatchFinished$ = this.store.select(fromIndoor.getIsMatchFinished);

    this.fieldPlayersLeftTeam$ = this.store.select(root => fromIndoor.getFieldPlayers(root, this.TeamSideValues.leftTeam));

    this.fieldPlayersRightTeam$ = this.store.select(root => fromIndoor.getFieldPlayers(root, this.TeamSideValues.rightTeam));

    this.leftTeam$ = this.store.select(fromIndoor.getLeftTeam);

    this.rightTeam$ = this.store.select(fromIndoor.getRightTeam);

    this.scoreConfig$ = this.store.select(fromIndoor.getScoreConfiguration);

    this.isStartingSixLocked$ = this.store.select(fromIndoor.isStartingSixLocked);

    this.liberoRegistrationEnabled$ = this.store.select(fromIndoor.isLiberoRegistrationEnabled)

    this.subscription.add(
      this.store.select(fromIndoor.getSelectedMatch).subscribe(match => {
        this.match = match;
        this.matchId = match.uuid;
      }));

    // Promise workaround see: https://github.com/angular/angular/issues/15634
    Promise.resolve().then(() => {
      this.subscription.add(
        this.store.select(fromIndoor.getLineupsByTeamSides).pipe(
        distinctUntilChanged((x, y) => x.leftTeam.liberoRotatedOut === y.leftTeam.liberoRotatedOut && x.rightTeam.liberoRotatedOut === y.rightTeam.liberoRotatedOut),
        withLatestFrom(this.store.select(fromShared.getListening), this.store.select(fromIndoor.getIsMatchFinished)),)
        .subscribe(([ lineups, isListening, isMatchFinished ]) => {
          if (isListening || isMatchFinished) {
            return;
          }
          this.checkLiberoRotation(lineups, TeamSideValues.leftTeam);
          this.checkLiberoRotation(lineups, TeamSideValues.rightTeam);
        })
      );
    })

  }


  checkLiberoRotation(lineups: ByTeamSide<Lineup<Player>>, side: TeamSide) {
    if(this.readMode) return;

    const lineup = lineups[side];

    if (!lineup.rotated || !lineup.liberoRotatedOut) {
      return;
    }
    const dialogRef = this.dialog.open(LiberoRotatedOutDialogComponent);
    const teamCode = side === TeamSideValues.leftTeam ? this.teamSides.leftTeam : this.teamSides.rightTeam;
    dialogRef.componentInstance.title = this.translate.get('component.lineups.libero_rotated_out');
    dialogRef.componentInstance.content = this.translate.get('component.lineups.libero_rotated_out_for_p', { libero: lineup.liberoRotatedOut, player: lineup.current[3] }); // TODO: Hard coded
    dialogRef.componentInstance.buttonText = this.translate.get('app.ok');
    dialogRef.componentInstance.team = this.match[teamCode];
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onLiberOut(liberoOut: LiberoOut) {
    this.dispatchService.dispatchRemoteAction(new LiberoOutAction(liberoOut, this.matchId));
  }

  onLiberoIn(liberoIn: LiberoIn) {
    this.dispatchService.dispatchRemoteAction(new LiberoInAction(liberoIn, this.matchId));
  }

  onLiberoSwitch(liberoSwitch: LiberoSwitch) {
    this.dispatchService.dispatchRemoteAction(new LiberoSwitchAction(liberoSwitch, this.matchId));
  }

  onSubstitution(substitution: Substitution) {
    this.dispatchService.dispatchRemoteAction(new SubstitutionAction(substitution, this.matchId));
  }

  onExceptionalSubstitution(substitution: ExceptionalSubstitution) {
    this.dispatchService.dispatchRemoteAction(new ExceptionalSubstitutionAction(substitution, this.matchId));
  }

  onNomination(nomination: Nomination) {
    this.dispatchService.dispatchRemoteAction(new NominationAction(nomination, this.matchId));
  }

  onDenomination(c: Denomination) {
    this.dispatchService.dispatchRemoteAction(new DenominationAction(c, this.matchId));
  }

  // reset dragged to null when successfully dropped on the lineup
  onDropped() {
    this.draggableService.dragged.emit(null);
  }

  get TeamSideValues() {
    return TeamSideValues;
  }

}
