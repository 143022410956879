import { INJURY } from './injuries.action.js';
import { GameStateAction } from '../game-state.action.js';
import { Player } from '../../../interfaces/models/player.js';

export type Injuries<T> = T[];

export const initialState: Injuries<string> = [];

export const injuriesReducer = (state = initialState, action: GameStateAction) => {
  switch (action.type) {
    case INJURY:
      return [ ...state, action.payload.playerId ];
    default:
      return state;
  }
};

export const isPlayerInjured = (injuries: Injuries<Player>, playerUuid: string) =>
  typeof injuries.find(p => p.uuid === playerUuid) !== 'undefined';
