import { Injectable, OnDestroy } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as fromIndoor from 'app/root/indoor.reducer';
import {
  getMatch
} from '../../../../shared/reducers/match-states/match-state.reducer';

@Injectable({
  providedIn: 'root'
})
export class TeamPreparationsGuard implements CanActivate, OnDestroy {

  arbitrationConfirmed: boolean;

  private sub = new Subscription()

  constructor(public store: Store<fromIndoor.IndoorRoot>, private router: Router) {
    this.sub.add(
      this.store.select(fromIndoor.getMatchState).subscribe(ms => {
        if (!ms) {
          return
        }
        this.arbitrationConfirmed = getMatch(ms).arbitrationConfirmed
      })
    )
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return !this.arbitrationConfirmed ? true : this.router.createUrlTree(['/match-view'])
  }
  
}
