import { environment } from "environments/environment";

interface Config {
  apiHttpServerUrl: string;
  scoresheetServerUrl: string;
  matchEndPoint: string;
  matchV2EndPoint: string;
  matchRefreshEndPoint: string;
  pdfScoresheetEndPoint: string;
  isBeach: boolean;
}
export const config: Config = {
  apiHttpServerUrl: environment.apiHttpServerUrl,
  matchV2EndPoint: '/match/v2/',
  matchEndPoint: '/match/json/',
  matchRefreshEndPoint: '/match/v2/refresh/',
  scoresheetServerUrl: environment.scoresheetServerUrl,
  pdfScoresheetEndPoint: '/scoresheet/pdf/',
  isBeach: environment.beach
};
