import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';

import { SetScore } from '../../../../shared/reducers/game-state/match-sets/set-score/set-score.reducer';
import { TeamSides } from '../../../../shared/reducers/game-state/match-sets/team-sides/team-sides.reducer';

import { AmbientHelpers } from 'app/helpers/ambient-helpers';

@Component({
  selector: 'sams-scoreboard',
  template: `
      <div class="score-container">
        <button mat-raised-button
          color="primary"
          class="score-button"
          (click)="onLeftTeamScored()"
          (window:keydown.ArrowLeft)="onLeftTeamScored()"
          [disabled]="!isSetRunning">
          <mat-icon>add</mat-icon>
        </button>

        <div class="set-scores">
          <div class="team-score">{{leftTeamScore}}</div>
          :
          <div class="team-score">{{rightTeamScore}}</div>
        </div>

        <button mat-raised-button
          color="primary"
          class="score-button"
          (click)="onRightTeamScored()"
          (window:keydown.ArrowRight)="onRightTeamScored()"
          [disabled]="!isSetRunning">
          <mat-icon>add</mat-icon>
        </button>
     </div>
  `,
    styles: [`
      div.score-container {
        display: flex;
        width: 100%;
        justify-content: center;
        text-align: center;
        white-space: nowrap;
      }
      button.score-button {
        margin: 0 15px;
      }

      @media (max-width: 400px) {
      button.score-button {
        margin: 0;
      }
    }


      div.set-scores {
        font-size: 40px;
      }

      div.team-score {
        width: 50px;
        letter-spacing: 0px;
        display: inline-block;
        font-weight: bolder;
        padding: 0px 5px;
      }
    `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScoreboardComponent implements OnInit {

  @Input() setScore: SetScore;

  @Input() isSetRunning: boolean;

  @Input() teamSides: TeamSides;

  @Output() incrementScore = new EventEmitter<string>();

  ngOnInit() {
  }

  get leftTeam() {
    return this.teamSides.leftTeam;
  }

  get rightTeam() {
    return this.teamSides.rightTeam;
  }

  get leftTeamScore() {
    return this.setScore[this.leftTeam];
  }

  get rightTeamScore() {
    return this.setScore[this.rightTeam];
  }

  onLeftTeamScored() {
    if (this.isSetRunning && !AmbientHelpers.isAnyDialogOpen()) {
      this.incrementScore.emit(this.leftTeam);
    }
  }

  onRightTeamScored() {
    if (this.isSetRunning && !AmbientHelpers.isAnyDialogOpen()) {
      this.incrementScore.emit(this.rightTeam);
    }
  }

}
