import {
  ChangeDetectionStrategy,
  Component,
  Input
} from "@angular/core";
import { TeamCode } from '../../../../shared/interfaces/models/team-codes';
import { TeamHighlightAction, TeamHighlight } from '../../../../shared/reducers/team-highlights/team-highlights.action';
import { DispatchService } from "app/connections/dispatch.service";

@Component({
  selector: 'sams-team-highlight',
  template: `
    <mat-menu #highlightMenu="matMenu">
      <button (click)="onAttackHighlight()" mat-menu-item>{{'component.team-highlight.attack_highlight' | translate}}</button>
      <button (click)="onServeHighlight()" mat-menu-item>{{'component.team-highlight.serve_highlight' | translate}}</button>
      <button (click)="onBlockHighlight()" mat-menu-item>{{'component.team-highlight.block_highlight' | translate}}</button>
      <button (click)="onPlayHighlight()" mat-menu-item>{{'component.team-highlight.play_highlight' | translate}}</button>
      <button (click)="onPlayUnrelatedHighlight()" mat-menu-item>{{'component.team-highlight.play_unrelated_highlight' | translate}}</button>
    </mat-menu>
    <button mat-raised-button [matMenuTriggerFor]="highlightMenu" class="highlight-button" color="warn">
      <mat-icon>whatshot</mat-icon>
      Highlight
    </button>
  `,
    styles: [`
      button.highlight-button {
        min-width: 130px;
        height: 100%;
      }
   `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamHighlightComponent {

  @Input() matchId: string;

  @Input() teamCode: TeamCode;

  constructor(private dispatchService: DispatchService) {

  }

  onTeamHighlight(highlight: TeamHighlight) {
    this.dispatchService.dispatchRemoteAction(new TeamHighlightAction({ teamCode: this.teamCode, highlight }, this.matchId));
  }

  onAttackHighlight() {
    this.onTeamHighlight(TeamHighlight.ATTACK_HIGHLIGHT);
  }

  onBlockHighlight() {
    this.onTeamHighlight(TeamHighlight.BLOCK_HIGHLIGHT);
  }

  onServeHighlight() {
    this.onTeamHighlight(TeamHighlight.SERVE_HIGHLIGHT);
  }

  onPlayHighlight() {
    this.onTeamHighlight(TeamHighlight.PLAY_HIGHLIGHT);
  }

  onPlayUnrelatedHighlight() {
    this.onTeamHighlight(TeamHighlight.PLAY_UNRELATED_HIGHLIGHT);
  }

}
