import { MatchAction } from '../../interfaces/interfaces.js';
import { TeamCode } from '../../interfaces/models/team-codes.js';

export const TOGGLE_CAPTAIN  = '[TeamSquad] toggle captain';
export const TOGGLE_LIBERO   = '[TeamSquad] toggle libero';
export const TOGGLE_PLAYER   = '[TeamSquad] toggle player';
export const TOGGLE_OFFICIAL = '[TeamSquad] toggle official';
export const SELECT_ALL      = '[TeamSquad] select all';
export const RESET           = '[TeamSquad] reset all';

// This is a dedicated action since one must be able to undo the
// teamsquad confirmation at any time which is not supported
// yet by the undo-reducer. We also need to wipe the undo history
// when the teamsquad confirmation is undone since we dont want to
// redo actions with players that are not in the teamsquad anymore
// after altering the teamsquad
export const UNDO_TEAMSQUAD_CONFIRMATION = '[TeamSquad] undo confirmation';
export const CONFIRM_TEAMSQUAD = '[TeamSquad] confirm';

export interface ResetPayload {
  teamCode: TeamCode;
}

export interface ConfirmTeamSquadPayload {
  teamCode: TeamCode;
  pin: string;
}

export interface ToggleOne {
  teamMemberId: string;
  teamCode: TeamCode;
}

export interface ToggleMultiple {
  teamMemberIds: string[];
  teamCode: TeamCode;
}

export class ToggleCaptainAction extends MatchAction {
  readonly type = TOGGLE_CAPTAIN;
  constructor(public payload: ToggleOne, matchId: string) {
    super(matchId);
  }
}

export class ToggleLiberoAction extends MatchAction {
  readonly type = TOGGLE_LIBERO;
  constructor(public payload: ToggleOne, matchId: string) {
    super(matchId);
  }
}

export class TogglePlayerAction extends MatchAction {
  readonly type = TOGGLE_PLAYER;
  constructor(public payload: ToggleOne, matchId: string) {
    super(matchId);
  }
}

export class ToggleOfficialAction extends MatchAction {
  readonly type = TOGGLE_OFFICIAL;
  constructor(public payload: ToggleOne, matchId: string) {
    super(matchId);
  }
}

export class SelectAllAction extends MatchAction {
  readonly type = SELECT_ALL;
  constructor(public payload: ToggleMultiple, matchId: string) {
    super(matchId);
  }
}

export class ResetAction extends MatchAction {
  readonly type = RESET;
  constructor(public payload: ResetPayload, matchId: string) {
    super(matchId);
  }
}

export class UndoTeamsquadConfirmationAction extends MatchAction {
  readonly type = UNDO_TEAMSQUAD_CONFIRMATION;
  constructor(public payload: { teamCode: TeamCode }, matchId: string) {
    super(matchId);
  }
}

export class ConfirmTeamSquadAction extends MatchAction {
  readonly type = CONFIRM_TEAMSQUAD;
  constructor(public payload: ConfirmTeamSquadPayload, matchId: string) {
    super(matchId);
  }
}

export type TeamSquadAction
  = ToggleCaptainAction
  | ToggleLiberoAction
  | TogglePlayerAction
  | ToggleOfficialAction
  | SelectAllAction
  | ResetAction
  | ConfirmTeamSquadAction
  | UndoTeamsquadConfirmationAction;
