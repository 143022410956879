import { Observable, of, Subscription, from } from 'rxjs';

import * as uuid from 'uuid';

import { switchMap, catchError } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';

import {
  RemoveMatchAction,
  AddMatchSuccessAction,
  AddMatchFailAction,
  DeselectMatchAction,
  AddMatchAction,
  MatchIdentifiers,
  AddMatchSuccessPayload
} from '../../../../shared/reducers/matches-overview/matches-overview.action';

import {
  AuthAction,
  AuthErrorType,
  AuthFailureAction
} from '../../../../shared/reducers/auth/auth.action';

import { Match } from 'app/models';
import * as fromShared from 'app/root/shared.reducer';
import { config } from 'app/config/config';
import { environment } from 'environments/environment';
import { ConnectionAuth } from '../../../../shared/reducers/connections/connections.reducer';
import { ErrorService } from 'app/error/error.service';
import { testMatchBeach } from 'app/beach/test-match-beach';
import { BeachMatch } from '../../../../shared/beach/model/beach-match';
import { SocketService } from 'app/connections/socket.service';
import { testMatchIndoor } from 'app/test-match-indoor';

@Injectable()
export class MatchesOverviewService implements OnDestroy {

  // TOD: SCORE-343 deprecated
  private matchUrl: string;

  private matchV2Url: string;

  private matchRefreshUrl: string;

  private subscription = new Subscription();

  private scoreVersion: string;

  constructor(private http: HttpClient,
    public store: Store<fromShared.Root>,
    public errorService: ErrorService,
    public socketService: SocketService) {

    // TOD: SCORE-343 deprecated
    this.matchUrl = environment.apiHttpServerUrl + config.matchEndPoint;

    this.matchV2Url = environment.apiHttpServerUrl + config.matchV2EndPoint;
    this.matchRefreshUrl = environment.apiHttpServerUrl + config.matchRefreshEndPoint;

    this.subscription.add(
      this.store.select(fromShared.getVersion).subscribe(version => this.scoreVersion = version)
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // TODO: SCORE-343 deprecated
  // old tokens were in the uuid format
  isDeprecatedAuthMatch(uuid: string) {
    const uuidValidationRegex = /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/
    return uuid.match(uuidValidationRegex) !== null
  }

  isVsdMatch(samsInstanceName: string) {
    return samsInstanceName === "Bayern"
      || samsInstanceName === "BayernBeach"
      || samsInstanceName === "BayernTest"
      || samsInstanceName === "BayernTestEntw"
      || samsInstanceName === "BayernBeachTest"
      || samsInstanceName === "BayernBeachTestEntw"
      || samsInstanceName === "DvvBeach"
      || samsInstanceName === "DvvBeachTest"
  }

  addMatch(fetchToken: string) {
    this.store.dispatch(new AddMatchAction(fetchToken))

    const samsInstanceName = fetchToken.split('-')[0]
    const firstDashIndex = fetchToken.indexOf('-');
    const uuid = fetchToken.slice(firstDashIndex + 1);

    if (this.isVsdMatch(samsInstanceName) || this.isDeprecatedAuthMatch(uuid)) {
      this.subscription.add(this.fetchMatch(fetchToken).subscribe(a => this.store.dispatch(a)))
    } else {
      this.subscription.add(this.fetchV2Match(fetchToken).subscribe(a => this.store.dispatch(a)))
    }
  }

  addTestMatch() {
    const testMatch: Match | BeachMatch = environment.beach ? testMatchBeach : testMatchIndoor
    const { match, auth } = this.prepareTestMatch(testMatch)
    this.subscription.add(
      of(new AddMatchSuccessAction({ match, auth  }))
        .subscribe(a => this.store.dispatch(a))
    )
  }

  removeMatch(matchId: string) {
    this.store.dispatch(new RemoveMatchAction(matchId));
    this.store.dispatch(new DeselectMatchAction());
  }

  fetchV2Match(fetchToken: string): Observable<AddMatchSuccessAction | AddMatchFailAction | AuthAction> {
    return this.http.get(this.matchV2Url + fetchToken).pipe(
      switchMap((result: AddMatchSuccessAction) => this.onFetchV2MatchSuccess(result, fetchToken)),
      catchError((err: HttpErrorResponse) => this.onFetchMatchError(err)))
  }

  refreshV2Match(matchIdentifiers: MatchIdentifiers, auth: ConnectionAuth) {
    const { matchId, fetchToken } = matchIdentifiers
    this.store.dispatch(new AddMatchAction(fetchToken))
    this.subscription.add(
      this.http.post(this.matchRefreshUrl + fetchToken, auth).pipe(
        switchMap((action: AddMatchSuccessAction) => {
          this.removeMatch(matchId);
          const match = this.prepareMatch(action.payload.match as Match | BeachMatch, fetchToken)
          return of({...action, payload: { ...action.payload, match }} as AddMatchSuccessAction)
        }),
        catchError((err: HttpErrorResponse) => {
          return this.onFetchMatchError(err)
        }))
        .subscribe(a => this.store.dispatch(a))
    )
  }

  // TODO: SCORE-343 deprecated
  fetchMatch(fetchToken: string): Observable<AddMatchSuccessAction | AddMatchFailAction | AuthAction> {
    return this.http.get(this.matchUrl + fetchToken).pipe(
      switchMap((match: Match | BeachMatch) => this.onFetchMatchSuccess(match, fetchToken)),
      catchError((err: HttpErrorResponse) => this.onFetchMatchError(err)))
  }

  // TODO: SCORE-343 deprecated
  refreshMatch(matchIdentifiers: MatchIdentifiers, auth?: ConnectionAuth) {
    if (!auth) {
      this.removeMatch(matchIdentifiers.matchId)
      this.addMatch(matchIdentifiers.fetchToken)
    } else {
      this.refreshV2Match(matchIdentifiers, auth)
    }
  }

  // TOD: SCORE-343 deprecated
  onFetchMatchSuccess(payload: Match | BeachMatch, fetchToken: string) {
    const match = this.prepareMatch(payload, fetchToken)
    this.socketService.joinRoom(match.uuid)
    return of(new AddMatchSuccessAction({ auth: null, match}))
  }

  onFetchV2MatchSuccess(action: AddMatchSuccessAction, fetchToken: string) {
    const match = this.prepareMatch(action.payload.match as Match | BeachMatch, fetchToken)
    this.socketService.joinRoom(match.uuid, action.payload.auth)
    return of({...action, payload: { ...action.payload, match }} as AddMatchSuccessAction)
  }

  onFetchMatchError(err: HttpErrorResponse) {
    if (!err?.error?.type) {
      const failAction = new AddMatchFailAction({ message: err.message, status: err.status });
      return from(this.errorService.localizeAddMatchFailAction(failAction))
    }
    const authFailureAction = new AuthFailureAction({
      name: err.name,
      status: err.status,
      message: err.message,
      error: AuthErrorType.HTTP_ERROR
    })
    return from(this.errorService.localizeAuthErrorAction(authFailureAction))
  }

  private prepareMatch(match: Match | BeachMatch, fetchToken: string): Match | BeachMatch {
    return {
      ...match,
      fetchToken,
      scoreVersion: this.scoreVersion,
      loadingTimestamp: +new Date()
    };
  }

  private prepareTestMatch(match: Match | BeachMatch): AddMatchSuccessPayload {
    const matchUuid = uuid.v4()
    return {
      match: {
        ...match,
        uuid: matchUuid,
        date: new Date().toISOString(),
        isTestMatch: true,
        scoreVersion: this.scoreVersion,
        loadingTimestamp: +new Date()
      },
      auth: {
        id_token: 'test_token',
        match_uuid: matchUuid,
        samsInstanceName: "SamsDvvTest",
      } 
    }
  }

}
