import {
  matchSetsReducer,
  MatchSets,
  isMatchFinished
} from './match-sets/match-sets.reducer.js';
import { MatchSetsAction } from './match-sets/match-sets.action.js';
import { GameStateAction, REPLACE_LIBERO } from './game-state.action.js';
import {
  SCORE,
  SCORE_OVERRIDE,
  PENALTY_SCORE,
  PenaltyScoreAction,
  ScoreAction
} from './match-sets/set-score/set-score.action.js';
import {
  END_SET,
  START_SET,
  START_TIMEOUT,
  START_TECHNICAL_TIMEOUT,
  SET_START_TIME,
  START_MATCH,
  CANCEL_TECHNICAL_TIMEOUT,
  CANCEL_TIMEOUT,
  CANCEL_SET_PAUSE,
  CHANGE_SET_PAUSE_DURATION
} from './match-sets/time-keeping/time-keeping.action.js';
import { CHANGE_SERVING } from './match-sets/serving/serving.action.js';
import { SWITCH_SIDES } from './match-sets/team-sides/team-sides.action.js';
import {
  SUBSTITUTION,
  NOMINATION,
  LIBERO_IN,
  LIBERO_OUT,
  LIBERO_SWITCH,
  EXCEPTIONAL_SUBSTITUTION,
  DENOMINATION,
  ROTATE_CLOCKWISE,
  ROTATE_ANTICLOCKWISE,
  LiberoSwitchAction,
  LiberoSwitch
} from './match-sets/lineups/lineups.action.js';
import { TeamSquads } from '../match-preparation/team-squads.reducer.js';
import { TeamCodes, ByTeamCode } from '../../interfaces/models/team-codes.js';
import { SanctionAction } from './sanctions/sanctions.action.js';
import {
  TEAM_WARNING,
  TEAM_PENALTY,
  TeamPenaltyAction,
  TEAM_IMPROPER_REQUEST,
} from './sanctions/team-sanction/team-sanction.action.js';
import {
  IndividualPenaltyAction,
  INDIVIDUAL_PENALTY,
  INDIVIDUAL_WARNING,
  INDIVIDUAL_EXPULSION,
  INDIVIDUAL_DISQUALIFICATION
} from './sanctions/individual-sanction/individual-sanction.action.js';
import { Sanctions, AdditionalSanctionsState } from './sanctions/sanctions.reducer.js';
import { MatchConfiguration } from "../../interfaces/models/match-configuration.js";


import * as fromMatchSets from './match-sets/match-sets.reducer.js';
import { sanctionsByTeamCodeReducer } from './sanctions/sanctions-by-team-code.reducer.js';
import { INJURY, InjuriesAction } from './injuries/injuries.action.js';
import { Injuries, isPlayerInjured } from './injuries/injuries.reducer.js';
import { injuriesByTeamCodeReducer } from './injuries/injuries-by-team-code.reducer.js';
import {
  LIBERO_UNABLE_TO_PLAY,
  LiberosAction,
  ADD_NEW_LIBERO,
  AddNewLiberoAction,
  AddNewLibero,
  LIBERO_PLAYED
} from './liberos/liberos.action.js';
import { Liberos, isLiberoUnableToPlay } from './liberos/liberos.reducer.js';
import { liberosByTeamCodeReducer } from './liberos/liberos-by-team-code.reducer.js';
import { mvpsByTeamCodeReducer } from './mvps/mvps-by-team-code.reducer.js';
import { Player } from '../../interfaces/models/player.js';
import {
  isTeamMemberCurrentlyExpelled,
  isTeamMemberDisqualified,
  IndividualSanctions
} from './sanctions/individual-sanction/individual-sanction.reducer.js';
import { getSetScore, getTimeKeeping } from './match-sets/match-set.reducer.js';
import { getTotalDurationSeconds } from './match-sets/time-keeping/time-keeping.reducer.js';
import { SELECT_MVP, MvpAction } from './mvps/mvp.action.js';
import { Mvp } from './mvps/mvp.reducer.js';
import { ScoreConfiguration } from '../../interfaces/models/score-configuration.js';
import { LOCK_STARTING_SIX } from './match-sets/starting-six.action.js';
import { SET_DEFAULT_LIBERO } from './liberos/liberos.action.js';
import {
  CHALLENGE,
  CHALLENGE_DECLINED,
  CHALLENGE_SUCCESS
} from './match-sets/challenges/challenges.action.js';

export interface GameState {
  isMatchBall: boolean;
  matchSets: MatchSets;
  sanctionsByTeamCode: ByTeamCode<Sanctions>;
  injuriesByTeamCode: ByTeamCode<Injuries<string>>;
  liberosByTeamCode: ByTeamCode<Liberos<string>>;
  mvpsByTeamCode: ByTeamCode<Mvp<string>>;
};

export interface AdditionalGameState {
  scoreConfig: ScoreConfiguration
  matchConfig: MatchConfiguration;
  teamSquads: TeamSquads;
}

export const getInitialState = (additionalGameState: AdditionalGameState): GameState => ({
  isMatchBall: false,
  matchSets: matchSetsReducer(undefined, {} as MatchSetsAction, additionalGameState),
  sanctionsByTeamCode: sanctionsByTeamCodeReducer(undefined, {} as SanctionAction),
  injuriesByTeamCode: injuriesByTeamCodeReducer(undefined, {} as InjuriesAction),
  liberosByTeamCode: liberosByTeamCodeReducer(undefined, {} as LiberosAction),
  mvpsByTeamCode: mvpsByTeamCodeReducer(undefined, {} as MvpAction)
});

const getAdditionalSanctionsState = (state: GameState): AdditionalSanctionsState => {
  return {
    setScore: fromMatchSets.getCurrentMatchSet(state.matchSets).setScore,
    currentSetNumber: fromMatchSets.getCurrentSetNumber(state.matchSets)
  };
};

export const gameStateReducer = (state: GameState, action: GameStateAction, additionalGameState: AdditionalGameState): GameState => {

  if (!state && additionalGameState) {
    state = getInitialState(additionalGameState)
  }

  switch (action.type) {
    case SCORE:
    case PENALTY_SCORE:
    case SCORE_OVERRIDE:
    case END_SET:
    case START_SET:
    case SET_START_TIME:
    case CHANGE_SET_PAUSE_DURATION:
    case CANCEL_SET_PAUSE:
    case CANCEL_TIMEOUT:
    case START_TIMEOUT:
    case CANCEL_TECHNICAL_TIMEOUT:
    case START_TECHNICAL_TIMEOUT:
    case CHANGE_SERVING:
    case SWITCH_SIDES:
    case SUBSTITUTION:
    case EXCEPTIONAL_SUBSTITUTION:
    case LIBERO_IN:
    case LIBERO_SWITCH:
    case LIBERO_OUT:
    case DENOMINATION:
    case NOMINATION:
    case TEAM_WARNING:
    case START_MATCH:
    case INJURY:
    case ADD_NEW_LIBERO:
    case SET_DEFAULT_LIBERO:
    case LIBERO_PLAYED:
    case LIBERO_UNABLE_TO_PLAY:
    case TEAM_IMPROPER_REQUEST:
    case ROTATE_CLOCKWISE:
    case ROTATE_ANTICLOCKWISE:
    case LOCK_STARTING_SIX:
    case CHALLENGE:
    case CHALLENGE_SUCCESS:
    case CHALLENGE_DECLINED: {
      return delegateAction(state, action, additionalGameState);
    }

    case INDIVIDUAL_PENALTY: {
      const newState = delegateAction(state, action, additionalGameState);
      return {
        ...newState,
        matchSets: penalizeSanctionedTeam(newState.matchSets, action, additionalGameState)
      }
    }

    case INDIVIDUAL_WARNING:
    case INDIVIDUAL_EXPULSION:
    case INDIVIDUAL_DISQUALIFICATION: {
      return delegateAction(state, action, additionalGameState);
    }

    case SELECT_MVP: {
      return delegateAction(state, action, additionalGameState);
    }

    case TEAM_PENALTY: {
      const newState = delegateAction(state, action, additionalGameState);
      return {
        ...newState,
        matchSets: penalizeSanctionedTeam(newState.matchSets, action, additionalGameState)
      }
    }

    case REPLACE_LIBERO: {
      const { teamCode, newLiberoId, oldLiberoId } = action.payload;
      const liberoSwitch: LiberoSwitch = {
        teamCode,
        liberoInId: newLiberoId,
        liberoOutId: oldLiberoId
      };
      const addNewLibero: AddNewLibero = {
        teamCode,
        playerId: newLiberoId
      };
      const newState = delegateAction(state, action, additionalGameState)

      return {
        ...newState,
        matchSets: matchSetsReducer(newState.matchSets, new LiberoSwitchAction(liberoSwitch, action.matchId), additionalGameState),
        liberosByTeamCode: liberosByTeamCodeReducer(newState.liberosByTeamCode, new AddNewLiberoAction(addNewLibero, action.matchId))
      }
    }

    default:
      return state;
  }
};

const delegateAction = (state: GameState, action: GameStateAction, additionalGameState: AdditionalGameState): GameState => {
  const additionalSanctionsState = getAdditionalSanctionsState(state);

  const matchSets = matchSetsReducer(state.matchSets, action, additionalGameState);
  return {
    ...state,
    isMatchBall: matchBall(matchSets, additionalGameState),
    matchSets,
    sanctionsByTeamCode: sanctionsByTeamCodeReducer(state.sanctionsByTeamCode, action, additionalSanctionsState),
    injuriesByTeamCode: injuriesByTeamCodeReducer(state.injuriesByTeamCode, action),
    liberosByTeamCode: liberosByTeamCodeReducer(state.liberosByTeamCode, action, additionalGameState.teamSquads),
    mvpsByTeamCode: mvpsByTeamCodeReducer(state.mvpsByTeamCode, action)
  };
};

export const penalizeSanctionedTeam = (matchSets: MatchSets, action: TeamPenaltyAction | IndividualPenaltyAction, additionalGameState: AdditionalGameState) => {

  if (!action.payload.isMatchFinished) {
    const penalizedTeamCode = action.payload.teamCode;
    const favouredTeamCode = penalizedTeamCode === TeamCodes.team1 ? TeamCodes.team2 : TeamCodes.team1;
    return matchSetsReducer(matchSets, new PenaltyScoreAction(favouredTeamCode, action.matchId), additionalGameState)
  }

  return matchSets

};

export const matchBall = (state: MatchSets, additionalGameState: AdditionalGameState) => {
  const config = additionalGameState.matchConfig
  const currentMatchSet = fromMatchSets.getCurrentMatchSet(state)
  const setScore = getSetScore(currentMatchSet)
  const matchFinished = isMatchFinished(state, config)
  // if the match is already finished there is no matchball
  if (matchFinished) {
    return false
  } else {

    // else we check if the next score of the leading team would finish the match
    const leadingTeam = setScore.team1 > setScore.team2 ? TeamCodes.team1 : TeamCodes.team2
    const nextStateIfLeadingTeamScores = matchSetsReducer(state, new ScoreAction(leadingTeam, 'dummy'), additionalGameState)
    return isMatchFinished(nextStateIfLeadingTeamScores, config)
  }
}


// Selectors
export const getMatchSets = (state: GameState) => state.matchSets;
export const getMatchDuration = (state: GameState) => getMatchSets(state).reduce((sum, set) => sum + getTotalDurationSeconds(getTimeKeeping(set)), 0);
export const getSanctionsByTeamCode = (state: GameState) => state.sanctionsByTeamCode;
export const getInjuriesByTeamCode = (state: GameState) => state.injuriesByTeamCode;
export const getLiberosByTeamCode = (state: GameState) => state.liberosByTeamCode;
export const getMvpsByTeamCode = (state: GameState) => state.mvpsByTeamCode;
export const isMatchBall = (state: GameState) => state.isMatchBall; 

export const requiresSubstitution = (injuries: Injuries<Player>, playersUnallowedToPlay: string[], uuid: string) =>
  typeof injuries.find(p => p.uuid === uuid) !== 'undefined' || playersUnallowedToPlay.indexOf(uuid) > -1;

export const isPlayerUnallowedToPlay = (injuries: Injuries<Player>, sanctions: IndividualSanctions, currentSetNumber: number, playerUuid: string) =>
  isPlayerInjured(injuries, playerUuid) || isTeamMemberCurrentlyExpelled(currentSetNumber, sanctions, playerUuid) || isTeamMemberDisqualified(sanctions, playerUuid);

export const isLiberoUnallowedToPlay = 
  (liberos: Liberos<Player>,
   injuries: Injuries<Player>,
   sanctions: IndividualSanctions,
   currentSetNumber: number,
   liberoUuid: string) => isLiberoUnableToPlay(liberos, liberoUuid) || isPlayerUnallowedToPlay(injuries, sanctions, currentSetNumber, liberoUuid);
