import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as uuid from 'uuid';

import {
  ArbitrationMember,
  LicenseLevels,
  LicenseLevel
} from '../../../../shared/interfaces/models/arbitration';

@Component({
  selector: 'sams-arbitration-selection',
  template: `
    <div class="container">
      <mat-checkbox class="confirmation-box" [checked]="editedArbitrationMember && editedArbitrationMember.selected" (change)="onToggle()" [disabled]="!selectable || expanded || !editedArbitrationMember.personUuid"></mat-checkbox>
      <mat-expansion-panel class="panel" [expanded]="expanded" (opened)="onExpand()" [disabled]="!editable || !selectable || expanded" hideToggle="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div>
              <div [ngClass]="{ 'selected': editedArbitrationMember.selected }">{{label}}</div>
              <div class="required-label" *ngIf="required">*{{'app.required' | translate}}</div>
            </div>
          </mat-panel-title>
          <mat-panel-description>
            <div [ngClass]="{ 'selected': editedArbitrationMember.selected }">
              <div>{{editedArbitrationMember.lastName}}, {{editedArbitrationMember.firstName}}</div>
              <div class="license-details">
                <div *ngIf="editedArbitrationMember.licenseNumber">{{'app.license_number' | translate}}: {{editedArbitrationMember.licenseNumber}}</div>
                <div *ngIf="editedArbitrationMember.licenseLevel">{{'app.license_level' | translate}}: {{('component.arbitration-selection.licenseLevels.' + editedArbitrationMember.licenseLevel) | translate}}</div>
              </div>
            </div>
            <mat-icon *ngIf="editable">edit</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <form #editForm="ngForm" class="edit-form">
          <table class="full-width" cellspacing="0">
            <tr>
              <td>
                <mat-form-field class="full-width">
                  <input matInput placeholder="{{'app.lastname' | translate}}" [(ngModel)]="editedArbitrationMember.lastName" required name="lastName">
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="full-width">
                  <input matInput placeholder="{{'app.firstname' | translate}}" [(ngModel)]="editedArbitrationMember.firstName" required name="firstName">
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td>
                <mat-form-field class="full-width">
                  <mat-select placeholder="{{'app.license_level' | translate}}" [(ngModel)]="editedArbitrationMember.licenseLevel" name="licenseLevel">
                    <mat-option *ngFor="let licenseLevel of licenseLevels" [value]="licenseLevel">{{('component.arbitration-selection.licenseLevels.' + licenseLevel) | translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="full-width">
                  <input matInput placeholder="{{'app.license_number' | translate}}" [(ngModel)]="editedArbitrationMember.licenseNumber" name="licenseNumber" [disabled]="editedArbitrationMember.licenseLevel === LicenseLevels.none">
                </mat-form-field>
              </td>
            </tr>
          </table>

          <mat-action-row>
            <button type="button" mat-raised-button class="positive" (click)="onSubmit(editForm);" [disabled]="!editForm.form.valid || editForm.form.pristine">{{'app.save' | translate}}</button>
            <button type="button" mat-raised-button class="negative" (click)="resetForm(editForm);">{{'app.cancel' | translate}}</button>
          </mat-action-row>

        </form>

      </mat-expansion-panel>
    </div>
  `,
  styles: [`

  .selected {
    color: black;
  }
  mat-action-row {
    justify-content: flex-start;
  }

  mat-action-row button {
    margin-right: 2%;
  }

  .edit-form {
    min-width: 150px;
    width: 100%;
  }
  
  .full-width {
    width: 100%;
  }

  .full-width td {
    padding: 0 2%;
  }

  .container {
    display: flex;
    align-items: center;
  }
  .confirmation-box {
    width: 10%;
    text-align: center;
  }
  .panel {
    width: 90%;
  }

  mat-panel-title, 
  mat-panel-description {
    flex-basis: 0;
  }
  mat-panel-description {
    justify-content: space-between;
    align-items: center;
  }
  mat-panel-title mat-checkbox {
    margin: 0 16px;
  }
  mat-expansion-panel-header mat-icon {
    width: 20px; height: 20px;
    font-size: 20px;
  }
  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: none!important;
  }
  .license-details {
    display: flex;
    justify-content: space-between; 
    width: 300px;
    font-size: 85%;
  }

  `]
})
export class ArbitrationSelectionComponent implements OnInit {

  @Input() arbitrationMember: ArbitrationMember;

  editedArbitrationMember: ArbitrationMember;

  @Input() required: boolean;

  @Input() editable = true;

  @Input() selectable: boolean;

  @Input() label: String;

  @Output() submit = new EventEmitter<ArbitrationMember>();

  @Output() toggle = new EventEmitter<boolean>();

  expanded = false;

  selected = false;

  ngOnInit() {
    this.editedArbitrationMember = { ...this.arbitrationMember };
  }

  onExpand() {
    this.expanded = true;
  }

  resetForm(editForm: NgForm) {
    editForm.reset({ ...this.arbitrationMember });
    this.expanded = false;
  }

  onSubmit(editForm: NgForm) {
    this.editedArbitrationMember.selected = this.selectable;
    if (!this.editedArbitrationMember.personUuid) {
      this.editedArbitrationMember = {
        ...this.editedArbitrationMember,
        personUuid: uuid.v4(),
        enteredManually: true
      }
    }
    this.submit.emit({ ...this.editedArbitrationMember });
    editForm.reset({ ...this.editedArbitrationMember });
    this.expanded = false;
  }

  onToggle() {
    if (this.selectable) {
      this.toggle.emit(true);
    }
  }

  isValidMember(m: ArbitrationMember) {
    return m !== null && m.firstName && m.lastName;
  }

  get licenseLevels(): LicenseLevel[] {
    return Object.keys(this.LicenseLevels).map(key => this.LicenseLevels[key]);
  }

  get LicenseLevels() {
    return LicenseLevels;
  }

}
