import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Timeout } from '../../../../../shared/reducers/game-state/match-sets/time-keeping/time-keeping.action';
import { SetScore } from '../../../../../shared/reducers/game-state/match-sets/set-score/set-score.reducer';
import { TeamCode, TeamCodes } from 'app/models';

@Component({
  selector: 'sams-timeout',
  template: `
    <button class="timeout-button" mat-stroked-button color="primary"
      (click)="onTimeout()"
      [disabled]="isTimeoutDisabled(numberOfTimeouts)"
      [ngClass]="{ 'exceeded': isMaxTimeoutsExceeded(numberOfTimeouts), 'warning': isMaxTimeoutsNearlyExceeded(numberOfTimeouts) }">
      <mat-icon>timer</mat-icon>
      <div class="num-of-timeouts">{{numberOfTimeouts}}</div>
    </button>
  `,
  styles: [`
    .timeout-button.exceeded {
      background-color: tomato;
      color: white;
      cursor: not-allowed;
    }
    .timeout-button.warning {
      background-color: gold;
      color: white;
    }
    button {
      margin: 0 5px;
      width: 40px;
      min-width: 40px;
      max-width: 100px;
      padding: 0;
    }
    .num-of-timeouts {
      font-size: 20px;
    }
  `]
})
export class TimeoutComponent implements OnInit {

  @Input() teamCode: TeamCode

  @Input() maxTimeoutsPerSet;

  @Input() setScore: SetScore;

  @Input() numberOfTimeouts: number;

  @Input() isSetRunning: boolean;

  @Input() hasStarted: boolean;

  @Output() timeout = new EventEmitter<Timeout>();

  ngOnInit() {
  }

  onTimeout() {
    // even though button is disabled we check anyway, to support f.e. keyboard shortcuts
    if (this.isMaxTimeoutsExceeded(this.numberOfTimeouts)) {
      return;
    }
    const scoreSelf = this.teamCode === TeamCodes.team1 ? this.setScore.team1 : this.setScore.team2;
    const scoreOther = this.teamCode === TeamCodes.team1 ? this.setScore.team2 : this.setScore.team1;
    this.timeout.emit({ teamCode: this.teamCode, scoreSelf, scoreOther, timestamp: +new Date() });
  }

  isMaxTimeoutsExceeded(numOfTeamTimeouts: number) {
    return numOfTeamTimeouts >= this.maxTimeoutsPerSet;
  }

  isMaxTimeoutsNearlyExceeded(numOfTeamTimeouts: number) {
    return numOfTeamTimeouts === this.maxTimeoutsPerSet - 1;
  }

  isTimeoutDisabled(numberOfTimeouts: number) {
    return !this.hasStarted || !this.isSetRunning || this.isMaxTimeoutsExceeded(numberOfTimeouts)
  }

}
