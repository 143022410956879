
import { startWith, combineLatest } from 'rxjs/operators';
import {
  Component,
  OnInit
} from '@angular/core';
import { Observable } from 'rxjs';

import { SocketService } from '../connections/socket.service';

@Component({
  selector: 'sams-connection',
  template: `
    <mat-icon *ngIf="!(isConnected$ | async)" style="color: tomato; margin: 15px;">wifi_off</mat-icon>
    <mat-icon *ngIf="isConnected$ | async" style="color: limegreen; margin: 15px;">wifi</mat-icon>
  `,
  styles: [``]
})
export class ConnectionComponent implements OnInit {

  isConnected$: Observable<boolean>;

  constructor(public socketService: SocketService) {
  }

  ngOnInit() {
    const isOnline$ = this.socketService.isOnline$.pipe(startWith(window.navigator.onLine))
    this.isConnected$ = this.socketService.isConnected$.pipe(startWith(this.socketService.socket.connected), combineLatest(isOnline$, (x, y) => x && y));
  }

}
