import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

import {
  TeamSanction,
  TeamPenalty
} from "../../../../../shared/reducers/game-state/sanctions/team-sanction/team-sanction.action";
import {
  TeamSanctions
} from '../../../../../shared/reducers/game-state/sanctions/team-sanction/team-sanction.reducer';

import { TeamCode, TeamSide } from "app/models";
import { SetScore } from '../../../../../shared/reducers/game-state/match-sets/set-score/set-score.reducer';

@Component({
  selector: 'sams-team-sanctions',
  template: `
  <div class="team-sanction-wrapper">
    <sams-team-sanction-display [state]="teamSanctions"></sams-team-sanction-display>
    <sams-team-sanction
      [state]="teamSanctions"
      (warning)="onTeamWarning()"
      (penalty)="onTeamPenalty()"
      (improperRequest)="onImproperRequest()">
    </sams-team-sanction>
  </div>
  `,
  styles: [`
    .team-sanction-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamSanctionsComponent {

  @Input() teamCode: TeamCode;

  @Input() teamSide: TeamSide;

  @Input() matchId: string;

  @Input() isMatchFinished: boolean;

  @Input() setScore: SetScore;

  @Input() currentSetNumber: number;

  @Input() teamSanctions: TeamSanctions;

  @Output() teamWarning = new EventEmitter<TeamSanction>()

  @Output() teamPenalty = new EventEmitter<TeamPenalty>()

  @Output() improperRequest = new EventEmitter<TeamSanction>()

  onTeamWarning() {
    this.teamWarning.emit({
      teamCode: this.teamCode,
      setScore: this.setScore,
      currentSetNumber: this.currentSetNumber
    })
  }

  onTeamPenalty() {
    this.teamPenalty.emit({
      teamCode: this.teamCode, isMatchFinished: this.isMatchFinished,
      setScore: this.setScore,
      currentSetNumber: this.currentSetNumber
    })
  }

  onImproperRequest() {
    this.improperRequest.emit({
      teamCode: this.teamCode,
      setScore: this.setScore,
      currentSetNumber: this.currentSetNumber
    })
  }

}
