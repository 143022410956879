import { config } from "app/config/config";
import { BeachMatch } from "../../../../../shared/beach/model/beach-match";
import { BeachTeam } from "../../../../../shared/beach/model/beach-team";
import { BeachMatchState } from "../../../../../shared/beach/reducers/match-states/beach-match-state.reducer";
import { BeachMatchSet } from "../../../../../shared/beach/reducers/match-states/game-state/match-sets/match-set/beach-match-set.reducer";
import { TeamCode, TeamCodes } from "../../../../../shared/interfaces/models";
import { SanctionType } from "../../../../../shared/interfaces/models/sanction-type";
import { getTotalDurationSeconds } from "../../../../../shared/reducers/game-state/match-sets/time-keeping/time-keeping.reducer"
import { SanctionsByTeamCode } from "../../../../../shared/reducers/game-state/sanctions/sanctions-by-team-code.reducer";
import { Sanctions } from "../../../../../shared/reducers/game-state/sanctions/sanctions.reducer";

export interface BeachResultMatchStateSet {
  durationSeconds: number;
  score: number[];
}

export interface BeachMatchSanction {
  type: SanctionType;
  followUpSanction: boolean;
  teamMemberUuid: string;
  teamUuid: string;
}

interface BeachResultMatchState {
  scoresheetPdfUrl: string;
  sets: BeachResultMatchStateSet[];
  sanctions: BeachMatchSanction[];
  comments?: string;
}


function createIndividualSanction(team: BeachTeam, type: SanctionType, teamMemberId: string): BeachMatchSanction {
  return {
    followUpSanction: false,
    teamMemberUuid: teamMemberId,
    teamUuid: team.uuid,
    type
  };
}

function createTeamSanction(team: BeachTeam, type: SanctionType): BeachMatchSanction {
  return {
    followUpSanction: false,
    teamMemberUuid: null,
    teamUuid: team.uuid,
    type
  };
}


function mapSanctions(sanctions: Sanctions, team: BeachTeam): BeachMatchSanction[] {
  const result: BeachMatchSanction[] = [];
  if (sanctions.team.improperRequest) {
    result.push(createTeamSanction(team, SanctionType.IMPROPER_REQUEST));
  }
  if (sanctions.team.warning) {
    result.push(createTeamSanction(team, SanctionType.WARNING));
  }
  sanctions.team.penalties.forEach(_ => result.push(createTeamSanction(team, SanctionType.PENALTY)));

  Object.keys(sanctions.individuals.disqualifications).forEach(uuid => result.push(createIndividualSanction(team, SanctionType.DISQUALIFICATION, uuid)));
  Object.keys(sanctions.individuals.expulsions).forEach(uuid => result.push(createIndividualSanction(team, SanctionType.EXPULSION, uuid)));
  Object.keys(sanctions.individuals.penalties).forEach(uuid => result.push(createIndividualSanction(team, SanctionType.PENALTY, uuid)));
  Object.keys(sanctions.individuals.warnings).forEach(uuid => result.push(createIndividualSanction(team, SanctionType.WARNING, uuid)));

  return result;
}

const getMatchSanctions = (match: BeachMatch, sanctionsByTeamCode: SanctionsByTeamCode): BeachMatchSanction[] => {
  return Object.keys(TeamCodes).reduce((sanctions, teamCode: TeamCode) => {
    return [
      ...sanctions,
      ...mapSanctions(sanctionsByTeamCode[teamCode], match[teamCode])
    ];
  }, [])

}


const getResultMatchStateSet = (matchSet: BeachMatchSet): BeachResultMatchStateSet => {
  return {
    durationSeconds: getTotalDurationSeconds(matchSet.timeKeeping),
    score: [matchSet.setScore.team1, matchSet.setScore.team2]
  };
}

export const getBeachResultMatchState = (state: BeachMatchState): BeachResultMatchState => {

  const matchSets = state.gameStates.present.matchSets
  const match = state.match
  const sanctionsByTeamCode = state.gameStates.present.sanctions

  const scoresheetPdfUrl = `${config.scoresheetServerUrl}${config.pdfScoresheetEndPoint}${match.uuid}/${match.matchNumber}`

  return {
    scoresheetPdfUrl: scoresheetPdfUrl,
    sets: matchSets.sets.map(getResultMatchStateSet),
    sanctions: getMatchSanctions(match, sanctionsByTeamCode),
    comments: state.matchInfo.comments
  };

}
