import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import {
  IndividualSanctions
} from '../../../../../shared/reducers/game-state/sanctions/individual-sanction/individual-sanction.reducer';
import {
  IndividualWarningAction,
  IndividualPenaltyAction,
  IndividualExpulsionAction,
  IndividualDisqualificationAction
} from '../../../../../shared/reducers/game-state/sanctions/individual-sanction/individual-sanction.action';

import { TeamCode, TeamSide } from 'app/models';
import { SetScore } from '../../../../../shared/reducers/game-state/match-sets/set-score/set-score.reducer';
import { DispatchService } from 'app/connections/dispatch.service';

@Component({
  selector: 'sams-individual-sanctions',
  template: `
    <sams-team-member-sanction
      [state]="individualSanctions"
      [teamMemberId]="teamMemberId"
      [allowedPenalties]="allowedPenalties"
      (warning)="onWarning($event)"
      (penalty)="onPenalty($event)"
      (expulsion)="onExpulsion($event)"
      (disqualification)="onDisqualification($event)">
    </sams-team-member-sanction>
  `,
  styles: [`
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndividualSanctionsComponent {

  @Input() teamCode: TeamCode;

  @Input() teamSide: TeamSide;

  @Input() matchId: string;

  @Input() teamMemberId: string;

  @Input() isMatchFinished: boolean;

  @Input() setScore: SetScore;

  @Input() currentSetNumber: number;

  @Input() individualSanctions: IndividualSanctions;

  @Input() allowedPenalties = 1;

  constructor(private dispatchService: DispatchService) {
  }

  onWarning(teamMemberId: string) {
    this.dispatchService.dispatchRemoteAction(
      new IndividualWarningAction({
        teamCode: this.teamCode,
        teamMemberId: teamMemberId,
        setScore: this.setScore,
        currentSetNumber: this.currentSetNumber
      }, this.matchId));
  }

  onPenalty(teamMemberId: string) {
    this.dispatchService.dispatchRemoteAction(
      new IndividualPenaltyAction({ teamCode: this.teamCode,
        teamMemberId: teamMemberId,
        isMatchFinished: this.isMatchFinished,
        setScore: this.setScore,
        currentSetNumber: this.currentSetNumber
      }, this.matchId));
  }

  onExpulsion(teamMemberId: string) {
    this.dispatchService.dispatchRemoteAction(
      new IndividualExpulsionAction({ teamCode: this.teamCode,
        teamMemberId: teamMemberId,
        setScore: this.setScore,
        currentSetNumber: this.currentSetNumber
      }, this.matchId));
  }

  onDisqualification(teamMemberId: string) {
    this.dispatchService.dispatchRemoteAction(
      new IndividualDisqualificationAction({ teamCode: this.teamCode,
        teamMemberId: teamMemberId,
        setScore: this.setScore,
        currentSetNumber: this.currentSetNumber
      }, this.matchId));
  }

}
