import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { LicenseService } from './license-service';
import { License } from '../../../../../shared/interfaces/models/license';
import { SocketService } from 'app/connections/socket.service';

@Component({
  selector: 'sams-license-photo',
  template: `
    <div class="license-photo">
      <mat-progress-spinner *ngIf="isLoading" mode="indeterminate"></mat-progress-spinner>
      <div *ngIf="isLoading" [ngStyle]="{ 'width': size }" class="placeholder"></div>
      <img *ngIf="!isNoPhoto" [ngStyle]="{ 'width': size }" [ngClass]="{ 'base64': isBase64Image }" [src]="imageSource"/>
    </div>
    <h4 *ngIf="isBase64Image"> {{ 'component.license-photo.offline_license_photo' | translate }}</h4>
    <h4 *ngIf="isNoPhoto">{{ 'component.license-photo.no_license_photo' | translate }}</h4>

  `,
  styles: [`
    .placeholder {
      height: 333px;
    }
    mat-progress-spinner {
      position: absolute;
    }
    .license-photo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;
    }
    img {
      min-height: 133px;
      height: auto;
    }
    img.base64 {
      width: 100px!important;
      height: auto;
    }
    h4 {
      text-align: center;
      max-width: 300px;
      color: darkgrey;
    }
 `]
})
export class LicensePhotoComponent implements OnInit {

  @Input() license: License | null;

  @Input() matchId: string;

  @Input() size: number;

  public imageSource: string | SafeUrl

  public isLoading = true

  public success = false

  private sub: Subscription

  constructor (public socketService: SocketService, public sanitizer: DomSanitizer, public licenseService: LicenseService) {
  }

  ngOnInit() {
    this.updateImageSource()
    this.sub = this.socketService.isOnline$.subscribe(isOnline => {
      if (isOnline && !this.success && !this.isLoading) {
        this.updateImageSource()
      }
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  get isBase64Image() {
    return this.imageSource && typeof this.imageSource === 'string'
  }

  private async updateImageSource() {
    const licensePhoto = await this.licenseService.getLicensePhoto(this.license, this.matchId)
    if (licensePhoto) {
      this.imageSource = licensePhoto
    }
    this.isLoading = false
  }

  get isNoPhoto() {
    return !this.isLoading && this.imageSource === undefined
  }

}
