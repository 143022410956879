import { Injectable, OnDestroy } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as fromIndoor from 'app/root/indoor.reducer';
import {
  getMatch,
  getTeamSquads,
  isGameStateTouched
} from '../../../../shared/reducers/match-states/match-state.reducer';

@Injectable({
  providedIn: 'root'
})
export class MatchPreparationGuard implements CanActivate, OnDestroy {

  isGameStateTouched: boolean;

  arbitrationConfirmed: boolean;

  haveBothConfirmed: boolean;

  private sub = new Subscription()

  constructor(
    public store: Store<fromIndoor.IndoorRoot>,
    private router: Router) {
    this.sub.add(
      this.store.select(fromIndoor.getMatchState).subscribe(ms => {
        if (!ms) {
          return
        }
        this.arbitrationConfirmed = getMatch(ms).arbitrationConfirmed
        const teamSquads = getTeamSquads(ms)
        this.haveBothConfirmed = teamSquads.team1.confirmed && teamSquads.team2.confirmed
        this.isGameStateTouched = isGameStateTouched(ms)
      })
    )
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (!this.haveBothConfirmed) {
        return this.router.createUrlTree(['/team-preparations'])
      }
      return !this.isGameStateTouched ? true : this.router.createUrlTree(['/match-view'])
  }
  
}
