import { createSelector } from 'reselect';

import * as fromMatchStates from './match-states/match-states.reducer';
import * as fromMatchState from './match-states/match-state.reducer';
import * as fromGameState from './game-state/game-state.reducer';
import * as fromTeamSides from './game-state/match-sets/team-sides/team-sides.reducer';
import * as fromMatchSets from './game-state/match-sets/match-sets.reducer';
import * as fromMatchSet from './game-state/match-sets/match-set.reducer';
import * as fromTimeKeeping from './game-state/match-sets/time-keeping/time-keeping.reducer';
import * as fromLineups from './game-state/match-sets/lineups/lineups.reducer';
import * as fromServing from './game-state/match-sets/serving/serving.reducer';
import * as fromSanctions from './game-state/sanctions/sanctions.reducer';
import * as fromIndividualSanctions from './game-state/sanctions/individual-sanction/individual-sanction.reducer';
import * as fromStartingSix from './game-state/match-sets/starting-six.reducer';
import * as fromShared from './shared.reducer';

import { TeamSquad, StateTeamSquad } from './match-preparation/team-squad.reducer';
import { Injuries, isPlayerInjured } from './game-state/injuries/injuries.reducer';
import { Liberos } from './game-state/liberos/liberos.reducer';
import { LiberosByTeamCode } from './game-state/liberos/liberos-by-team-code.reducer';
import { TeamSquads } from './match-preparation/team-squads.reducer';
import { scoreMetaReducer } from './score-meta.reducer';


import {
  ByTeamCode,
  TeamCode,
  Player,
  Match,
  Team,
  ByTeamSide,
  TeamSide,
  TeamMember
} from '../interfaces/models';

import { MvpsByTeamCode, mvpsByTeamCodeReducer } from './game-state/mvps/mvps-by-team-code.reducer';
import { Mvp } from './game-state/mvps/mvp.reducer';
import { MatchSets } from './game-state/match-sets/match-sets.reducer';
import { ChallengeState } from './game-state/match-sets/challenges/challenges.reducer';
import {
  PositionsByTeamSides,
  Root,
  SanctionsByTeamSides,
  fromTeamCodesToTeamSides,
  sharedReducers
} from './shared.reducer';
import { MatchStates, matchStatesReducer } from './match-states/match-states.reducer';

export interface IndoorRoot extends Root {
  matchStates: MatchStates;
}

export const indoorReducers = {
  ...sharedReducers,
  matchStates: matchStatesReducer,
  samsScore: scoreMetaReducer
};

// ==== Selectors ====

const mapIdsToObjects = <T extends TeamMember>(objects: T[], ids: string[]) => ids.map(id => id ? mapIdToObject(objects, id) : null);

const mapIdToObject = <T extends TeamMember>(objects: T[], uuid: string) => objects.find(p => p.uuid === uuid);

/** replace the liberoSubstitute with the liberoOnField */
const getCurrentLineupWithLibero = (lineup: fromLineups.Lineup<Player>) =>
  fromLineups.getCurrentLineup(lineup)
    .map(
      p => p === null
        ? null
        : p === lineup.liberoSubstitute && lineup.liberoOnField ? lineup.liberoOnField : p
    );

const mapLineupToPlayers = (lineup: fromLineups.Lineup<string>, teamSquad: TeamSquad, liberos: Liberos<Player>) => {
  return {
    ...lineup,
    current: mapIdsToObjects(teamSquad.players, lineup.current),
    liberoOnField: mapIdToObject(teamSquad.players, lineup.liberoOnField),
    liberoSubstitute: mapIdToObject(teamSquad.players, lineup.liberoSubstitute),
    liberoRotatedOut: mapIdToObject(teamSquad.players, lineup.liberoRotatedOut),
    liberos: [...teamSquad.liberos, ...liberos.newLiberos]
  };
};

const mapLineupsToPlayers = (lineups: fromLineups.Lineups, teamSquads: ByTeamCode<TeamSquad>, liberos: ByTeamCode<Liberos<Player>>) => {
  return {
    team1: mapLineupToPlayers(lineups.team1, teamSquads.team1, liberos.team1),
    team2: mapLineupToPlayers(lineups.team2, teamSquads.team2, liberos.team2)
  };
};

const idsToPlayers = (teamSquads: ByTeamCode<TeamSquad>, playerIdsByTeamCode: ByTeamCode<string[]>) => {
  return {
    team1: mapIdsToObjects(teamSquads.team1.players, playerIdsByTeamCode.team1),
    team2: mapIdsToObjects(teamSquads.team2.players, playerIdsByTeamCode.team2)
  };
};

const mapTeamSquadsIdsToObjects = (match: Match, teamSquads: TeamSquads): ByTeamCode<TeamSquad> => {
  return {
    team1: mapTeamSquadIdsToObjects(teamSquads.team1, match.team1),
    team2: mapTeamSquadIdsToObjects(teamSquads.team2, match.team2)
  };
};

const mapMvpIdsToObjects = (teamSquads: ByTeamCode<TeamSquad>, mvps: MvpsByTeamCode): ByTeamCode<Mvp<Player>> => {
  if (!mvps) {
    return mvpsByTeamCodeReducer(undefined, {} as any) as any;
  }
  return {
    team1: { player: mapIdToObject(teamSquads.team1.players, mvps.team1.player) },
    team2: { player: mapIdToObject(teamSquads.team2.players, mvps.team2.player) }
  }
}

const mapLiberosIdsToObjects = (teamSquads: ByTeamCode<TeamSquad>, liberos: LiberosByTeamCode) => {
  return {
    team1: mapLiberoIdsToObjects(teamSquads.team1, liberos.team1),
    team2: mapLiberoIdsToObjects(teamSquads.team2, liberos.team2)
  };
};

const mapLiberoIdsToObjects = (teamSquad: TeamSquad, liberos: Liberos<string>) => {
  const defaultLibero = teamSquad.liberos.length === 1 ? teamSquad.liberos[0].uuid : liberos.defaultLibero
  return {
    ...liberos,
    newLiberos: mapIdsToObjects(teamSquad.players, liberos.newLiberos),
    unableToPlay: mapIdsToObjects(teamSquad.players, liberos.unableToPlay),
    defaultLibero: mapIdToObject(teamSquad.players, defaultLibero),
    liberosPlayed: mapIdsToObjects(teamSquad.players, liberos.liberosPlayed)
  };
};

const mapTeamSquadIdsToObjects = (teamSquad: StateTeamSquad, team: Team) => {
  return {
    ...teamSquad,
    players: mapIdsToObjects(team.players, teamSquad.players),
    liberos: mapIdsToObjects(team.players, teamSquad.liberos),
    captain: mapIdToObject(team.players, teamSquad.captain),
    officials: mapIdsToObjects(team.officials, teamSquad.officials),
  };
};


// # match-states
export const getMatchStates = (state: IndoorRoot) => state.matchStates;
export const getMatchesList = createSelector(getMatchStates, fromMatchStates.getMatchesList);
export const getSelectedMatch = createSelector(getMatchStates, fromShared.getMatchId, fromMatchStates.getMatchById);
export const getMatchState = createSelector(getMatchStates, fromShared.getMatchId, fromMatchStates.getMatchState);
export const getGameState = createSelector(getMatchState, fromMatchState.getGameState);
export const getGameStates = createSelector(getMatchState, fromMatchState.getGameStates);
export const isGameStateTouched = createSelector(getMatchState, fromMatchState.isGameStateTouched);
export const getMatch = createSelector(getMatchState, fromMatchState.getMatch);
export const getTeamSquads = createSelector(getMatch, createSelector(getMatchState, fromMatchState.getTeamSquads), mapTeamSquadsIdsToObjects);
export const getGameHistory = createSelector(getMatchState, fromMatchState.getGameHistory);
// # match-states

// # score-config
export const getScoreConfiguration = createSelector(getMatch, match => match.scoreConfiguration);
export const getMinPlayersInTeam = createSelector(getScoreConfiguration, config => config.minPlayersInTeam);
export const getMaxPlayersInTeam = createSelector(getScoreConfiguration, config => config.maxPlayersInTeam);
export const getManualPlayerSubmissionEnabled = createSelector(getScoreConfiguration, config => config.manualPlayerSubmissionEnabled);
export const getManualOfficialSubmissionEnabled = createSelector(getScoreConfiguration, config => config.manualOfficialSubmissionEnabled);
export const getNumberOfLiberos = createSelector(getScoreConfiguration, config => config.numberOfLiberos);
export const getNumberOfSpectatorsSubmissionMandatory = createSelector(getScoreConfiguration, config => config.numberOfSpectatorsSubmissionMandatory);
export const getMaxRegularSubstitutionsPerSet = createSelector(getScoreConfiguration, config => config.maxRegularSubstitutionsPerSet);
export const getMaxTimeoutsPerSet = createSelector(getScoreConfiguration, config => config.maxTimeoutsPerSet);
export const getTimeoutDurationSeconds = createSelector(getScoreConfiguration, config => config.timeoutDurationSeconds);
export const getTechnicalTimeoutDurationSeconds = createSelector(getScoreConfiguration, config => config.technicalTimeoutDurationSeconds);
export const getSetPauseDurationSeconds = createSelector(getScoreConfiguration, config => config.setPauseDurationSeconds);
export const getDecidingSetPauseDurationSeconds = createSelector(getScoreConfiguration, config => config.decidingSetPauseDurationSeconds);
export const getDecidingSetDrawSides = createSelector(getScoreConfiguration, config => config.decidingSetDrawSides);
export const getDecidingSetSideChangeAtPoints = createSelector(getScoreConfiguration, config => config.decidingSetSideChangeAtPoints);
export const getRefereePinOrigin = createSelector(getScoreConfiguration, config => config.refereePinOrigin);
export const getTotalPlayersOnField = createSelector(getScoreConfiguration, config => config.totalPlayersOnField);
// # score-config


// # match
export const getMatchConfiguration = createSelector(getMatch, match => match.matchConfiguration);
export const getTeam1 = createSelector(getMatch, match => match.team1);
export const getTeam2 = createSelector(getMatch, match => match.team2);
export const getTeam1Description = createSelector(getMatch, match => match.team1Description);
export const getTeam2Description = createSelector(getMatch, match => match.team1Description);
export const getArbitration = createSelector(getMatch, match => match.arbitration);
export const getArbitrationPin = createSelector(getArbitration, arbitration => arbitration.arbitrationPin);
export const getComments = createSelector(getMatch, match => match.comments);
export const getNumOfSpectators = createSelector(getMatch, match => match.numOfSpectators);
export const getTeamByCode = createSelector(getMatch, fromShared.projectTeamCode, (state: Match, teamCode: TeamCode) => state[teamCode]);
export const isLiberoRegistrationEnabled = createSelector(getMatch, match => match.liberoRegistrationEnabled);
// # match


// # game-state
export const getMatchSets = createSelector(getGameState, fromGameState.getMatchSets);
export const getSanctionsByTeamCode = createSelector(getGameState, fromGameState.getSanctionsByTeamCode);
export const getInjuriesByTeamCode = createSelector(getGameState, fromGameState.getInjuriesByTeamCode);
export const getLiberosByTeamCode = createSelector(getTeamSquads, createSelector(getGameState, fromGameState.getLiberosByTeamCode), mapLiberosIdsToObjects);
export const getMvpsByTeamCode = createSelector(getTeamSquads, createSelector(getGameState, fromGameState.getMvpsByTeamCode), mapMvpIdsToObjects);
// # game-state


// # liberos
const hasDefaultLibero = (teamSquad: TeamSquad, liberos: Liberos<Player>) => teamSquad.liberos.length <= 1 || liberos.defaultLibero != null
export const getAreDefaultLiberosSet = createSelector(getTeamSquads, getLiberosByTeamCode,
  (teamSquads, liberos) => hasDefaultLibero(teamSquads.team1, liberos.team1) && hasDefaultLibero(teamSquads.team2, liberos.team2))
// # liberos

// # match-sets
export const getCurrentMatchSet = createSelector(getMatchSets, fromMatchSets.getCurrentMatchSet);
export const getMatchSetForSetNumber = createSelector(getMatchSets, fromShared.projectSetNumber, fromMatchSets.getMatchSetForSetNumber)
export const getCurrentSetIndex = createSelector(getMatchSets, fromMatchSets.getCurrentSetIndex);
export const getCurrentSetNumber = createSelector(getMatchSets, fromMatchSets.getCurrentSetNumber);
export const getCurrentSetScore = createSelector(getCurrentMatchSet, fromMatchSet.getSetScore);
export const getSetPointsByTeamCodes = createSelector(getMatchSets, getMatchConfiguration, fromMatchSets.getSetPoints);
export const getIsMatchFinished = createSelector(getMatchSets, getMatchConfiguration, fromMatchSets.isMatchFinished);
export const getHasMatchStarted = createSelector(getMatchSets, fromMatchSets.isMatchStarted);
export const isDecidingSet = createSelector(getMatchSets, getMatchConfiguration, fromMatchSets.isDecidingSet);
export const getHasCurrentSetStarted = createSelector(getMatchSets, getMatchConfiguration, fromMatchSets.getHasCurrentSetStarted);

export const getCurrentSetPauseStart = createSelector(getMatchSets, getCurrentSetIndex, (state: MatchSets, currentSetIndex: number) => {
  const previousSet = state[currentSetIndex - 1];
  if (previousSet) {
    return previousSet.timeKeeping.endTimeMs;
  }
  return 0;
})
// # match-sets

// # match-set
export const getIsSetRunning = createSelector(getCurrentMatchSet, fromMatchSet.isSetRunning);
export const getTeamSides = createSelector(getCurrentMatchSet, fromMatchSet.getTeamSides);
export const getLeftTeamCode = createSelector(getTeamSides, fromTeamSides.getLeftTeamCode);
export const getRightTeamCode = createSelector(getTeamSides, fromTeamSides.getRightTeamCode);
export const getLeftTeam = createSelector(getMatch, getLeftTeamCode, (state: Match, teamCode: TeamCode) => state[teamCode]);
export const getRightTeam = createSelector(getMatch, getRightTeamCode, (state: Match, teamCode: TeamCode) => state[teamCode]);
export const getTimeKeeping = createSelector(getCurrentMatchSet, fromMatchSet.getTimeKeeping);
export const getServing = createSelector(getCurrentMatchSet, fromMatchSet.getServing);
export const getCurrentSetScoreByTeamSides = createSelector(getCurrentSetScore, getTeamSides, fromTeamCodesToTeamSides);
export const getCurrentlyServing = createSelector(getServing, fromServing.getCurrentlyServing);
export const isCurrentlyServing = createSelector(getCurrentlyServing, fromShared.projectTeamCode, fromServing.isCurrentlyServing);
export const getSetPointsByTeamSides = createSelector(getSetPointsByTeamCodes, getTeamSides, fromTeamCodesToTeamSides);

export const getNumberOfTimeouts = createSelector(getTimeKeeping, fromTimeKeeping.getNumberOfTimeouts);
export const getInjuries = createSelector(getTeamSquads, getInjuriesByTeamCode, idsToPlayers);
const getLineups = createSelector(createSelector(getCurrentMatchSet, fromMatchSet.getLineups), getTeamSquads, getLiberosByTeamCode, mapLineupsToPlayers);
const getChallenges = createSelector(getCurrentMatchSet, fromMatchSet.getChallenges);
export const wasTeamTimeoutTakenBeforeScore = createSelector(getCurrentMatchSet, fromMatchSet.wasTeamTimeoutTakenBeforeScore)

export const getNumberOfTimeoutsByTeamSides = createSelector(
  getNumberOfTimeouts,
  getTeamSides,
  fromShared.fromTeamCodesToTeamSides);

export const getStartingSix = createSelector(getCurrentMatchSet, fromMatchSet.getStartingSix);
export const isStartingSixLocked = createSelector(
  createSelector(getStartingSix, fromStartingSix.isStartingSixLocked),
  getHasMatchStarted, (locked, started) => locked && !started);
// # match-set


export const getSanctionsByTeamSides = createSelector(getSanctionsByTeamCode, getTeamSides, fromTeamCodesToTeamSides);

export const getChallengesByTeamSides = createSelector(
  getChallenges,
  getTeamSides, fromTeamCodesToTeamSides);

export const getInjuriesByTeamSides = createSelector(getInjuries, getTeamSides, fromTeamCodesToTeamSides);

export const getLiberosByTeamSides = createSelector(
  getLiberosByTeamCode,
  getTeamSides, fromTeamCodesToTeamSides);

export const getMvpsByTeamSides = createSelector(
  getMvpsByTeamCode,
  getTeamSides,
  fromTeamCodesToTeamSides);

export const getIsMvpsSubmissionFinished = createSelector(
  getMvpsByTeamSides,
  getMatchConfiguration,
  (mvps, config) => {
    if (!config.mvpSubmissionEnabled) return true;
    if (config.singleTeamMvpSubmissionEnabled) {
      return !!mvps.leftTeam.player || !!mvps.rightTeam.player
    }
    return !!mvps.leftTeam.player && !!mvps.rightTeam.player
  }
)

export const getTeamSquadsByTeamSides = createSelector(
  getTeamSquads,
  getTeamSides,
  fromTeamCodesToTeamSides);


export const getPositionsByTeamSide = createSelector(getScoreConfiguration, fromLineups.getPositionsByTeamSide);

const orderCurrentLineupByPositions = (positions: fromLineups.FieldPositions, currentLineup: Player[]) =>
  currentLineup.map((p, i) => currentLineup[positions[i] - 1]);

/** returns lineups by team sides with the current lineup ordered by positions */
export const getLineupsByTeamSides = createSelector(
  getLineups,
  getTeamSides,
  fromTeamCodesToTeamSides);

export const getTeamLineup = createSelector(getLineupsByTeamSides, fromShared.projectTeamSide, (state: LineupsByTeamSides, teamSide: TeamSide) => state[teamSide]);

/** The current lineup with the libero if he is on the field AND the liberoSubstitute */
const getFullCurrentLineup = createSelector(getTeamLineup, lineup => lineup.liberoOnField ? [...lineup.current, lineup.liberoOnField] : lineup.current);

export const getTeamSquadByTeamCode = createSelector(getTeamSquads, fromShared.projectTeamCode, (state: ByTeamCode<TeamSquad>, teamCode: TeamCode) => state[teamCode]);

export const getTeamSquad = createSelector(getTeamSquadsByTeamSides, fromShared.projectTeamSide, (state: TeamSquadsByTeamSides, teamSide: TeamSide) => state[teamSide]);
export const getPositions = createSelector(getPositionsByTeamSide, fromShared.projectTeamSide, (state: PositionsByTeamSides, teamSide: TeamSide) => state[teamSide]);
export const getTeamInjuries = createSelector(getInjuriesByTeamSides, fromShared.projectTeamSide, (state: InjuriesByTeamSides, teamSide: TeamSide) => state[teamSide]);
export const getSanctionsOfTeam = createSelector(getSanctionsByTeamSides, fromShared.projectTeamSide, (state: SanctionsByTeamSides, teamSide: TeamSide) => state[teamSide]);
export const getTeamChallenges = createSelector(getChallengesByTeamSides, fromShared.projectTeamSide, (state: ChallengesByTeamSides, teamSide: TeamSide) => state[teamSide]);

/** The current lineup ordered by positions with the libero if he is on the field but without the liberoSubstitute */
export const getFieldPlayers = createSelector(getPositions, createSelector(getTeamLineup, getCurrentLineupWithLibero), orderCurrentLineupByPositions);

export const getPlayersOnBench = createSelector(getTeamSquad, getTeamLineup,
  (teamSquad, lineup) =>
    teamSquad.players
      .filter(p => lineup.current.indexOf(p) === -1)
      .filter(p => lineup.liberos.indexOf(p) < 0)
);

// # sanctions
export const getTeamSanctions = createSelector(getSanctionsOfTeam, fromSanctions.getTeamSanctions);
export const getTeamImproperRequest = createSelector(getSanctionsOfTeam, fromSanctions.getTeamImproperRequest);
export const getTeamPenalties = createSelector(getSanctionsOfTeam, fromSanctions.getTeamPenalties);
export const getTeamWarning = createSelector(getSanctionsOfTeam, fromSanctions.getTeamWarning);

export const getIndividualSanctions = createSelector(getSanctionsOfTeam, fromSanctions.getIndividualSanctions)
export const getIndividualExpulsions = createSelector(getSanctionsOfTeam, fromSanctions.getIndividualExpulsions);
export const getIndividualDisqualifications = createSelector(getSanctionsOfTeam, fromSanctions.getIndividualDisqualifications);
export const getIndividualPenalties = createSelector(getSanctionsOfTeam, fromSanctions.getIndividualPenalties);
export const getIndividualWarnings = createSelector(getSanctionsOfTeam, fromSanctions.getIndividualWarnings);

export const getTeamMemberIdsThatAreUnallowedToPlay = createSelector(
  getIndividualSanctions,
  getCurrentSetNumber,
  fromIndividualSanctions.getTeamMemberIdsThatAreNotAllowedToPlay
);

// # sanctions

export const getPlayersUnallowedToPlayOnField = createSelector(
  getTeamMemberIdsThatAreUnallowedToPlay,
  getFullCurrentLineup,
  (teamMemberIdsUnallowedToPlay, currentLineup) =>
    currentLineup.filter(p => teamMemberIdsUnallowedToPlay.indexOf(p.uuid) > -1)
);

export const getInjuredPlayersOnField = createSelector(
  getTeamInjuries,
  getFullCurrentLineup,
  (injuries, currentLineup) =>
    currentLineup.filter(p => isPlayerInjured(injuries, p.uuid))
);

// lineups-modification
const getPreviousStartingLineup = createSelector(
  getMatchSets,
  getCurrentSetIndex,
  fromShared.projectTeamSide,
  getTeamSides,
  (matchSets, currentSetIndex, teamSide, teamSides) => currentSetIndex > 0 ? matchSets[currentSetIndex - 1].lineups[teamSides[teamSide]].starting : []
);

// we have to remove players that are not allowed to play or injured
export const getPreviousStartingLineupNomination = createSelector(
  getTeamMemberIdsThatAreUnallowedToPlay,
  getTeamInjuries,
  getPreviousStartingLineup,
  (playersUnallowedToPlay: string[], injuries: Player[], previousStartingLineup: string[]) =>
    previousStartingLineup.filter(uuid => !fromGameState.requiresSubstitution(injuries, playersUnallowedToPlay, uuid))
);
// lineups-modification

export const getAdditionalPlayers = createSelector(
  getTeamByCode,
  team => team.additionalClubPlayers ?
    // filter only players that were not already added to the team
    team.additionalClubPlayers.filter(additionalPlayer => team.players.find(p => p.personUuid === additionalPlayer.personUuid) === undefined)
    : []
)


export type LineupsByTeamSides = ByTeamSide<fromLineups.Lineup<Player>>;
export type ChallengesByTeamSides = ByTeamSide<ChallengeState>;
export type TeamSquadsByTeamSides = ByTeamSide<TeamSquad>;
export type LiberosByTeamSides = ByTeamSide<Liberos<Player>>;
export type MvpsByTeamSides = ByTeamSide<Mvp<Player>>;
export type InjuriesByTeamSides = ByTeamSide<Injuries<Player>>;
