import { MatchesOverviewComponent } from "./matches-overview.component";
import { MatchPreviewComponent } from "./match-preview.component";
import { TokenFormComponent } from "./token-form.component";
import { MatchFinalizedWarningDialogComponent } from "./match-finalized-warning-dialog";
import { MaximumMatchesWarningDialogComponent } from "./maximum-matches-warning-dialog";
import { MatchesOverviewService } from "./matches-overview.service";

export const MATCHES_OVERVIEW_DIALOGS = [
  MatchFinalizedWarningDialogComponent,
  MaximumMatchesWarningDialogComponent
];

export const MATCHES_OVERVIEW_COMPONENTS = [
  MatchesOverviewComponent,
  MatchPreviewComponent,
  TokenFormComponent,
  ...MATCHES_OVERVIEW_DIALOGS
];

export const MATCHES_OVERVIEW_SERVICES = [
  MatchesOverviewService
];
