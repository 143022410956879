import { BrowserModule, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

import { UndoableComponent } from './undoable/undoable.component';
import { environment } from 'environments/environment';
import { ROUTES } from './app.routes';
import { BEACH_ROUTES } from './beach/beach.routes';
import { indoorReducerToken, indoorReducerProvider } from 'app/root/indoor.reducer';
import { beachReducerToken, beachReducerProvider } from 'app/root/beach.reducer';

import { MATCH_VIEW_COMPONENTS, MATCH_VIEW_SERVICES } from './match-view';
import { MATCH_FINALIZATION_COMPONENTS } from './match-finalization';
import { MATCH_PREPARATION_COMPONENTS, MATCH_PREPARATION_SERVICES } from './match-preparation';
import { CONNECTIONS_COMPONENTS, CONNECTIONS_SERVICES, CONNECTIONS_DIALOGS } from './connections';
import { MATCHES_OVERVIEW_COMPONENTS, MATCHES_OVERVIEW_SERVICES, MATCHES_OVERVIEW_DIALOGS } from './matches-overview';
import { PIPES } from './pipes';
import { UI_COMPONENTS } from './ui';
import { MATERIAL_MODULES } from './material.module';
import { ERRROR_COMPONENTS, ERROR_SERVICES } from './error';
import { GlobalErrorHandler } from './error/error-handler';
import { MATCH_VIEW_DIALOGS } from './match-view/dialogs';
import { ColorPickerModule } from 'ngx-color-picker';
import { ServiceWorkerModule } from '@angular/service-worker';
import { UpdateService } from './update-service';
import { UpdateDialogComponent } from './update-dialog.component';
import { BEACH_COMPONENTS } from './beach/beach.component';
import { AppComponent } from './app.component';
import { config } from './config/config';
import { metaReducers } from './root/shared.reducer';
import { BeachFinalizationService } from './beach/finalization/beach-finalization.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

const APP_ROUTES = config.isBeach ? BEACH_ROUTES : ROUTES

const prodImports = [
  BrowserModule,
  HammerModule,
  ServiceWorkerModule.register('/ngsw-worker.js', { enabled: true, registrationStrategy: "registerImmediately" }),
  BrowserAnimationsModule,
  FormsModule,
  HttpClientModule,
  ...MATERIAL_MODULES,
  RouterModule.forRoot(APP_ROUTES, { relativeLinkResolution: 'legacy' }),
  StoreModule.forRoot(config.isBeach ? beachReducerToken : indoorReducerToken, { metaReducers, runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true } }),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [ HttpClient ]
    }
  }),
  ColorPickerModule
];

const devImports = [
  ...prodImports,
  StoreDevtoolsModule.instrument({ maxAge: 5 })
];

@NgModule({
  declarations: [
    AppComponent,
    ...BEACH_COMPONENTS,
    ...ERRROR_COMPONENTS,
    ...MATCH_VIEW_COMPONENTS,
    ...MATCH_PREPARATION_COMPONENTS,
    ...MATCH_FINALIZATION_COMPONENTS,
    ...MATCHES_OVERVIEW_COMPONENTS,
    ...UI_COMPONENTS,
    ...CONNECTIONS_COMPONENTS,
    ...PIPES,
    UndoableComponent,
    UpdateDialogComponent,
  ],
  imports: environment.production ? prodImports : devImports
  ,
  providers: [
    { provide: HammerGestureConfig, useClass: HammerGestureConfig },
    UpdateService,
    BeachFinalizationService,
    ...MATCHES_OVERVIEW_SERVICES,
    ...MATCH_PREPARATION_SERVICES,
    ...CONNECTIONS_SERVICES,
    ...MATCH_VIEW_SERVICES,
    ...ERROR_SERVICES,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    config.isBeach ? beachReducerProvider : indoorReducerProvider
  ],
  entryComponents: [
    ...CONNECTIONS_DIALOGS,
    ...MATCH_VIEW_DIALOGS,
    ...MATCHES_OVERVIEW_DIALOGS
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
