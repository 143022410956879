import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';

import {
  TeamSanctions,
  preTeamWarning,
  preTeamImproperRequest,
  preTeamPenalty
} from '../../../../../shared/reducers/game-state/sanctions/team-sanction/team-sanction.reducer';

@Component({
  selector: 'sams-team-sanction',
  template: `
    <button mat-raised-button [matMenuTriggerFor]="menu">
      <mat-icon>booking</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button [disabled]="!preTeamWarning" mat-menu-item (click)="warning.emit()">
        <mat-icon [style.color]="'gold'">booking</mat-icon>
        {{'app.warning' | translate}}
      </button>
      <button [disabled]="!preTeamPenalty" mat-menu-item (click)="penalty.emit()">
        <mat-icon [style.color]="'tomato'">booking</mat-icon>
        {{'app.penalty' | translate}}
      </button>
      <button [disabled]="!preTeamImproperRequest" mat-menu-item (click)="improperRequest.emit()">
        <mat-icon>no_sim</mat-icon>
        {{'app.improper_request' | translate}}
      </button>
    </mat-menu>
  `,
  styles: [`
    .mat-raised-button {
      width: 50px;
      min-width: 50px;
      padding:0;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamSanctionComponent {

  @Input() state: TeamSanctions;

  @Output() warning = new EventEmitter<void>();

  @Output() penalty = new EventEmitter<void>();

  @Output() improperRequest = new EventEmitter<void>();

  get preTeamWarning() {
    return preTeamWarning(this.state);
  }

  get preTeamPenalty() {
    return preTeamPenalty(this.state);
  }

  get preTeamImproperRequest() {
    return preTeamImproperRequest(this.state);
  }

}
