import { MatchAction } from '../../../../interfaces/interfaces.js';
import { TeamCode } from '../../../../interfaces/models/team-codes.js';
import { MatchStateAction } from '../../../match-states/match-state.action.js';

export interface Timeout {
  timestamp: number;
  teamCode: TeamCode;
  scoreSelf: number;
  scoreOther: number;
}

export const SET_TIME_KEEPING         = '[Timeouts] set time keeping'
export const CHANGE_SET_PAUSE_DURATION = '[Timeouts] Change set pause duration';
export const CANCEL_SET_PAUSE          = '[Timeouts] Cancel set pause';
export const CANCEL_TIMEOUT            = '[Timeouts] Cancel timeout';
export const START_TIMEOUT             = '[Timeouts] Start timeout';
export const START_TECHNICAL_TIMEOUT   = '[Timeouts] Start technical timeout';
export const CANCEL_TECHNICAL_TIMEOUT  = '[Timeouts] Cancel technical timeout';
export const START_SET                 = '[TimeKeeping] Start';
export const START_MATCH               = '[TimeKeeping] Start Match';
export const SET_START_TIME            = '[TimeKeeping] Set Start Time';
export const END_SET                   = '[TimeKeeping] End';

export interface SetTimeKeepingPayload {
  setPauseDurationSeconds: number
}

export class SetTimeKeepingAction extends MatchAction {
  readonly type = SET_TIME_KEEPING;
  constructor(public payload: SetTimeKeepingPayload, matchId: string) {
    super(matchId);
  }
}

export class SetStartTimeAction extends MatchAction {
  readonly type = SET_START_TIME;
  constructor(public payload: number, matchId: string) {
    super(matchId);
  }
}

export class StartSetAction extends MatchAction {
  readonly type = START_SET;
  constructor(public payload: number, matchId: string) {
    super(matchId);
  }
}

export class EndSetAction extends MatchAction {
  readonly type = END_SET;
  constructor(public payload: number, matchId: string) {
    super(matchId);
  }
}

export class ChangeSetPauseDurationAction extends MatchAction {
  readonly type = CHANGE_SET_PAUSE_DURATION;
  constructor(public payload: { newDuration: number}, matchId: string) {
    super(matchId);
  }
}
export class CancelSetPauseAction extends MatchAction {
  readonly type = CANCEL_SET_PAUSE;
  constructor(matchId: string) {
    super(matchId);
  }
}
export class CancelTimeoutAction extends MatchAction {
  readonly type = CANCEL_TIMEOUT;
  constructor(matchId: string) {
    super(matchId);
  }
}

export class StartTimeoutAction extends MatchAction {
  readonly type = START_TIMEOUT;
  constructor(public payload: Timeout, matchId: string) {
    super(matchId);
  }
}

export class StartTechnicalTimeoutAction extends MatchAction {
  readonly type = START_TECHNICAL_TIMEOUT;
  constructor(public payload: number, matchId: string) {
    super(matchId);
  }
}

export class CancelTechnicalTimeoutAction extends MatchAction {
  readonly type = CANCEL_TECHNICAL_TIMEOUT;
  constructor(matchId: string) {
    super(matchId);
  }
}

export class StartMatchAction extends MatchAction {
  readonly type = START_MATCH;
  constructor(public payload: { startTimeMs: number, actualStartTimeMs: number }, matchId: string) {
    super(matchId);
  }
}

export const isStartMatchAction = (action: MatchStateAction): action is StartMatchAction => action.type === START_MATCH

export type TimeKeepingAction
  = SetTimeKeepingAction
  | StartSetAction
  | StartMatchAction
  | SetStartTimeAction
  | EndSetAction
  | ChangeSetPauseDurationAction
  | CancelSetPauseAction
  | CancelTimeoutAction
  | StartTimeoutAction
  | CancelTechnicalTimeoutAction
  | StartTechnicalTimeoutAction;
