import {
  IndividualSanctionAction,
  INDIVIDUAL_WARNING,
  INDIVIDUAL_PENALTY,
  INDIVIDUAL_EXPULSION,
  INDIVIDUAL_DISQUALIFICATION,
  IndividualExpulsionAction,
  IndividualDisqualificationAction,
  IndividualWarningAction,
  IndividualPenaltyAction
} from './individual-sanction.action.js';
import { SetScore } from '../../match-sets/set-score/set-score.reducer.js';

export type IndividualWarnings = TeamMemberSanction<IndividualWarning>;
export type IndividualPenalties = TeamMemberSanction<IndividualPenalty[]>;
export type IndividualExpulsions = TeamMemberSanction<IndividualExpulsion>;
export type IndividualDisqualifications = TeamMemberSanction<IndividualDisqualification>;

export type TeamMemberSanctions = IndividualWarnings | IndividualPenalties | IndividualExpulsions | IndividualDisqualifications;

export interface TeamMemberSanction<T> {
  [teamMemberId: string]: T;
}

export type IndividualWarning = IndividualSanction;
export type IndividualPenalty = IndividualSanction;
export interface IndividualExpulsion extends IndividualSanction {
  isAllowedToPlayNextSets?: boolean;
}
export type IndividualDisqualification = IndividualSanction;

export interface IndividualSanction {
  teamMemberId: string;
  setScore: SetScore;
  currentSetNumber: number;
  timestamp: number;
};

export interface IndividualSanctions {
  warnings: IndividualWarnings;
  penalties: IndividualPenalties;
  expulsions: IndividualExpulsions;
  disqualifications: IndividualDisqualifications;
}

export const initialState: IndividualSanctions = {
  warnings: {},
  penalties: {},
  expulsions: {},
  disqualifications: {}
};

export const teamMemberPenaltyReducer =
  (state: IndividualPenalties,
   action: IndividualPenaltyAction,
   setScore: SetScore,
   currentSetNumber: number): IndividualPenalties => {
  const teamMemberId = action.payload.teamMemberId;
  return {
    ...state,
    [teamMemberId]: [
      ...state[teamMemberId] || [],
      { setScore,
      currentSetNumber,
      timestamp: action.timestamp,
      teamMemberId }]
  };
}

export const teamMemberExpulsionReducer =
  (state: IndividualExpulsions,
   action: IndividualExpulsionAction,
   setScore: SetScore,
   currentSetNumber: number): IndividualExpulsions => {
  const teamMemberId = action.payload.teamMemberId;
  return {
    ...state,
    [teamMemberId]: {
      setScore,
      currentSetNumber,
      timestamp: action.timestamp,
      teamMemberId,
      isAllowedToPlayNextSets: true
    }
  };
}

export const teamMemberSanctionReducer =
  (state: IndividualWarnings | IndividualDisqualifications,
   action: IndividualWarningAction | IndividualDisqualificationAction,
   setScore: SetScore,
   currentSetNumber: number): IndividualWarnings | IndividualDisqualifications => {
  const teamMemberId = action.payload.teamMemberId;
  return {
    ...state,
    [teamMemberId]: {
      setScore,
      currentSetNumber,
      timestamp: action.timestamp,
      teamMemberId
    }
  };
}

export const individualSanctionsReducer =
  (state: IndividualSanctions = initialState,
   action: IndividualSanctionAction,
   setScore: SetScore,
   currentSetNumber: number): IndividualSanctions => {

  switch (action.type) {

    case INDIVIDUAL_WARNING: {
      return {
        ...state,
        warnings: teamMemberSanctionReducer(state.warnings, action, setScore, currentSetNumber)
      };
    }
    case INDIVIDUAL_PENALTY: {
      return {
        ...state,
        penalties: teamMemberPenaltyReducer(state.penalties, action, setScore, currentSetNumber)
      };
    }
    case INDIVIDUAL_EXPULSION: {
      return {
        ...state,
        expulsions: teamMemberExpulsionReducer(state.expulsions, action, setScore, currentSetNumber)
      };
    }
    case INDIVIDUAL_DISQUALIFICATION: {
      return {
        ...state,
        disqualifications: teamMemberSanctionReducer(state.disqualifications, action, setScore, currentSetNumber)
      };
    }

    default:
      return state;
  }
};

// # selectors
export const getIndividualDisqualifications = (state: IndividualSanctions) => state.disqualifications;
export const getIndividualExpulsions = (state: IndividualSanctions) => state.expulsions;
export const getIndividualPenalties = (state: IndividualSanctions) => state.penalties;
export const getIndividualWarnings = (state: IndividualSanctions) => state.warnings;

export const filterCurrentlyExpelledTeamMemberIds = (state: IndividualSanctions, currentSetNumber: number) =>
  Object.keys(getIndividualExpulsions(state))
    .filter(teamMemberId => isTeamMemberCurrentlyExpelled(currentSetNumber, state, teamMemberId));

/** returns an unordered list of teamMemberIds that are not allowed to play in the current set */
export const getTeamMemberIdsThatAreNotAllowedToPlay = (state: IndividualSanctions, currentSetNumber: number) =>
  Array.from(new Set([
    ...Object.keys(getIndividualDisqualifications(state)),
    ...filterCurrentlyExpelledTeamMemberIds(state, currentSetNumber)
  ]));

export const isTeamMemberWarned = (state: IndividualSanctions, teamMemberId: string) =>
  typeof getIndividualWarnings(state)[teamMemberId] !== 'undefined';

export const isTeamMemberPenalized = (state: IndividualSanctions, teamMemberId: string) =>
  typeof getIndividualPenalties(state)[teamMemberId] !== 'undefined';

export const isTeamMemberExpelled = (state: IndividualSanctions, teamMemberId: string) =>
  typeof getIndividualExpulsions(state)[teamMemberId] !== 'undefined';

export const isTeamMemberCurrentlyExpelled = (currentSetNumber: number, state: IndividualSanctions, teamMemberId: string) => {
    const expulsion = getIndividualExpulsions(state)[teamMemberId];
    return typeof expulsion !== 'undefined' && (expulsion.currentSetNumber === currentSetNumber || !expulsion.isAllowedToPlayNextSets)
  };

export const isTeamMemberDisqualified = (state: IndividualSanctions, teamMemberId: string) =>
  typeof getIndividualDisqualifications(state)[teamMemberId] !== 'undefined';

export const isTeamMembersHighestSanctionWarning = (state: IndividualSanctions, teamMemberId: string) => 
  isTeamMemberWarned(state, teamMemberId) &&
  !(isTeamMemberDisqualified(state, teamMemberId) || isTeamMemberPenalized(state, teamMemberId) || isTeamMemberExpelled(state, teamMemberId));

export const isTeamMembersHighestSanctionPenalty = (state: IndividualSanctions, teamMemberId: string) =>
  isTeamMemberPenalized(state, teamMemberId)
  && !(isTeamMemberExpelled(state, teamMemberId) || isTeamMemberDisqualified(state, teamMemberId));

export const isTeamMembersHighestSanctionExpulsion = (state: IndividualSanctions, teamMemberId: string) =>
  isTeamMemberExpelled(state, teamMemberId) && !isTeamMemberDisqualified(state, teamMemberId);

export const isTeamMembersHighestSanctionDisqualification = isTeamMemberDisqualified;

// # preconditions
type Pre = (state: IndividualSanctions) => boolean;
type Poss = (state: IndividualSanctions, teamMemberId: string) => boolean;

/** Only one teamMember per team can be warned the next one will be penalized directly */
const preIndividualWarning: Pre = state => Object.keys(getIndividualWarnings(state)).length === 0;

export const possIndividualDisqualification: Poss = (state, teamMemberId) =>
  !isTeamMemberDisqualified(state, teamMemberId);

export const possIndividualExpulsion: Poss = (state, teamMemberId) =>
  !isTeamMemberExpelled(state, teamMemberId) && possIndividualDisqualification(state, teamMemberId);

export const possIndividualPenalty = (state: IndividualSanctions, teamMemberId: string, allowedPenalties: number) =>
  (!isTeamMemberPenalized(state, teamMemberId) || getIndividualPenalties(state)[teamMemberId].length < allowedPenalties) && possIndividualExpulsion(state, teamMemberId);

export const possIndividualWarning: Poss = (state, teamMemberId) =>
  preIndividualWarning(state) && !isTeamMemberWarned(state, teamMemberId) && !isTeamMemberPenalized(state, teamMemberId);
