import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { Player } from 'app/models';
import * as fromIndoor from 'app/root/indoor.reducer';
import { SanctionsByTeamSides } from 'app/root/shared.reducer';

export enum SortedBy {
  firstName = 'firstName',
  lastName = 'lastName',
  jerseyNumber = 'jerseyNumber',
};

@Pipe({ name: 'sortPlayers' })
export class SortPlayersPipe implements PipeTransform, OnDestroy {

  injuriesByTeamSides: fromIndoor.InjuriesByTeamSides;
  sanctionsByTeamSides: SanctionsByTeamSides
  currentSetNumber: number;

  subscription = new Subscription();
  
  constructor(private store: Store<fromIndoor.IndoorRoot>) {

    this.subscription.add(
      store.select(fromIndoor.getInjuriesByTeamSides)
        .subscribe(x => this.injuriesByTeamSides = x)
    );

    this.subscription.add(
      store.select(fromIndoor.getSanctionsByTeamSides)
        .subscribe(x => this.sanctionsByTeamSides = x)
    );

    this.subscription.add(
      store.select(fromIndoor.getCurrentSetNumber)
        .subscribe(x => this.currentSetNumber = x)
    );

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  sortBy(players: Player[], getPlayerProp: (p: Player) => string | number) {
    return [...players].sort((a, b) => {
      if (!a || !b) {
        return 0;
      }
      const x = getPlayerProp(a);
      const y = getPlayerProp(b);
      if (!x && !y) return 0
      if (!x) return 1
      if (!y) return -1
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  transform(players: Player[], objKey: string): Player[] {
    if (objKey === SortedBy.jerseyNumber) {
      return this.sortBy(players, p => +p.jerseyNumber);
    }
    return this.sortBy(players, p => p[objKey]);
  }
}
