
import {
  startWith,
  distinctUntilChanged,
  merge,
  delay,
  map,
  filter
} from 'rxjs/operators';
import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { Observable } from 'rxjs';
import { SocketService } from './socket.service';


@Component({
  selector: 'sams-notification',
  template: `
    <mat-icon>
        {{(displayNotification$ | async) ? "notifications_active" : "notifications"}}
    </mat-icon>
  `,
  styles: [``]
})
export class NotificationComponent implements OnInit {

  @Input() matchId: string;

  displayNotification$: Observable<boolean>;

  constructor(public socketService: SocketService) {
  }

  ngOnInit() {
    const filtered$ = this.socketService.receiveAction$.pipe(
      filter(obj => obj.matchId === this.matchId),
      map(_ => true),);

    const autoCloseNotification$ = filtered$.pipe(
      delay(5000),
      map(_ => false),);

    this.displayNotification$ = filtered$.pipe(merge(autoCloseNotification$),
      distinctUntilChanged(),
      startWith(false),);
  }

}
