import { debounceTime, take, throttleTime } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { MatchState } from '../../../../shared/reducers/match-states/match-state.reducer';
import { SaveCommentsAction } from '../../../../shared/reducers/match-states/match-modification.action';

import { Match, Player, Team, TeamSide, TeamCode } from 'app/models';
import { ScoresheetPresenter } from 'app/helpers/scoresheet-presenter';
import * as fromIndoor from 'app/root/indoor.reducer';
import * as fromShared from 'app/root/shared.reducer';
import { DispatchService } from 'app/connections/dispatch.service';
import { RefereePinOrigins } from '../../../../shared/interfaces/models/referee-pin-origin';
import { MatchSets, SetPoints } from '../../../../shared/reducers/game-state/match-sets/match-sets.reducer';
import { TranslateService } from '@ngx-translate/core';
import { LiberosByTeamSides, LineupsByTeamSides } from '../root/indoor.reducer';
import { TeamSideValues } from '../../../../shared/interfaces/models/team-sides';
import { LiberoPlayedAction } from '../../../../shared/reducers/game-state/liberos/liberos.action';
import { TeamSides } from '../../../../shared/reducers/game-state/match-sets/team-sides/team-sides.reducer';
import { ConfirmFinalizationAction } from '../../../../shared/reducers/match-states/match-finalization/match-finalization.action';
import {
  getStatusCode,
  isMatchFinalized,
  isMatchSuccessfullyFinalized
} from '../../../../shared/reducers/match-states/match-finalization/match-finalization.reducer';

@Component({
  selector: 'sams-match-finalization',
  template: `
    <sams-syncing *ngIf="!match.isTestMatch" [matchId]="matchId"></sams-syncing>

    <mat-toolbar color="secondary">
      <button *ngIf="matchFinalized" mat-button (click)="navigateHome()">{{'app.back_to_matches_overview' | translate}}</button>
      <button *ngIf="!matchFinalized" mat-button (click)="navigateBack()">{{'app.back_to_match' | translate}}</button>
      <span style="flex: 1 1 auto;"></span>
      <div (click)="navigateHome()" style="height: 100%; width: auto; cursor: pointer;">
        <sams-score-logo></sams-score-logo>
      </div>
      <span style="flex: 1 1 auto;"></span>
      <sams-connection></sams-connection>
    </mat-toolbar>

    <div class="match-finalization">
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title>
            <h2 class="mat-card-h2">{{'component.match-finalization.finalize_match' | translate}}</h2>
          </mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <div class="flex-container">
            <div class="match-container">
              <div class="match-header">
                <div class="team-logo">
                  <sams-logo [src]="team1.logoImageMax400x400?.url" [alt]="team1.name" [letter]="team1.singleLetterName" size="100px"></sams-logo>
                  {{team1.name}}
                </div>
                <div class="match-info">
                  <div>
                    {{match.matchSeries.name}}
                  </div>
                  <div>
                    {{formatDate()}}
                  </div>
                  <div>
                    {{formatTime()}} Uhr
                  </div>
                </div>
                <div class="team-logo">
                  <sams-logo [src]="team2.logoImageMax400x400?.url" [alt]="team2.name" [letter]="team2.singleLetterName" size="100px"></sams-logo>
                  {{team2.name}}
                </div>
              </div>
              <div class="match-body">
                <sams-match-final-scores [setPoints]="setPoints$ | async" [matchSets]="matchSets$ | async" [matchState]="matchState"></sams-match-final-scores>
              </div>
            </div>

            <div class="trivia-container">
              <sams-num-of-spectators class="num-of-spectators"
                [matchId]="matchId"
                [numOfSpectators]="numOfSpectators$ | async"
                [placeholder]="'app.number_of_spectators'"
                [required]="numberOfSpectatorsSubmissionMandatory">
              </sams-num-of-spectators>
              <button class="scoresheet-button" mat-raised-button (click)="openScoresheetView()">{{'app.scoresheet' | translate}}</button>
              <div class="vertical-spacer"></div>
              <div *ngIf="isMvpSubmissionEnabled" class="mvps">
                <div class="mvp" *ngIf="(mvpsByTeamSides$ | async)?.leftTeam.player">
                  <div class="mvp-team">
                    <img class="mvp-icon" src="assets/icons/player-game.svg"> MVP {{(leftTeam$ | async)?.name}}:
                  </div>
                  <div class="mvp-player">
                    <div class="mvp-player-jersey-number">
                      {{(mvpsByTeamSides$ | async)?.leftTeam.player.jerseyNumber}}
                    </div>
                    <div class="mvp-player-name">
                      {{getFullName((mvpsByTeamSides$ | async)?.leftTeam.player)}}
                    </div>
                  </div>
                </div>
                <div class="mvp" *ngIf="(mvpsByTeamSides$ | async)?.rightTeam.player">
                  <div class="mvp-team">
                    <img class="mvp-icon" src="assets/icons/player-game.svg"> MVP {{(rightTeam$ | async)?.name}}:
                  </div>
                  <div class="mvp-player">
                    <div class="mvp-player-jersey-number">
                      {{(mvpsByTeamSides$ | async)?.rightTeam.player.jerseyNumber}}
                    </div>
                    <div class="mvp-player-name">
                      {{getFullName((mvpsByTeamSides$ | async)?.rightTeam.player)}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="liberoRegistrationEnabled$ | async" class="liberos-played-wrapper">
            <h2 class="liberos-played-heading">Eingesetzte Liberos:</h2>
            <div class="liberos-played">
              <div class="liberos-played-team">
                <h3>{{(leftTeam$ | async)?.name}}</h3>
                <mat-checkbox class="player-select"
                  *ngFor="let p of lineupsByTeamSides.leftTeam.liberos"
                  (change)="onLiberoPlayed(p.uuid, teamSides.leftTeam)"
                  [checked]="hasLiberoPlayed(p, TeamSides.leftTeam)" [value]="p">
                  {{p.jerseyNumber}} {{p.lastName}}
                </mat-checkbox>
              </div>
              <div class="liberos-played-team">
                <h3>{{(rightTeam$ | async)?.name}}</h3>
                <mat-checkbox class="player-select"
                  *ngFor="let p of lineupsByTeamSides.rightTeam.liberos"
                  (change)="onLiberoPlayed(p.uuid, teamSides.rightTeam)"
                  [checked]="hasLiberoPlayed(p, TeamSides.rightTeam)" [value]="p">
                  {{p.jerseyNumber}} {{p.lastName}}
                </mat-checkbox>
              </div>
            </div>
          </div>

          <div class="comments">
            <h2>{{'app.comments' | translate}}</h2>
            <p>{{comments$ | async}}</p>
            <p class="no-comments" *ngIf="!(comments$ | async)">keine Bemerkungen</p>
          </div>
        </mat-card-content>

        <mat-card-actions *ngIf="!matchFinalized && pinUnknown">
            <div class="team-pins">
              <sams-pin
                placeholder="{{team1.name}}"
                buttonLabel="{{'app.confirm' | translate}}"
                [pin]="team1.pin"
                [disabled]="team1PinHash"
                (success)="onTeam1PinSuccess($event)">
              </sams-pin>
              <sams-pin
                placeholder="{{team2.name}}"
                buttonLabel="{{'app.confirm' | translate}}"
                [pin]="team2.pin"
                [disabled]="team2PinHash"
                (success)="onTeam2PinSuccess($event)">
              </sams-pin>
            </div>
            <div class="action-buttons">
              <button class="positive" [disabled]="!refereePinEnabled" mat-raised-button (click)="onTeamPinsSuccess()">
                <mat-icon>send</mat-icon> {{'component.match-finalization.finalize' | translate}}
              </button>
              <button class="warn" mat-raised-button (click)="onRevertPinUnknown()">
                <mat-icon>cancel</mat-icon> {{'app.cancel' | translate}}
              </button>
            </div>
        </mat-card-actions>

        <mat-card-actions *ngIf="!matchFinalized">
          <div class="flex-container pins" *ngIf="!matchFinalized && !pinUnknown">

            <div class="team-pins">
              <sams-pin
                placeholder="{{team1.name}}"
                buttonLabel="{{'app.confirm' | translate}}"
                [pin]="team1.pin"
                [disabled]="team1PinHash"
                (success)="onTeam1PinSuccess($event)">
              </sams-pin>
              <sams-pin
                placeholder="{{team2.name}}"
                buttonLabel="{{'app.confirm' | translate}}"
                [pin]="team2.pin"
                [disabled]="team2PinHash"
                (success)="onTeam2PinSuccess($event)">
              </sams-pin>
            </div>
        
            <div class="referee-pin">
              <sams-pin
                *ngIf="isRefereePinRequired"
                placeholder="{{(isTeamArbitration ? 'app.team_referee_pin' : 'app.referee_pin') | translate}}"
                buttonLabel="{{'component.match-finalization.finalize' | translate}}"
                [pin]="arbitrationPin$ | async"
                [disabled]="!refereePinEnabled"
                (failure)="onRefereePinFailure($event)"
                (success)="finalize($event)">
              </sams-pin>
              <div class="action-buttons">
                <button *ngIf="teamPinFinalizationEnabled && refereePinFailure" [disabled]="!refereePinEnabled" mat-raised-button class="warn pin-unknown-button" (click)="onPinUnknown()">
                  {{'app.unknown_pin' | translate}}
                </button>
                <button *ngIf="!isRefereePinRequired" class="positive" [disabled]="!refereePinEnabled" mat-raised-button (click)="finalize(null)">
                  <mat-icon>send</mat-icon> {{'component.match-finalization.finalize' | translate}}
                </button>
              </div>
            </div>

          </div>
          </mat-card-actions>

            <mat-card class="warn" *ngIf="matchFinalized && !isMatchSuccessfullyFinalized && statusCode && (statusCode != 403 && statusCode != 401)">
              <mat-card-content style="text-align: center;">{{'component.match-finalization.match_finalization_failed_message' | translate}}</mat-card-content>
              <mat-card-actions>
                <button mat-raised-button [disabled]="finalizeButtonDisabled" (click)="finalize(null)">
                  {{'component.match-finalization.resubmit' | translate}}
                  <mat-progress-bar *ngIf="matchFinalized && !statusCode" mode="indeterminate"></mat-progress-bar>
                </button>
                <button style="color: black;" mat-raised-button (click)="openScoresheetView()">
                  {{'component.match-finalization.save_scoresheet' | translate}}
                </button>
              </mat-card-actions>
            </mat-card>

            <mat-card class="success" *ngIf="isMatchSuccessfullyFinalized">
              <mat-card-content style="text-align: center;">{{'component.match-finalization.match_finalization_success_message' | translate}}</mat-card-content>
            </mat-card>

            <mat-card class="yellow" *ngIf="matchFinalized && !isMatchSuccessfullyFinalized && (!statusCode || (statusCode == 403 || statusCode == 401))">
              <mat-card-content style="text-align: center;">{{'component.match-finalization.match_finalized_message' | translate}}</mat-card-content>
              <mat-card-actions>
                <button mat-raised-button [disabled]="finalizeButtonDisabled" (click)="finalize(null)">
                  {{'component.match-finalization.resubmit' | translate}}
                  <mat-progress-bar *ngIf="matchFinalized && !statusCode" mode="indeterminate"></mat-progress-bar>
                </button>
                <button mat-raised-button (click)="openScoresheetView()">
                  {{'component.match-finalization.save_scoresheet' | translate}}
                </button>
              </mat-card-actions>
            </mat-card>

          </mat-card>

    </div>

  `,
  styles: [`

    .liberos-played-heading {
      margin-left: 15px;
    }
    .liberos-played {
      margin-left: 15px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .liberos-played-team {
      margin: 15px;
    }

    .liberos-played-team .player-select {
      margin-right: 15px;
    }

    .action-buttons {
      margin-top: 68px;
    }

    button {
      max-height: 36px;
    }

    .pins {
      padding-bottom: 30px;
    }

    .mvp {
      display: flex;
      flex-direction: column;
      padding: 15px 0px;
    }

    .mvp:first-child {
      border-bottom: 1px solid grey;
    }

    .mvp-player {
      display: flex;
    }

    .mvp-player-jersey-number {
      flex: 0 0 50px;
      text-align: center;
    }

    .mvp-team {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .mat-card-header {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: auto auto;
      position: relative;
    }

    .mat-card-avatar {
      position: absolute;
      top: 0;
      left: 0;
    }
    
    .mat-card-h2 {
      text-align: center;
      margin: 15px 16px 0 -16px;
    }

    .mat-card-content {
      display: grid;
      grid-template-rows: auto auto;
      grid-template-columns: auto;
    }

    .mat-card-content .flex-container {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-around;
    }

    .match-container {
      display: grid;
      grid-template-columns: repeat(9, auto);
      grid-template-rows: repeat(2, auto);
    }

    .trivia-container {
      display: flex;
      flex-direction: column;
    }

    .match-header {
      display: grid;
      grid-column: 1 / 10;
      grid-row: 1 / 2;
      grid-template-columns: repeat(9, 1fr);
      align-items: center;
    }

    .match-body {
      display: grid;
      grid-column: 1 / 10;
      grid-row: 2 / 3;
      grid-template-columns: repeat(9, 1fr);
      align-items: center;
    }
    sams-match-final-scores {
      display: grid;
      grid-column: 1 / 10;
    }
    .match-info {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      grid-column: 4 / 7;
      grid-row: 1 / 2;
    }
    .match-info div {
      margin: 2px;
    }
    .match-info div:first-child {
      width: 100%;
    }

    .team-logo {
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .team-logo:first-child {
      grid-column: 1 / 4;
      grid-row: 1 / 2;
    }
    .team-logo:last-child {
      display: flex;
      align-items: center;
      flex-direction: column;
      grid-column: 7 / 10;
      grid-row: 1 / 2;
    }

    .team-logo >* {
      margin: 5px;
    }

    .mvp-icon {
      height: 50px;
      width: auto;
    }

    .no-comments {
      font-style: italic;
      color: grey; 
    }

    .comments {
      white-space: pre-wrap;
      max-width: 600px;
      align-self: flex-start;
      margin-left: 15px;
    }

    .team-pins {
      display: flex;
    }

    .team-pins >* {
      margin: 0 15px;
    }

    .referee-pin {
      display: flex;
    }

    .referee-pin >.ng-star-inserted {
      margin: 0 15px;
    }
    .referee-pin >.action-buttons {
      margin-right: 15px;
      margin-left: 15px;
    }

    .yellow {
      background-color: #feda56;
    }

    .reconnect {
      position: absolute;
      top: 140px;
      color: white;
      z-index: 1;
    }

    mat-card-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    mat-card-actions {
      display: flex;
      justify-content: center;
    }

    mat-card.success {
      background-color: lightgreen;
      font-weight: bold;
      font-size: 1.1rem;
    }

    .warn {
      background-color: tomato;
      color: white;
    }

    h2 {
      color: #424242;
      margin: 0;
      margin-top: 15px;
    }

    div.match-finalization {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 15px 0;
    }

    .flex-container {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
    }

    .flex-container > * {
      margin: 0 15px;
    }

  `]
})
export class MatchFinalizationComponent implements OnInit, OnDestroy {

  match: Match;

  teamSides: TeamSides;

  matchDate: Date;

  matchState: MatchState;

  pinUnknown = false;

  numOfSpectators$: Observable<number>;

  refereePinHash: string;

  team1PinHash: string;

  team2PinHash: string;

  arbitrationPin$: Observable<string>;

  comments$: Observable<string>;

  leftTeam$: Observable<Team>;

  rightTeam$: Observable<Team>;

  mvpsByTeamSides$: Observable<fromIndoor.MvpsByTeamSides>;

  matchSets$: Observable<MatchSets>;

  setPoints$: Observable<SetPoints>;

  liberoRegistrationEnabled$: Observable<boolean>;

  liberosByTeamSides: LiberosByTeamSides;

  lineupsByTeamSides: LineupsByTeamSides;

  refereePinFailure = false;

  private finalizeClicked = new Subject<string>();

  finalizeButtonDisabled = false;

  private subscription = new Subscription();

  constructor(
    private store: Store<fromIndoor.IndoorRoot>,
    private router: Router,
    public translate: TranslateService,
    private dispatchService: DispatchService) {

  }

  openScoresheetView() {
    new ScoresheetPresenter(this.matchState).openScoresheetView();
  }

  ngOnInit() {

    this.finalizeClicked
      .asObservable()
      .pipe(throttleTime(3000))
      .subscribe((newHash: string) => {
        this.finalizeButtonDisabled = true;
        setTimeout(() => {
          this.finalizeButtonDisabled = false
        }, 3000)
        this.onRefereePinSuccess(newHash)
      });

    this.numOfSpectators$ = this.store.select(fromIndoor.getNumOfSpectators);

    this.subscription.add(
      this.store.select(fromIndoor.getMatchState).subscribe(matchState => this.matchState = matchState)
    );

    this.subscription.add(
      this.store.select(fromIndoor.getMatch).subscribe(match => {
        this.match = match
        this.matchDate = new Date(match.date)
      })
    );

    this.arbitrationPin$ = this.store.select(fromIndoor.getArbitrationPin);

    this.comments$ = this.store.select(fromIndoor.getComments);

    this.mvpsByTeamSides$ = this.store.select(fromIndoor.getMvpsByTeamSides);

    this.leftTeam$ = this.store.select(fromIndoor.getLeftTeam);

    this.rightTeam$ = this.store.select(fromIndoor.getRightTeam);

    this.matchSets$ = this.store.select(fromIndoor.getMatchSets);
    
    this.setPoints$ = this.store.select(fromIndoor.getSetPointsByTeamCodes);

    this.liberoRegistrationEnabled$ = this.store.select(fromIndoor.isLiberoRegistrationEnabled);

    this.subscription.add(
      this.store.select(fromIndoor.getLiberosByTeamSides).subscribe(liberosByTeamSides => this.liberosByTeamSides = liberosByTeamSides)
    );

    this.subscription.add(
      this.store.select(fromIndoor.getLineupsByTeamSides).subscribe(lineupsByTeamSides => this.lineupsByTeamSides = lineupsByTeamSides)
    );

    this.subscription.add(
      this.store.select(fromIndoor.getTeamSides)
        .subscribe(teamSides => this.teamSides = teamSides)
    );

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  navigateBack() {
    this.router.navigate(['/match-view']);
  }

  navigateHome() {
    this.router.navigate(['/matches-overview']);
  }

  onPinUnknown() {
    this.pinUnknown = true;
    this.team1PinHash = undefined;
    this.team2PinHash = undefined;
  }

  onRevertPinUnknown() {
    this.pinUnknown = false;
  }

  onTeam1PinSuccess(newHash: string) {
    this.team1PinHash = newHash;
  }

  onTeam2PinSuccess(newHash: string) {
    this.team2PinHash = newHash;
  }

  onRefereePinFailure = () => {
    this.refereePinFailure = true;
  }

  finalize(newHash: string) {
    this.finalizeClicked.next(newHash);
  }

  onTeamPinsSuccess = async() => {
    const msg = await this.translate.get('component.match-finalization.finalized_by_teams').toPromise()
    const comments = await this.comments$.pipe(take(1)).toPromise()
    const newComments = comments ? comments + '\n' + msg : msg
    this.dispatchService.dispatchRemoteAction(new SaveCommentsAction(newComments, this.matchId));
    this.dispatchService.dispatchRemoteAction(new ConfirmFinalizationAction({
      refereePin: this.refereePinHash,
      team1Pin: this.team2PinHash,
      team2Pin: this.team1PinHash
    }, this.matchId));
  }

  onRefereePinSuccess = (newHash: string) => {
    this.refereePinHash = newHash ? newHash : this.refereePinHash
    this.dispatchService.dispatchRemoteAction(new ConfirmFinalizationAction({
      refereePin: this.refereePinHash,
      team1Pin: this.team2PinHash,
      team2Pin: this.team1PinHash
    }, this.matchId));
  }

  onLiberoPlayed(liberoId: string, teamCode: TeamCode) {
    this.dispatchService.dispatchRemoteAction(new LiberoPlayedAction({ liberoId, teamCode }, this.matchId));
  }

  hasLiberoPlayed = (p: Player, teamSide: TeamSide) => {
    return this.liberosByTeamSides[teamSide].liberosPlayed.find(l => l.uuid === p.uuid)
  }

  get refereePinEnabled() {
    return typeof this.team1PinHash !== 'undefined'
      && typeof this.team2PinHash !== 'undefined'
      && typeof this.refereePinHash === 'undefined';
  }

  get arbitration() {
    return this.match.arbitration;
  }

  get firstReferee() {
    return this.arbitration.firstReferee;
  }

  get team1() {
    return this.match.team1;
  }

  get team2() {
    return this.match.team2;
  }

  get matchId() {
    return this.match.uuid;
  }

  get matchFinalized() {
    return isMatchFinalized(this.match.finalization)
  }

  get statusCode() {
    return getStatusCode(this.match.finalization)
  }

  get isMatchSuccessfullyFinalized() {
    return isMatchSuccessfullyFinalized(this.match.finalization)
  }

  get isRefereePinRequired() {
    return this.match.scoreConfiguration.refereePinOrigin !== RefereePinOrigins.NONE
  }

  get isMvpSubmissionEnabled() {
    return this.match.matchConfiguration.mvpSubmissionEnabled;
  }

  get teamPinFinalizationEnabled() {
    return this.match.scoreConfiguration.teamPinFinalizationEnabled;
  }

  formatDate = (): string => {
    const dateOpts = { day: '2-digit', weekday: 'short', month: '2-digit' }
    return this.matchDate.toLocaleString('de', dateOpts as any).replace(',', '')
  }

  formatTime = (): string => {
    const timeOpts = { hour: '2-digit', minute:'2-digit' }
    return this.matchDate.toLocaleString('de', timeOpts as any)
  }

  get isTeamArbitration() {
    return this.match.scoreConfiguration.refereePinOrigin === RefereePinOrigins.TEAM_ARBITRATION
  }

  get numberOfSpectatorsSubmissionMandatory() {
    return this.match.scoreConfiguration.numberOfSpectatorsSubmissionMandatory;
  }

  get TeamSides() {
    return TeamSideValues
  }
  
  getFullName(p: Player) {
    return p ? p.firstName + ' ' + p.lastName : null
  }

}
