import { Component, Input } from '@angular/core';

@Component({
  selector: 'sams-jersey-number-column',
  template: `
      <span style="font-weight: bold;">{{jerseyNumber}}</span>
      <span style="margin-left: 3px;">{{substitutionInfo}}</span>
  `,
  styles: [`
    :host {
      display: flex;
      justify-content: center;
      flex-grow: 0;
      width: 30%;
    }
    @media all and (max-width: 1280px) {
      :host {
        width: 35%;
      }

    }
  `],
  host: {'class': 'flex-center-vertical'}
})
export class JerseyNumberColumnComponent {

  @Input() jerseyNumber: string;

  @Input() substitutionInfo: string;

}
