import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { TeamSquad } from '../../../../shared/reducers/match-preparation/team-squad.reducer';
import {
  ToggleOfficialAction,
  UndoTeamsquadConfirmationAction,
  TogglePlayerAction,
  ToggleLiberoAction,
  ToggleCaptainAction,
  SelectAllAction,
  ResetAction,
  ConfirmTeamSquadAction
} from '../../../../shared/reducers/match-preparation/team-squad.action';

import { Match, TeamCode, ScoreConfiguration } from 'app/models';
import {
  SetShirtColorAction,
  SetShortsColorAction,
  ChangeJerseyAction
} from '../../../../shared/reducers/match-states/match-modification.action';
import { SelectedJersey } from '../../../../shared/interfaces/models/jerseys';
import { DispatchService } from 'app/connections/dispatch.service';

@Component({
  selector: 'sams-team-preparation',
  template: `
    <sams-team-squad
      [team]="team"
      [match]="match"
      [teamCode]="teamCode"
      [scoreConfiguration]="scoreConfiguration"
      [matchId]="matchId"
      [teamSquad]="teamSquad"
      [isGameStateTouched]="isGameStateTouched"
      (togglePlayer)="togglePlayer($event)"
      (toggleOfficial)="toggleOfficial($event)"
      (toggleCaptain)="toggleCaptain($event)"
      (toggleLibero)="toggleLibero($event)"
      (checkAll)="checkAll($event)"
      (reset)="reset()"
      (undoConfirm)="undoConfirm()"
      (confirm)="confirm($event)"
      (setShirtColor)="setShirtColor($event)"
      (setShortsColor)="setShortsColor($event)"
      (changeJersey)="changeJersey($event)">
    </sams-team-squad>
  `,
  styles: [`
  `]
})
export class TeamPreparationComponent implements OnInit, OnDestroy {

  @Input() teamCode: TeamCode;

  @Input() isGameStateTouched: boolean;

  @Input() teamSquad: TeamSquad;

  @Input() match: Match;

  @Input() scoreConfiguration: ScoreConfiguration;

  private subscription = new Subscription();

  constructor(public dispatchService: DispatchService) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  togglePlayer(playerId: string) {
    this.dispatchService.dispatchRemoteAction(new TogglePlayerAction({ teamCode: this.teamCode, teamMemberId: playerId }, this.matchId));
  }

  toggleOfficial(officialId: string) {
    this.dispatchService.dispatchRemoteAction(new ToggleOfficialAction({ teamCode: this.teamCode, teamMemberId: officialId }, this.matchId));
  }

  toggleCaptain(playerId: string) {
    this.dispatchService.dispatchRemoteAction(new ToggleCaptainAction({ teamCode: this.teamCode, teamMemberId: playerId }, this.matchId));
  }

  toggleLibero(playerId: string) {
    this.dispatchService.dispatchRemoteAction(new ToggleLiberoAction({ teamCode: this.teamCode, teamMemberId: playerId }, this.matchId));
  }

  checkAll(playerIds: string[]) {
    this.dispatchService.dispatchRemoteAction(new SelectAllAction({ teamCode: this.teamCode, teamMemberIds: playerIds }, this.matchId));
  }

  reset() {
    this.dispatchService.dispatchRemoteAction(new ResetAction({ teamCode: this.teamCode }, this.matchId));
  }

  undoConfirm() {
    this.dispatchService.dispatchRemoteAction(new UndoTeamsquadConfirmationAction({ teamCode: this.teamCode }, this.matchId));
  }

  confirm(pin: string) {
    this.dispatchService.dispatchRemoteAction(new ConfirmTeamSquadAction({ pin, teamCode: this.teamCode }, this.matchId));
  }

  setShirtColor(color: string) {
    this.dispatchService.dispatchRemoteAction(new SetShirtColorAction({ color, teamCode: this.teamCode }, this.matchId));
  }

  setShortsColor(color: string) {
    this.dispatchService.dispatchRemoteAction(new SetShortsColorAction({ color, teamCode: this.teamCode }, this.matchId));
  }

  changeJersey(jerseyType: SelectedJersey) {
    this.dispatchService.dispatchRemoteAction(new ChangeJerseyAction({ jerseyType, teamCode: this.teamCode }, this.matchId));
  }

  get matchId() {
    return this.match.uuid;
  }

  get team() {
    return this.match[this.teamCode];
  }

}
