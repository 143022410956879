import { PlayerListComponent } from "./player-list.component";
import { PlayerListHeaderComponent } from "./player-list-header.component";
import { JerseyNumberColumnComponent } from "./jersey-number-column.component";
import { PlayerNameColumnComponent } from "./player-name-column.component";
import { LiberosComponent } from "./liberos.component";
import { InjuryComponent } from "./injury.component";
import { InjuryDisplayComponent } from "./injury-display.component";
import { SelectMvpComponent } from "./select-mvp.component";
import { MvpDisplayComponent } from "./mvp-display.component";

export const PLAYER_LIST_COMPONENTS = [
  PlayerListComponent,
  PlayerListHeaderComponent,
  JerseyNumberColumnComponent,
  PlayerNameColumnComponent,
  LiberosComponent,
  SelectMvpComponent,
  MvpDisplayComponent,
  InjuryComponent,
  InjuryDisplayComponent
];
