import { ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { Root } from '../../../../shared/reducers/shared.reducer';
export * from '../../../../shared/reducers/shared.reducer';

const localStorageConfig = {
  keys: [{ matchesOverview: ['matchIds', 'matchId'] }, 'matchStates', 'connections'],
  rehydrate: true
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync(localStorageConfig)(reducer);
}

export const metaReducers: MetaReducer<Root>[] = [localStorageSyncReducer]
