import { TeamCodes } from "../../../../../interfaces/models/team-codes.js";
import { SetPoints } from "../../../../../reducers/game-state/match-sets/match-sets.reducer.js";
import {
  PENALTY_SCORE,
  SCORE,
  SCORE_OVERRIDE,
} from "../../../../../reducers/game-state/match-sets/set-score/set-score.action.js";
import { SET_SIDES, SWITCH_SIDES } from "../../../../../reducers/game-state/match-sets/team-sides/team-sides.action.js";
import {
  CANCEL_SET_PAUSE,
  CANCEL_TECHNICAL_TIMEOUT,
  CANCEL_TIMEOUT,
  END_SET,
  EndSetAction,
  SET_START_TIME,
  START_MATCH,
  START_SET,
  START_TECHNICAL_TIMEOUT,
  START_TIMEOUT,
  SetStartTimeAction
} from "../../../../../reducers/game-state/match-sets/time-keeping/time-keeping.action.js";
import {
  timeKeepingReducer
} from "../../../../../reducers/game-state/match-sets/time-keeping/time-keeping.reducer.js";
import {
  BeachMatchSet,
  beachMatchSetReducer,
  isBeachSetFinished,
  isSetStarted
} from "./match-set/beach-match-set.reducer.js";
import { BeachMatchSetsAction } from "./beach-match-sets.action.js";
import {
  BeachRulesAction,
  SET_BEACH_RULES
} from "./rules/beach-rules.action.js";
import {
  BeachRules,
  beachRulesReducer
} from "./rules/beach-rules.reducer.js";
import { SetMatchSetRulesAction, SetMatchSetRulesPayload } from "./match-set/beach-match-set-rules.action.js";
import { SET_BEACH_LINEUP } from "./match-set/lineups/beach-lineups.action.js";
import { CHANGE_SERVING, SET_SERVING } from "../../../../../reducers/game-state/match-sets/serving/serving.action.js";
import { BeachMatchSetAction, COIN_TOSS } from "./match-set/beach-match-set.action.js";
import {
  INDIVIDUAL_DISQUALIFICATION,
  INDIVIDUAL_EXPULSION
} from "../../../../../reducers/game-state/sanctions/individual-sanction/individual-sanction.action.js";
import { MatchAction } from "../../../../../interfaces/interfaces.js";

export interface BeachMatchSets {
  sets: BeachMatchSet[]
  rules: BeachRules
}

const initialState: BeachMatchSets = {
  sets: [beachMatchSetReducer(undefined, {} as BeachMatchSetAction)],
  rules: beachRulesReducer(undefined, {} as BeachRulesAction)
}

const currentBeachMatchSetReducer = (state: BeachMatchSets, action: BeachMatchSetsAction): BeachMatchSets => {
  const sets = state.sets
  const currentMatchSet = sets[sets.length - 1];
  const currentSetIndex = sets.length - 1;

  return {
    ...state,
    sets: [
      ...sets.slice(0, currentSetIndex),
      beachMatchSetReducer(currentMatchSet, action),
      ...sets.slice(currentSetIndex + 1)
    ]
  };
}

export const beachMatchSetsReducer = (
  state: BeachMatchSets = initialState,
  action: BeachMatchSetsAction): BeachMatchSets => {

  switch (action.type) {

    case SET_BEACH_RULES: {
      // TODO: SCORE-BEACH should this be regularSet in every case?
      const { regularSet } = action.payload
      const payload: SetMatchSetRulesPayload = {
        technicalTimeout: regularSet.technicalTimeout,
        setPauseDurationSeconds: regularSet.setPauseDurationSeconds,
        setSideChangeAtPoints: regularSet.setSideChangeAtPoints,
        minimumPointDifference: regularSet.minimumPointDifference,
        pointsToWinSet: regularSet.pointsToWinSet
      }
      const setMatchSetRulesAction = new SetMatchSetRulesAction(payload, action.matchId)
      const newState = currentBeachMatchSetReducer(state, setMatchSetRulesAction)
      return {
        ...newState,
        rules: beachRulesReducer(state.rules, action)
      }
    }

    case SET_BEACH_LINEUP:
    case CHANGE_SERVING:
    case SET_SERVING:
    case SET_SIDES:
    case SWITCH_SIDES:
    case START_MATCH:
    case START_SET:
    case SET_START_TIME:
    case END_SET:
    case CANCEL_SET_PAUSE:
    case START_TIMEOUT:
    case CANCEL_TIMEOUT:
    case START_TECHNICAL_TIMEOUT:
    case CANCEL_TECHNICAL_TIMEOUT:
    case COIN_TOSS: {
      return currentBeachMatchSetReducer(state, action);
    }

    case SCORE_OVERRIDE:
    case INDIVIDUAL_EXPULSION:
    case SCORE:
    case PENALTY_SCORE: {
      const nextState = currentBeachMatchSetReducer(state, action)
      return addSetReducer(nextState, action);
    }

    case INDIVIDUAL_DISQUALIFICATION: {
      const penalizedState = currentBeachMatchSetReducer(state, action)
      const nextState = addSetReducer(penalizedState, action);
      return nextState
    }

    default:
      return state
  }

};

// TODO: SCORE-BEACH refactor to isBeachSetFinished(set: BeachMatchSet)
export const isBeachMatchFinished = (state: BeachMatchSets) => {
  const setPoints = getSetPoints(state)
  const setNumber = getCurrentSetNumber(state)
  const currentMatchSet = getCurrentMatchSet(state)
  const setScore = currentMatchSet.setScore;
  const setsToWin = state.rules.match.setsToWin
  const matchDecidedBySetsToWin = setPoints.team1 >= setsToWin || setPoints.team2 >= setsToWin
  const {
    minimumPointDifference,
    pointsToWinSet
  } = currentMatchSet.rules
  const setIsFinished = isBeachSetFinished(setScore, minimumPointDifference, pointsToWinSet);
  return matchDecidedBySetsToWin || setNumber === state.rules.match.maximumSets && setIsFinished
}

// TODO: SCORE-BEACH refactor to isBeachSetFinished(set: BeachMatchSet)
export const calculateSetPoints = (state: BeachMatchSets): SetPoints => {

  const initial = { team1: 0, team2: 0 }

  if (!state) {
    return initial
  }

  return state.sets.reduce((setPoints: SetPoints, { setScore, rules }) => {

    const setIsFinished = isBeachSetFinished(setScore, rules.minimumPointDifference, rules.pointsToWinSet);
    if (setIsFinished) {
      if (setScore.team1 > setScore.team2) {
        setPoints.team1++;
      }
      if (setScore.team1 < setScore.team2) {
        setPoints.team2++;
      }
    }

    return setPoints;
  }, initial);

};

// TODO: SCORE-BEACH check me!!!
const createNewSet = (
  state: BeachMatchSets,
  action: MatchAction): BeachMatchSet => {

  // previousSet will be the current one, since the new one is not created yet
  const previousSet = getCurrentMatchSet(state)
  const setPauseDurationMs = state.rules.regularSet.setPauseDurationSeconds * 1000

  const { regularSet } = state.rules
  const setMatchSetRulesAction = new SetMatchSetRulesAction(regularSet, action.matchId)
  const matchSet = beachMatchSetReducer(undefined, setMatchSetRulesAction)
  // TODO: SCORE-BEACH only setMatchSetRules?

  return  {
    ...matchSet,
    setNumber: state.sets.length + 1,
    timeKeeping: {
      ...timeKeepingReducer(undefined, new SetStartTimeAction(action.timestamp + setPauseDurationMs, action.matchId)),
      // TODO: SCORE-BEACH SetTimeKeepingReducerAction
      setPauseDurationMs
    },
    lineups: {
      ...matchSet.lineups,
      team1: {
        ...matchSet.lineups.team1,
        starting: previousSet.lineups.team1.starting,
        current: previousSet.lineups.team1.starting
      },
      team2: {
        ...matchSet.lineups.team2,
        starting: previousSet.lineups.team2.starting,
        current: previousSet.lineups.team2.starting
      }
    },
    coinTossWinner: previousSet.coinTossWinner === TeamCodes.team1 ? TeamCodes.team2 : TeamCodes.team1
  };
};

// TODO: SCORE-BEACH check me!!!
// TODO: generalize with createNewSet!
const createDecidingSet = (state: BeachMatchSets, action: MatchAction): BeachMatchSet => {

  // TODO: SCORE-BEACH createNewSet uses BeachSetRules
  const { decidingSet } = state.rules
  const setPauseDurationMs = decidingSet.setPauseDurationSeconds * 1000
  const setMatchSetRulesAction = new SetMatchSetRulesAction(decidingSet, action.matchId)

  const previousSet = getCurrentMatchSet(state)
  const matchSet = [setMatchSetRulesAction].reduce(beachMatchSetReducer, undefined)

  return {
    ...matchSet,
    setNumber: state.sets.length + 1,
    isDecidingSet: true,
    timeKeeping: {
      ...timeKeepingReducer(undefined, new SetStartTimeAction(action.timestamp + setPauseDurationMs, action.matchId)),
      setPauseDurationMs
    },
    lineups: {
      ...matchSet.lineups,
      team1: {
        ...matchSet.lineups.team1,
        starting: previousSet.lineups.team1.starting,
        current: previousSet.lineups.team1.starting
      },
      team2: {
        ...matchSet.lineups.team2,
        starting: previousSet.lineups.team2.starting,
        current: previousSet.lineups.team2.starting
      }
    }
  };
}

const endCurrentSet = (state: BeachMatchSet, action: MatchAction): BeachMatchSet => {
  return {
    ...state,
    timeKeeping: timeKeepingReducer(state.timeKeeping, new EndSetAction(action.timestamp, action.matchId))
  };
};

const endMatch = (state: BeachMatchSets, action: BeachMatchSetsAction): BeachMatchSets => {
  const currentSetIndex = getCurrentSetIndex(state);
  const currentMatchSet = getCurrentMatchSet(state);
  return {
    ...state,
    sets: [
      ...state.sets.slice(0, currentSetIndex),
      endCurrentSet(currentMatchSet, action),
      ...state.sets.slice(currentSetIndex + 1)
    ]
  }
}

const addSet = (state: BeachMatchSets, action: BeachMatchSetsAction): BeachMatchSets => {

  const currentSetNumber = getCurrentSetNumber(state);
  const currentSetIndex = getCurrentSetIndex(state);
  const currentMatchSet = getCurrentMatchSet(state);
  const setNumber = currentSetNumber + 1
  const isDecidingSet = setNumber === state.rules.match.maximumSets

  return {
    ...state,
    sets: [
      ...state.sets.slice(0, currentSetIndex),
      endCurrentSet(currentMatchSet, action),
      ...state.sets.slice(currentSetIndex + 1),
      isDecidingSet ? createDecidingSet(state, action) : createNewSet(state, action)
    ]
  }
}

function addSetReducer(state: BeachMatchSets,
  action: BeachMatchSetsAction): BeachMatchSets {

  switch (action.type) {

    case INDIVIDUAL_DISQUALIFICATION: {
      const isMatchDecided = isBeachMatchFinished(state);
      const isMatchUndecided = !isMatchDecided;

      if (isMatchUndecided) {
        const nextState = addSet(state, action)
        const penalizedState = currentBeachMatchSetReducer(nextState, action)
        return addSetReducer(penalizedState, action)
      }

      if (isMatchDecided) {
        return endMatch(state, action)
      }
      return state
    }

    case INDIVIDUAL_EXPULSION:
    case SCORE_OVERRIDE:
    case PENALTY_SCORE:
    case SCORE: {

      const currentMatchSet = getCurrentMatchSet(state);
      const setScore = currentMatchSet.setScore
      const { minimumPointDifference, pointsToWinSet } = currentMatchSet.rules

      const setIsFinished = isBeachSetFinished(setScore, minimumPointDifference, pointsToWinSet);
      const isMatchDecided = isBeachMatchFinished(state);
      const isMatchUndecided = !isMatchDecided;
      const shouldAddSet = isMatchUndecided && setIsFinished

      if (shouldAddSet) {
        return addSet(state, action)
      }

      if (isMatchDecided) {
        return endMatch(state, action)
      }

      return state;
    }
    default: {
      return state;
    }
  }

}

export const getCurrentSetNumber = (state: BeachMatchSets) => state.sets.length;
export const getCurrentSetIndex = (state: BeachMatchSets) => state.sets.length - 1;
export const getCurrentMatchSet = (state: BeachMatchSets) => state.sets[getCurrentSetIndex(state)];
export const getPreviousMatchSet = (state: BeachMatchSets) => state.sets[getCurrentSetIndex(state) - 1];
export const getMatchSetForSetNumber = (state: BeachMatchSets, setNumber: number) => state.sets[setNumber - 1];
export const getSetPoints = calculateSetPoints;
export const isMatchStarted = (state: BeachMatchSets) => isSetStarted(state.sets[0]);

export const isDecidingSet = (state: BeachMatchSets) =>
  getCurrentSetNumber(state) === state.rules.match.maximumSets

export const getWinningTeamCode = (state: BeachMatchSets) => {
  const points = getSetPoints(state);

  if (!isBeachMatchFinished(state)) {
    return undefined;
  }
  if (points.team1 > points.team2)
    return TeamCodes.team1;
  if (points.team2 > points.team1)
    return TeamCodes.team2;

  return undefined;
}

export const getHasCurrentSetStarted = (state: BeachMatchSets) =>
  !isBeachMatchFinished(state) && isSetStarted(getCurrentMatchSet(state));
