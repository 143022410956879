import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { UpdateDialogComponent } from './update-dialog.component';
import { first } from 'rxjs/operators';

@Injectable()
export class UpdateService {

  constructor(updates: SwUpdate, public dialog: MatDialog) {
    updates.available.subscribe(event => {
      const dialogRef: MatDialogRef<UpdateDialogComponent, boolean> = this.dialog.open(UpdateDialogComponent);
      dialogRef.afterClosed().pipe(first()).subscribe(() => {
        updates.activateUpdate().then(() => document.location.reload());
      });
    });
  }
}
