import { MatchAction } from '../../../../interfaces/interfaces.js';
import { TeamSides } from './team-sides.reducer.js';

export const SWITCH_SIDES = '[TeamSides] Switch Sides';
export const SET_SIDES = '[TeamSides] Set Sides';

export class SwitchSidesAction extends MatchAction {
  readonly type = SWITCH_SIDES;
  constructor(matchId: string) {
    super(matchId);
  }
}

export interface SetSidesActionPayload {
  teamSides: TeamSides
}

export class SetSidesAction extends MatchAction {
  readonly type = SET_SIDES;
  constructor(public payload: SetSidesActionPayload, matchId: string) {
    super(matchId);
  }
}

export type TeamSidesAction
  = SwitchSidesAction
  | SetSidesAction;
