import { MatchState, matchStateReducer } from './match-state.reducer.js';
import { ByMatchId, byMatchIdReducer } from '../helpers/by-match-id.reducer.js';

export type MatchStates = ByMatchId<MatchState>;

export const matchStatesReducer = byMatchIdReducer(matchStateReducer);

// ==== Selectors ====
export const getMatchState = (state: MatchStates, id: string) => state[id];
export const getMatchesList = (state: MatchStates) => Object.keys(state).map(key => state[key].match)
export const getMatchById = (state: MatchStates, id: string) => state[id]?.match
