import { Component } from '@angular/core';
import { Input } from '@angular/core';

import { Team, Match } from 'app/models';
import { LicensedPlayer } from '../../../../../shared/interfaces/models/player';
import { AgeClassType } from '../../../../../shared/interfaces/models/license';

@Component({
  selector: 'sams-license-backside',
  template: `
    <div class="license-card-back-side-grid">
      <div class="user-data">
        <sams-license-photo
          [license]="player.license"
          [matchId]="match.uuid"
          [size]="'100px'">
        </sams-license-photo>
        <p class="player_info" *ngIf="player?.isNewPlayer">{{ 'component.license-backside.manually_created_player' | translate }}</p>
        <p class="player_info" *ngIf="player?.isAdditionalClubPlayer">{{ 'component.license-backside.additional_club_player' | translate }}</p>
      </div>
      <div *ngIf="player?.isNewPlayer" class="license-data">
        <h3>{{'component.license.license_data' | translate}}</h3>
        <div class="data-label">{{'component.license.license_number' | translate: { licenseNumber: license?.licenseNumber || '-' } }}</div>
      </div>
      <div *ngIf="!player?.isNewPlayer" class="license-data">
        <h3>{{'component.license.license_data' | translate}}</h3>
        <div class="data-label">{{license?.associationName || '-'}}</div>
        <div class="data-label">{{'component.license.license_number' | translate: { licenseNumber: license?.licenseNumber || '-' } }}</div>
        <div class="data-label">{{('component.license.club' | translate) + ' ' + ('component.license.club_number' | translate: { clubNumber: license?.clubNumber || '-' })}}</div>
        <div class="data-label">{{license?.clubName || '-'}}</div>
        <div class="data-label">{{'component.license.valid_from' | translate: { validFrom: license?.validFrom ? (license?.validFrom | date: 'dd.MM.yyyy') : '-' } }}</div>
        <div class="data-label">{{'component.license.valid_until' | translate: { validUntil: license?.validUntil ? (license?.validUntil | date: 'dd.MM.yyyy') : '-' } }}</div>
        <div class="data-label">{{'component.license.loading_timestamp' | translate: { loadingTimestamp: match?.loadingTimestamp ? (match?.loadingTimestamp | date: 'dd.MM.yyyy') : '-' } }}</div>
        <div class="data-label">{{'component.license.itc_originating_association' | translate: { originatingAssociation: license?.itc?.originatingAssociation || '-' } }}</div>
        <div class="data-label">{{'component.license.itc_number' | translate: { number: license?.itc?.number || '-' } }}</div>
        <div class="data-label">{{'component.license.itc_valid_from' | translate: { validFrom: license?.itc?.validFrom ? (license?.itc?.validFrom | date: 'dd.MM.yyyy') : '-' } }}</div>
        <div class="data-label">{{'component.license.itc_valid_until' | translate: { validUntil: license?.itc?.validFrom ? (license?.itc?.validUntil | date: 'dd.MM.yyyy') : '-' } }}</div>
      </div>
      <div *ngIf="!player?.isNewPlayer" class="eligibility-data">
        <h3>{{'component.license.eligibility_to_play' | translate}}</h3>
        <div class="data-label">{{'component.license.season_name' | translate: { seasonName: license?.seasonName || '-' } }}</div>
        <div class="data-label">{{license?.matchSeriesName || '-'}}</div>
        <div class="data-label">{{license?.teamName || '-'}}</div>
        <div class="data-label">{{'component.license.eligible_to_play_from' | translate: { eligibleToPlayFrom: license?.eligibleToPlayFrom ? (license?.eligibleToPlayFrom | date: 'dd.MM.yyyy') : '-' } }}</div>
        <div class="data-label">{{'component.license.eligible_to_play_until' | translate: { eligibleToPlayUntil: license?.eligibleToPlayUntil ? (license?.eligibleToPlayUntil | date: 'dd.MM.yyyy') : '-' } }}</div>
        <div class="data-label">
          {{'component.license.age_class' | translate: { ageClass: license?.ageClass?.classType !== AgeClassType.NONE && license?.ageClass?.classType != null ? ('app.age_class_' + license?.ageClass?.classType | translate : { ageClass: license?.ageClass?.currentClass }) : '-' } }}
        </div>
        <div class="data-label">{{'component.license.release_date' | translate: { releaseDate: license?.releaseDate ? (license?.releaseDate | date: 'dd.MM.yyyy') : '-' } }}</div>
        <div class="data-label">{{'component.license.last_match_assignment' | translate: { lastMatchAssignment: license?.lastMatchAssignment ? (license?.lastMatchAssignment | date: 'dd.MM.yyyy') : '-' } }}</div>
      </div>
      <div *ngIf="higherPlay1" class="higher-play">
        {{'component.license.higher_play_1' | translate: { higherPlayOn: higherPlay1?.higherPlayOn ? (higherPlay1?.higherPlayOn | date: 'dd.MM.yyyy') : '-', higherPlayIn: higherPlay1?.higherPlayIn || '-' } }}
      </div>
      <div *ngIf="higherPlay2" class="higher-play">
        {{'component.license.higher_play_2' | translate: { higherPlayOn: higherPlay2?.higherPlayOn ? (higherPlay2?.higherPlayOn | date: 'dd.MM.yyyy') : '-', higherPlayIn: higherPlay2?.higherPlayIn || '-' } }}
      </div>
      <div *ngIf="higherPlay3" class="higher-play">
        {{'component.license.higher_play_3' | translate: { higherPlayOn: higherPlay3?.higherPlayOn ? (higherPlay3?.higherPlayOn | date: 'dd.MM.yyyy') : '-', higherPlayIn: higherPlay3?.higherPlayIn || '-' } }}
      </div>
      <div *ngIf="higherPlay4" class="higher-play">
        {{'component.license.higher_play_4' | translate: { higherPlayOn: higherPlay4?.higherPlayOn ? (higherPlay4?.higherPlayOn | date: 'dd.MM.yyyy') : '-', higherPlayIn: higherPlay4?.higherPlayIn || '-' } }}
      </div>
      <div *ngIf="higherPlay5" class="higher-play">
        {{'component.license.higher_play_5' | translate: { higherPlayOn: higherPlay5?.higherPlayOn ? (higherPlay5?.higherPlayOn | date: 'dd.MM.yyyy') : '-', higherPlayIn: higherPlay5?.higherPlayIn || '-' } }}
      </div>


    </div>
  `,
  styles: [`

    .user-data {
      margin-top: 15px; 
    }

    .user-data-label {
      text-align: center;
      font-size: 16px;
      margin: 15px 0px;
    }

    h3 {
      font-size: 16px;
      font-weight: bold;
    }

    .data-label {
      margin: 5px 0px;
    }

    .license-card-back-side-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
      gap: 10px; /* space between grid items */
    }

    .license-photo {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;
    }

    .license-photo img {
      width: 100px;
      height: auto; /*to preserve the aspect ratio of the image*/
    }

    .player_info {
      text-align: center;
    }

  `]

})
export class LicenseBacksideComponent {

  @Input() player: LicensedPlayer;

  @Input() team: Team;

  @Input() match: Match;

  constructor() {
  }

  get AgeClassType() {
    return AgeClassType
  }

  get license() {
    return this.player.license
  }

  get higherPlay1() {
    return this.getHigherPlay(0)
  }

  get higherPlay2() {
    return this.getHigherPlay(1)
  }

  get higherPlay3() {
    return this.getHigherPlay(2)
  }

  get higherPlay4() {
    return this.getHigherPlay(3)
  }

  get higherPlay5() {
    return this.getHigherPlay(4)
  }

  getHigherPlay(i: number) {
    return this.license?.higherPlay?.[i]
  }

}
