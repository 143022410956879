import {
  TEAM_WARNING,
  TEAM_PENALTY,
  TEAM_IMPROPER_REQUEST,
  TeamPenaltyAction,
  TeamSanctionAction,
} from './team-sanction.action.js';
import { Sanction } from '../sanctions.reducer.js';
import { SetScore } from '../../match-sets/set-score/set-score.reducer.js';

export type TeamPenalties = TeamPenalty[];
export type TeamPenalty = Sanction;
export type TeamWarning = Sanction;
export type TeamImproperRequest = Sanction;

export interface TeamSanctions {
  warning: TeamWarning;
  penalties: TeamPenalties;
  improperRequest: TeamImproperRequest;
}

export const initialState: TeamSanctions = {
  warning: null,
  penalties: [],
  improperRequest: null
}

export const teamPenaltiesReducer = (state: TeamPenalties = [], action: TeamPenaltyAction, setScore: SetScore, currentSetNumber: number): TeamPenalties => {
  return [
    ...state,
    { setScore, currentSetNumber, timestamp: action.timestamp }
  ];
};

export const teamSanctionsReducer = (state: TeamSanctions = initialState, action: TeamSanctionAction, setScore: SetScore, currentSetNumber: number): TeamSanctions => {
  switch (action.type) {
    case TEAM_WARNING:
      return {
        ...state,
        warning: { setScore, currentSetNumber, timestamp: action.timestamp }
      };
    case TEAM_IMPROPER_REQUEST:
      return {
        ...state,
        improperRequest: { setScore, currentSetNumber, timestamp: action.timestamp }
      };
    case TEAM_PENALTY:
      return {
        ...state,
        penalties: teamPenaltiesReducer(state.penalties, action, setScore, currentSetNumber)
      };
    default:
      return state;
  }
};

// # Selectors
export const getTeamImproperRequest = (state: TeamSanctions) => state.improperRequest;
export const getTeamPenalties = (state: TeamSanctions) => state.penalties;
export const getTeamWarning = (state: TeamSanctions) => state.warning;

export const isTeamWarned = (state: TeamSanctions) => getTeamWarning(state) !== null;
export const getNumOfTeamPenalties = (state: TeamSanctions) => getTeamPenalties(state).length;
export const isTeamPenalized = (state: TeamSanctions) => getNumOfTeamPenalties(state) > 0;
export const hasTeamImproperRequest = (state: TeamSanctions) => getTeamImproperRequest(state) !== null;
export const hasTeamNoBooking = (state: TeamSanctions) => !isTeamPenalized(state) && !isTeamWarned(state); 
export const isTeamWarnedButNotPenalized = (state: TeamSanctions) => isTeamWarned(state) && !isTeamPenalized(state);

// # Preconditions
type Pre = (state: TeamSanctions) => boolean;
export const preTeamPenalty: Pre = isTeamWarned;
export const preTeamWarning: Pre = state => !isTeamWarned(state) && !isTeamPenalized(state);
export const preTeamImproperRequest: Pre = state => !hasTeamImproperRequest(state);
