import * as moment from 'moment';

import {
  SUBSTITUTION,
  NOMINATION,
  LIBERO_IN,
  LIBERO_OUT,
  DENOMINATION,
  EXCEPTIONAL_SUBSTITUTION,
  ROTATE_CLOCKWISE
} from '../../../../shared/reducers/game-state/match-sets/lineups/lineups.action';
import {
  START_SET,
  END_SET,
  START_TIMEOUT,
  START_MATCH,
  CANCEL_TECHNICAL_TIMEOUT,
  CANCEL_TIMEOUT,
  CANCEL_SET_PAUSE,
  CHANGE_SET_PAUSE_DURATION
} from '../../../../shared/reducers/game-state/match-sets/time-keeping/time-keeping.action';
import {
  SCORE,
  SCORE_OVERRIDE
} from '../../../../shared/reducers/game-state/match-sets/set-score/set-score.action';
import { CHANGE_SERVING } from '../../../../shared/reducers/game-state/match-sets/serving/serving.action';
import { SWITCH_SIDES } from '../../../../shared/reducers/game-state/match-sets/team-sides/team-sides.action';
import { ROMAN_NUMBERS } from '../../../../shared/reducers/game-state/match-sets/lineups/lineups.reducer';
import {
  TEAM_WARNING,
  TEAM_PENALTY,
  TEAM_IMPROPER_REQUEST
} from '../../../../shared/reducers/game-state/sanctions/team-sanction/team-sanction.action';
import {
  INDIVIDUAL_WARNING,
  INDIVIDUAL_PENALTY,
  INDIVIDUAL_EXPULSION,
  INDIVIDUAL_DISQUALIFICATION
} from '../../../../shared/reducers/game-state/sanctions/individual-sanction/individual-sanction.action';
import { MatchState } from '../../../../shared/reducers/match-states/match-state.reducer';
import { INJURY } from '../../../../shared/reducers/game-state/injuries/injuries.action';
import { GameStateAction, REPLACE_LIBERO } from '../../../../shared/reducers/game-state/game-state.action';
import {
  LIBERO_SWITCH,
  ROTATE_ANTICLOCKWISE
} from '../../../../shared/reducers/game-state/match-sets/lineups/lineups.action';
import {
  LIBERO_UNABLE_TO_PLAY,
  ADD_NEW_LIBERO,
  SET_DEFAULT_LIBERO,
  LIBERO_PLAYED
} from '../../../../shared/reducers/game-state/liberos/liberos.action';

import { LocalizedHistoryActionMessage, LocalizedHistoryAction } from 'app/models/localized-history-action';
import { TeamCode, Team, Player } from 'app/models';
import { SELECT_MVP } from '../../../../shared/reducers/game-state/mvps/mvp.action';
import { LOCK_STARTING_SIX } from '../../../../shared/reducers/game-state/match-sets/starting-six.action';
import { TeamCodes } from '../../../../shared/interfaces/models/team-codes';
import {
  CHALLENGE,
  CHALLENGE_DECLINED,
  CHALLENGE_SUCCESS
} from '../../../../shared/reducers/game-state/match-sets/challenges/challenges.action';
import { BeachGameStateAction } from '../../../../shared/beach/reducers/match-states/game-state/beach-game-state.action';
import { MEDICAL_TIMEOUT, RECOVERY_INTERRUPTION } from '../../../../shared/beach/reducers/match-states/game-state/interruptions/beach-interruption.action';
import { RECOVERY_INTERRUPTION_CAUSE } from '../../../../shared/beach/model/beach-interruption';
import { COIN_TOSS } from '../../../../shared/beach/reducers/match-states/game-state/match-sets/match-set/beach-match-set.action';

// TODO: SCORE BEACH Type BeachMatchState
export class LocalizedHistoryActionBuilder {

  constructor(private matchState: MatchState, private action: GameStateAction | BeachGameStateAction) {
  }

  build(): LocalizedHistoryAction {

    if (this.action == null) {

      const time = moment(Date.now()).format('HH:mm:ss');
      return new LocalizedHistoryAction(time, '134', new LocalizedHistoryActionMessage('-', null));
    }

    const time = moment(this.action.timestamp).format('HH:mm:ss');
    const uuid = this.action.uuid; // is used in the history as trackByFn
    const message = this.localize(this.action);

    return new LocalizedHistoryAction(time, uuid, message);
  }

  getTeam(matchId: string, teamCode: TeamCode) {
    return this.matchState.match[teamCode];
  }

  getPlayer(playerId: string, team: Team) {
    return team.players.find(p => p.uuid === playerId);
  }

  getTeamMember(teamMemberId: string, team: Team) {
    const player = team.players.find(p => p.uuid === teamMemberId);
    return player ? player : team.officials.find(o => o.uuid === teamMemberId);
  }

  localize = (action: GameStateAction | BeachGameStateAction): LocalizedHistoryActionMessage => {
    switch (action.type) {

      case SUBSTITUTION: {

        const { teamCode, playerInId, playerOutId } = action.payload;
        const matchId = action.matchId;

        const team = this.getTeam(matchId, teamCode);
        const playerIn = this.getPlayer(playerInId, team);
        const playerOut = this.getPlayer(playerOutId, team);

        return new LocalizedHistoryActionMessage('action.substitution', { clubCode: team.clubCode || team.shortName, playerIn, playerOut });
      }

      case EXCEPTIONAL_SUBSTITUTION: {
        const { teamCode, playerInId, playerOutId } = action.payload;
        const matchId = action.matchId;

        const team = this.getTeam(matchId, teamCode);
        const playerIn = this.getPlayer(playerInId, team);
        const playerOut = this.getPlayer(playerOutId, team);

        return new LocalizedHistoryActionMessage('action.exceptional_substitution', { clubCode: team.clubCode || team.shortName, playerIn, playerOut });
      }

      case LIBERO_IN: {
        const { teamCode, liberoId, liberoSubstitute } = action.payload;
        const matchId = action.matchId;

        const team = this.getTeam(matchId, teamCode);
        const libero = this.getPlayer(liberoId, team);
        const player = this.getPlayer(liberoSubstitute, team);

        return new LocalizedHistoryActionMessage('action.libero_in', { clubCode: team.clubCode || team.shortName, libero, player });

      }

      case LIBERO_OUT: {
        const { teamCode, liberoId, playerInId } = action.payload;
        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);

        const libero = this.getPlayer(liberoId, team);
        const player = this.getPlayer(playerInId, team);

        return new LocalizedHistoryActionMessage('action.libero_out', { clubCode: team.clubCode || team.shortName, libero, player });

      }

      case LIBERO_SWITCH: {
        const { teamCode, liberoInId, liberoOutId } = action.payload;
        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);

        const liberoIn = this.getPlayer(liberoInId, team);
        const liberoOut = this.getPlayer(liberoOutId, team);

        return new LocalizedHistoryActionMessage('action.libero_switch', { clubCode: team.clubCode || team.shortName, liberoIn, liberoOut });
      }

      case LIBERO_UNABLE_TO_PLAY: {
        const { teamCode, liberoId } = action.payload;
        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);
        const libero = this.getPlayer(liberoId, team);

        return new LocalizedHistoryActionMessage('action.libero_unable_to_play', { clubCode: team.clubCode || team.shortName, libero });

      }

      case ADD_NEW_LIBERO: {
        const { playerId, teamCode } = action.payload;
        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);
        const player = this.getPlayer(playerId, team);

        return new LocalizedHistoryActionMessage('action.add_new_libero', { clubCode: team.clubCode || team.shortName, player });
      }

      case SET_DEFAULT_LIBERO: {
        const { liberoId, teamCode } = action.payload;
        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);
        const player = this.getPlayer(liberoId, team);

        return new LocalizedHistoryActionMessage('action.set_default_libero', { clubCode: team.clubCode || team.shortName, player });
      }

      case LIBERO_PLAYED: {
        const { liberoId, teamCode } = action.payload;
        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);
        const player = this.getPlayer(liberoId, team);

        return new LocalizedHistoryActionMessage('action.libero_played', { clubCode: team.clubCode || team.shortName, player });
      }

      case REPLACE_LIBERO: {
        const { newLiberoId, teamCode } = action.payload;
        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);
        const player = this.getPlayer(newLiberoId, team);

        return new LocalizedHistoryActionMessage('action.replace_libero', { clubCode: team.clubCode || team.shortName, player });
      }

      case DENOMINATION: {

        const matchId = action.matchId;
        const team = this.getTeam(matchId, action.payload.teamCode);

        const position = ROMAN_NUMBERS[action.payload.playerIndex];

        return new LocalizedHistoryActionMessage('action.denomination', { clubCode: team.clubCode || team.shortName, position });

      }

      case NOMINATION: {

        const { teamCode, playerInId, playerOutIndex } = action.payload;

        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);
        const player = this.getPlayer(playerInId, team);

        const position = ROMAN_NUMBERS[playerOutIndex];

        return new LocalizedHistoryActionMessage('action.nomination', { clubCode: team.clubCode || team.shortName, position, player });
      }

      case MEDICAL_TIMEOUT: {
        const { teamCode, playerId } = action.payload
        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);
        const player = this.getPlayer(playerId, team);
        return new LocalizedHistoryActionMessage('action.beach.medical_timeout', { clubCode: team.clubCode || team.shortName, player });
      }

      case RECOVERY_INTERRUPTION: {
        const { teamCode, playerId, cause } = action.payload
        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);
        const player = this.getPlayer(playerId, team);

        switch (cause) {
          case RECOVERY_INTERRUPTION_CAUSE.TOILETTE: {
            return new LocalizedHistoryActionMessage('action.beach.recovery_interruption.toilette', { clubCode: team.clubCode || team.shortName, player });
          }
          case RECOVERY_INTERRUPTION_CAUSE.TRAUMA_RELATED: {
            return new LocalizedHistoryActionMessage('action.beach.recovery_interruption.trauma', { clubCode: team.clubCode || team.shortName, player });
          }

          case RECOVERY_INTERRUPTION_CAUSE.WEATHER: {
            return new LocalizedHistoryActionMessage('action.beach.recovery_interruption.weather', { clubCode: team.clubCode || team.shortName, player });
          }
          default: return
        }

      }

      case COIN_TOSS: {
        const teamCode = action.payload
        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);
        return new LocalizedHistoryActionMessage('action.beach.coin_toss', { clubCode: team.clubCode || team.shortName });
      }

      case ROTATE_CLOCKWISE: {
        const teamCode = action.payload;
        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);

        return new LocalizedHistoryActionMessage('action.rotate_clockwise', { clubCode: team.clubCode || team.shortName });
      }

      case ROTATE_ANTICLOCKWISE: {
        const teamCode = action.payload;
        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);

        return new LocalizedHistoryActionMessage('action.rotate_anticlockwise', { clubCode: team.clubCode || team.shortName });
      }

      case START_SET: {
        return new LocalizedHistoryActionMessage('action.start_set', null, true);
      }

      case START_MATCH: {
        return new LocalizedHistoryActionMessage('action.start_match', null, true);
      }

      case END_SET: {
        return new LocalizedHistoryActionMessage('action.end_set', null, true);
      }

      case START_TIMEOUT: {

        const { teamCode } = action.payload;
        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);

        return new LocalizedHistoryActionMessage('action.start_timeout', { clubCode: team.clubCode || team.shortName }, true);
      }

      case CHANGE_SET_PAUSE_DURATION: {
        return new LocalizedHistoryActionMessage('action.change_set_pause_duration', { minutes: action.payload.newDuration / 1000 / 60 });
      }

      case CANCEL_SET_PAUSE: {
        return new LocalizedHistoryActionMessage('action.cancel_set_pause', null);
      }

      case CANCEL_TIMEOUT: {
        return new LocalizedHistoryActionMessage('action.cancel_timeout', null);
      }
      case CANCEL_TECHNICAL_TIMEOUT: {
        return new LocalizedHistoryActionMessage('action.cancel_technical_timeout', null);
      }

      case SCORE: {
        const teamCode = action.payload;
        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);

        return new LocalizedHistoryActionMessage('action.score', { clubCode: team.clubCode || team.shortName });
      }

      case SCORE_OVERRIDE: {
        return new LocalizedHistoryActionMessage('action.score_override', null);
      }

      case CHANGE_SERVING: {
        return new LocalizedHistoryActionMessage('action.change_serving', null);
      }

      case SWITCH_SIDES: {
        return new LocalizedHistoryActionMessage('action.switch_sides', null);
      }

      case INJURY: {
        const { playerId, teamCode } = action.payload;
        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);
        const player = this.getPlayer(playerId, team);

        return new LocalizedHistoryActionMessage('action.injury', { clubCode: team.clubCode || team.shortName, player }, true);
      }

      case INDIVIDUAL_WARNING: {
        const { teamCode, teamMemberId } = action.payload;

        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);
        const teamMember = this.getTeamMember(teamMemberId, team);

        return new LocalizedHistoryActionMessage('action.individual_warning', { clubCode: team.clubCode || team.shortName, teamMember }, true);
      }

      case INDIVIDUAL_PENALTY: {
        const { teamCode, teamMemberId, isMatchFinished } = action.payload;

        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);
        const otherTeamCode = teamCode === TeamCodes.team1 ? TeamCodes.team2 : TeamCodes.team1
        const otherTeam = this.getTeam(matchId, otherTeamCode);
        const teamMember = this.getTeamMember(teamMemberId, team);

        return isMatchFinished
          ? new LocalizedHistoryActionMessage('action.individual_penalty', { clubCode: team.clubCode || team.shortName, teamMember }, true)
          : new LocalizedHistoryActionMessage('action.individual_penalty_with_score', { clubCode: team.clubCode || team.shortName, teamMember, otherClubCode: otherTeam.clubCode || otherTeam.shortName }, true);
      }

      case INDIVIDUAL_EXPULSION: {
        const { teamCode, teamMemberId } = action.payload;

        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);
        const teamMember = this.getTeamMember(teamMemberId, team);

        return new LocalizedHistoryActionMessage('action.individual_expulsion', { clubCode: team.clubCode || team.shortName, teamMember }, true);
      }

      case INDIVIDUAL_DISQUALIFICATION: {
        const { teamCode, teamMemberId } = action.payload;
        const matchId = action.matchId;

        const team = this.getTeam(matchId, teamCode);
        const teamMember = this.getTeamMember(teamMemberId, team);

        return new LocalizedHistoryActionMessage('action.individual_disqualification', { clubCode: team.clubCode || team.shortName, teamMember }, true);
      }

      case TEAM_WARNING: {
        const { teamCode } = action.payload;
        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);

        return new LocalizedHistoryActionMessage('action.team_warning', { clubCode: team.clubCode || team.shortName }, true);
      }

      case TEAM_IMPROPER_REQUEST: {
        const { teamCode } = action.payload;
        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);

        return new LocalizedHistoryActionMessage('action.team_improper_request', { clubCode: team.clubCode || team.shortName }, true);
      }

      case TEAM_PENALTY: {
        const { teamCode, isMatchFinished } = action.payload;
        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);
        const otherTeamCode = teamCode === TeamCodes.team1 ? TeamCodes.team2 : TeamCodes.team1
        const otherTeam = this.getTeam(matchId, otherTeamCode);

        return isMatchFinished
          ? new LocalizedHistoryActionMessage('action.team_penalty', { clubCode: team.clubCode || team.shortName }, true)
          : new LocalizedHistoryActionMessage('action.team_penalty_with_score', { clubCode: team.clubCode || team.shortName, otherClubCode: otherTeam.clubCode || otherTeam.shortName }, true);
      }

      case SELECT_MVP: {
        const { teamCode, playerUuid } = action.payload;
        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);
        const player = this.getTeamMember(playerUuid, team);
        return new LocalizedHistoryActionMessage('action.select_mvp', { clubCode: team.clubCode || team.shortName, player });
      }

      case LOCK_STARTING_SIX: {
        return new LocalizedHistoryActionMessage('action.lock_starting_six', null);
      }

      case CHALLENGE: {
        const { teamCode } = action.payload;
        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);
        return new LocalizedHistoryActionMessage('action.challenge', { clubCode: team.clubCode || team.shortName }, true);
      }

      case CHALLENGE_SUCCESS: {
        const { teamCode } = action.payload;
        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);
        return new LocalizedHistoryActionMessage('action.challenge_success', { clubCode: team.clubCode || team.shortName }, true);
      }

      case CHALLENGE_DECLINED: {
        const { teamCode } = action.payload;
        const matchId = action.matchId;
        const team = this.getTeam(matchId, teamCode);
        return new LocalizedHistoryActionMessage('action.challenge_declined', { clubCode: team.clubCode || team.shortName }, true);
      }

      default: {
        // debugging
        // return new LocalizedHistoryActionMessage('action.' + action.type, null);
        return new LocalizedHistoryActionMessage(null, null);
      }

    }

  }
}