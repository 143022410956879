import { MatchViewComponent } from "./match-view.component";
import { ScoreboardComponent } from "./scoreboard.component";
import { TimeKeepingComponent } from "./time-keeping.component";
import { MatchSetsComponent } from "./match-sets.component";
import { CountdownComponent } from "./countdown.component";
import { DraggableService } from './draggable.service';

import { MATCH_VIEW_DIALOGS } from "./dialogs";
import { PlayerSelectComponent } from "./dialogs/player-select.component";

import { PLAYER_LIST_COMPONENTS } from "./player-list";
import { LINEUPS_COMPONENTS } from "./lineups";
import { INDIVIDUAL_SANCTIONS_COMPONENTS } from "./individual-sanctions";
import { TEAM_SANCTIONS_COMPONENTS } from "./team-sanctions";
import { TeamHighlightComponent } from "./team-highlight.component";
import { RefereeViewComponent } from './referee-view.component';
import { CountdownDisplayComponent } from './countdown-display';
import { ChallengeComponent } from "./challenge/challenge.component";
import { TimeoutComponent } from "./timeout/timeout.component";

export const MATCH_VIEW_SERVICES = [
  DraggableService
];

export const MATCH_VIEW_COMPONENTS = [
  ...MATCH_VIEW_DIALOGS,
  ...PLAYER_LIST_COMPONENTS,
  ...LINEUPS_COMPONENTS,
  ...INDIVIDUAL_SANCTIONS_COMPONENTS,
  ...TEAM_SANCTIONS_COMPONENTS,
  MatchViewComponent,
  RefereeViewComponent,
  ScoreboardComponent,
  TeamHighlightComponent,
  TimeKeepingComponent,
  MatchSetsComponent,
  CountdownComponent,
  PlayerSelectComponent,
  CountdownDisplayComponent,
  TimeoutComponent,
  ChallengeComponent
];
