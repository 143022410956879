import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import {
  Official,
  TeamOfficialFunctions
} from '../../../../shared/interfaces/models/official';

@Component({
  selector: 'sams-add-official',
  template: `
    <div [hidden]="hidden" style="background-color: whitesmoke;">

        <h2 style="padding: 15px 0px 0px 25px; margin-bottom: 0px;">{{title}}</h2>
        <form #officialForm="ngForm">

          <div class="flex-form new-official">

            <div>
              <p>
                <mat-form-field>
                  <input matInput [(ngModel)]="model.firstName" name="firstName" [placeholder]="'app.firstname' | translate" required>
                </mat-form-field>
              </p>

              <p>
                <mat-form-field>
                  <input matInput [(ngModel)]="model.lastName" name="lastName" [placeholder]="'app.lastname' | translate" required>
                </mat-form-field>
              </p>

            </div>

            <div>

              <p>
                <mat-form-field>
                  <mat-select placeholder="{{'app.offical_function_type' | translate}}" [(ngModel)]="model.function" name="function" required>
                    <mat-option *ngFor="let f of officialFunctions" [value]="f">{{('app.' + f.toLowerCase()) | translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </p>

            </div>
            
          </div>

          <div class="add-official-buttons">
            <button type="button" mat-raised-button class="positive" (click)="onSubmit()" [disabled]="!officialForm.form.valid">{{'app.save' | translate}}</button>
            <button type="button" mat-raised-button class="reset" (click)="onReset();">{{'app.reset' | translate}}</button>
            <button type="button" mat-raised-button class="cancel" (click)="cancel.emit()">{{'app.cancel' | translate}}</button>
          </div>

        </form>
    </div>
  `,
  styles: [`
    .add-official-buttons {
      padding: 0px 0px 25px 25px;
    }
    .add-official-buttons button {
      margin-right: 15px;
    }
    .new-official mat-form-field{
      width: 250px;
    }
    .flex-form {
      display: flex;
      justify-content: space-around;
    }
    .reset {
      background-color: LightCoral;
    }


  `]
})
export class AddOfficialComponent implements OnInit {

  @Input() title: string;

  @Input() hidden: boolean;

  @Output() cancel = new EventEmitter<boolean>();

  @Output() submit = new EventEmitter<Official>();

  @Input() official: Official = {} as Official;

  errMsg: string;

  model: Official;

  constructor() {
  }

  ngOnInit() {
    this.onReset();
  }

  onSubmit() {
    this.submit.emit(this.model);
    this.onReset();
  }

  onReset() {
    this.model = { ...this.official };
  }

  get officialFunctions() {
    return Object.keys(TeamOfficialFunctions).map(key => TeamOfficialFunctions[key]);
  }

}
