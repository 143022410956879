import {
  SEND,
  RECEIVE,
  REPLACE_HISTORY,
  ReplaceHistoryAction,
  ConnectionsAction
} from './connections.action.js';

import { ByMatchId, byMatchIdReducer } from '../helpers/by-match-id.reducer.js';
import { RemoteAction } from '../../interfaces/interfaces.js';
import { START_MATCH } from '../game-state/match-sets/time-keeping/time-keeping.action.js';
import { INITIALIZE_MATCH } from '../match-states/match-state.action.js';
import { deleteLicensePhotosFromMatch } from '../match-states/match-modification.reducer.js';
import { ADD_MATCH_SUCCESS } from '../matches-overview/matches-overview.action.js';
import { AUTH_TOKEN_REFRESH } from '../auth/auth.action.js';

export type ActionHistory = RemoteAction[];
export interface ConnectionAuth {
  id_token: string
  match_uuid: string
  expire?: number
  samsInstanceName: string
}

export interface Connection {
  listening: boolean;
  actionHistory: ActionHistory;
  auth: ConnectionAuth;
}

export type Connections = ByMatchId<Connection>;

export const initialState = {
  listening: false,
  actionHistory: [] as ActionHistory
};

export const connectionReducer = (state = initialState as Connection, action: ConnectionsAction): Connection => {

  switch (action.type) {

    case START_MATCH: {
      const actionHistory = state.actionHistory.map(a => {
        if (a.type === INITIALIZE_MATCH) {
          return {
            ...a,
            payload: deleteLicensePhotosFromMatch(a.payload)
          }
        }
        return a
      })
      return {
        ...state,
        actionHistory: [...actionHistory, (action as RemoteAction)] 
      }
    }

    case ADD_MATCH_SUCCESS: {
      return {
        ...state,
        auth: action.payload.auth
      }
    }

    case AUTH_TOKEN_REFRESH: {
      return {
        ...state,
        auth: action.payload
      }
    }

    case SEND:
      return {
        ...state,
        listening: false
      };

    case RECEIVE:
      return {
        ...state,
        listening: true
      };

    case REPLACE_HISTORY:
      return {
        ...state,
        actionHistory: (action as ReplaceHistoryAction).payload
      };

    default:
      if (!(action as RemoteAction).uuidHistoryChecksum) { // we do not want to add actions without a uuid checksum to the actionHistory
        return state;
      }
      return {
        ...state,
        actionHistory: [...state.actionHistory, (action as RemoteAction)]
      };

  }

};

export const connectionsReducer = byMatchIdReducer(connectionReducer);

// ==== Selectors ====
export const getConnection = (state: Connections, id: string) => state[id];
export const getListening = (state: Connection) => state.listening;
export const getActionHistory = (state: Connection) => state.actionHistory;
export const getAuth = (state: Connection) => state.auth;
