import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { IndoorRoot } from '../../root/indoor.reducer';
import { Store } from '@ngrx/store';
import * as fromIndoor from 'app/root/indoor.reducer';
import * as fromShared from 'app/root/shared.reducer';

@Component({
  selector: 'sams-set-finished-spectator-count-dialog',
  template: `
    <h1 mat-dialog-title>{{'app.set_finished' | translate}}</h1>
    <p>{{'component.set-finished-spectator-count-dialog.msg' | translate}}</p>
    <div class="num-of-spectators">
      <sams-num-of-spectators
        [matchId]="matchId$ | async"
        [numOfSpectators]="numOfSpectators$ | async"
        [placeholder]="'app.number_of_spectators'"
        [required]="true">
      </sams-num-of-spectators>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button color="primary" (click)="onConfirm()">{{'app.ok' | translate}}</button>
    </div>
  `,
  styles: [`
    .num-of-spectators {
      width: 50%;
    }
  `]
})
export class SetFinishedSpectatorCountDialogComponent {

  matchId$: Observable<string>

  numOfSpectators$: Observable<number>

  constructor(public dialogRef: MatDialogRef<SetFinishedSpectatorCountDialogComponent>, public store: Store<IndoorRoot>) {
    dialogRef.disableClose = true;
    this.numOfSpectators$ = this.store.select(fromIndoor.getNumOfSpectators)
    this.matchId$ = this.store.select(fromShared.getMatchId)
  }

  onConfirm = () => {
    this.dialogRef.close();
  }

}
