
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import * as fromBeach from 'app/root/beach.reducer';
import * as fromShared from 'app/root/shared.reducer';
import { TeamSideValues } from '../../../../../shared/interfaces/models';
import {
  SwitchSidesAction
} from '../../../../../shared/reducers/game-state/match-sets/team-sides/team-sides.action';
import {
  ConfirmBeachMatchPreparationAction
} from '../../../../../shared/beach/reducers/match-states/match-preparation/beach-match-preparation.action';
import { BeachMatchPreparation } from '../../../../../shared/beach/reducers/match-states/match-preparation/beach-match-preparation.reducer';
import { Router } from '@angular/router';
import { BeachMatch } from '../../../../../shared/beach/model/beach-match';
import { DispatchService } from '../../connections/dispatch.service';

@Component({
  selector: 'sams-beach-match-preparation',
  template: `
    <sams-syncing *ngIf="!match.isTestMatch" [matchId]="matchId"></sams-syncing>

    <mat-toolbar color="secondary">
      <div (click)="navigateHome()" style="height: 100%; width: auto; cursor: pointer;">
        <sams-score-logo></sams-score-logo>
      </div>
      <sams-listening *ngIf="!match.isTestMatch" [matchId]="matchId"></sams-listening>
      <sams-connection></sams-connection>
    </mat-toolbar>
    <div class="match-preparation">

      <mat-card>
        <h2>{{'beach.match_preparation' | translate}}</h2>

        <div *ngIf="isGameStateTouched">
          <h3>{{'app.closed' | translate}}</h3>
          <button mat-stroked-button class="positive" (click)="navigateForward()">
            {{'app.forward_to_match_view' | translate}}
          </button>
        </div>

        <mat-tab-group *ngIf="!isGameStateTouched">
          <mat-tab [label]="'app.teams' | translate">
            <div class="flex-container">
              <sams-beach-team-squad
                [matchId]="matchId"
                [teamCode]="leftTeamCode"
                [teamSquad]="leftTeamSquad"
                [coinTossWinner]="coinTossWinner"
                [teamSide]="TeamSideValues.leftTeam"
                [isServing]="leftTeamCode === servingTeamCode"
                [positions]="leftTeamPositions">
              </sams-beach-team-squad>
              <sams-beach-team-squad
                [matchId]="matchId"
                [teamCode]="rightTeamCode"
                [teamSquad]="rightTeamSquad"
                [teamSide]="TeamSideValues.rightTeam"
                [coinTossWinner]="coinTossWinner"
                [isServing]="rightTeamCode === servingTeamCode"
                [positions]="rightTeamPositions">
              </sams-beach-team-squad>
            </div>
            <div class="action-buttons">
              <button [disabled]="!areBothBeachTeamSquadsSigned || !isArbitrationComplete || isGameStateTouched" class="confirm" mat-raised-button class="positive" (click)="confirm()">
                {{'app.confirm' | translate}}
              </button>
              <button class="switch-sides" mat-raised-button (click)="switchSides()">
                {{'component.match-view.switch_sides' | translate}}
              </button>
            </div>
          </mat-tab>
          <mat-tab class="arbitration-tab" [label]="'app.arbitration' | translate">
            <div class="flex-container">
              <sams-beach-arbitration
                [matchId]="matchId"
                [arbitration]="arbitration">
              </sams-beach-arbitration>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-card>
    </div>
  `,
  styles: [`

    mat-card {
      width: 340px;
    }

    h2 {
      font-weight: normal;
      margin: 15px;
      padding-top: 15px;
    }

    .flex-container {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      margin-top: 15px;
    }

    .match-preparation {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 15px;
    }

    .action-buttons {
      margin: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

  `]
})
export class BeachMatchPreparationComponent implements OnInit, OnDestroy {

  matchId: string;

  matchPreparation: BeachMatchPreparation;

  match: BeachMatch;

  isGameStateTouched: boolean;

  private subscription = new Subscription()

  constructor(
    public store: Store<fromBeach.BeachRoot>,
    public dispatchService: DispatchService,
    private router: Router) {
  }

  ngOnInit() {
    this.subscription.add(
      this.store.select(fromShared.getMatchId).subscribe(matchId => this.matchId = matchId)
    )
    this.subscription.add(
      this.store.select(fromBeach.getSelectedBeachMatch).subscribe(match => this.match = match)
    )
    this.subscription.add(
      this.store.select(fromBeach.getBeachMatchPreparation).subscribe(matchPreparation => this.matchPreparation = matchPreparation)
    )
    this.subscription.add(
      this.store.select(fromBeach.isGameStateTouched).subscribe(isGameStateTouched => this.isGameStateTouched = isGameStateTouched)
    )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  navigateHome() {
    this.router.navigate(['/matches-overview'])
  }

  get arbitration() {
    return this.matchPreparation.arbitration
  }

  get coinTossWinner() {
    return this.matchPreparation.coinTossWinner
  }

  get teamSides() {
    return this.matchPreparation.teamSides
  }

  get servingTeamCode() {
    return this.matchPreparation.servingTeamCode
  }

  get teamSquads() {
    return this.matchPreparation.teamSquads
  }

  get leftTeamSquad() {
    return this.teamSquads[this.teamSides.leftTeam]
  }

  get rightTeamSquad() {
    return this.teamSquads[this.teamSides.rightTeam]
  }

  get leftTeamCode() {
    return this.teamSides.leftTeam
  }
  
  get rightTeamCode() {
    return this.teamSides.rightTeam
  }

  get leftTeamPositions() {
    return [2, 1]
  }

  get rightTeamPositions() {
    return [1, 2]
  }

  get areBothBeachTeamSquadsSigned() {
    return this.teamSquads.team1.signature.signatureString != null
      && this.teamSquads.team2.signature.signatureString != null
  }

  confirm() {
    this.dispatchService.dispatchRemoteAction(new ConfirmBeachMatchPreparationAction({ confirmed: true }, this.matchId))
    this.navigateForward()
  }

  navigateForward() {
    this.router.navigate(['/match-view'])
  }

  switchSides() {
    this.dispatchService.dispatchRemoteAction(new SwitchSidesAction(this.matchId));
  }

  get isArbitrationComplete() {
    return this.arbitration.firstReferee && this.arbitration.scorer
  }

  get TeamSideValues() {
    return TeamSideValues
  }

}