import { MatchPreparationComponent } from './match-preparation.component';
import { TeamSquadComponent } from './team-squad.component';
import { AddPlayerComponent } from './add-player.component';
import { TeamPreparationComponent } from './team-preparation.component';
import { TeamPreparationsComponent } from './team-preparations.component';
import { ArbitrationSelectionComponent } from './arbitration-selection-component';
import { AddOfficialComponent } from './add-official.component';
import { LoadPlayersComponent } from './load-players.component';
import { LicenseComponent } from './license/license.component';
import { LicenseBacksideComponent } from './license/license-backside.component';
import { LicenseDialogComponent } from './license/license-dialog.component';
import { LicenseFrontsideComponent } from './license/license-frontside.component';
import { LicenseService } from './license/license-service';
import { LicensePhotoComponent } from './license/license-photo.component';

export const MATCH_PREPARATION_COMPONENTS = [
  MatchPreparationComponent,
  TeamSquadComponent,
  AddPlayerComponent,
  TeamPreparationComponent,
  TeamPreparationsComponent,
  ArbitrationSelectionComponent,
  AddOfficialComponent,
  LoadPlayersComponent,
  LicenseComponent,
  LicenseFrontsideComponent,
  LicenseBacksideComponent,
  LicenseDialogComponent,
  LicensePhotoComponent
];

export const MATCH_PREPARATION_SERVICES = [
  LicenseService
];
