import { Component, Input } from '@angular/core';

import { Injuries } from '../../../../../shared/reducers/game-state/injuries/injuries.reducer';
import { InjuryAction } from '../../../../../shared/reducers/game-state/injuries/injuries.action';

import { Player, TeamCode } from 'app/models';
import { DispatchService } from 'app/connections/dispatch.service';

@Component({
  selector: 'sams-injury',
  template: `
      <button [disabled]="isInjured" (click)="onInjury()" mat-menu-item>
        <mat-icon style="color: #139039;">add_box</mat-icon>
        <span>{{'app.injury' | translate}}</span>
      </button>
  `,
  styles: [`

  `],
})
export class InjuryComponent {

  @Input() teamCode: TeamCode;

  @Input() playerId: string;

  @Input() injuries: Injuries<Player>;

  @Input() matchId: string;

  constructor(public dispatchService: DispatchService) {
  }

  onInjury() {
    this.dispatchService.dispatchRemoteAction(new InjuryAction({ playerId: this.playerId, teamCode: this.teamCode }, this.matchId));
  }

  get isInjured() {
    return this.injuries.find(p => p.uuid === this.playerId);
  }

}
