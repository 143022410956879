import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import { Player, ManuallyCreatedPlayer } from 'app/models/player';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sams-add-player',
  template: `
    <div [hidden]="hidden">

        <h2 style="font-size: 20px; text-align: center;">
          {{title}}
        </h2>
        <form #playerForm="ngForm">

          <div class="flex-form new-player">

            <div>
              <p>
                <mat-form-field>
                  <input matInput [(ngModel)]="model.firstName" name="firstName" [placeholder]="'app.firstname' | translate" required>
                </mat-form-field>
              </p>

              <p>
                <mat-form-field>
                  <input matInput [(ngModel)]="model.lastName" name="lastName" [placeholder]="'app.lastname' | translate" required>
                </mat-form-field>
              </p>

              <p>
                <mat-form-field>
                  <input type="date" matInput [(ngModel)]="model.dateOfBirth" name="dateOfBirth" [placeholder]="'app.date_of_birth' | translate" required>
                </mat-form-field>
              </p>
            </div>

            <div>
              <p>
                <mat-form-field [color]="determineAccent(newPlayerJerseyNumber, model.jerseyNumber)">
                  <input
                    [(ngModel)]="model.jerseyNumber" 
                    [pattern]="jerseyNumberPattern"
                    [placeholder]="'app.jersey_number' | translate"
                    matInput
                    name="newPlayerJerseyNumber"
                    #newPlayerJerseyNumber="ngModel"
                    maxlength="2"
                    required>
                </mat-form-field>
              </p>

              <p>
                <mat-form-field>
                  <input matInput [(ngModel)]="model.license.licenseNumber" name="licenseNumber" [placeholder]="'app.license_number' | translate" required>
                </mat-form-field>
              </p>

              <p>
                <mat-form-field>
                  <input matInput [(ngModel)]="model.division" name="division" [placeholder]="'app.division' | translate" required>
                </mat-form-field>
              </p>
            </div>
            
            
          </div>
          
          <p class="warn">{{errMsg$ | async}}</p>

          <div class="add-player-buttons">
            <button type="button" mat-raised-button class="positive" (click)="onSubmit()" [disabled]="!playerForm.form.valid || confirmed">{{'app.save' | translate}}</button>
            <button type="button" mat-raised-button class="reset" [disabled]="confirmed" (click)="onReset();">{{'app.reset' | translate}}</button>
            <button type="button" mat-raised-button class="cancel" [disabled]="confirmed" (click)="cancel.emit()">{{'app.cancel' | translate}}</button>
          </div>

        </form>
    </div>
  `,
  styles: [`
    .warn {
      color: coral;
      font-size: 12px;
    }
    .flex-form {
      display: flex;
      justify-content: space-around;
    }
    .reset {
      background-color: LightCoral;
    }


  `]
})
export class AddPlayerComponent implements OnInit {

  readonly DATE_FORMAT = 'YYYY-MM-DD'

  readonly GERMAN_DATE_FORMAT = 'DD.MM.YYYY'

  readonly jerseyNumberPattern = '^([1-9]|[1-9][0-9])$';

  @Input() confirmed: boolean;

  @Input() title: string;

  @Input() players: Player[];

  @Input() hidden: boolean;

  @Output() cancel = new EventEmitter<boolean>();

  @Output() submit = new EventEmitter<ManuallyCreatedPlayer>();

  errMsg$: Observable<string>;

  @Input() player = { license: {} } as ManuallyCreatedPlayer;

  model: ManuallyCreatedPlayer;

  constructor(public translate: TranslateService) {
  }

  ngOnInit() {
    this.onReset();
  }

  onSubmit() {
    const d = moment(this.model.dateOfBirth, this.GERMAN_DATE_FORMAT, true)
    // checking german format first is important here
    // since german format is valid DATE_FORMAT but wrong dates occur aka 10.11.1991 -> 1991-11-10 instead of 1991-10-11
    if (d.isValid()) {
      this.model.dateOfBirth = d.format(this.DATE_FORMAT)
    } else if (!moment(this.model.dateOfBirth, this.DATE_FORMAT, true).isValid()) {
      this.errMsg$ = this.translate.get('component.add-player.invalid_date');
      return
    }
    this.submit.emit(this.model);
    this.onReset();
  }

  onReset() {
    this.model = { ...this.player, license: { ...this.player.license } };
  }

  determineAccent(input, value: string): string {
    if (this.isValid(input, value)) {
      return 'primary';
    } else {
      return 'warn';
    }
  }

  isDuplicateJeseyNumber(jerseyNumber: string) {
    return this.players.filter(p => p.jerseyNumber === jerseyNumber && p.uuid !== this.model.uuid).length > 0;
  }

  isValid(input, value: string): boolean {
    const isAlreadyTaken = this.isDuplicateJeseyNumber(value);
    return input.valid && !isAlreadyTaken;
  }

}
