import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'sams-info-dialog',
  template: `
    <h1 mat-dialog-title>{{title | async}}</h1>
    <p style="line-height: 22px;" *ngIf="content">{{content | async}}</p>
    <div class="action-buttons" mat-dialog-actions>
      <button mat-raised-button color="primary" mat-dialog-close="Ok">{{buttonText | async}}</button>
    </div>
  `,
  styles: [`
    .action-buttons {
      display: flex;
      justify-content: center;
    }
  `]
})
export class InfoDialogComponent {

  title: Observable<string>;

  content?: Observable<string>;

  buttonText: Observable<string>;

  constructor(public dialogRef: MatDialogRef<InfoDialogComponent>) {
    dialogRef.disableClose = true;
  }
}
