import { timeKeepingReducer, TimeKeeping } from './time-keeping/time-keeping.reducer.js';
import { TimeKeepingAction, StartTechnicalTimeoutAction, START_TIMEOUT } from './time-keeping/time-keeping.action.js';
import { TeamSides, teamSidesReducer } from './team-sides/team-sides.reducer.js';
import { TeamSidesAction, SwitchSidesAction } from './team-sides/team-sides.action.js';
import { lineupsReducer, Lineups, AdditionalLineupsState, SERVING_INDEX } from './lineups/lineups.reducer.js';
import { LineupsAction } from './lineups/lineups.action.js';
import { servingReducer, Serving } from './serving/serving.reducer.js';
import { ServingAction } from './serving/serving.action.js';
import { SetScore, setScoreReducer } from './set-score/set-score.reducer.js';
import { SetScoreAction, ScoreAction, SCORE, SCORE_OVERRIDE, PENALTY_SCORE } from './set-score/set-score.action.js';
import { GameStateAction } from '../game-state.action.js';
import * as fromSetScore from './set-score/set-score.reducer.js';
import { TeamCode, TeamCodes } from '../../../interfaces/models/team-codes.js';
import { ScoreConfiguration } from '../../../interfaces/models/score-configuration.js';
import { StartingSix, startingSixReducer } from './starting-six.reducer.js';
import { StartingSixAction } from './starting-six.action.js';
import { streakReducer, Streak } from './set-score/streak.reducer.js';
import { MatchConfiguration } from '../../../interfaces/models/match-configuration.js';
import { Challenges, challengesReducer } from './challenges/challenges.reducer.js';
import { ChallengesAction } from './challenges/challenges.action.js';

export const firstTechnicalTimeoutAtPoints = 8
export const secondTechnicalTimeoutAtPoints = 16

export interface MatchSet {
  setScore: SetScore;
  streak: Streak;
  teamSides: TeamSides;
  serving: Serving;
  timeKeeping: TimeKeeping;
  lineups: Lineups;
  challenges: Challenges;
  setScoreAtChangeSideInDecidingSet: SetScore;
  isSetBall: boolean;
  isTechnicalTimeoutPristine: boolean;
  wasTeamTimeoutTakenBeforeScore: boolean;
  startingSix: StartingSix;
}

export interface AdditionalMatchSetState {
  isDecidingSet: boolean
  newSetScore: SetScore
  currentSetNumber: number
  scoreConfig: ScoreConfiguration
  matchConfig: MatchConfiguration
}

export const setFinished = (minimumPointDifference: number) => (pointsToWin: number) => (setScore: SetScore) => {
  const scoreDiff = fromSetScore.getScoreDifference(setScore);
  const maxScore = fromSetScore.getMaxScore(setScore);
  return scoreDiff >= minimumPointDifference
    && maxScore >= pointsToWin;
};

export interface SetFinishedConfig {
  minimumPointDifference: number
  pointsToWinDecidingSet: number
  pointsToWinSet: number
  maximumSets: number
  alwaysPlayMaximumNumberOfSets: boolean
}

export const decidingSet = (currentSetNumber: number, config: SetFinishedConfig) => config.alwaysPlayMaximumNumberOfSets ? false : currentSetNumber === config.maximumSets;

export const isSetFinished = (currentSetNumber: number, config: SetFinishedConfig, setScore: SetScore) => {

  const {
    minimumPointDifference,
    pointsToWinDecidingSet,
    pointsToWinSet
  } = config;

  const isDecidingSet = decidingSet(currentSetNumber, config);

  const setFinishedWithMinPointDiff = setFinished(minimumPointDifference);
  const isNormalSetFinished = setFinishedWithMinPointDiff(pointsToWinSet);
  const isDecidingSetFinished = setFinishedWithMinPointDiff(pointsToWinDecidingSet);

  return isDecidingSet ?
    isDecidingSetFinished(setScore) :
    isNormalSetFinished(setScore);
};

export const switchSidesInDecidingSet = (oldMaxScore: number, newMaxScore: number, isDecidingSet: boolean, decidingSetSideChangeAtPoints: number) => {
  if (!isDecidingSet) {
    return false;
  }
  if (newMaxScore === decidingSetSideChangeAtPoints && newMaxScore !== oldMaxScore) {
    return true;
  }
  return false;
};

export const technicalTimeout = (oldMaxScore: number, newMaxScore: number, isDecidingSet: boolean, firstTechnicalTimeoutAtPoints: number, secondTechnicalTimeoutAtPoints: number, technicalTimeoutDurationSeconds: number) => {
  if (isDecidingSet || technicalTimeoutDurationSeconds === 0) {
    return false;
  }
  if ((newMaxScore === firstTechnicalTimeoutAtPoints || newMaxScore === secondTechnicalTimeoutAtPoints) && newMaxScore !== oldMaxScore) {
    return true;
  }
  return false;
};


export const setBall = (currentSetNumber: number, config: SetFinishedConfig, setScore: SetScore) => {
  const leadingTeam = setScore.team1 > setScore.team2 ? TeamCodes.team1 : TeamCodes.team2
  const nextSetScoreIfLeadingTeamScores = setScoreReducer(setScore, new ScoreAction(leadingTeam, 'dummyUuid'))
  return !isSetFinished(currentSetNumber, config, setScore) && isSetFinished(currentSetNumber, config, nextSetScoreIfLeadingTeamScores)
}


export const matchSetReducer = (state: MatchSet, action: GameStateAction, additionalMatchSetState: AdditionalMatchSetState): MatchSet => {

  if (!state) {

    const setScore = setScoreReducer(undefined, {} as ScoreAction)
    const streak = streakReducer(undefined, {} as ScoreAction)
    const serving = servingReducer(undefined, {} as ServingAction)
    const newSetScore = setScoreReducer(setScore, action as SetScoreAction);
    const { matchConfig, currentSetNumber, scoreConfig } = additionalMatchSetState

    const isSetFinishedAfterScore = additionalMatchSetState ? isSetFinished(currentSetNumber, matchConfig, newSetScore) : false;

    const additionalLineupsState: AdditionalLineupsState = {
      streak,
      currentSetScore: setScore,
      serving,
      newServing: serving,
      isSetFinishedAfterScore,
      newSetScore,
      scoreConfig,
      wasTeamTimeoutTakenBeforeScore: false
    };
    

    const initialState: MatchSet = {
      setScore,
      streak,
      teamSides: teamSidesReducer(undefined, {} as TeamSidesAction),
      serving: servingReducer(undefined, {} as ServingAction),
      timeKeeping: timeKeepingReducer(undefined, {} as TimeKeepingAction),
      lineups: lineupsReducer(undefined, {} as LineupsAction, additionalLineupsState),
      setScoreAtChangeSideInDecidingSet: null,
      isSetBall: false,
      isTechnicalTimeoutPristine: false,
      wasTeamTimeoutTakenBeforeScore: false,
      startingSix: startingSixReducer(undefined, {} as StartingSixAction),
      challenges: challengesReducer(undefined, {} as ChallengesAction)
    };
    return initialState
  }

  const { currentSetNumber, matchConfig } = additionalMatchSetState
  const scoreConfig = additionalMatchSetState.scoreConfig
  const newSetScore = setScoreReducer(state.setScore, action as SetScoreAction);
  const streak = streakReducer(state.streak, action as SetScoreAction); 
  const isSetFinishedAfterScore = additionalMatchSetState ? isSetFinished(currentSetNumber, matchConfig, newSetScore) : false;
  const newServing = isSetFinishedAfterScore ? state.serving : servingReducer(state.serving, action as ServingAction)
  const additionalLineupsState: AdditionalLineupsState = {
    streak,
    currentSetScore: state.setScore,
    newServing: newServing,
    serving: state.serving,
    isSetFinishedAfterScore,
    newSetScore,
    scoreConfig,
    wasTeamTimeoutTakenBeforeScore: state.wasTeamTimeoutTakenBeforeScore
  };

  const isSetBall = additionalMatchSetState ? setBall(currentSetNumber, matchConfig, newSetScore) : false
  const oldMaxScore = fromSetScore.getMaxScore(state.setScore);
  const newMaxScore = fromSetScore.getMaxScore(newSetScore);
  const technicalTimeoutDurationSeconds = scoreConfig ? scoreConfig.technicalTimeoutDurationSeconds : 60 
  const isTechnicalTimeoutPristine = additionalMatchSetState
    ? technicalTimeout(oldMaxScore, newMaxScore, additionalMatchSetState.isDecidingSet, firstTechnicalTimeoutAtPoints, secondTechnicalTimeoutAtPoints, technicalTimeoutDurationSeconds)
    : false

  const newState = {
    ...state,
    setScore: newSetScore,
    streak,
    isSetBall,
    timeKeeping: timeKeepingReducer(state.timeKeeping, action as TimeKeepingAction),
    teamSides: teamSidesReducer(state.teamSides, action as TeamSidesAction),
    lineups: lineupsReducer(state.lineups, action as LineupsAction, additionalLineupsState),
    serving: isSetFinishedAfterScore ? state.serving : servingReducer(state.serving, action as ServingAction),
    isTechnicalTimeoutPristine,
    startingSix: startingSixReducer(state.startingSix, action as StartingSixAction),
    challenges: challengesReducer(state.challenges, action as ChallengesAction)
  };

  const decidingSetSideChangeAtPoints = scoreConfig ? scoreConfig.decidingSetSideChangeAtPoints : 8

  switch (action.type) {
    case SCORE:
    case PENALTY_SCORE:
    case SCORE_OVERRIDE:
      return {
        ...newState,
        wasTeamTimeoutTakenBeforeScore: false,
        isTechnicalTimeoutPristine,
        setScoreAtChangeSideInDecidingSet:
          switchSidesInDecidingSet(oldMaxScore, newMaxScore, additionalMatchSetState.isDecidingSet, decidingSetSideChangeAtPoints)
            ? newState.setScore
            : newState.setScoreAtChangeSideInDecidingSet,
        teamSides:
          switchSidesInDecidingSet(oldMaxScore, newMaxScore, additionalMatchSetState.isDecidingSet, decidingSetSideChangeAtPoints)
            ? teamSidesReducer(newState.teamSides, new SwitchSidesAction(action.matchId))
            : newState.teamSides,
        timeKeeping:
          isTechnicalTimeoutPristine
            ? timeKeepingReducer(newState.timeKeeping, new StartTechnicalTimeoutAction(action.timestamp, action.matchId))
            : newState.timeKeeping
      };
    case START_TIMEOUT:
      return {
        ...newState,
        wasTeamTimeoutTakenBeforeScore: true
      }
  }

  return newState;
};


// ==== Selectors ====
export const getSetScore = (state: MatchSet) => state.setScore;
export const getLeftTeamScore = (state: MatchSet) => getSetScore(state)[getTeamSides(state).leftTeam];
export const getRightTeamScore = (state: MatchSet) => getSetScore(state)[getTeamSides(state).rightTeam];
export const getLeadingTeamCode = (state: MatchSet) => {
  const setScore = getSetScore(state);
  if (setScore.team1 === setScore.team2) return undefined
  return setScore.team1 > setScore.team2 ? TeamCodes.team1 : TeamCodes.team2
}
export const getTimeKeeping = (state: MatchSet) => state.timeKeeping;
export const getTeamSides = (state: MatchSet) => state.teamSides;
export const getLineups = (state: MatchSet) => state.lineups;
export const getChallenges = (state: MatchSet) => state.challenges;
export const getServing = (state: MatchSet) => state.serving;
export const isSetStarted = (state: MatchSet) => getTimeKeeping(state).actualStartTimeMs !== 0;
export const isSetRunning = (state: MatchSet) => isSetStarted(state) && getTimeKeeping(state).currentPauseStartTime === 0;
export const setTimeEnded = (state: MatchSet) => getTimeKeeping(state).endTimeMs !== 0;
export const isSetBall = (state: MatchSet) => state.isSetBall;
export const isTechnicalTimeoutPristine = (state: MatchSet) => state.isTechnicalTimeoutPristine;
export const getStartingSix = (state: MatchSet) => state.startingSix
export const wasTeamTimeoutTakenBeforeScore = (state: MatchSet) => state.wasTeamTimeoutTakenBeforeScore;

export const isPlayerServing = (teamCode: TeamCode, currentlyServing: TeamCode, fieldIndex: number) => {
  return fieldIndex === SERVING_INDEX
    && teamCode === currentlyServing;
};
// ==== Selectors ====
