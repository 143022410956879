import { Component, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScoreConfiguration } from '../../../../../shared/interfaces/models/score-configuration';

@Component({
  selector: 'sams-set-finished-duration-dialog',
  template: `
    <h1 mat-dialog-title>{{'app.set_finished' | translate}}</h1>
    <p>{{'component.set-finished-duration-dialog.msg' | translate}}</p>
    <div mat-dialog-actions>
      <button mat-raised-button color="primary"
        (click)="onDurationSelection(setPauseDurationSeconds)">
        {{setPauseDurationSeconds/60}} {{'component.set-finished-duration-dialog.minutes' | translate}}
      </button>
      <button *ngIf="pauseDurationAfterSecondSetValue1"
        mat-raised-button color="primary"
        (click)="onDurationSelection(pauseDurationAfterSecondSetValue1)">
        {{pauseDurationAfterSecondSetValue1/60}} {{'component.set-finished-duration-dialog.minutes' | translate}}
      </button>
      <button *ngIf="pauseDurationAfterSecondSetValue2"
        mat-raised-button color="primary"
        (click)="onDurationSelection(pauseDurationAfterSecondSetValue2)">
        {{pauseDurationAfterSecondSetValue2/60}} {{'component.set-finished-duration-dialog.minutes' | translate}}
      </button>
    </div>
  `,
})
export class SetFinishedDurationDialogComponent {

  public onDurationChange = new EventEmitter<number>();

  constructor(public dialogRef: MatDialogRef<SetFinishedDurationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public scoreConfiguration: ScoreConfiguration) {

    dialogRef.disableClose = true;
  }

  onDurationSelection = (minutes: number) => {
    if (minutes !== this.scoreConfiguration.setPauseDurationSeconds) {
      this.onDurationChange.emit(minutes * 1000);
    }
    this.dialogRef.close();
  }

  get setPauseDurationSeconds() {
    return this.scoreConfiguration.setPauseDurationSeconds 
  }

  get pauseDurationAfterSecondSetValue1() {
    return this.scoreConfiguration.pauseDurationAfterSecondSet.valueOne
  }

  get pauseDurationAfterSecondSetValue2() {
    return this.scoreConfiguration.pauseDurationAfterSecondSet.valueTwo
  }

}
