import { MatchAction } from '../../../interfaces/interfaces.js';
import { TeamCode } from '../../../interfaces/models/team-codes.js';

export const SELECT_MVP = '[Mvps] Select mvp'

export interface SelectMvp {
  teamCode: TeamCode;
  playerUuid: string;
}

export class SelectMvpAction extends MatchAction {
  readonly type = SELECT_MVP;
  constructor(public payload: SelectMvp, matchId: string) {
    super(matchId);
  }
}

export type MvpAction
  = SelectMvpAction
