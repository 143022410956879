
import {map, take} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';

import { MatchState } from '../../../../shared/reducers/match-states/match-state.reducer';
import { TeamSquad } from '../../../../shared/reducers/match-preparation/team-squad.reducer';

import { Match, ByTeamCode, TeamCodes, ScoreConfiguration } from 'app/models';
import * as fromIndoor from 'app/root/indoor.reducer';
import * as fromShared from 'app/root/shared.reducer';
import { TeamSquadsPresenter } from 'app/helpers/team-squads-presenter';
import { IndoorRoot } from 'app/root/indoor.reducer';

@Component({
  selector: 'sams-team-preparations',
  template: `
  <div *ngIf="matchId" class="team-preparations">
    <sams-syncing *ngIf="!(selectedMatch$ | async)?.isTestMatch" [matchId]="matchId"></sams-syncing>

    <mat-toolbar color="secondary">
      <button mat-button (click)="navigateBack()">{{'app.back_to_matches_overview' | translate}}</button>
      <span style="flex: 1 1 auto;"></span>
      <div (click)="navigateHome()" style="height: 100%; width: auto; cursor: pointer;">
        <sams-score-logo></sams-score-logo>
      </div>
      <span style="flex: 1 1 auto;"></span>
      <button [disabled]="!(haveBothConfirmed$ | async)" (click)="openTeamSquadsView()" mat-mini-fab color="primary" style="margin-right: 25px;"><mat-icon>print</mat-icon></button>
      <button [disabled]="!(haveBothConfirmed$ | async)" [ngClass]="{ 'forward': haveBothConfirmed$ | async }" mat-raised-button (click)="navigate()">{{'app.forward_to_match_prep' | translate}}</button>
      <sams-connection></sams-connection>
    </mat-toolbar>
    
    <div class="flex-container team-prep">
      <sams-team-preparation
        [teamCode]="team1"
        [isGameStateTouched]="isGameStateTouched$ | async"
        [teamSquad]="(teamSquads$ | async)?.team1"
        [match]="selectedMatch$ | async"
        [scoreConfiguration]="scoreConfiguration$ | async">
      </sams-team-preparation>
      <sams-team-preparation
        [teamCode]="team2"
        [isGameStateTouched]="isGameStateTouched$ | async"
        [teamSquad]="(teamSquads$ | async)?.team2"
        [match]="selectedMatch$ | async"
        [scoreConfiguration]="scoreConfiguration$ | async">
      </sams-team-preparation>
    </div>
  </div>

  `,
  styles: [`
    .flex-container {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      width: 100%;
    }

    .score-logo {
      cursor: pointer;
      height: 90%;
      width: auto;
    }

    .team-prep > * {
      min-width: 45%;
    }

    .forward {
      background-color: lightgreen;
    }

    @media only screen and (max-width: 1200px) {
      .team-prep {
        // flex-direction: column;
      }
    }

  `]
})
export class TeamPreparationsComponent implements OnInit, OnDestroy {

  selectedMatch$: Observable<Match>;

  teamSquads$: Observable<ByTeamCode<TeamSquad>>;

  haveBothConfirmed$: Observable<boolean>;

  isGameStateTouched$: Observable<boolean>;

  scoreConfiguration$: Observable<ScoreConfiguration>;

  matchId: string;

  matchState: MatchState;

  private subscription = new Subscription();

  constructor(public store: Store<IndoorRoot>, public router: Router) {

  }

  ngOnInit() {

    this.subscription.add(
      this.store.select(fromShared.getMatchId).subscribe(matchId => this.matchId = matchId)
    );

    this.selectedMatch$ = this.store.select(fromIndoor.getMatch);

    this.teamSquads$ = this.store.select(fromIndoor.getTeamSquads);

    this.subscription.add(
      this.store.select(fromIndoor.getMatchState).subscribe(matchState => this.matchState = matchState)
    );

    this.haveBothConfirmed$ = this.teamSquads$.pipe(map(({ team1, team2 }) => team1.confirmed && team2.confirmed));

    this.isGameStateTouched$ = this.store.select(fromIndoor.isGameStateTouched);

    this.scoreConfiguration$ = this.store.select(fromIndoor.getScoreConfiguration);

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  navigate() {
    this.router.navigate(['/match-preparation']);
  }

  navigateBack() {
    this.navigateHome();
  }

  navigateHome() {
    this.router.navigate(['/matches-overview']);
  }

  openTeamSquadsView() {
    new TeamSquadsPresenter(this.matchState).openTeamSquadsView();
  }

  get team1() {
    return TeamCodes.team1;
  }

  get team2() {
    return TeamCodes.team2;
  }

}
