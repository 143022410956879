import { Action } from '../../interfaces/interfaces.js';
import { ConnectionAuth } from '../connections/connections.reducer.js';

export const AUTH_FAILURE = '[Auth] Auth Failure';
export const AUTH_TOKEN_REFRESH = '[Auth] Token refresh';
export const AUTH_SUCCESS = '[Auth] Auth Success';

export type AuthTokenRefreshPayload = ConnectionAuth
export type AuthSuccessPayload = ConnectionAuth 

export enum AuthErrorType {
  UNAUTHORIZED = "UNAUTHORIZED",
  BAD_REQUEST = "BAD_REQUEST",
  TOKEN_EXPIRED = "TOKEN_EXPIRED",
  JSON_WEBTOKEN_ERROR = "JSON_WEBTOKEN_ERROR",
  TOKEN_NOT_BEFORE_ERROR = "TOKEN_NOT_BEFORE_ERROR",
  HTTP_ERROR = "HTTP_ERROR",
  SERVER_ERROR = "SERVER_ERROR"
}

export interface AuthFailurePayload {
  name: string
  status: number
  message: string
  error: AuthErrorType
}

export class AuthTokenRefreshAction implements Action {
  readonly type = AUTH_TOKEN_REFRESH;
  constructor(public payload: AuthTokenRefreshPayload) { }
}

export class AuthFailureAction implements Action {
  readonly type = AUTH_FAILURE;
  constructor(public payload: AuthFailurePayload) { }
}

export class AuthSuccessAction implements Action {
  readonly type = AUTH_SUCCESS;
  constructor(public payload: AuthSuccessPayload) { }
}

export type AuthAction =
  | AuthSuccessAction
  | AuthFailureAction 
  | AuthTokenRefreshAction;
