import {
  Subscription
} from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';

import { 
  ActionHistory,
  Connections
} from '../../../../shared/reducers/connections/connections.reducer';
import {
  ReplaceHistoryAction,
  SendAction,
} from '../../../../shared/reducers/connections/connections.action';
import { Md5Util } from '../../../../shared/utils/md5-util';
import {
  MatchAction,
  RemoteAction,
} from '../../../../shared/interfaces/interfaces';

import * as fromShared from '../root/shared.reducer';

@Injectable()
export class ConnectionsService implements OnDestroy {

  connections: Connections;

  private subscription = new Subscription();

  constructor(
    public store: Store<fromShared.Root>,
    public dialog: MatDialog) {

    this.subscription.add(
      this.store.select(fromShared.getConnections)
        .subscribe(connections => this.connections = connections)
    );

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public toRemoteAction(action: MatchAction) {
    const actionHistory = this.getActionHistory(action.matchId)
    const orderRank = actionHistory.length

    const remoteAction: RemoteAction = {
      ...action,
      uuidHistoryChecksum: this.createHistoryChecksum(action.matchId),
      orderRank
    };

    this.store.dispatch(remoteAction);
    this.store.dispatch(new SendAction(remoteAction.matchId));
    return remoteAction
  }

  public getActionHistory(matchId: string) {
    return this.connections[matchId].actionHistory
  }

  public replaceActionHistory(matchId: string, actionHistory: ActionHistory) {
    this.store.dispatch(new ReplaceHistoryAction(actionHistory, matchId));
  }

  public createHistoryChecksum(matchId: string): string {
    const uuidHistory = this.getUuidHistory(matchId);
    return Md5Util.caluclateChecksum(uuidHistory);
  }

  private getUuidHistory(matchId: string) {
    return this.getActionHistory(matchId).map(a => a.uuid);
  }

}
