import { OnInit } from '@angular/core';
import {
  Component,
  Input,
  ChangeDetectionStrategy,
  EventEmitter,
  Output
} from '@angular/core';

import { GameStates, MatchState } from '../../../../shared/reducers/match-states/match-state.reducer';
import { GameStateAction } from '../../../../shared/reducers/game-state/game-state.action';

import { LocalizedHistoryActionBuilder } from 'app/helpers/localized-history-action-builder';

@Component({
  selector: 'sams-undoable',
  template: `
    <div class="undoable-actions">

      <div class="undo">
        <button mat-raised-button
          [matTooltip] = "localizedPast ? (localizedPast.message.key | translate:localizedPast.message.params) : null"
          (click)="onUndo()"
          [disabled]="!isUndoable()"
          (window:keydown.u)="onUndo()">
          <mat-icon class="mat-24">undo</mat-icon>
        </button>
      </div>

      <div class="present">{{ localizedPresent ? (localizedPresent.message.key | translate:localizedPresent.message.params) : null }}</div>

      <div class="redo">
        <button mat-raised-button
          [matTooltip] = "localizedFuture ? (localizedFuture.message.key | translate:localizedFuture.message.params) : null"
          (click)="onRedo()"
          [disabled]="!isRedoable()"
          (window:keydown.r)="onRedo()">
          <mat-icon class="mat-24">redo</mat-icon>
        </button>
      </div>

    </div>
  `,
  styles: [`
    div.undoable-actions {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .undo, .redo, .present {
      text-align: center;
      margin: 8px;
    }
    .undo button, .redo button {
      width: 5vw;
      min-width: 50px;
      max-width: 100px;
      padding: 0;
    }
    .present {
      width: 200px;
      font-size: 14px;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UndoableComponent {

  @Input() gameStates: GameStates;
 
  @Input() matchState: MatchState;

  @Output() undo = new EventEmitter<number>();

  @Output() redo = new EventEmitter<number>();

  constructor() {
  }

  onUndo() {
    if (this.isUndoable()) {
      this.undo.emit(1);
    }
  }

  onRedo() {
    if (this.isRedoable()) {
      this.redo.emit(1);
    }
  }

  isUndoable(nStates: number = 1) {
    return this.past.length >= nStates;
  }

  isRedoable(nStates: number = 1) {
    return this.future.length >= nStates;
  }

  get present() {
    return this.gameStates.action;
  }

  get past() {
    return this.gameStates.past;
  }

  get localizedPast() {
    return this.localize(this.firstPast);
  }
  get localizedFuture() {
    return this.localize(this.firstFuture);
  }
  get localizedPresent() {
    return this.localize(this.present);
  }

  get future() {
    return this.gameStates.future;
  }

  get firstPast() {
    return this.gameStates.past[this.gameStates.past.length - 1];
  }

  get firstFuture() {
    return this.gameStates.future[0];
  }

  localize(a: GameStateAction) {
    return new LocalizedHistoryActionBuilder(this.matchState, a).build();
  }

}
