import {
  Input,
  Component,
  EventEmitter,
  Output
} from '@angular/core';
import {
  keyframes,
  style,
  animate,
  trigger,
  transition,
  state
} from '@angular/animations';

import { Match, Team } from 'app/models';
import { LicensedPlayer } from '../../../../../shared/interfaces/models/player';

export const swiperight = [
  style({ opacity: 1 }),
  style({ transform: 'translate3d(100%, 0, 0)' }),
]

export const swipeleft = [
  style({ opacity: 1 }),
  style({ transform: 'translate3d(-100%, 0, 0)' }),
]

export const appear = [
  style({ opacity: 1 }),
  style({ opacity: 0 })
]

export const disappear = [
  style({ transform: 'scale(1)', opacity: 1 }),
  style({ transform: 'scale(0.75)', opacity: 0 })
]

@Component({
  selector: 'sams-license',
  template: `
    <mat-card>
      <div class="main-content"
          [@cardAnimator]="animationState"
          (@cardAnimator.done)="resetAnimationState()"
          (swipeleft)='onSwipeLeft()'
          (window:keydown.ArrowLeft)="onSwipeRight()"
          (window:keydown.ArrowRight)="onSwipeLeft()"
          (swiperight)='onSwipeRight()'>
        <div (click)="toggleFlip()">

          <mat-icon *ngIf="!flipped" class="flip-icon">flip_to_front</mat-icon>
          <mat-icon *ngIf="flipped" class="flip-icon">flip_to_back</mat-icon>

          <div class="approved-stamp stamp" [@approved]="approvedState">{{'app.approved' | translate}}</div>
          <div class="denied-stamp stamp" [@denied]="deniedState">{{'app.denied' | translate}}</div>

          <div *ngIf="player.license?.approved && (!approvedView || !flipped)" class="approved-stamp stamp" >{{'app.approved' | translate}}</div>
          <div *ngIf="player.license?.approved === false && (!approvedView || !flipped)" class="denied-stamp stamp" >{{'app.denied' | translate}}</div>
        </div>

        <button [disabled]="index === 0" class="arrow-button" mat-icon-button (click)="onSwipeRight()">
          <mat-icon class="arrow-icon">keyboard_arrow_left</mat-icon>
        </button>

        <mat-card-content (click)="toggleFlip()" [@flipState]="flip" class="license-card">

          <div *ngIf="players.length !== 0" class="license-card">
            <sams-license-frontside
              *ngFor="let p of players"
              [hidden]="flipped || (p.uuid !== player.uuid)"
              class="license-card-side license-card-front-side"
              [player]="p"
              [match]="match">
            </sams-license-frontside>

            <sams-license-backside
              *ngFor="let p of players"
              [hidden]="!flipped || (p.uuid !== player.uuid)"
              class="license-card-side license-card-back-side"
              [player]="p"
              [match]="match"
              [team]="team">
            </sams-license-backside>
          </div>
        </mat-card-content>

        <button [disabled]="index === players.length - 1" class="arrow-button" mat-icon-button (click)="onSwipeLeft()">
          <mat-icon class="arrow-icon">keyboard_arrow_right</mat-icon>
        </button>
      </div>

      <mat-card-actions>
          <button mat-raised-button color="primary" (click)="onCancel()">{{'app.close' | translate}}</button>
          <div class="center">
            <button [disabled]="player.license?.approved === false" mat-raised-button color="warn" (click)="onDeny()">{{'app.deny' | translate}}</button>
            <button [disabled]="player.license?.approved === true" mat-raised-button class="positive" (click)="onApprove()">{{'app.approve' | translate}}</button>
          </div>
      </mat-card-actions>

    </mat-card>

  `,
  styles: [`

    .mat-card-content {
      background-color: whitesmoke;
      border: 1px solid white;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
      margin: 0 0 20px 20px;
    }

    .main-content {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mat-card-actions {
      display: flex;
      justify-content: space-between;
    }

    .center {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 200px;
    }

    @keyframes pulse {
      0% {
        transform: scale(2);
      }
      100% {
        transform: scale(1);
      }
    }

    .arrow-icon {
      vertical-align: middle;
      font-size: 45px;
      font-weight: bold;
    }

    .flip-icon {
      cursor: pointer;
      position: absolute;
      top: 35px;
      right: 80px;
      font-size: 30px;
      color: rgba(0,0,0,0.5);
      z-index: 10;
    }

    .stamp {
      cursor: pointer;
      font-size: 30px;
      font-style: italic;
      font-weight: 700;
      position: absolute;
      top: 100px;
      left: 450px;
      z-index: 10;
      padding: 5px;
    }

    .denied-stamp {
      color: tomato;
      border: 5px solid tomato;
    }

    .approved-stamp {
      color: lightgreen;
      border: 5px solid lightgreen;
    }

    .license-card {
      height: 400px;
      width: 600px;
      transition: transform 0.2s ease-out;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mat-card-content {
      cursor: pointer;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .license-card {
      -webkit-transform-style: preserve-3d;
              transform-style: preserve-3d;
      -webkit-transform         : transform 1s;
          -ms-transform         : transform 1s;
              transform         : transform 1s;
    }

    .license-card-side {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      text-align: center;
    }

    .license-card-front-side {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      backgroundcard-: green;
      -webkit-transform : rotateY(0deg);
      -ms-transform     : rotateY(0deg);
      transform         : rotateY(0deg);
    }

    .license-card-back-side {
      height: 90%;
      font-size: 12px;
      margin: 15px;
      display: flex;
      text-align: left;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      width: 100%;
      -webkit-transform : rotateY(-180deg);
      -ms-transform     : rotateY(-180deg);
      transform         : rotateY(-180deg);
    }
  `],
  animations: [
    trigger('cardAnimator', [
      transition('* => swiperight', animate(250, keyframes(swiperight))),
      transition('* => swipeleft', animate(250, keyframes(swipeleft))),
      transition('* => disappear', animate('250ms ease-out', keyframes(disappear))),
      transition('* => appear', animate('250ms ease-out', keyframes(appear)))
    ]),
    trigger('flipState', [
      state('active', style({
        transform: 'rotateY(179deg)'
      })),
      state('inactive', style({
        transform: 'rotateY(0)'
      })),
      transition('active => inactive', animate('300ms ease-out')),
      transition('inactive => active', animate('200ms ease-in'))
    ]),
    trigger('approved', [
      state('visible', style({
        opacity: 1,
        transform: 'scale(1)'
      })),
      state('void', style({
        opacity: 0,
        transform: 'scale(0.8)',
        animation: 'pulse 1s ease-in-out 1'
      }))
    ]),
    trigger('denied', [
      state('visible', style({
        opacity: 1,
        transform: 'scale(1)'
      })),
      state('void', style({
        opacity: 0,
        transform: 'scale(0.8)',
        animation: 'pulse 1s ease-in-out 1'
      }))
    ])
  ]

})
export class LicenseComponent {

  @Input() approvedView: boolean;

  @Input() players: LicensedPlayer[];

  @Input() match: Match;

  @Input() team: Team;

  @Output() finished = new EventEmitter<boolean>();

  @Output() approve = new EventEmitter<LicensedPlayer>();

  @Output() deny = new EventEmitter<LicensedPlayer>();

  index = 0;

  flip: string = 'inactive';

  approvedState: string = 'void';

  deniedState: string = 'void';

  animationState: string;

  get player() {
    return this.players[this.index]
  }

  get flipped() {
    return this.flip === 'active'
  }

  get license() {
    return this.player.license
  }

  onDeny() {
    if (this.approvedView) {
      this.approvedState = 'void'
      this.deny.emit(this.player);
      return
    }
    this.deniedState = 'visible';
    this.animationState = 'disappear';
    this.deny.emit(this.player);
    if (this.index === this.players.length -1) {
      this.index--
    }
    const self = this
    setTimeout(() => {
      self.deniedState = 'void';
    }, 100);
  }

  onApprove() {
    if (this.approvedView) {
      this.deniedState = 'void'
      this.approve.emit(this.player);
      return
    }
    this.approvedState = 'visible';
    this.animationState = 'appear';
    this.approve.emit(this.player);
    if (this.index === this.players.length -1) {
      this.index--
    }
    const self = this
    setTimeout(() => {
      self.approvedState = 'void';
    }, 100);
  }

  onCancel() {
    this.finished.emit(true)
  }

  onSwipeLeft() {
    this.nextPass()
  }

  onSwipeRight() {
    this.previousPass()
  }

  toggleFlip() {
    this.flip = (this.flip === 'inactive') ? 'active' : 'inactive';
  }

  nextPass() {
    if (this.index === this.players.length - 1) {
      return
    }
    this.animationState = 'swipeleft'
    this.index++
  }

  previousPass() {
    if (this.index === 0) {
      return
    }
    this.animationState = 'swiperight'
    this.index--
  }

  resetAnimationState() {
    this.animationState = '';
  }

}
