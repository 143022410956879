import { Pipe, PipeTransform } from '@angular/core';
import { SetScore } from '../../../../shared/reducers/game-state/match-sets/set-score/set-score.reducer';
import { TeamCode, TeamCodes } from 'app/models';

@Pipe({ name: 'setScore' })
export class SetScorePipe implements PipeTransform {
  constructor() { }

  transform(setScore: SetScore, teamCode: TeamCode): string {
    let otherTeamCode: TeamCode;
    
    if (teamCode === TeamCodes.team1) {
      otherTeamCode = TeamCodes.team2;
    } else {
      otherTeamCode = TeamCodes.team1;
    }

    return setScore[teamCode] + ':' + setScore[otherTeamCode];
  }
}
