import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TimeKeeping } from '../../../../shared/reducers/game-state/match-sets/time-keeping/time-keeping.reducer';
import { ScoreConfiguration } from '../../../../shared/interfaces/models/score-configuration';
import { MatchSet } from '../../../../shared/reducers/game-state/match-sets/match-set.reducer';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sams-countdown-display',
  template: `
    <div class="timeout-countdown">
      <sams-countdown
        [title]="translate.get('app.timeout')"
        [readMode]="readMode"
        [startTimeMs]="timeKeeping.timeoutStartTimeMs"
        [durationMs]="timeoutDurationMs"
        (cancel)="onCancelTimeout()">
      </sams-countdown>
      <sams-countdown
        [title]="translate.get('app.technical_timeout')"
        [readMode]="readMode"
        [startTimeMs]="timeKeeping.technicalTimeoutStartTimeMs"
        [durationMs]="technicalTimeoutDurationMs"
        (cancel)="onCancelTechnicalTimeout()">
      </sams-countdown>
      <sams-countdown
        *ngIf="!setPauseCancelled && !hasSetStarted && !isMatchFinished"
        [title]="translate.get('app.set_pause')"
        [readMode]="readMode"
        [startTimeMs]="currentSetPauseStart"
        [durationMs]="currentSetPauseDurationMs"
        (cancel)="onCancelSetPause()">
      </sams-countdown>
    </div>
  `,
  styles: [`
    .timeout-countdown {
      text-align: center;
      width: 100%;
      font-weight: bold;
      color: #424242;
    }
  `]
})
export class CountdownDisplayComponent implements OnInit {

  @Input() readMode = false;

  @Input() matchId: string;

  @Input() timeKeeping: TimeKeeping;

  @Input() scoreConfiguration: ScoreConfiguration;

  @Input() hasSetStarted: boolean;

  @Input() isMatchFinished: boolean;

  @Input() currentMatchSet: MatchSet;

  @Input() currentSetPauseStart: number;

  @Output() cancelSetPause = new EventEmitter<void>();

  @Output() cancelTimeout = new EventEmitter<void>();

  @Output() cancelTechnicalTimeout = new EventEmitter<void>();

  constructor(
    public translate: TranslateService
    ) {
  }

  ngOnInit() {

  }

  onCancelSetPause() {
    this.cancelSetPause.emit()
  }

  onCancelTimeout() {
    this.cancelTimeout.emit()
  }

  onCancelTechnicalTimeout() {
    this.cancelTechnicalTimeout.emit()
  }

  get setPauseCancelled() {
    return this.currentMatchSet.timeKeeping.setPauseCancelled;
  }

  get currentSetPauseDurationMs() {
    return this.currentMatchSet.timeKeeping.setPauseDurationMs;
  }

  get technicalTimeoutDurationMs() {
    return this.scoreConfiguration.technicalTimeoutDurationSeconds * 1000;
  }

  get timeoutDurationMs() {
    return this.scoreConfiguration.timeoutDurationSeconds * 1000;
  }

}
