import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface CommentsDialogData {
  comments: string
}

@Component({
  selector: 'sams-comments-dialog',
  template: `
    <div class="comments">  
      <div class="flex-item">
        <h1 mat-dialog-title>{{'app.comments' | translate}}</h1>
      </div>
      <mat-form-field class="middle-item">
        <textarea #commentsTextArea matInput [(ngModel)]="comments" [placeholder]="'app.comments' | translate" [maxlength]="maxChars"></textarea>
        <mat-hint>{{commentsTextArea.value.length}} / {{maxChars}}</mat-hint>
      </mat-form-field>
      <div class="flex-item" mat-dialog-actions>
        <button mat-raised-button class="positive" (click)="onSave()">{{'app.save' | translate}}</button>
        <button mat-raised-button class="negative" (click)="onCancel()">{{'app.cancel' | translate}}</button>
      </div>
    </div>
  `,
  styles: [`
    .flex-item {
      flex: 0 0 auto; /* Forces side columns to stay same width */
    }
    .middle-item {
      flex: 1 1 auto; /* Lets middle column shrink/grow to available width */
    }
    textarea {
      resize: none;
      height: 50vh;
      width: 100%;
    }
    .comments {
      width: 60vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: space-between;
    }
  `]
})
export class CommentsDialogComponent {

  readonly maxChars = 500;

  comments: string;

  constructor(public dialogRef: MatDialogRef<CommentsDialogComponent, string>, @Inject(MAT_DIALOG_DATA) private data: CommentsDialogData) {
    this.comments = data.comments
    dialogRef.disableClose = true;
  }

  onSave() {
    this.dialogRef.close(this.comments);
  }

  onCancel() {
    this.dialogRef.close(null);
  }

}
