import { MatchAction } from '../../interfaces/interfaces.js';

export const UNDO = '[Undoable] Undo last action';
export const REDO = '[Undoable] Redo previous action';

export class UndoAction extends MatchAction {
  readonly type = UNDO;

  constructor(public payload: number, matchId: string) {
    super(matchId);
  }
}

export class RedoAction extends MatchAction {
  readonly type = REDO;

  constructor(public payload: number, matchId: string) {
    super(matchId);
  }
}

export type UndoableAction
  = UndoAction
  | RedoAction;
