import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import {
  isTeamPenalized,
  hasTeamNoBooking,
  getNumOfTeamPenalties,
  hasTeamImproperRequest,
  TeamSanctions,
  isTeamWarnedButNotPenalized
} from '../../../../../shared/reducers/game-state/sanctions/team-sanction/team-sanction.reducer';

@Component({
  selector: 'sams-team-sanction-display',
  template: `
    <span class="num-of-penalties" *ngIf="numOfTeamPenalties > 1">{{numOfTeamPenalties}}</span>
    <mat-icon *ngIf="isTeamPenalized" [matTooltip]="'component.team-sanction.is_penalized' | translate" [style.color]="'tomato'">booking</mat-icon>
    <mat-icon *ngIf="isTeamWarnedButNotPenalized" [matTooltip]="'component.team-sanction.is_warned' | translate" [style.color]="'gold'">booking</mat-icon>
    <mat-icon *ngIf="hasTeamNoBooking" [matTooltip]="'component.team-sanction.no_sanction' | translate" [style.color]="'lightgrey'">booking</mat-icon>
    <mat-icon
      [matTooltip] = "(hasTeamImproperRequest ? 'component.team-sanction.has_requested_improperly' : 'component.team-sanction.has_not_requested_improperly') | translate"
      [style.color] = "hasTeamImproperRequest ? 'black' : 'lightgrey'">
      no_sim
    </mat-icon>
  `,
  styles: [`
    .num-of-penalties {
      font-size: 20px;
      position: absolute;
      -webkit-text-stroke: 1px white;
      font-weight: bold;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamSanctionDisplayComponent {

  @Input() state: TeamSanctions;

  get isTeamPenalized() {
    return isTeamPenalized(this.state);
  }
  
  get hasTeamNoBooking() {
    return hasTeamNoBooking(this.state);
  }

  get numOfTeamPenalties() {
    return getNumOfTeamPenalties(this.state);
  }

  get hasTeamImproperRequest() {
    return hasTeamImproperRequest(this.state);
  }

  get isTeamWarnedButNotPenalized() {
    return isTeamWarnedButNotPenalized(this.state);
  }

}
