import {
  LineupsAction,
  SUBSTITUTION,
  NOMINATION,
  SubstitutionAction,
  NominationAction,
  LiberoInAction,
  LiberoOutAction,
  LIBERO_OUT,
  LIBERO_IN,
  LIBERO_SWITCH,
  EXCEPTIONAL_SUBSTITUTION,
  DenominationAction,
  DENOMINATION,
  ROTATE_CLOCKWISE,
  ROTATE_ANTICLOCKWISE
} from './lineups.action.js';
import { SCORE, ScoreAction, PENALTY_SCORE, PenaltyScoreAction } from '../set-score/set-score.action.js';
import { ByTeamCode, TeamCodes } from '../../../../interfaces/models/team-codes.js';
import { SetScore } from '../set-score/set-score.reducer.js';
import { ByTeamSide } from '../../../../interfaces/models/team-sides.js';
import { Player } from '../../../../interfaces/models/player.js';
import { ScoreConfiguration } from '../../../../interfaces/models/score-configuration.js';
import { Streak } from '../set-score/streak.reducer.js';
import { Serving } from '../serving/serving.reducer.js';

export type Lineups = ByTeamCode<Lineup<string>>;
export type StateLineup = Lineup<string>

// we could generalize these, but then we would lose the semantics of playerIn and playerOut
export interface SubstitutedInFor {
  [playerInId: string]: { playerOutId: string, setScore: SetScore };
}

export interface SubstitutedOutFor {
  [playerOutId: string]: { playerInId: string, setScore: SetScore };
}

export type ServingEndScores = number[];

export interface ServingEndScoresByPlayerIds {
  [playerId: string]: ServingEndScores;
}

export interface Lineup<T> {
  current: T[];
  starting: string[];
  substitutedInFor: SubstitutedInFor;
  substitutedOutFor: SubstitutedOutFor;
  servingEndScoresByPlayerIds: ServingEndScoresByPlayerIds;
  liberoOnField: T;
  liberoSubstitute: T;
  liberoRotatedOut: T;
  rotated: boolean;
  hasSubstitutedPlayerBeforeScore: boolean;
  setScoreWhenLiberoChanged: SetScore;
  numOfSubstitutions: number;
  numOfExceptionalSubstitutions: number;
  /** only available in views via selector getLineupsByTeamSides */
  liberos?: T[];
}

export interface AdditionalLineupsState {
  streak: Streak;
  currentSetScore: SetScore;
  serving: Serving;
  newServing: Serving;
  isSetFinishedAfterScore: boolean;
  newSetScore: SetScore;
  scoreConfig: ScoreConfiguration
  wasTeamTimeoutTakenBeforeScore: boolean
}

const LIBERO_OUT_IDX = 3;
const SERVING_POSITION = 0;

const getInitialLineup = (scoreConfig: ScoreConfiguration): Lineup<string> => {

  let initial: string[]
  const totalPlayersOnField = scoreConfig ? scoreConfig.totalPlayersOnField : 6
  
  if (totalPlayersOnField === 4) {
    initial = [null, null, null, null, undefined, undefined]
  } else {
    initial = Array.from({
      length: totalPlayersOnField < 4 ? totalPlayersOnField : 6
    }, (v, i) => null)
  }

  return {
    starting: initial,
    current: initial,
    substitutedInFor: {},
    substitutedOutFor: {},
    servingEndScoresByPlayerIds: {},
    liberoOnField: null,
    liberoSubstitute: null,
    liberoRotatedOut: null,
    rotated: false,
    setScoreWhenLiberoChanged: null,
    numOfSubstitutions: 0,
    numOfExceptionalSubstitutions: 0,
    hasSubstitutedPlayerBeforeScore: false
  };
};

export const getInitialState = (scoreConfig: ScoreConfiguration): Lineups => ({
  team1: getInitialLineup(scoreConfig),
  team2: getInitialLineup(scoreConfig)
});

export interface RotationState<T> {
  rotated: boolean
  current: T[]
}

export const rotate = <T>(state: RotationState<T>, totalPlayerOnField: number, antiClockwise = false): RotationState<T> => {
  const current = state.current.slice(0, totalPlayerOnField);
  const tail = state.current.slice(totalPlayerOnField)
  const rotated = antiClockwise ? [current[current.length -1], ...current.slice(0, -1), ...tail] : [...current.slice(1), current[0], ...tail]

  return {
    rotated: true,
    current: rotated
  };
};

const removePlayer = (playerIds: string[], playerOutIndex: number) => {
  return replacePlayer(playerIds, null, playerOutIndex);
};

const replacePlayer = (playerIds: string[], playerInId: string, playerOutIndex: number) => {
  return [
    ...playerIds.slice(0, playerOutIndex),
    playerInId,
    ...playerIds.slice(playerOutIndex + 1)
  ];
};

const substitute = (state: Lineup<string>, action: SubstitutionAction, setScore: SetScore, wasTeamTimeoutTakenBeforeScore: boolean): Lineup<string> => {

  const {
    playerInId,
    playerOutId,
    playerOutIndex
  } = action.payload;

  return {
    ...state,
    liberoSubstitute: state.liberoSubstitute === playerOutId ? null : state.liberoSubstitute, // this happens if the libero substitute is injured
    liberoOnField: state.liberoSubstitute === playerOutId ? null : state.liberoOnField, // this happens if the libero substitute is injured
    setScoreWhenLiberoChanged: state.liberoSubstitute === playerOutId ? setScore : state.setScoreWhenLiberoChanged,
    numOfSubstitutions: state.numOfSubstitutions + 1,
    substitutedInFor: {
      ...state.substitutedInFor,
      [playerInId]: { playerOutId, setScore }
    },
    substitutedOutFor: {
      ...state.substitutedOutFor,
      [playerOutId]: { playerInId, setScore }
    },
    current: replacePlayer(state.current, playerInId, playerOutIndex),
    hasSubstitutedPlayerBeforeScore: wasTeamTimeoutTakenBeforeScore ? false : true
  }
};

const denominate = (state: Lineup<string>, action: DenominationAction): Lineup<string> => {
  return {
    ...state,
    starting: removePlayer(state.current, action.payload.playerIndex),
    current: removePlayer(state.current, action.payload.playerIndex)
  }
};

const nominate = (state: Lineup<string>, action: NominationAction): Lineup<string> => {

  const { playerInId, playerOutIndex } = action.payload;

  return {
    ...state,
    starting: replacePlayer(state.current, playerInId, playerOutIndex),
    current: replacePlayer(state.current, playerInId, playerOutIndex)
  }
};

const liberoOut = (state: Lineup<string>, action: LiberoOutAction, setScore: SetScore): Lineup<string> => {
  return {
    ...state,
    liberoSubstitute: <string>null,
    liberoOnField: <string>null,
    setScoreWhenLiberoChanged: setScore
  };
};

const liberoIn = (state: Lineup<string>, action: LiberoInAction, setScore: SetScore): Lineup<string> => {
  const { liberoId, liberoSubstitute } = action.payload;
  return {
    ...state,
    liberoOnField: liberoId,
    liberoSubstitute,
    setScoreWhenLiberoChanged: setScore
  };
};

const servingEndScores = (state: ServingEndScores = [], score: number): ServingEndScores => {
  return [
    ...state,
    score
  ];
};

export const setServingEndScoresByPlayerIds = (
  state: ServingEndScoresByPlayerIds,
  servingPlayer: string,
  score: number): ServingEndScoresByPlayerIds => {
  return {
    ...state,
    [servingPlayer]: servingEndScores(state[servingPlayer], score)
  };
};

const rotateLiberoOut = (lineup: Lineup<string>, action: LineupsAction | ScoreAction | PenaltyScoreAction, newSetScore: SetScore): Lineup<string> => {
  return {
    ...lineup,
    liberoRotatedOut: lineup.liberoOnField,
    liberoSubstitute: null,
    liberoOnField: null,
    setScoreWhenLiberoChanged: action.type !== PENALTY_SCORE ? newSetScore : lineup.setScoreWhenLiberoChanged
  }
}

export const lineupsReducer = (
  state: Lineups,
  action: LineupsAction | ScoreAction | PenaltyScoreAction,
  additionalLineupsState: AdditionalLineupsState): Lineups => {

  if (!state) {
    state = getInitialState(additionalLineupsState.scoreConfig)
  }

  const { scoreConfig } = additionalLineupsState;
  const portugueseRule = scoreConfig ? scoreConfig.portugueseRule : 0
  const totalPlayersOnField = scoreConfig ? scoreConfig.totalPlayersOnField : 6 

  switch (action.type) {

    case SCORE:
    case PENALTY_SCORE: {
      const {
        serving,
        newServing,
        currentSetScore,
        isSetFinishedAfterScore,
        newSetScore
      } = additionalLineupsState;

      const teamThatScored = action.payload;

      const otherTeam = teamThatScored === TeamCodes.team1 ? TeamCodes.team2 : TeamCodes.team1;
      const lineup: Lineup<string> = { ...state[teamThatScored], hasSubstitutedPlayerBeforeScore: false };
      const otherLineup: Lineup<string> = { ...state[otherTeam], hasSubstitutedPlayerBeforeScore: false };
      const rotatedLineup = { ...lineup, ...rotate(lineup, totalPlayersOnField) }

      const shouldPortugalRotate =  !lineup.rotated && (newServing.currentServings % portugueseRule === 0)

      if (teamThatScored === serving.currentlyServing) {

        if (shouldPortugalRotate) {

          const servingPlayer = isSetFinishedAfterScore ? rotatedLineup.current[SERVING_POSITION] : lineup.current[SERVING_POSITION];
          const newLineup = isSetFinishedAfterScore ? lineup : rotatedLineup;
          const isLiberoRotatingOut = newLineup.current[LIBERO_OUT_IDX] === lineup.liberoSubstitute && lineup.liberoOnField

          const servicedLineup: Lineup<string> = {
            ...newLineup,
            servingEndScoresByPlayerIds:
              setServingEndScoresByPlayerIds(
                lineup.servingEndScoresByPlayerIds,
                servingPlayer,
                newSetScore[teamThatScored])
          }

          const rotatedLiberoLineup: Lineup<string> = isLiberoRotatingOut
            ? rotateLiberoOut(servicedLineup, action, newSetScore)
            : { ...servicedLineup, liberoRotatedOut: null }

          return {
            ...state,
            [teamThatScored]: rotatedLiberoLineup,
            [otherTeam]: {
              ...otherLineup,
              rotated: false
            }
          };

        }

        const servingPlayer = (isSetFinishedAfterScore && serving.currentlyServing === otherTeam) ? rotatedLineup.current[SERVING_POSITION] : lineup.current[SERVING_POSITION];

        return {
          ...state,
          [teamThatScored]: {
            ...lineup,
            rotated: false,
            liberoRotatedOut: null,
            servingEndScoresByPlayerIds: isSetFinishedAfterScore
              ? setServingEndScoresByPlayerIds(lineup.servingEndScoresByPlayerIds, servingPlayer, newSetScore[teamThatScored])
              : lineup.servingEndScoresByPlayerIds
          },
          [otherTeam]: {
            ...otherLineup,
            rotated: false,
            liberoRotatedOut: null
          }
        };

      } else {

        // see https://gitlab.sams-server.de/score/score/issues/441
        const servingPlayer = (isSetFinishedAfterScore && serving.currentlyServing === otherTeam) ? rotatedLineup.current[SERVING_POSITION] : lineup.current[SERVING_POSITION];

        const newLineup = isSetFinishedAfterScore ? lineup : rotatedLineup;
        const isLiberoRotatingOut = newLineup.current[LIBERO_OUT_IDX] === lineup.liberoSubstitute && lineup.liberoOnField

        const servicedLineup: Lineup<string> = {
          ...newLineup,
          servingEndScoresByPlayerIds: isSetFinishedAfterScore
            ? setServingEndScoresByPlayerIds(newLineup.servingEndScoresByPlayerIds, servingPlayer, newSetScore[teamThatScored])
            : lineup.servingEndScoresByPlayerIds
        }

        const rotatedLiberoLineup: Lineup<string> = isLiberoRotatingOut
          ? rotateLiberoOut(servicedLineup, action, newSetScore)
          : { ...servicedLineup, liberoRotatedOut: null }

        const otherServingPlayer = otherLineup.current[SERVING_POSITION];

        return {
          ...state,
          [teamThatScored]: rotatedLiberoLineup,
          [otherTeam]: {
            ...otherLineup,
            rotated: false,
            servingEndScoresByPlayerIds: setServingEndScoresByPlayerIds(otherLineup.servingEndScoresByPlayerIds, otherServingPlayer, currentSetScore[otherTeam])
          }
        };

      }

    }

    case SUBSTITUTION: {
      const teamCode = action.payload.teamCode;
      const currentSetScore = additionalLineupsState.currentSetScore;
      const lineup = state[teamCode];

      return {
        ...state,
        [teamCode]: substitute(lineup, action, currentSetScore, additionalLineupsState.wasTeamTimeoutTakenBeforeScore)
      };
    }

    case EXCEPTIONAL_SUBSTITUTION: {
      const { teamCode, playerInId, playerOutIndex } = action.payload;
      const lineup = state[teamCode];

      return {
        ...state,
        [teamCode]: {
          ...lineup,
          numOfExceptionalSubstitutions: lineup.numOfExceptionalSubstitutions + 1,
          current: replacePlayer(lineup.current, playerInId, playerOutIndex)
        }
      };
    }

    case LIBERO_OUT: {
      const { teamCode } = action.payload;
      const lineup = state[teamCode];
      const currentSetScore = additionalLineupsState.currentSetScore;

      return {
        ...state,
        [teamCode]: liberoOut(lineup, action, currentSetScore)
      };
    }

    case LIBERO_SWITCH: {
      const { teamCode, liberoInId } = action.payload;
      const lineup = state[teamCode];
      const currentSetScore = additionalLineupsState.currentSetScore;

      return {
        ...state,
        [teamCode]: {
          ...lineup,
          liberoOnField: liberoInId,
          setScoreWhenLiberoChanged: currentSetScore
        }
      };
    }

    case LIBERO_IN: {
      const teamCode = action.payload.teamCode;
      const lineup = state[teamCode];
      const currentSetScore = additionalLineupsState.currentSetScore;

      return {
        ...state,
        [teamCode]: liberoIn(lineup, action, currentSetScore)
      };
    }

    case DENOMINATION: {
      const teamCode = action.payload.teamCode;
      const lineup = state[teamCode];

      return {
        ...state,
        [teamCode]: denominate(lineup, action)
      };
    }

    case NOMINATION: {
      const teamCode = action.payload.teamCode;
      const lineup = state[teamCode];

      return {
        ...state,
        [teamCode]: nominate(lineup, action)
      };
    }

    case ROTATE_CLOCKWISE: {
      const teamCode = action.payload;
      const lineup = state[teamCode];
      const rotated = rotate(lineup, totalPlayersOnField).current
      
      return {
        ...state,
        [teamCode]: {
          ...lineup,
          starting: rotated,
          current: rotated
        }
      };
    }

    case ROTATE_ANTICLOCKWISE: {
      const teamCode = action.payload;
      const lineup = state[teamCode];

      const rotated = rotate(lineup, totalPlayersOnField, true).current

      return {
        ...state,
        [teamCode]: {
          ...lineup,
          starting: rotated,
          current: rotated
        }
      };
    }

    default: {
      return state;
    }

  }

};

// TODO: find better place for this
export const ROMAN_NUMBERS = ['I', 'II', 'III', 'IV', 'V', 'VI'];
export const SERVING_INDEX = 0;
export const BACKROW_INDICES = [4, 5, 0];
// ==== Selectors ====
export const getCurrentLineup = <T>(state: Lineup<T>) => state.current;
export const getStartingLineup = <T>(state: Lineup<T>) => state.starting;
export const getSubstitutedInFor = <T>(state: Lineup<T>) => state.substitutedInFor;
export const getSubstitutedOutFor = <T>(state: Lineup<T>) => state.substitutedOutFor;
export const getNumOfSubstitutions = <T>(state: Lineup<T>) => state.numOfSubstitutions;
export const getNumOfExceptionalSubstitutions = <T>(state: Lineup<T>) => state.numOfExceptionalSubstitutions;
export const getLiberoOnField = <T>(state: Lineup<T>) => state.liberoOnField;
export const getIsLiberoOnField = <T>(state: Lineup<T>) => state.liberoOnField !== null;
export const getLiberoRotatedOut = <T>(state: Lineup<T>) => state.liberoRotatedOut;
export const getLiberoSubstitute = <T>(state: Lineup<T>) => state.liberoSubstitute
export const getRotated = <T>(state: Lineup<T>) => state.rotated;
export const getServingEndScoresByPlayerIds = <T>(state: Lineup<T>) => state.servingEndScoresByPlayerIds;
export const getSetScoreWhenLiberoChanged = <T>(state: Lineup<T>) => state.setScoreWhenLiberoChanged;

/** Checks if the player was on the field !!ATTENTION does not work for liberos!! */
export const wasPlayerOnField = (state: Lineup<string>, playerUuid: string) =>
  state.starting.indexOf(playerUuid) >= 0
  || Object.keys(state.substitutedInFor).indexOf(playerUuid) >= 0;

export const getPlayerIndex = (state: Lineup<Player>, player: Player) =>
  player === state.liberoOnField
    ? state.current.indexOf(state.liberoSubstitute) 
    : state.current.indexOf(player);

export type FieldPositions = number[];

export interface FieldConfig {
  totalPlayersOnField: 2 | 3 | 4 | 6
}

export const getPositionsByTeamSide = (config: FieldConfig): ByTeamSide<FieldPositions> => {
  let leftTeam: number[]

  switch (config.totalPlayersOnField) {
    case 2:
      leftTeam = [2, 1]
      break;
    case 3:
      leftTeam = [3, 2, 1]
      break;
    case 4:
      leftTeam = [undefined, 4, undefined, 3, 1, 2]
      break;
    case 6:
      leftTeam = [5, 4, 6, 3, 1, 2]
      break;
  }
  return {
    leftTeam,
    rightTeam: [...leftTeam].reverse()
  }
}

export const isSmallField = (config: FieldConfig) => config.totalPlayersOnField < 4
