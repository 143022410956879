import { createSelector } from 'reselect';

import * as fromMatchesOverview from './matches-overview/matches-overview.reducer';
import * as fromConnections from './connections/connections.reducer';
import { Connections, connectionsReducer } from './connections/connections.reducer';
import {
  MatchesOverview,
  matchesOverviewReducer
} from './matches-overview/matches-overview.reducer';

import {
  ByTeamCode,
  ByTeamSide,
  TeamCode,
  TeamSide
} from '../interfaces/models';
import { TeamSides } from './game-state/match-sets/team-sides/team-sides.reducer';
import { Sanctions } from './game-state/sanctions/sanctions.reducer';

export interface ScoreMeta {
  version: string;
}

export interface Root {
  matchesOverview: MatchesOverview;
  connections: Connections;
  samsScore: ScoreMeta
}

export const sharedReducers = {
  connections: connectionsReducer,
  matchesOverview: matchesOverviewReducer
}

export const fromTeamCodesToTeamSides = <T>(state: ByTeamCode<T>, { leftTeam, rightTeam }: TeamSides): ByTeamSide<T> => ({
  leftTeam: state[leftTeam],
  rightTeam: state[rightTeam]
})


export const getVersion = (state: Root) => state.samsScore.version

// ==== Selectors ====
export const projectTeamSide = (_: Root, teamSide: TeamSide) => teamSide;
export const projectTeamCode = (_: Root, teamCode: TeamCode) => teamCode;
export const projectSetNumber = (_: Root, setNumber: number) => setNumber;

// # matches-overview
export const getMatchesOverview = (state: Root) => state.matchesOverview;
export const getMatchId = createSelector(getMatchesOverview, fromMatchesOverview.getMatchId);
export const getIsAnyMatchSelected = createSelector(getMatchesOverview, fromMatchesOverview.getIsAnyMatchSelected);
export const getMatchIds = createSelector(getMatchesOverview, fromMatchesOverview.getMatchIds);
export const getLoading = createSelector(getMatchesOverview, fromMatchesOverview.getLoading);
export const getErrMsg = createSelector(getMatchesOverview, fromMatchesOverview.getErrMsg);
// # matches-overview


// # connections
export const getConnections = (state: Root) => state.connections;
export const getConnection = createSelector(getConnections, getMatchId, fromConnections.getConnection);
export const getListening = createSelector(getConnection, fromConnections.getListening);
export const getActionHistory = createSelector(getConnection, fromConnections.getActionHistory);
export const getAuth = createSelector(getConnection, fromConnections.getAuth);
// # connections

export interface PositionsByTeamSides {
  leftTeam: number[];
  rightTeam: number[];
}

export type SetScoreByTeamSides = ByTeamSide<number>;
export type SetPointsByTeamSides = ByTeamSide<number>;
export type SanctionsByTeamSides = ByTeamSide<Sanctions>;
