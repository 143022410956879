import { MatchAction } from '../../../interfaces/interfaces.js';
import { TeamCode } from '../../../interfaces/models/team-codes.js';

export const INJURY = '[Injuries] Injury';

export interface Injury {
  teamCode: TeamCode;
  playerId: string;
}

export class InjuryAction extends MatchAction {
  readonly type = INJURY;
  constructor(public payload: Injury, matchId: string) {
    super(matchId);
  }
}

export type InjuriesAction
  = InjuryAction;
