import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SetScoreByTeamSides } from '../../../../../shared/reducers/shared.reducer';

export type ManualChangesDialogResult = SetScoreByTeamSides | null;

export interface ManualChangesDialogConfig {
  pointsToWinDecidingSet: number
  pointsToWinSet: number
  minimumPointDifference: number
}

export interface ManualChangesDialogData {
  setScore: SetScoreByTeamSides;
  leftTeamShortName: string;
  rightTeamShortName: string;
  config: ManualChangesDialogConfig;
  isDecidingSet: boolean;
}

@Component({
  selector: 'sams-manual-changes-dialog',
  template: `
  <div class="manual-changes">
      <h1 mat-dialog-title>{{'app.manual_changes' | translate}}</h1>
      <div class="flex score-inputs">
        <mat-form-field>
          <input type="number" matInput [(ngModel)]="setScore.leftTeam" placeholder="{{leftTeamShortName}}" min="0" required>
        </mat-form-field>
        :
        <mat-form-field>
          <input type="number" matInput [(ngModel)]="setScore.rightTeam" placeholder="{{rightTeamShortName}}" min="0" required>
        </mat-form-field>
      </div>
      <div class="warn" *ngIf="!isValidSetScore && isSetScoreTouched">{{'component.manual-changes-dialog.invalid_score' | translate}}</div>
      <div mat-dialog-actions>
        <button [disabled]="!isValidSetScore" class="save" mat-raised-button (click)="onSave()">{{'app.save' | translate}}</button>
        <button class="cancel" mat-raised-button (click)="onCancel()">{{'app.cancel' | translate}}</button>
      </div>
  </div>
  `,
  styles: [`
  mat-form-field {
    width: 80px;
  }
  .warn {
    color: tomato;
  }
  .save {
    background-color: lightgreen;
  }
  .cancel {
    background-color: tomato;
  }
  .flex {
    display: flex;
    align-items: baseline;
  }
  .score-inputs input {
    text-align: center;
  }
  `]
})
export class ManualChangesDialogComponent {

  setScore: SetScoreByTeamSides;

  initialSetScore: SetScoreByTeamSides;

  leftTeamShortName: string;
  
  rightTeamShortName: string;

  config: ManualChangesDialogConfig;

  isDecidingSet: boolean;

  constructor(public dialogRef: MatDialogRef<ManualChangesDialogComponent, ManualChangesDialogResult>, @Inject(MAT_DIALOG_DATA) private data: ManualChangesDialogData) {
    dialogRef.disableClose = true;
    this.setScore = data.setScore
    this.leftTeamShortName = data.leftTeamShortName
    this.rightTeamShortName = data.rightTeamShortName
    this.config = data.config
    this.isDecidingSet = data.isDecidingSet
  }

  ngOnInit() {
    this.initialSetScore = { ...this.setScore };
  }

  get isSetScoreTouched() {
    return this.setScore.leftTeam !== this.initialSetScore.leftTeam || this.setScore.rightTeam !== this.initialSetScore.rightTeam
  }

  get isValidSetScore() {
    const valid = this.isValidScore(this.setScore.leftTeam) && this.isValidScore(this.setScore.rightTeam) && this.isSetScoreTouched

    const pointsToWinSet = this.isDecidingSet ? this.config.pointsToWinDecidingSet : this.config.pointsToWinSet;
    const maxScore = Math.max(this.setScore.leftTeam, this.setScore.rightTeam)
    if (maxScore > pointsToWinSet) {
      return valid && (this.config.minimumPointDifference >= Math.abs(this.setScore.leftTeam - this.setScore.rightTeam))
    }
    return valid;
  }

  isValidScore(score: number) {
    return typeof score !== 'undefined' && score !== null && score >= 0;
  }

  onSave() {
    this.dialogRef.close(this.setScore);
  }

  onCancel() {
    this.dialogRef.close(null);
  }

}
