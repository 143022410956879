export interface Itc {
  originatingAssociation?: string;
  number?: string;
  validFrom?: string;
  validUntil?: string;
}

export interface HigherPlay {
  higherPlayOn: string;
  higherPlayIn: string;
}

export enum AgeClassType {
  SENIOR = "SENIOR",
  JUNIOR = "JUNIOR",
  NONE = "NONE"
}

export interface AgeClass {
  currentClass: number
  classType: AgeClassType
}

interface BaseLicense {
  associationName?: string;
  licenseNumber?: string;
  validFrom?: string;
  validUntil?: string;
  clubName: string;
  clubNumber: string;
  itc?: Itc;
  seasonName?: string;
  matchSeriesName?: string;
  teamName?: string;
  lastMatchAssignment?: string;
  transferEligibleToPlay?: string;
  eligibleToPlayFrom?: string;
  eligibleToPlayUntil?: string;
  releaseDate?: string;
  ageClass?: AgeClass;
  higherPlay?: HigherPlay[];
  approved?: boolean;
}

export interface License extends BaseLicense {
  licensePhoto?: string;
  licensePhotoBase64?: string;
}
