import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';

import {
  IndividualSanctions,
  possIndividualWarning,
  possIndividualPenalty,
  possIndividualExpulsion,
  possIndividualDisqualification
} from '../../../../../shared/reducers/game-state/sanctions/individual-sanction/individual-sanction.reducer';

@Component({
  selector: 'sams-team-member-sanction',
  template: `
    <button [disabled]="isWarningDisabled" (click)="warning.emit(teamMemberId)" mat-menu-item>
      <mat-icon class="yellow-card">book</mat-icon>
      <span>{{'app.warning' | translate}}</span>
    </button>

    <button [disabled]="isPenaltyDisabled" (click)="penalty.emit(teamMemberId)" mat-menu-item>
      <mat-icon class="red-card">book</mat-icon>
      <span>{{'app.penalty' | translate}}</span>
    </button>

    <button [disabled]="isExpulsionDisabled" (click)="expulsion.emit(teamMemberId)" mat-menu-item>
      <mat-icon class="yellow-card">book</mat-icon>
      <mat-icon class="red-card red-expulsion-card">book</mat-icon>
      <span>{{'app.expulsion' | translate}}</span>
    </button>

    <button [disabled]="isDisqualificationDisabled" (click)="disqualification.emit(teamMemberId)" mat-menu-item>
      <mat-icon class="yellow-card yellow-disqualification-card">book</mat-icon>
      <mat-icon class="red-card red-diqualification-card">book</mat-icon>
      <span>{{'app.disqualification' | translate}}</span>
    </button>
  `,
  styles: [`
    .yellow-card {
      color: gold!important;
    }
    .red-card {
      color: tomato!important;
    }
    .red-expulsion-card {
      margin-left: -35px!important;
    }
    .yellow-disqualification-card {
      margin-right: -10px;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamMemberSanctionComponent {

  @Input() state: IndividualSanctions;

  @Input() teamMemberId: string;

  @Input() allowedPenalties: number;

  @Output() warning = new EventEmitter<string>();

  @Output() penalty = new EventEmitter<string>();

  @Output() expulsion = new EventEmitter<string>();

  @Output() disqualification = new EventEmitter<string>();

  get isWarningDisabled() {
    return !possIndividualWarning(this.state, this.teamMemberId);
  }

  get isPenaltyDisabled() {
    return !possIndividualPenalty(this.state, this.teamMemberId, this.allowedPenalties);
  }

  get isExpulsionDisabled() {
    return !possIndividualExpulsion(this.state, this.teamMemberId);
  }

  get isDisqualificationDisabled() {
    return !possIndividualDisqualification(this.state, this.teamMemberId);
  }

}
