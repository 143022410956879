import { AUTH_FAILURE } from '../auth/auth.action';
import {
  MatchesOverviewAction,
  ADD_MATCH,
  ADD_MATCH_SUCCESS,
  ADD_MATCH_FAIL,
  REMOVE_MATCH,
  SELECT_MATCH,
  DESELECT_MATCH,
  SET_ERR_MSG
} from './matches-overview.action';

export interface MatchesOverview {
  matchIds: string[];
  matchId: string;
  loading: boolean;
  errMsg: string;
};

export const initialState: MatchesOverview = {
  matchIds: [],
  matchId: null,
  loading: false,
  errMsg: null
};

export const matchesOverviewReducer = (state = initialState, action: MatchesOverviewAction) => {

  switch (action.type) {

    case ADD_MATCH: {
      return {
        ...state,
        loading: true
      };
    }

    case ADD_MATCH_SUCCESS: {
      const matchId = action.payload.match.uuid;
      return {
        ...state,
        matchIds: [...state.matchIds, matchId],
        loading: false,
      };
    }

    case ADD_MATCH_FAIL: {
      return {
        ...state,
        loading: false,
        errMsg: action.payload.message
      };
    }

    case AUTH_FAILURE: {
      return {
        ...state,
        loading: false,
        errMsg: action.payload.message
      }
    }

    case REMOVE_MATCH: {
      return {
        ...state,
        matchIds: state.matchIds.filter(id => id !== action.payload)
      };      
    }

    case SELECT_MATCH: {
      return {
        ...state,
        matchId: action.payload
      };
    }

    case DESELECT_MATCH: {
      return {
        ...state,
        matchId: null
      };
    }

    case SET_ERR_MSG: {
      return {
        ...state,
        errMsg: action.payload
      };      
    }

    default: {
      return state;      
    }

  }

};

// Selectors
export const getMatchIds = (state: MatchesOverview) => state.matchIds;
export const getMatchId = (state: MatchesOverview) => state.matchId;
export const getLoading = (state: MatchesOverview) => state.loading;
export const getErrMsg = (state: MatchesOverview) => state.errMsg;
export const getIsAnyMatchSelected = (state: MatchesOverview) => state.matchId !== null;
