export class LocalizedHistoryAction {
  constructor(public time: string, public uuid: string, public message: LocalizedHistoryActionMessage) {

  }
}

export class LocalizedHistoryActionMessage {
  constructor(public key: string, public params: any, public primary: boolean = false) {

  }
}