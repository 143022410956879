import { MatchAction } from '../../../../interfaces/interfaces.js';
import { TeamCode } from '../../../../interfaces/models/team-codes.js';
import { SetScore } from '../../match-sets/set-score/set-score.reducer.js';

export const INDIVIDUAL_WARNING = '[Sanction] Individual Warning';
export const INDIVIDUAL_PENALTY = '[Sanction] Individual Penalty';
export const INDIVIDUAL_EXPULSION = '[Sanction] Individual Expulsion';
export const INDIVIDUAL_DISQUALIFICATION = '[Sanction] Individual Disqualification';

interface IndividualSanctionPayload {
  setScore: SetScore,
  currentSetNumber: number
  teamCode: TeamCode;
  teamMemberId: string;
}

export interface IndividualPenaltyPayload extends IndividualSanctionPayload {
  isMatchFinished: boolean;
}

export type IndividualWarningPayload = IndividualSanctionPayload;
export type IndividualExpulsionPayload = IndividualSanctionPayload
export type IndividualDisqualificationPayload = IndividualSanctionPayload;

export class IndividualWarningAction extends MatchAction {
  readonly type = INDIVIDUAL_WARNING;
  constructor(public payload: IndividualWarningPayload, matchId: string) {
    super(matchId);
  }
}

export class IndividualPenaltyAction extends MatchAction {
  readonly type = INDIVIDUAL_PENALTY;
  constructor(public payload: IndividualPenaltyPayload, matchId: string) {
    super(matchId);
  }
}

export class IndividualExpulsionAction extends MatchAction {
  readonly type = INDIVIDUAL_EXPULSION;
  constructor(public payload: IndividualExpulsionPayload, matchId: string) {
    super(matchId);
  }
}

export class IndividualDisqualificationAction extends MatchAction {
  readonly type = INDIVIDUAL_DISQUALIFICATION;
  constructor(public payload: IndividualDisqualificationPayload, matchId: string) {
    super(matchId);
  }
}

export type IndividualSanctionAction
  = IndividualWarningAction
  | IndividualPenaltyAction
  | IndividualExpulsionAction
  | IndividualDisqualificationAction;
