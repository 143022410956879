import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import {
  Lineup,
  ROMAN_NUMBERS
} from '../../../../../shared/reducers/game-state/match-sets/lineups/lineups.reducer';

import { Player, Team } from 'app/models';

export type NoLiberoOnFieldDialogResult = { selectedPlayer: Player, selectedLibero: Player };

@Component({
  selector: 'sams-no-liber-on-field-dialog',
  template: `
    <div>  
      <div class="dialog-header">
        <sams-logo [src]="team.logoImageMax400x400?.url" [alt]="team.name" [letter]="team.singleLetterName" size="80px"></sams-logo>
        <h1 mat-dialog-title>{{title | async}}</h1>
      </div>

      <h4>{{'component.no-libero-on-field-dialog.available_liberos' | translate}}</h4>
      <mat-radio-group class="radio-group" [(ngModel)]="selectedLibero">
        <mat-radio-button class="radio-button" *ngFor="let p of availableLiberos" [value]="p">
          {{p.jerseyNumber}} {{p.lastName}}
        </mat-radio-button>
      </mat-radio-group>
      
      <h4>{{'component.no-libero-on-field-dialog.available_players' | translate}}</h4>
      <mat-radio-group class="radio-group" [(ngModel)]="selectedPlayer">
        <mat-radio-button class="radio-button" *ngFor="let p of availablePlayers" [value]="p">
          <div style="display: inline-block; width: 20px">{{getLineupPosition(p)}}</div> {{p.jerseyNumber}} {{p.lastName}}
        </mat-radio-button>
      </mat-radio-group>

      <div mat-dialog-actions>
        <button mat-raised-button
          [ngStyle]="{ 'background-color': selectedPlayer && selectedLibero ? 'lightgreen' : '', 'margin-right': '30px' }"
          [disabled]="!selectedPlayer || !selectedLibero" (click)="onConfirm()">
          {{'app.confirm' | translate}}
        </button>
        <button mat-raised-button style="background-color: tomato;" (click)="onCancel()">{{'component.no-libero-on-field-dialog.no_libero_change_action' | translate}}</button>
      </div>

    </div>
  `,
  styles: [`

    .dialog-header {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    h1 {
      margin: 0;
      margin-left: 10px;
    }

    .radio-group {
      display: inline-flex;
      flex-direction: column;
    }
    
    .radio-button {
      margin: 5px;
    }
    
    .selected-value {
      margin: 15px 0;
    }
  `]
})
export class NoLiberoOnFieldDialogComponent implements OnInit {

  title: Observable<string>;

  lineup: Lineup<Player>;
  
  availableLiberos: Player[];
  
  selectedLibero: Player;

  defaultLibero: Player;

  selectedPlayer: Player;
  
  availablePlayers: Player[];

  team: Team;

  constructor(public dialogRef: MatDialogRef<NoLiberoOnFieldDialogComponent, NoLiberoOnFieldDialogResult>) {
    this.dialogRef.disableClose = true;
  }

  getLineupPosition(p: Player) {
    return ROMAN_NUMBERS[this.lineup.current.indexOf(p)];
  }

  ngOnInit() {
    this.selectedPlayer = this.lineup.current[0];

    if (this.availableLiberos.length > 0) {
      if (this.defaultLibero && this.isDefaultLiberoAvailable()) {
        this.selectedLibero = this.defaultLibero
      } else {
        this.selectedLibero = this.availableLiberos[0];
      }
    }
  }

  isDefaultLiberoAvailable() {
    return this.availableLiberos.find(p => p.uuid === this.defaultLibero.uuid)
  }

  onConfirm() {
    this.dialogRef.close({ selectedPlayer: this.selectedPlayer, selectedLibero: this.selectedLibero });
  }

  onCancel() {
    this.dialogRef.close(null);
  }

}
