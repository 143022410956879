import { RemoteAction } from '../interfaces/interfaces';
import { ScoreMeta } from './shared.reducer';

export interface IndoorScoreMeta extends ScoreMeta {
  version: string;
}

export const initialState = {
  version: '5.0.8'
};

export const scoreMetaReducer = (state = initialState as IndoorScoreMeta, action: RemoteAction): IndoorScoreMeta => {
  return state;
};

// ==== Selectors ====
export const getScoreVersion = (state: ScoreMeta) => state.version;
