
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { Injuries } from '../../../../../shared/reducers/game-state/injuries/injuries.reducer';

import { Player, TeamCode } from 'app/models';

@Component({
  selector: 'sams-injury-display',
  template: `
    <mat-icon *ngIf="isInjured" style="color: #139039;">add_box</mat-icon>
  `,
  styles: [`
    mat-icon {
      background-color: #e8e8e8;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InjuryDisplayComponent {

  @Input() playerId: string;

  @Input() teamCode: TeamCode;

  @Input() injuries: Injuries<Player>

  get isInjured() {
    return this.injuries.find(p => p.uuid === this.playerId);
  }

}
