import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import {
  EditNumOfSpectatorsAction
} from '../../../../shared/reducers/match-states/match-modification.action';
import { Subject, Subscription } from 'rxjs';
import { DispatchService } from 'app/connections/dispatch.service';

@Component({
  selector: 'sams-num-of-spectators',
  template: `
    <mat-form-field class="input-container">
      <input matInput #numOfSpecsInput [value]="numOfSpectators" (change)="onNumOfSpectatorsChange($event, numOfSpecsInput)" type="number" min="0" max="1000000" [placeholder]="placeholder | translate" [required]="required">
    </mat-form-field>
  `,
  styles: [`
    .input-container {
      width: 100%;
    }
  `]
})
export class NumOfSpectatorsComponent implements OnInit, OnDestroy {

  @Input() placeholder: string;

  @Input() matchId: string;

  @Input() required = false;

  @Input() numOfSpectators: number;

  private onChange = new Subject<number>()

  private sub = new Subscription()

  constructor(public dispatchService: DispatchService) {
  }

  ngOnInit() {
    this.sub.add(
      this.onChange
        .pipe(debounceTime(100), distinctUntilChanged())
        .subscribe(numOfSpectators => {
          this.dispatchService.dispatchRemoteAction(new EditNumOfSpectatorsAction(numOfSpectators, this.matchId));
        })
    )
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  onNumOfSpectatorsChange(event: any, numOfSpecsInput: any) {
    const value = event.target.value;
    const numOfSpectators = Number.parseInt(value)
    if (numOfSpectators >= 0 && numOfSpectators <= 100000) {
      this.onChange.next(numOfSpectators)
    } else {
      numOfSpecsInput.value = this.numOfSpectators;
    }
  }

}
