import { Nationality } from './nationality';

export type LicenseLevel = 'none' | 'youth' | 'D' | 'C' | 'BK' | 'B' | 'AK' | 'A' | 'I';

export const LicenseLevels = {
  none : 'none',
  youth: 'youth',
  D    : 'D',
  C    : 'C',
  BK   : 'BK',
  B    : 'B',
  AK   : 'AK',
  A    : 'A',
  I    : 'I'
};

export type ArbitrationMemberType = 'firstReferee' | 'secondReferee' | 'challengeReferee' | 'firstLinesman' | 'secondLinesman' | 'thirdLinesman' | 'fourthLinesman' | 'observer' | 'supervisor' | 'scorer' | 'scorerAssistant';

export const ArbitrationMemberTypes = {
  firstReferee   : 'firstReferee',
  secondReferee  : 'secondReferee',
  challengeReferee: 'challengeReferee',
  firstLinesman  : 'firstLinesman',
  secondLinesman : 'secondLinesman',
  thirdLinesman  : 'thirdLinesman',
  fourthLinesman : 'fourthLinesman',
  observer       : 'observer',
  supervisor     : 'supervisor',
  scorer         : 'scorer',
  scorerAssistant: 'scorerAssistant',
};

export interface Arbitration {
  arbitrationPin: string;
  firstReferee: ArbitrationMember;
  secondReferee: ArbitrationMember;
  challengeReferee: ArbitrationMember;
  firstLinesman: ArbitrationMember;
  secondLinesman: ArbitrationMember;
  thirdLinesman: ArbitrationMember;
  fourthLinesman: ArbitrationMember;
  observer: ArbitrationMember;
  supervisor: ArbitrationMember;
  // score
  scorer?: ArbitrationMember;
  scorerAssistant?: ArbitrationMember;
}

export interface ArbitrationMember {
  personUuid: string;
  firstName: string;
  lastName: string;
  nationality: Nationality;
  // TODO: score save in state not model
  licenseLevel?: string;
  licenseNumber?: string;
  selected?: boolean;
  enteredManually?: boolean;
}
