import { Component } from '@angular/core';
import { Input } from '@angular/core';

import { LicensedPlayer } from '../../../../../shared/interfaces/models/player';
import { Match } from '../../../../../shared/interfaces/models/match';

@Component({
  selector: 'sams-license-frontside',
  template: `
    <h3>{{ player?.jerseyNumber }} {{ player?.firstName }} {{ player?.lastName }}</h3>
    <sams-license-photo
      [license]="player.license"
      [matchId]="match.uuid"
      [size]="'250px'">
    </sams-license-photo>
  `,
  styles: [`
    h3 {
      margin: 15px;
    }
 `]
})
export class LicenseFrontsideComponent {

  @Input() player: LicensedPlayer;

  @Input() match: Match;

  constructor () {
  }

}
