import { Injectable, EventEmitter } from '@angular/core';

import { Player, TeamCode } from 'app/models';

export interface Dragged {
  benchPlayer: Player,
  teamCode: TeamCode
}

@Injectable()
export class DraggableService {

  public dragged = new EventEmitter<Dragged>();

}
