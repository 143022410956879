
import {filter, first} from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { OnDestroy } from '@angular/core';

import {
  LiberoSwitch,
  LiberoOutAction,
  LiberoSwitchAction,
  LiberoOut
} from '../../../../../shared/reducers/game-state/match-sets/lineups/lineups.action';
import { TeamSquad } from '../../../../../shared/reducers/match-preparation/team-squad.reducer';
import { Lineup } from '../../../../../shared/reducers/game-state/match-sets/lineups/lineups.reducer';
import { SetScore } from '../../../../../shared/reducers/game-state/match-sets/set-score/set-score.reducer';
import { Injuries } from '../../../../../shared/reducers/game-state/injuries/injuries.reducer';
import { LiberoUnableToPlayAction, AddNewLiberoAction, SetDefaultLiberoAction, LiberoPlayedAction } from '../../../../../shared/reducers/game-state/liberos/liberos.action';
import { Liberos } from '../../../../../shared/reducers/game-state/liberos/liberos.reducer';
import { Serving } from '../../../../../shared/reducers/game-state/match-sets/serving/serving.reducer';
import { IndividualSanctions } from '../../../../../shared/reducers/game-state/sanctions/individual-sanction/individual-sanction.reducer';
import { SelectMvpAction } from '../../../../../shared/reducers/game-state/mvps/mvp.action';
import { isPlayerUnallowedToPlay, isLiberoUnallowedToPlay } from '../../../../../shared/reducers/game-state/game-state.reducer';

import * as fromIndoor from 'app/root/indoor.reducer';
import {
  SubstitutionDialogComponent,
  SubstitutionDialogData,
  SubstitutionDialogResult
} from 'app/match-view/dialogs/substitution-dialog.component';
import { TeamCode, Player, Team, TeamSide, TeamSideValues } from 'app/models';
import { DraggableService, Dragged } from 'app/match-view/draggable.service';
import { SetScorePipe } from 'app/pipes/set-score';
import { SortedBy } from 'app/pipes/sort-players';
import { Mvp } from '../../../../../shared/reducers/game-state/mvps/mvp.reducer';
import { DispatchService } from 'app/connections/dispatch.service';

@Component({
  selector: 'sams-player-list',
  template: `

  <mat-tab-group>


    <!-- BENCH -->
    <mat-tab color="primary" [label]="'app.bench' | translate">

      <div class="bench">
      
        <sams-player-list-header
          [teamSide]="teamSide"
          [titleTranslationKey]="'app.bench'"
          (sortedBy)="onBenchSortedBy($event)">
        </sams-player-list-header>

        <div *ngFor="let p of playersOnBench | sortPlayers: benchSortedBy : teamSide; let i = index;"
          class="table-row"
          [ngStyle]="{ 'flex-direction': isLeftTeam ? 'row' : 'row-reverse' }">

          <div class="menu-column">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>menu</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <sams-select-mvp [playerUuid]="p.uuid" [hidden]="isSelectMvpHidden" [disabled]="isSelectMvpDisabled" (selectMvp)="onSelectMvp(p.uuid)"></sams-select-mvp>
              <sams-individual-sanctions
                [individualSanctions]="individualSanctions"
                [teamCode]="teamCode"
                [teamMemberId]="p.uuid"
                [matchId]="matchId"
                [teamSide]="teamSide"
                [setScore]="setScore"
                [currentSetNumber]="currentSetNumber"
                [isMatchFinished]="isMatchFinished">
              </sams-individual-sanctions>
            </mat-menu>
          </div>

          <div class="icon-column">
            <sams-injury-display
              [injuries]="injuries"
              [teamCode]="teamCode"
              [playerId]="p.uuid">
            </sams-injury-display>
            <sams-team-member-sanction-display
              [teamMemberId]="p.uuid"
              [state]="individualSanctions">
            </sams-team-member-sanction-display>
            <mat-icon *ngIf="isPlayerLocked(p.uuid) || isPlayerUnallowedToPlay(p)">lock</mat-icon>
            <div *ngIf="isCaptain(p)" class="captain">C</div>
            <sams-mvp-display [player]="p" [mvp]="mvp"></sams-mvp-display>
          </div>

          <div class="player-info draggable-element"
              [ngClass] = "{ 'draggable': isDraggable(p) }"
              [draggable] = "isDraggable(p)"
              (dragstart) = "onDragStart($event, p)"
              (dragend) = "onDragEnd($event)">
            <sams-jersey-number-column [jerseyNumber]="p.jerseyNumber" [substitutionInfo]="formatSubstitutedOutFor(p.uuid)">
            </sams-jersey-number-column>
            <sams-player-name-column [player]="p"></sams-player-name-column>
          </div>

        </div>

      </div>

      <sams-liberos
        [matchId]="matchId"
        [teamCode]="teamCode"
        [teamSide]="teamSide"
        [liberos]="liberos"
        [individualSanctions]="individualSanctions"
        [lineup]="lineup"
        [currentSetNumber]="currentSetNumber"
        [injuries]="injuries"
        [setScore]="setScore"
        [hasSetStarted]="hasSetStarted"
        [isMatchFinished]="isMatchFinished"
        [isSelectMvpHidden]="isSelectMvpHidden"
        [isSelectMvpDisabled]="isSelectMvpDisabled"
        [mvp]="mvp"
        [isStartingSixLocked]="isStartingSixLocked"
        [liberoRegistrationEnabled]="liberoRegistrationEnabled"
        [teamSquad]="teamSquad"
        (liberoOut)="onLiberoOut($event)"
        (addNewLibero)="onAddNewLibero()"
        (liberoUnableToPlay)="onLiberoUnableToPlay($event)"
        (liberoPlayed)="onLiberoPlayed($event)"
        (setDefaultLibero)="onSetDefaultLibero($event)"
        (liberoSwitch)="onLiberoSwitch($event)"
        (selectMvp)="onSelectMvp($event)"
        (draggedChange)="onDragged($event)">
      </sams-liberos>
    </mat-tab>
    <!-- BENCH -->
        
    <!-- FIELD -->    
    <mat-tab [label]="'app.field' | translate">

      <div class="field">

        <sams-player-list-header
          [teamSide]="teamSide"
          [titleTranslationKey]="'app.field'"
          (sortedBy)="onFieldSortedBy($event)">
        </sams-player-list-header>

        <div *ngFor = "let p of lineup.current | sortPlayers: fieldSortedBy : teamSide; let i = index;"
          class="table-row"
          [ngStyle]="{ 'flex-direction': isLeftTeam ? 'row' : 'row-reverse' }"
          [ngClass] = "{ 'libero-sub': isLiberoSub(p) }">

          <ng-container *ngIf="p">

            <div class="menu-column">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>menu</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <sams-select-mvp [playerUuid]="p.uuid" [hidden]="isSelectMvpHidden" [disabled]="isSelectMvpDisabled" (selectMvp)="onSelectMvp(p.uuid)"></sams-select-mvp>
                <sams-injury
                  [matchId]="matchId"
                  [injuries]="injuries"
                  [teamCode]="teamCode"
                  [playerId]="p.uuid">
                </sams-injury>
                <sams-individual-sanctions
                  [individualSanctions]="individualSanctions"
                  [teamCode]="teamCode"
                  [teamMemberId]="p.uuid"
                  [matchId]="matchId"
                  [teamSide]="teamSide"
                  [setScore]="setScore"
                  [currentSetNumber]="currentSetNumber"
                  [isMatchFinished]="isMatchFinished">
                </sams-individual-sanctions>
              </mat-menu>
            </div>

            <div class="icon-column">
              <sams-injury-display
                [injuries]="injuries"
                [teamCode]="teamCode"
                [playerId]="p.uuid">
              </sams-injury-display>
              <sams-team-member-sanction-display
                [teamMemberId]="p.uuid"
                [state]="individualSanctions">
              </sams-team-member-sanction-display>
              <mat-icon *ngIf="isPlayerLocked(p.uuid) || isPlayerUnallowedToPlay(p)">lock</mat-icon>
              <div *ngIf="isCaptain(p)" class="captain">C</div>
              <sams-mvp-display [player]="p" [mvp]="mvp"></sams-mvp-display>
            </div>

          <div class="player-info draggable-element"
              [ngClass] = "{ 'draggable': isDraggable(p) }"
              [draggable] = "isDraggable(p)"
              (dragstart) = "onDragStart($event, p)"
              (dragend) = "onDragEnd($event)">
              <sams-jersey-number-column [jerseyNumber]="p.jerseyNumber" [substitutionInfo]="formatSubstitutedOutFor(p.uuid)">
              </sams-jersey-number-column>
              <sams-player-name-column [player]="p"></sams-player-name-column>
            </div>

          </ng-container>

        </div>

      </div>

      <sams-liberos
        [matchId]="matchId"
        [teamCode]="teamCode"
        [teamSide]="teamSide"
        [liberos]="liberos"
        [individualSanctions]="individualSanctions"
        [lineup]="lineup"
        [currentSetNumber]="currentSetNumber"
        [injuries]="injuries"
        [setScore]="setScore"
        [hasSetStarted]="hasSetStarted"
        [isMatchFinished]="isMatchFinished"
        [isSelectMvpHidden]="isSelectMvpHidden"
        [isSelectMvpDisabled]="isSelectMvpDisabled"
        [mvp]="mvp"
        [isStartingSixLocked]="isStartingSixLocked"
        [liberoRegistrationEnabled]="liberoRegistrationEnabled"
        [teamSquad]="teamSquad"
        (liberoOut)="onLiberoOut($event)"
        (addNewLibero)="onAddNewLibero()"
        (liberoUnableToPlay)="onLiberoUnableToPlay($event)"
        (liberoPlayed)="onLiberoPlayed($event)"
        (setDefaultLibero)="onSetDefaultLibero($event)"
        (liberoSwitch)="onLiberoSwitch($event)"
        (selectMvp)="onSelectMvp($event)"
        (draggedChange)="onDragged($event)">
      </sams-liberos>
    </mat-tab>
    <!-- FIELD -->

    <!-- OFFICIALS -->
    <mat-tab [disabled]="officials.length === 0" [label]="'app.officials' | translate">
      <div class="officials-list">

        <sams-player-list-header
          [teamSide]="teamSide"
          [titleTranslationKey]="'app.officials'"
          [sortable]="false">
        </sams-player-list-header>

        <div *ngFor="let o of officials"
          [ngStyle]="{ 'flex-direction': isLeftTeam ? 'row' : 'row-reverse' }"
          class="table-row">
          <div class="menu-column">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>menu</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <sams-individual-sanctions
                [individualSanctions]="individualSanctions"
                [teamCode]="teamCode"
                [teamMemberId]="o.uuid"
                [matchId]="matchId"
                [teamSide]="teamSide"
                [setScore]="setScore"
                [currentSetNumber]="currentSetNumber"
                [isMatchFinished]="isMatchFinished">
              </sams-individual-sanctions>
            </mat-menu>
          </div>
          <div class="icon-column">
            <sams-team-member-sanction-display
              [teamMemberId]="o.uuid"
              [state]="individualSanctions">
            </sams-team-member-sanction-display>
          </div>

          <div class="official-info">
            <div class="official-first-name flex-center-vertical">
              {{o.firstName}}
            </div>
            <div class="official-last-name flex-center-vertical">
              {{o.lastName}}
            </div>
            <div class="official-function flex-center-vertical">
              {{('app.' + o.function.toLowerCase()) | translate }}
            </div>
          </div> 

        </div>
      </div>
    </mat-tab>
    <!-- OFFICIALS -->
    
  </mat-tab-group>

  `,
  styles: [`
    /deep/ .mat-tab-label {
      min-width: 33.3% !important;
    }
    /deep/ .mat-ink-bar {
      background-color: rgb(68,109,222)!important;
      height: 5px!important;
    }

    .libero-sub {
      background-color: gold;
    }

    .captain {
      text-align: center;
      color: white;
      width: 20px;
      background-color: RoyalBlue;
    }

    .draggable-element:hover {
      background-color: tomato;
    }
    .draggable-element.draggable:hover {
      background-color: lightgreen;
    }

    .player-info {
      display: flex;
      width: 70%;
      flex-grow: 0;
    }

    .draggable-element {
      cursor: not-allowed;
    }
    .draggable-element.draggable {
      cursor: move;
    }

    .table-row {
      border-bottom: 1px solid #e0e0e0;
      border-collapse: collapse;
      display: flex;
    }

    .menu-column {
      flex-grow: 1;
      width: 10%;
    }

    .icon-column {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-grow: 1;
      flex-direction: row;
      width: 25%;
    }

    .official-info {
      text-align: center;
      width: 75%;
      justify-content: center;
      display: flex;
    }

    .official-first-name {
      width: 30%;
    }

    .official-last-name {
      width: 30%;
    }

    .official-function {
      word-wrap: break-word;
      width: 35%;
    }

    .icon-column, .menu-column {
      background-color: #e8e8e8;
    }

  `],
  changeDetection: ChangeDetectionStrategy.OnPush
  
})
export class PlayerListComponent implements OnInit, OnDestroy {

  readonly maxNumfOfSubstitutions = 6;

  @Input() matchId: string;

  @Input() teamCode: TeamCode;

  @Input() teamSide: TeamSide;

  @Input() team: Team;

  @Input() teamSquad: TeamSquad;

  @Input() lineup: Lineup<Player>;

  @Input() individualSanctions: IndividualSanctions;

  @Input() injuries: Injuries<Player>;

  @Input() setScore: SetScore;

  @Input() currentSetNumber: number;

  @Input() hasSetStarted: boolean;

  @Input() isMatchFinished: boolean;

  @Input() liberos: Liberos<Player>;

  @Input() mvp: Mvp<Player>;

  @Input() mvpSubmissionEnabled: boolean;

  @Input() isMvpSubmissionFinished: boolean;

  @Input() playersOnBench: Player[];

  @Input() serving: Serving;

  @Input() isStartingSixLocked: boolean;

  @Input() liberoRegistrationEnabled: boolean;

  @Input() wasTeamTimeoutTakenBeforeScore: boolean;

  fieldSortedBy: SortedBy = SortedBy.jerseyNumber;
  benchSortedBy: SortedBy = SortedBy.jerseyNumber;

  private subscription = new Subscription();
  
  constructor(
    public draggableService: DraggableService,
    public store: Store<fromIndoor.IndoorRoot>,
    public dispatchService: DispatchService,
    public dialog: MatDialog,
    public translate: TranslateService) {
  }

  ngOnInit() {
    this.subscription.add(
      this.store.select(root => fromIndoor.getPlayersOnBench(root, this.teamSide)).subscribe(playersOnBench => this.playersOnBench = playersOnBench)
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onLiberoOut(liberoOut: LiberoOut) {
    this.dispatchService.dispatchRemoteAction(new LiberoOutAction(liberoOut, this.matchId));
  }

  onLiberoSwitch(liberoSwitch: LiberoSwitch) {
    this.dispatchService.dispatchRemoteAction(new LiberoSwitchAction(liberoSwitch, this.matchId));
  }

  onSelectMvp(playerUuid: string) {
    this.dispatchService.dispatchRemoteAction(new SelectMvpAction({ playerUuid, teamCode: this.teamCode }, this.matchId))
  }

  get isSelectMvpHidden() {
    return !this.mvpSubmissionEnabled
  }

  get isSelectMvpDisabled() {
    return this.mvp.player != null || !this.isMatchFinished || this.isMvpSubmissionFinished
  }
  
  get liberoCandidates() {
    return this.playersOnBench.filter(p => !this.isPlayerUnallowedToPlay(p));
  }

  onAddNewLibero() {

    const data: SubstitutionDialogData = {
      team: this.team,
      title: this.translate.get('component.player-list.add_new_libero'),
      availablePlayers: this.liberoCandidates,
      isUndoable: false
    };

    const dialogRef: MatDialogRef<SubstitutionDialogComponent, SubstitutionDialogResult> =
      this.dialog.open(SubstitutionDialogComponent, { data });

    dialogRef.afterClosed().pipe(
      first(),
      filter(result => result !== null),)
      .subscribe((player: Player) => {
        this.dispatchService.dispatchRemoteAction(new AddNewLiberoAction({ playerId: player.uuid, teamCode: this.teamCode }, this.matchId));
      });

  }

  onLiberoUnableToPlay(liberoId: string) {
    this.dispatchService.dispatchRemoteAction(new LiberoUnableToPlayAction({ liberoId, teamCode: this.teamCode }, this.matchId));
  }

  onLiberoPlayed(liberoId: string) {
    this.dispatchService.dispatchRemoteAction(new LiberoPlayedAction({ liberoId, teamCode: this.teamCode }, this.matchId));
  }

  onSetDefaultLibero(liberoId: string) {
    this.dispatchService.dispatchRemoteAction(new SetDefaultLiberoAction({ liberoId, teamCode: this.teamCode }, this.matchId));
  }

  onDragged(dragged: Dragged) {
    this.draggableService.dragged.emit(dragged);
  }

  isPlayerLocked(playerId: string) {
    if (typeof this.lineup.substitutedInFor[playerId] !== 'undefined' &&
        typeof this.lineup.substitutedOutFor[playerId] !== 'undefined') {
      return true;
    }
    return false;
  }

  formatSubstitutedInFor(playerId: string) {
    const substitute = this.lineup.substitutedInFor[playerId];
    if (typeof substitute !== 'undefined') {
      return this.formatSubstitutedFor(substitute.setScore, substitute.playerOutId);
    }
  }

  formatSubstitutedOutFor(playerId: string) {
    const substitute = this.lineup.substitutedOutFor[playerId];
    if (typeof substitute !== 'undefined') {
      return this.formatSubstitutedFor(substitute.setScore, substitute.playerInId);
    }
  }

  formatSubstitutedFor(setScore: SetScore, substituteId: string) {
    const player = this.teamSquad.players.find(p => p.uuid === substituteId);
    const jerseyNumber = `(${player ? player.jerseyNumber : '?'})`;
    const formattedSetScore = new SetScorePipe().transform(setScore, this.teamCode);
    return jerseyNumber + ' ' + formattedSetScore;
  }

  isCaptain(p: Player) {
    return this.teamSquad.captain === p && p !== null;
  }

  isMvp(p: Player) {
    return this.mvp.player.uuid === p.uuid;
  }

  isLiberoSub(p: Player) {
    if (p === null) {
      return false;
    }
    return this.lineup.liberoSubstitute === p;
  }

  onDragEnd(event: DragEvent) {
    this.onDragged(null);
  }

  isDraggable(p: Player) {
    if (this.isMatchFinished
      || this.isStartingSixLocked
      || (this.lineup.hasSubstitutedPlayerBeforeScore
      && this.wasTeamTimeoutTakenBeforeScore)) return false
    return (!this.isPlayerOnField(p.uuid) || (this.lineup.liberoSubstitute === p && this.isLiberoSwitchAllowed(p))) && !this.isPlayerLocked(p.uuid) && !this.isPlayerUnallowedToPlay(p) && !this.isNumOfSubstiutionsExceeded;
  }

  isLiberoSwitchAllowed(p: Player) {
    if (this.isMatchFinished) return false
    return !this.isLiberoUnallowedToPlay(p) && this.isLiberoAllowedToChange;
  }

  isLiberoUnallowedToPlay(l: Player) {
    return isLiberoUnallowedToPlay(this.liberos, this.injuries, this.individualSanctions, this.currentSetNumber, l.uuid);
  }

  get isNumOfSubstiutionsExceeded() {
    return this.lineup.numOfSubstitutions >= this.maxNumfOfSubstitutions;
  }

  get isLiberoAllowedToChange() {
    return this.isAnyLiberoOnField && this.isScoreBetweenLiberoChange;
  }

  get isScoreBetweenLiberoChange() {
    if (this.setScoreWhenLiberoChanged === null) return true
    return this.setScore.team1 !== this.setScoreWhenLiberoChanged.team1
      || this.setScore.team2 !== this.setScoreWhenLiberoChanged.team2;
  }

  get isAnyLiberoOnField() {
    return this.lineupLiberos.find(p => this.lineup.liberoOnField === p);
  }

  get lineupLiberos() {
    return this.lineup.liberos;
  }

  get setScoreWhenLiberoChanged() {
    return this.lineup.setScoreWhenLiberoChanged;
  }

  onDragStart(event: DragEvent, benchPlayer: Player) {
    event.dataTransfer.setData('text', 'dummy');
    this.onDragged({ benchPlayer, teamCode: this.teamCode });
  }

  isPlayerOnField(playerId: string) {
    return this.lineup.current.find(p => p ? p.uuid === playerId : false);
  }

  isPlayerUnallowedToPlay(p: Player) {
    return isPlayerUnallowedToPlay(this.injuries, this.individualSanctions, this.currentSetNumber, p.uuid);
  }

  onBenchSortedBy(sortedBy: SortedBy) {
    this.benchSortedBy = sortedBy;
  }

  onFieldSortedBy(sortedBy: SortedBy) {
    this.fieldSortedBy = sortedBy;
  }

  get officials() {
    return [...this.teamSquad.officials].sort();
  }

  get isLeftTeam() {
    return this.teamSide === TeamSideValues.leftTeam;
  }

}
