import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import * as uuid from 'uuid';

import {
  TeamSquad,
  possTogglePlayer,
  possToggleCaptain,
  possToggleLibero,
  possChangeJerseyNumber
} from '../../../../shared/reducers/match-preparation/team-squad.reducer';
import {
  AddPlayer,
  AddPlayerAction,
  AddOfficial,
  AddOfficialAction,
  ChangeJerseyNumber,
  ChangeJerseyNumberAction,
  EditPlayer,
  EditPlayerAction,
  EditOfficial,
  EditOfficialAction
} from '../../../../shared/reducers/match-states/match-modification.action';

import {
  Team,
  TeamCode,
  ScoreConfiguration,
  Official,
  Player,
  ManuallyCreatedPlayer,
  AdditionalClubPlayer,
  Match
} from 'app/models';
import { TeamCodes } from '../../../../shared/interfaces/models/team-codes';
import { JERSEY_TYPE } from '../../../../shared/interfaces/models/jerseys';
import { LicenseDialogComponent, LicenseDialogData } from './license/license-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DispatchService } from 'app/connections/dispatch.service';

@Component({
  selector: 'sams-team-squad',
  template: `
    <mat-card>
      <mat-card-content>
          <div class="card-header">
            <div class="team-info">
              <img *ngIf="team.logoImageMax400x400?.url" src="{{team.logoImageMax400x400?.url}}" alt="{{team.name}}" height="62" width="auto">
              <h1>
                {{team.name}}
                ({{selected.length}}/{{maxNumOfPlayers}})
              </h1>
            </div>

            <div *ngIf="jerseyColorSelectionEnabled" class="jerseys-container">
              <h2>{{('component.team-squad.' + selectedJerseyName) | translate}}</h2>
              <div class="jerseys">
                <mat-icon *ngIf="jerseySelectionActive" (click)="onPrevJersey()">navigate_before</mat-icon>
                <div class="dummy-space" *ngIf="!jerseySelectionActive"></div>

                <div *ngIf="!customJersey" class="jersey-logo">
                  <div class="trikot-logo"
                    [ngClass]="{ 'trikot-white': isTrikotWhite }"
                    [style.background]="isTrikotWhite ? 'black' : selectedJersey.shirtColorHex">
                  </div>

                  <div class="shorts-logo"
                    [ngClass]="{ 'shorts-white': isShortsWhite }"
                    [style.background]="isShortsWhite ? 'black' : selectedJersey.shortsColorHex">
                  </div>
                </div>

                <div *ngIf="customJersey" class="jersey-logo">
                  <div
                    class="trikot-logo clickable"
                    [ngClass]="{ 'trikot-white': isTrikotWhite }"
                    [style.background]="isTrikotWhite ? 'black' : shirtColorHexModel"
                    [cpPosition]="teamCode === TeamCodes.team1 ? 'right' : 'left'"
                    [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true"
                    [(colorPicker)]="shirtColorHexModel"
                    (colorPickerSelect)="onShirtColorSelected()">
                  </div>

                  <div
                    class="shorts-logo clickable"
                    [ngClass]="{ 'shorts-white': isShortsWhite }"
                    [style.background]="isShortsWhite ? 'black' : shortsColorHexModel"
                    [cpPosition]="teamCode === TeamCodes.team1 ? 'right' : 'left'"
                    [cpPositionOffset]="'50%'"
                    [cpPositionRelativeToArrow]="true"
                    [(colorPicker)]="shortsColorHexModel"
                    (colorPickerSelect)="onShortsColorSelected()">
                  </div>
                </div>
                <mat-icon *ngIf="jerseySelectionActive" (click)="onNextJersey()">navigate_next</mat-icon>
              </div>
            </div>

          </div>

        <div class="action-buttons">
          <sams-pin *ngIf="!confirmed"
            placeholder="{{'component.team-squad.team_pin' | translate}}"
            buttonLabel="{{'app.confirm' | translate}}"
            [pin]="team.pin"
            [disabled]="progressBarValue < 100 || addingPlayer || loadingPlayers || addingOfficial || editingPlayer || isAnyJerseyNumberDuplicate || isAnyJerseyNumberInvalid"
            (success)="onSuccess($event)">
          </sams-pin>

          <button class="license-button" [disabled]="isLicenseButtonDisabled" mat-raised-button color="primary" (click)="openLicenseDialog()">{{'component.license-dialog.title' | translate }}</button>
        </div>
 
        <br>

        <p *ngIf="!confirmed" class="warn">{{tooltip$ | async}}</p>

        <button mat-raised-button *ngIf="!confirmed" class="reset" (click)="onReset()" [disabled]="progressBarValue === 0">
          <mat-icon>settings_backup_restore</mat-icon> {{'app.reset' | translate}}
        </button>

        <button mat-raised-button *ngIf="confirmed" class="reset" (click)="onUndoConfirm()" [disabled]="isGameStateTouched">
          <mat-icon>mode_edit</mat-icon> {{'component.team-squad.undo_confirm' | translate}}
        </button>
        
        <button mat-raised-button (click)="onCheckAll()" [disabled]="allChecked || confirmed">
          <mat-icon>playlist_add_check</mat-icon> {{'component.team-squad.select_all' | translate}}
        </button>

        <button mat-raised-button [matMenuTriggerFor]="menu">
          <mat-icon>sort</mat-icon> {{'app.sort' | translate}}
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="sortedBy = 'jerseyNumber'">
            <span>{{'app.jersey_number' | translate}}</span>
          </button>
          <button mat-menu-item (click)="sortedBy = 'lastName'">
            <span>{{'app.lastname' | translate}}</span>
          </button>
          <button mat-menu-item (click)="sortedBy = 'firstName'">
            <span>{{'app.firstname' | translate}}</span>
          </button>
        </mat-menu>

        <div class="table-container">
          
          <mat-progress-bar
              [color] = "progressBarValue > 100 ? 'primary' : 'accent'"
              [value] = "progressBarValue">
          </mat-progress-bar>
        
          <table class="player-table">

            <ng-template ngFor let-p [ngForOf]="players | sortPlayers: sortedBy">
          
              <tr [ngClass]="{ 'selected': isSelected(p), 'not-approved': !isApproved(p) }">
                <td>
                  <div *ngIf="p.portraitPhotoMax600x600?.url" class="avatar-image">
                    <img [src]="p.portraitPhotoMax600x600?.url" alt="" />
                  </div>
                  <button mat-icon-button *ngIf="isNewPlayer(p)" [disabled]="confirmed" mat-raised-button (click)="editingPlayer = p.uuid">
                    <mat-icon>edit</mat-icon>
                  </button>
                </td>

                <td>
                  <mat-form-field class="jersey-number-input-container">
                    <input
                      [value]="p.jerseyNumber"
                      (change)="onChangeJerseyNumber($event, p)"
                      [pattern]="jerseyNumberPattern"
                      type="number"
                      class="jersey-number-editing"
                      matInput
                      [disabled]="isChangeJerseyNumberDisabled(p)"
                      id="jerseyNumber"
                      name="jerseyNumber"
                      maxlength="2"
                      required>
                  </mat-form-field>
                </td>
                <td class="name">
                  {{p.lastName}}, {{p.firstName}}
                  <div *ngIf="!p.eligible" style="color: tomato; font-size: 12px;">{{'component.team-squad.not_eligible' | translate}}</div>
                  <div *ngIf="p.license?.approved === undefined" style="color: tomato; font-size: 12px;">{{'component.team-squad.not_approved' | translate}}</div>
                  <div *ngIf="isNewPlayer(p)" style="font-size: 12px;">{{'component.team-squad.manually_added' | translate}}</div>
                  <div *ngIf="p.isAdditionalClubPlayer" style="font-size: 12px;">{{'component.team-squad.additional_club_player' | translate}}</div>
                </td>

                <td>
                  <mat-checkbox [checked]="isSelected(p)" (change)="onTogglePlayer(p)" [disabled]="isTogglePlayerDisabled(p)"></mat-checkbox>
                </td>

                <td>
                  <button mat-button (click)="onToggleCaptain(p)" [disabled]="isToggleCaptainDisabled(p)">
                    <div [ngClass]="{ 'captain': isCaptain(p) }">C</div>
                  </button>
                </td>

                <td>
                  <button mat-button
                    (click)="onToggleLibero(p)"
                    *ngIf="!noLiberosAllowed"
                    [disabled]="isToggleLiberoDisabled(p)">
                    <div [ngClass]="{ 'libero': isLibero(p) }">L</div>
                  </button>
                </td>
                
            </tr>
            <tr *ngIf="editingPlayer === p.uuid">
              <td colspan="6">
                <sams-add-player [players]="players" [title]="'component.team-squad.edit_player' | translate" [player]="p" [hidden]="!editingPlayer" (submit)="onEditPlayer($event)" (cancel)="onCancelEditPlayer()"></sams-add-player>
              </td>
            </tr>
            </ng-template>
          </table>

          <mat-progress-bar
              [color] = "progressBarValue > 100 ? 'primary' : 'accent'"
              [value] = "progressBarValue">
          </mat-progress-bar>

          <button *ngIf="!loadingPlayers" [disabled]="(additionalPlayers?.length < 1) || confirmed" mat-raised-button (click)="onLoadingPlayers()">
            <mat-icon>playlist_add</mat-icon> {{'component.team-squad.load_new_player' | translate}}
          </button>

          <button *ngIf="!addingPlayer && manualPlayerSubmissionEnabled" mat-raised-button (click)="onAddingPlayer()" [disabled]="confirmed">
            <mat-icon>add</mat-icon> {{'component.team-squad.add_new_player' | translate}}
          </button>

          <sams-load-players [teamCode]="teamCode" [title]="'component.team-squad.load' | translate" [confirmed]="confirmed" [hidden]="!loadingPlayers" (submit)="onLoadPlayers($event)" (cancel)="onCancelLoadPlayers()"></sams-load-players>
          <sams-add-player [players]="players" [title]="'component.team-squad.add_player' | translate" [confirmed]="confirmed" [hidden]="!addingPlayer" (submit)="onAddPlayer($event)" (cancel)="onCancelAddPlayer()"></sams-add-player>

        </div>

        <table class="officials-table">
          <tr>
            <th colspan="4" style="font-size: 20px;">
              {{'app.officials' | translate}}
            </th>
          </tr>

          <ng-template ngFor let-o [ngForOf]="officials">
            <tr>
                <td>
                  <div *ngIf="o.portraitPhotoMax600x600?.url" class="avatar-image">
                    <img [src]="o.portraitPhotoMax600x600?.url" alt="" />
                  </div>
                  <button *ngIf="isNewOfficial(o)" [disabled]="confirmed" mat-raised-button (click)="editingOfficial = o.uuid">
                    <mat-icon>edit</mat-icon>
                  </button>
                </td>
                <td class="name">
                  {{o.lastName}}, {{o.firstName}}
                  <div *ngIf="isNewOfficial(o)" style="font-size: 12px;">{{'component.team-squad.manually_added' | translate}}</div>
                </td>
                <td>
                  {{('app.' + o.function.toLowerCase()) | translate }}
                </td>
                <td>
                  <mat-checkbox [checked]="isOfficialSelected(o)" (change)="onToggleOfficial(o)" [disabled]="confirmed"></mat-checkbox>
                </td>
            </tr>
            <tr *ngIf="editingOfficial === o.uuid">
              <td colspan="6">
                <sams-add-official [title]="'component.team-squad.edit_official' | translate" [official]="o" [hidden]="!editingOfficial" (submit)="onEditOfficial($event)" (cancel)="onCancelEditOfficial()"></sams-add-official>
              </td>
            </tr>
          </ng-template>

        </table>

        <button *ngIf="!addingOfficial && manualOfficialSubmissionEnabled" mat-raised-button (click)="onAddingOfficial()" [disabled]="confirmed">
          <mat-icon>add</mat-icon> {{'component.team-squad.add_new_official' | translate}}
        </button>

        <sams-add-official [title]="'component.team-squad.add_official' | translate" [hidden]="!addingOfficial" (submit)="onAddOfficial($event)" (cancel)="onCancelAddOfficial()"></sams-add-official>

      </mat-card-content>
    </mat-card>
  `,
  styles: [`

    .license-button {
      margin-left: 5px;
    }

    tr.selected td {
      background: palegreen;
    }

    tr.not-approved td {
      background: white;
      color: grey;
    }

    tr.not-approved img {
      filter: opacity(50%);
    }

    .team-info {
      display: flex;
    }

    h1 {
      margin-left: 10px;
    }

    .dummy-space {
      margin-right: 20px;
    }

    h2 {
      margin-left: 10px;
      text-align: center;
    }

    .jerseys-container {
      display: flex;
      flex-direction: column;
      margin: 0 15px;
    }

    .jerseys .mat-icon {
      cursor: pointer;
      margin-top: 20px;
      font-size: 35px;
      font-weight: bold;
    }

    .jerseys {
      display: flex;
    }

    .card-header {
      justify-content: space-between;
      display: flex;
    }
    .card-header > * {
      margin-right: 15px;
    }
    .jersey-logo {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
    }
    .clickable {
      cursor: pointer;
    }
    .trikot-logo {
      height: 60px;
      width: 60px;
      -webkit-mask: url(/assets/icons/Trikot_Mann_Solid.svg) no-repeat center;
      mask: url(/assets/icons/Trikot_Mann_Solid.svg) no-repeat center;
    }
    .trikot-white {
      height: 60px;
      width: 60px;
      background: black;
      -webkit-mask: url(/assets/icons/Trikot_Mann_Regular.svg) no-repeat center;
      mask: url(/assets/icons/Trikot_Mann_Regular.svg) no-repeat center;
    }
    .shorts-logo {
      margin-top: 2px;
      margin-left: 8px;
      height: 44px;
      width: 44px;
      -webkit-mask: url(/assets/icons/Hose_Mann_Solid.svg) no-repeat center;
      mask: url(/assets/icons/Hose_Mann_Solid.svg) no-repeat center;
    }
    .shorts-white {
      background: black;
      margin-top: 2px;
      margin-left: 8px;
      height: 44px;
      width: 44px;
      -webkit-mask: url(/assets/icons/Hose_Mann_Regular.svg) no-repeat center;
      mask: url(/assets/icons/Hose_Mann_Regular.svg) no-repeat center;
    }
    .color-picker {
      visibility: hidden;
      height: 32px;
      width: 32px;
    }
    label {
      border: 1px solid darkgrey;
      cursor: pointer;
      margin: 10px;
      height: 32px;
      width: 32px;
    }
    .warn {
      color: coral;
    }
    mat-card {
      margin: 30px 0;
    }
    span.jersey-number {
      font-weight: bold;
    }

    .reset {
      background-color: LightCoral;
    }
    div.avatar-image {
      display: inline-block;
      height: 50px;
      max-width: 50px;
      overflow: hidden;
      text-align: center;
      border-radius: 50%;
      margin-right: 15px;
    }
    div.avatar-image img {
      width: 50px;
    }

    table {
      border-collapse: collapse;
    }
    table tr {
      border-bottom: 1px solid lightgrey;
    }
    table td {
      padding: 5px;
      background-color: WhiteSmoke;
    }
    .captain {
      color: white;
      background-color: RoyalBlue;
    }
    .libero {
      color: black;
      background-color: gold;
    }
    .jersey-number {
      margin-top: 2px;
      font-size: 30px;
      text-align: right;
      font-weight: bold;
    }
    .name {
      font-size: 16px;
    }
    .jersey-number-input-container {
      width: 40px;
    }
    .jersey-number-input-container input {
      -moz-appearance:textfield;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
    .jersey-number-editing {
      font-size: 30px;
      text-align: right;
    }
    
    .editing-cell {
      cursor: pointer;
    }

    .officials-table {
      margin-top: 35px;
      width: 100%;
    }

    .player-table {
      width: 100%;
    }


    @media only screen and (max-width: 1350px) {
      div.avatar-image {
        height: 3.7vw; // 50 / 1350
        max-width: 3.7vw;
        margin-right: 1.04vw; // 15 / 1350
      }
      div.avatar-image img {
        width: 3.7vw;
      }
      .name {
        font-size: 1.19vw; // 16 / 1350
      }
      .jersey-number-editing {
        // width: 4.07vw; // 55
        font-size: 2.22vw; // 30 / 1350
      }
    }

  `],
})
export class TeamSquadComponent implements OnInit {

  readonly jerseyNumberPattern = '^([1-9]|[1-9][0-9])$';

  addingPlayer = false;

  loadingPlayers = false;

  addingOfficial = false;

  errMsg: Observable<string>;

  editingPlayer: string = null;

  editingOfficial: string = null;

  sortedBy = 'jerseyNumber';

  shirtColorHexModel: string

  shortsColorHexModel: string

  @Input() scoreConfiguration: ScoreConfiguration;

  @Input() isGameStateTouched: boolean;

  @Input() team: Team;

  @Input() match: Match;

  @Input() matchId: string;

  @Input() teamCode: TeamCode;

  @Input() teamSquad: TeamSquad;

  @Output() togglePlayer = new EventEmitter<string>();

  @Output() toggleOfficial = new EventEmitter<string>();

  @Output() toggleCaptain = new EventEmitter<string>();

  @Output() toggleLibero = new EventEmitter<string>();

  @Output() checkAll = new EventEmitter<string[]>();

  @Output() reset = new EventEmitter<void>();

  @Output() undoConfirm = new EventEmitter<void>();

  @Output() confirm = new EventEmitter<string>();

  @Output() setShirtColor = new EventEmitter<string>();

  @Output() setShortsColor = new EventEmitter<string>();

  @Output() changeJersey = new EventEmitter<string>();

  constructor(
    public translate: TranslateService,
    private dispatchService: DispatchService,
    public dialog: MatDialog) {
  }

  ngOnInit() {
    this.shirtColorHexModel = this.shirtColorHex
    this.shortsColorHexModel = this.shortsColorHex
  }

  isWhite(hexColor: string) {
    return hexColor === '#ffffff';
  }

  get isTrikotWhite() {
    if (this.selectedJerseyName === JERSEY_TYPE.custom) {
      return this.isWhite(this.shirtColorHexModel);
    }
    return this.isWhite(this.selectedJersey.shirtColorHex)
  }

  get isShortsWhite() {
    if (this.selectedJerseyName === JERSEY_TYPE.custom) {
      return this.isWhite(this.shortsColorHexModel);
    }
    return this.isWhite(this.selectedJersey.shortsColorHex)
  }

  isTogglePlayerDisabled(p: Player) {
    return !possTogglePlayer(this.teamSquad, p);
  }

  isToggleCaptainDisabled(p: Player) {
    return !possToggleCaptain(this.teamSquad, p);
  }

  isToggleLiberoDisabled(p: Player) {
    return !possToggleLibero(this.scoreConfiguration, this.teamSquad, p);
  }

  isChangeJerseyNumberDisabled(p: Player) {
    return !possChangeJerseyNumber(this.isGameStateTouched, this.teamSquad, p);
  }

  onAddingPlayer() {
    this.addingPlayer = true;
  }

  onLoadingPlayers() {
    this.loadingPlayers = true;
  }

  onAddingOfficial() {
    this.addingOfficial = true;
  }

  onEditPlayer(player: Player) {
    const editPlayer: EditPlayer = { player, teamCode: this.teamCode };
    const action = new EditPlayerAction(editPlayer, this.matchId);
    this.dispatchService.dispatchRemoteAction(action);
    this.editingPlayer = null;
  }
  
  onEditOfficial(official: Official) {
    const editOfficial: EditOfficial = { official, teamCode: this.teamCode };
    const action = new EditOfficialAction(editOfficial, this.matchId);
    this.dispatchService.dispatchRemoteAction(action);
    this.editingOfficial = null;
  }

  onLoadPlayers(players: AdditionalClubPlayer[]) {
    const loadedPlayers: AdditionalClubPlayer[] = players.map(p => ({
      ...p,
      uuid: p.uuid ? p.uuid : uuid.v4(),
      eligible: true,
      isAdditionalClubPlayer: true }));
    loadedPlayers.forEach(p => this.addAndTogglePlayer(p));
    this.loadingPlayers = false
  }

  onAddPlayer(addedPlayer: ManuallyCreatedPlayer) {
    const newPlayer: ManuallyCreatedPlayer = { ...addedPlayer, uuid: uuid.v4(), isNewPlayer: true, personUuid: uuid.v4(), eligible: true };
    this.addAndTogglePlayer(newPlayer);
    this.addingPlayer = false;
  }

  addAndTogglePlayer(player: Player) {
    this.addPlayer(player);
    this.onTogglePlayer(player);
  }

  addPlayer(player: Player) {
    const addPlayer: AddPlayer = { player, teamCode: this.teamCode };
    const action = new AddPlayerAction(addPlayer, this.matchId);
    this.dispatchService.dispatchRemoteAction(action);
  }

  onAddOfficial(official: Official) {
    const newOfficial: Official = { ...official, uuid: uuid.v4(), isNewOfficial: true, personUuid: uuid.v4() };
    const addOfficial: AddOfficial = { official: newOfficial, teamCode: this.teamCode };
    const action = new AddOfficialAction(addOfficial, this.matchId);
    this.dispatchService.dispatchRemoteAction(action);
    this.onToggleOfficial(newOfficial);
    this.addingOfficial = false;
  }

  onCancelLoadPlayers() {
    this.loadingPlayers = false;
  }

  onCancelAddPlayer() {
    this.addingPlayer = false;
  }

  onCancelAddOfficial() {
    this.addingOfficial = false;
  }

  onCancelEditPlayer() {
    this.editingPlayer = null;
  }

  onCancelEditOfficial() {
    this.editingOfficial = null;
  }

  onShirtColorSelected() {
    this.setShirtColor.emit(this.shirtColorHexModel);
  }

  onShortsColorSelected() {
    this.setShortsColor.emit(this.shortsColorHexModel);
  }

  get shortsColorHex() {
    return this.team.jerseys?.custom?.shortsColorHex;
  }

  get shirtColorHex() {
    return this.team.jerseys?.custom?.shirtColorHex;
  }

  get additionalPlayers() {
    return this.team.additionalClubPlayers;
  }

  get isAnyJerseyNumberInvalid() {
    const result = !this.selectedPlayers.every(p => p.jerseyNumber !== null && +p.jerseyNumber > 0);
    if (result) {
      this.errMsg = this.translate.get('component.team-squad.invalid_jersey_number_selected');
    }
    return result;
  }

  get isAnyJerseyNumberDuplicate() {

    const jerseyNumbers = this.selectedPlayers.map(p => p.jerseyNumber).filter(n => n !== null);
    const distinctJerseyNumbers = new Set(jerseyNumbers);

    if (distinctJerseyNumbers.size !== jerseyNumbers.length) {
      this.errMsg = this.translate.get('component.team-squad.duplicate_jersey_number_selected');
      return true;
    }

    return false;
  }

  get selectedPlayers() {
    return this.players.filter(p => this.isSelected(p));
  }

  isNewPlayer(p: Player) {
    return p.isNewPlayer;
  }

  isNewOfficial(o: Official) {
    return o.isNewOfficial;
  }

  onChangeJerseyNumber($event: any, player: Player) {
    const jerseyNumber = $event.target.value;
    if (jerseyNumber.length === 0 || jerseyNumber.length > 2) {
      $event.target.value = player.jerseyNumber;
      return;
    }
    const changeJerseyNumber: ChangeJerseyNumber = {
      playerId: player.uuid,
      jerseyNumber,
      teamCode: this.teamCode
    };

    this.dispatchService.dispatchRemoteAction(new ChangeJerseyNumberAction(changeJerseyNumber, this.matchId));

  }

  isOneLiberoRuleActive(isPlayerSelected: boolean) {
    return (this.selected.length === this.numOfPlayersThatOnlyAllowOneLibero - 1 && !isPlayerSelected) ||
           (this.selected.length === this.numOfPlayersThatOnlyAllowOneLibero + 1 && isPlayerSelected);
  }

  isNoLiberoRuleActive(isPlayerSelected: boolean) {
    return (this.selected.length === this.numOfPlayersThatAllowNoLibero - 1 && !isPlayerSelected) ||
           (this.selected.length === this.numOfPlayersThatAllowNoLibero + 1 && isPlayerSelected);
  }

  isTwoLiberosRuleActive() {
    return this.selected.length >= this.numOfPlayersThatRequireTwoLiberos;
  }

  isOneLiberoRequiredRuleActive() {
    return this.numOfLiberos.length === 5
      && !this.isTwoLiberosRuleActive()
      && this.selected.length >= this.numOfPlayersThatRequireOneLibero;
  }

  onTogglePlayer(player: Player) {
    const isPlayerSelected = this.isSelected(player);

    if (this.isOneLiberoRuleActive(isPlayerSelected)) {
      if (this.isLibero1(player)) {
        this.resetLibero1();
      } else if (this.isLibero2(player)) {
        this.resetLibero2();
      } else if (this.libero1 && this.libero2) {
        this.resetLibero1();
      }
    } else if (this.isNoLiberoRuleActive(isPlayerSelected)) {
      this.resetLiberos();
    } else {

      if (this.isSelected(player)) {
        if (this.isLibero1(player)) {
          this.resetLibero1();
        }
        if (this.isLibero2(player)) {
          this.resetLibero2();
        }
      }
    }

    if (this.isSelected(player) && this.isCaptain(player)) {
      this.resetCaptain();
    }

    this.togglePlayer.emit(player.uuid);
  }

  onToggleOfficial(official: Official) {

    // if there are officials selected we have to check for the same identity and function
    if (this.selectedOfficials.length > 0) {

      const selectedOfficialWithSameFunction = this.selectedOfficials.find(o => o.function === official.function);
  
      if (typeof selectedOfficialWithSameFunction !== 'undefined' && selectedOfficialWithSameFunction.uuid !== official.uuid) {
        this.toggleOfficial.emit(selectedOfficialWithSameFunction.uuid);
      }
  
      const selectedOfficialWithSameIdentity = this.selectedOfficials.find(o => o.personUuid === official.personUuid);
      if (typeof selectedOfficialWithSameIdentity !== 'undefined' && selectedOfficialWithSameIdentity.uuid !== official.uuid) {
        this.toggleOfficial.emit(selectedOfficialWithSameIdentity.uuid);
      }
    }


    this.toggleOfficial.emit(official.uuid);
  }

  resetLiberos() {
    this.resetLibero1();
    this.resetLibero2();
  }

  resetLibero1() {
    if (this.libero1) {
      this.toggleLibero.emit(this.libero1.uuid);
    }
  }

  resetLibero2() {
    if (this.libero2) {
      this.toggleLibero.emit(this.libero2.uuid);
    }
  }

  resetCaptain() {
    this.toggleCaptain.emit(null);
  }

  onToggleCaptain(player: Player) {
    this.toggleCaptain.emit(player.uuid);
  }

  onToggleLibero(player: Player) {
    if (this.isLibero1(player)) {
      this.toggleLibero.emit(player.uuid);
    } else if (this.isLibero2(player)) {
      this.toggleLibero.emit(player.uuid);
    } else if (!this.libero1 && this.isOnlyOneLiberoAllowed) {
      this.resetLibero2();
      this.toggleLibero.emit(player.uuid);
    } else if (!this.libero2 && this.isOnlyOneLiberoAllowed) {
      this.resetLibero1();
      this.toggleLibero.emit(player.uuid);
    } else if (this.liberos.length >= 2) {
      this.resetLibero2();
      this.toggleLibero.emit(player.uuid);
    } else {
      this.toggleLibero.emit(player.uuid);
    }

  }

  onReset() {
    this.reset.emit();
  }

  onUndoConfirm() {
    this.undoConfirm.emit();
  }

  onSuccess(newHash: string) {
    this.confirm.emit(newHash);
  }

  onCheckAll() {
    this.checkAll.emit(
      this.players
        .filter(p => possTogglePlayer(this.teamSquad, p))
        .map(p => p.uuid)
    );
  }

  get isLicenseButtonDisabled() {
    return this.players.filter(p => p.license?.approved !== null).length === 0;
  }

  openLicenseDialog() {
    const data: LicenseDialogData = {
      matchId: this.matchId,
      teamCode: this.teamCode
    }

    this.dialog.open(LicenseDialogComponent, { data });
  }

  isSelected(player: Player) {
    return this.selected.indexOf(player) > -1;
  }

  isApproved(player: Player) {
    return player.license?.approved;
  }

  get numOfSelectedOfficials() {
    return this.selectedOfficials.length;
  }

  isOfficialSelected(official: Official) {
    return this.selectedOfficials.indexOf(official) > -1;
  }

  isCaptain(player: Player) {
    return this.captain === player;
  }

  isLibero1(player: Player) {
    return this.libero1 === player;
  }

  isLibero2(player: Player) {
    return this.libero2 === player;
  }

  isLibero(player: Player) {
    return this.liberos.indexOf(player) > -1;
  }

  get progressBarMultiplier() {
    return Math.ceil(100 / (this.minNumOfPlayers + 1)); // +1 for one captain
  }

  get progressBarValue() {
    const numOfPlayers = this.selected.length;
    let playersAdder = numOfPlayers > this.minNumOfPlayers ? this.minNumOfPlayers : numOfPlayers;

    const captainAdder = this.captain ? 1 : 0;

    let liberoAdder = 0;
    if (this.isTwoLiberosRuleActive()) {
      liberoAdder = this.liberos.length + 1;
    }

    if (this.isOneLiberoRequiredRuleActive()) {
      liberoAdder = this.liberos.length === 2 ? 1 : this.liberos.length;
    }

    if (this.selected.length >= this.numOfPlayersThatRequireTwoLiberos) {
      playersAdder = playersAdder - 2;
    }

    if (this.selected.length >= this.numOfPlayersThatRequireOneLibero) {
      playersAdder = playersAdder - 1;
    }

    if (numOfPlayers > this.maxNumOfPlayers) {
      const diffOfPlayer = numOfPlayers - this.maxNumOfPlayers;

      // treat too many players like too little players
      playersAdder = playersAdder - diffOfPlayer;
    }

    return (playersAdder + captainAdder + liberoAdder) * this.progressBarMultiplier;
  }

  get tooltip$() {
    const numOfPlayers = this.selected.length;
    const numOfMissingPlayers = this.minNumOfPlayers - numOfPlayers;

    if (numOfMissingPlayers > 0) {
      if (!this.captain) {
        return this.translate.get('component.team-squad.choose_n_players_and_a_captain', { n: numOfMissingPlayers });
      }
      return this.translate.get('component.team-squad.choose_n_players', { n: numOfMissingPlayers });
    }

    if (!this.captain) {
      return this.translate.get('component.team-squad.choose_a_captain');
    }

    if (this.isTwoLiberosRuleActive()) {
      const differenceBetweenTwoLiberoRule = numOfPlayers - this.numOfPlayersThatRequireTwoLiberos + 1;

      if (this.liberos.length === 1) {
        return this.translate.get('component.team-squad.choose_one_libero_or_remove_n_players', { nPlayers: differenceBetweenTwoLiberoRule });
      }

      if (this.liberos.length === 0) {
        return this.translate.get('component.team-squad.choose_two_liberos_or_remove_n_players', { nPlayers: differenceBetweenTwoLiberoRule });
      }
    }

    if (this.isOneLiberoRequiredRuleActive()) {
      const differenceBetweenOneLiberoRule = numOfPlayers - this.numOfPlayersThatRequireOneLibero + 1;

      if (this.liberos.length === 0) {
        return this.translate.get('component.team-squad.choose_one_libero_or_remove_n_players', { nPlayers: differenceBetweenOneLiberoRule });
      }
    }

    if (this.isAnyJerseyNumberDuplicate) {
      return this.translate.get('component.team-squad.duplicate_jersey_number_selected');
    }

    if (this.isAnyJerseyNumberInvalid) {
      return this.translate.get('component.team-squad.invalid_jersey_number_selected');
    }

    if (numOfPlayers > this.maxNumOfPlayers) {
      const diffOfPlayers = numOfPlayers - this.maxNumOfPlayers;
      return this.translate.get('component.team-squad.remove_n_players', { n: diffOfPlayers });
    }

    if (this.addingPlayer || this.editingPlayer || this.loadingPlayers || this.addingOfficial) {
      return this.translate.get('component.team-squad.finish_editing_msg');
    }

    if (this.approvalMissing) {
      return this.translate.get('component.team-squad.missing_player_approval');
    }

    return this.translate.get('component.team-squad.enter_team_pin');
  }

  get isOnlyOneLiberoAllowed() {
    return this.selected.length === this.numOfPlayersThatOnlyAllowOneLibero;
  }

  get isAnyLiberoSelected() {
    return this.liberos.length > 0;
  }

  get players() {
    return this.team.players;
  }

  get allChecked() {
    return this.selected.length === this.players.length;
  }

  get confirmed() {
    return this.teamSquad.confirmed;
  }

  get captain() {
    return this.teamSquad.captain;
  }

  get libero1() {
    return this.liberos[0];
  }

  get libero2() {
    return this.liberos[1];
  }

  get liberos() {
    return this.teamSquad.liberos;
  }

  get selected() {
    return this.teamSquad.players;
  }

  get officials() {
    // sort mutates so we spread to create a new object
    return [...this.team.officials].sort();
  }

  get selectedOfficials() {
    return this.teamSquad.officials;
  }

  get maxNumOfPlayers() {
    return this.scoreConfiguration.maxPlayersInTeam;
  }

  get minNumOfPlayers() {
    return this.scoreConfiguration.minPlayersInTeam;
  }

  get manualOfficialSubmissionEnabled() {
    return this.scoreConfiguration.manualOfficialSubmissionEnabled
  }

  get manualPlayerSubmissionEnabled() {
    return this.scoreConfiguration.manualPlayerSubmissionEnabled
  }

  get numOfLiberos() {
    return this.scoreConfiguration.numberOfLiberos
  }

  get numOfPlayersThatOnlyAllowOneLibero() {
    const rule = this.numOfLiberos[1]
    return rule ? rule.toPlayers : 0
  }

  get numOfPlayersThatAllowNoLibero() {
    const rule = this.numOfLiberos[0]
    return rule ? rule.toPlayers : this.minNumOfPlayers
  }

  get numOfPlayersThatRequireOneLibero() {
    const rule = this.numOfLiberos[3]
    return rule ? rule.fromPlayers : this.players.length + 1
  }

  get numOfPlayersThatRequireTwoLiberos() {
    const idx = this.numOfLiberos.length === 5 ? 4 : 3
    const rule = this.numOfLiberos[idx]
    return rule ? rule.fromPlayers : this.players.length + 1
  }

  get maxNumOfLiberosNeeded() {
    const rule = this.numOfLiberos[2]
    return rule ? rule.maxLiberos : 0
  } 

  get noLiberosAllowed() {
    return this.numOfLiberos.every(e => e.maxLiberos === 0)
  }

  get TeamCodes() {
    return TeamCodes;
  }

  get selectedJerseyName() {
    return this.team.selectedJersey
  }

  get selectedJersey() {
    return this.team.jerseys[this.team.selectedJersey]
  }

  get customJersey() {
    return this.selectedJerseyName === JERSEY_TYPE.custom
  }

  onNextJersey() {
    const idx = Object.keys(this.team.jerseys).indexOf(this.selectedJerseyName)
    const newName = Object.keys(this.team.jerseys)[idx + 1] ? Object.keys(this.team.jerseys)[idx + 1] : Object.keys(this.team.jerseys)[0]
    this.changeJersey.emit(newName)
  }

  onPrevJersey() {
    const idx = Object.keys(this.team.jerseys).indexOf(this.selectedJerseyName)
    const newName = Object.keys(this.team.jerseys)[idx + - 1] ? Object.keys(this.team.jerseys)[idx + - 1] : Object.keys(this.team.jerseys).slice(-1)[0]
    this.changeJersey.emit(newName)
  }

  get jerseySelectionActive() {
    return Object.keys(this.team.jerseys).length > 1
  }

  get jerseyColorSelectionEnabled() {
    return this.scoreConfiguration.jerseyColorSelectionEnabled;
  }

  get approvalMissing() {
    return this.selectedPlayers.filter(p => !p.license?.approved).length > 0;
  }

}