import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'sams-update-dialog',
  template: `
    <h3 mat-dialog-title>{{'component.update-dialog.new_version_available' | translate}}</h3>
    <div class="action-buttons" mat-dialog-actions>
      <button mat-raised-button class="positive" (click)="onClose()">
        {{'component.update-dialog.update' | translate}}
      </button>
    </div>
  `,
  styles: [`
    .action-buttons {
      display: flex;
      justify-content: center;
    }
  `]
})
export class UpdateDialogComponent {

  constructor(public dialogRef: MatDialogRef<UpdateDialogComponent, boolean>) {
    dialogRef.disableClose = true;
  }

  onClose() {
    this.dialogRef.close();
  }

}
