import { Injectable } from '@angular/core';
import { License } from '../../../../../shared/interfaces/models/license';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConnectionsService } from 'app/connections/connections.service';

@Injectable()
export class LicenseService {

  constructor(private sanitizer: DomSanitizer, private http: HttpClient, private connectionsService: ConnectionsService) {

  }

  private sanitizeUrl = (url: string) => {
    return this.sanitizer.bypassSecurityTrustUrl(url)
  }

  private getOfflineImage(license: License): string | SafeUrl {
    if (license?.licensePhotoBase64) {
      return 'data:image/jpg;base64,' + license.licensePhotoBase64;
    }
  }

  getIdToken(matchId: string) {
    return this.connectionsService.connections[matchId].auth?.id_token
  }

  async getLicensePhoto(license: License, matchId: string): Promise<string | SafeUrl> {

    const id_token = this.getIdToken(matchId)

    if (!license?.licensePhoto || !id_token) {
      return this.getOfflineImage(license);
    }

    try {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + id_token
      });

      const response = await this.http.get(license.licensePhoto, {
        headers,
        responseType: 'blob',
        observe: 'response'
      }).toPromise();
      
      if (response.ok) {
        const url = URL.createObjectURL(response.body);
        return this.sanitizeUrl(url);
      }

      return this.getOfflineImage(license);
    } catch (err) {
      return this.getOfflineImage(license);
    }
  }

}
