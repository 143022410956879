import {
  Component,
  Output,
  Input,
  EventEmitter,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Subscription } from "rxjs";
import { ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sams-token-form',
  template: `
    <mat-card class="token-form-card">
      <mat-card-header>
        <mat-card-title>
          <h3>{{title}}</h3>
        </mat-card-title>
      </mat-card-header>

      <form #tokenForm="ngForm" (ngSubmit)="onSubmit.emit(tokenForm.value.token)">
        <mat-form-field
            id="input-container"
            [color]="determineAccent(token)">
          <input matInput
            [(ngModel)]="formToken" 
            [pattern]="tokenPattern"
            id="token"
            name="token"
            #token="ngModel"
            placeholder="Token"
            required>
        </mat-form-field>
        <div class="info">{{determineInfoMsg(token) | async}}</div>
        <div class="warn">{{errMsg | translate}}</div>
  
        <mat-card-actions>
          <button type="submit" mat-raised-button [disabled]="!tokenForm.form.valid || formToken.length === 0">
            {{'component.token-form.add' | translate}}
          </button>
          <button mat-raised-button (click)="addTestMatch()">
            {{'component.token-form.add_test_match' | translate}}
          </button>
          <a target="_blank" href="http://wiki.sams-server.de/w/images/c/c2/SAMS_Score_Testspiel.pdf" mat-raised-button>
            {{'component.token-form.open_score_test_match' | translate}}
            <mat-icon>picture_as_pdf</mat-icon>
          </a>
          <a target="_blank" href="http://wiki.sams-server.de/wiki/SAMS_Score/Handbuch" mat-raised-button>
            {{'component.token-form.open_score_manual' | translate}}
          </a>
        </mat-card-actions>
      </form>

    </mat-card>
  `,
  styles: [`
    #input-container {
      width: 100%;
    }
    .info {
      color: grey;
    }
    h3 {
      color: darkgrey;
    }
    .token-form-card {
      padding-left: 50px;
    }
    .warn {
      color: tomato;
    }
    .mat-card-header .mat-card-title {
      font-size: 16px;
    }
  `]
})
export class TokenFormComponent implements OnInit, OnDestroy {

  @Input() title: string;

  @Input() errMsg: string;

  @Output() onSubmit = new EventEmitter<string>();
  
  @Output() onAddTestMatch = new EventEmitter<void>();

  formToken = '';

  private matchTokenSubscription = new Subscription();

  readonly prodTokenPattern = '[a-zA-Z]+-[A-Za-z]{20}';
  readonly testTokenPattern = '([a-zA-Z]*Test[a-zA-Z]*2?-)?[A-Za-z]{20}'

  constructor(private route: ActivatedRoute, private ref: ChangeDetectorRef, public translate: TranslateService) {  
    this.matchTokenSubscription = this.route.params.subscribe(params => {
      if (params.token) {
        this.formToken = params.token;
      }
      this.ref.markForCheck();
    });
  }

  get tokenPattern() {
    // if (environment.testing) {
    //   return this.testTokenPattern
    // }
    // if (environment.production) {
    //   return this.prodTokenPattern
    // }
    return '.*'
  }

  addTestMatch() {
    this.onAddTestMatch.emit();
    return false;
  }

  ngOnInit() {
  }

  determineAccent(token: any): string {
    return (this.shouldShowRequired(token) || this.shouldShowInvalid(token)) ? 'warn' : 'primary';
  }

  determineInfoMsg(token: any) {
    if (this.shouldShowRequired(token)) {
      return this.translate.get('component.token-form.token_required')
    } else if (this.shouldShowInvalid(token)) {
      return this.translate.get('component.token-form.token_invalid')
    }
    return null;
  }

  shouldShowRequired(token: any): boolean {
    return this.formToken && (this.formToken.length === 0 && token.dirty);
  }

  shouldShowInvalid(token: any): boolean {
    return token.invalid && (this.formToken.length > 0 && token.dirty);
  }

  ngOnDestroy() {
    this.matchTokenSubscription.unsubscribe();
  }

}
