import { Routes } from '@angular/router';

import { MatchesOverviewComponent } from 'app/matches-overview/matches-overview.component';
import { MatchViewComponent } from 'app/match-view/match-view.component';
import { MatchPreparationComponent } from 'app/match-preparation/match-preparation.component';
import { TeamPreparationsComponent } from 'app/match-preparation/team-preparations.component';
import { MatchFinalizationComponent } from 'app/match-finalization/match-finalization.component';
import { ErrorComponent } from 'app/error/error.component';
import { RefereeViewComponent } from './match-view/referee-view.component';
import { TeamPreparationsGuard } from './match-preparation/team-preparations.guard';
import { MatchPreparationGuard } from './match-preparation/match-preparation.guard';
import { MatchFinalizationGuard } from './match-finalization/match-finalization.guard';
import { MatchViewGuard } from './match-view/match-view.guard';
import { MatchGuard } from './match-guard';

export const ROUTES: Routes = [
    { path: 'index', redirectTo: 'matches-overview' },
    { path: '', redirectTo: 'matches-overview', pathMatch: 'full' },
    { path: 'matches-overview', component: MatchesOverviewComponent },
    { path: 'matches-overview/:token', component: MatchesOverviewComponent },
    { path: 'match-preparation', component: MatchPreparationComponent, canActivate: [MatchGuard, MatchPreparationGuard] },
    { path: 'match-finalization', component: MatchFinalizationComponent, canActivate: [MatchGuard, MatchFinalizationGuard] },
    { path: 'team-preparations', component: TeamPreparationsComponent, canActivate: [MatchGuard, TeamPreparationsGuard] },
    { path: 'match-view', component: MatchViewComponent, canActivate: [MatchGuard, MatchViewGuard] },
    { path: 'referee-view', component: RefereeViewComponent, canActivate: [MatchGuard] },
    { path: 'error', component: ErrorComponent }
];
