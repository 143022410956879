import { Component, Input, EventEmitter, Output } from '@angular/core';

import { TeamSide, TeamSideValues } from 'app/models';
import { SortedBy } from 'app/pipes/sort-players';

@Component({
  selector: 'sams-player-list-header',
  template: `
    <div class="table-row" [ngStyle]="{ 'flex-direction': isLeftTeam ? 'row' : 'row-reverse' }">

      <a role="button"
        class="title flex-center-vertical">
        {{titleTranslationKey | translate}}
      </a>

      <div class="player-info">
        <a *ngIf="sortable" role="button"
          href="javascript:void(0);"
          class="jersey-number" 
          (click)="onSortJerseyNumber()">
          <mat-icon>format_list_numbered</mat-icon>
        </a> 
        <a *ngIf="sortable" role="button" href="javascript:void(0);" class="first-name" (click)="onSortFirstName()">
          <mat-icon>sort_by_alpha</mat-icon>
        </a>
        <a *ngIf="sortable" role="button" href="javascript:void(0);" class="last-name" (click)="onSortLastName()">
          <mat-icon>sort_by_alpha</mat-icon>
        </a>

      </div>

    </div>
  `,
  styles: [`

    .jersey-number {
      justify-content: center;
      width: 30%;
      text-align: center;
      color: white; 
    }

    .last-name {
      text-align: left;
      width: 35%;
      color: white; 
    }

    .first-name {
      text-align: left;
      width: 35%;
      color: white; 
    }

    .player-info {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 70%;
    }

    .table-row {
      height: 27px;
      padding: 8px 0;
      background: #4d4d4d;
      display: flex;
    }

    .title {
      justify-content: center;
      color: white;
      text-decoration: none;
      flex-grow: 1;
      width: 35%;
    }

  `]
})
export class PlayerListHeaderComponent {

  @Input() teamSide: TeamSide;

  @Input() titleTranslationKey: string;

  @Input() sortable = true;

  @Output() sortedBy = new EventEmitter<string>();

  onSortJerseyNumber() {
    this.sortedBy.emit(SortedBy.jerseyNumber);
  }

  onSortFirstName() {
    this.sortedBy.emit(SortedBy.firstName);
  }

  onSortLastName() {
    this.sortedBy.emit(SortedBy.lastName);
  }

  get isLeftTeam() {
    return this.teamSide === TeamSideValues.leftTeam;
  }

}
