import { TeamCode } from '../../interfaces/models/team-codes.js';
import { MatchAction } from '../../interfaces/interfaces.js';

export const TEAM_HIGHLIGHT = '[TeamHighlight] Highlight';

export enum TeamHighlight {
  ATTACK_HIGHLIGHT = "ATTACK_HIGHLIGHT",
  BLOCK_HIGHLIGHT = "BLOCK_HIGHLIGHT",
  SERVE_HIGHLIGHT = "SERVE_HIGHLIGHT",
  PLAY_HIGHLIGHT = "PLAY_HIGHLIGHT",
  PLAY_UNRELATED_HIGHLIGHT = "PLAY_UNRELATED_HIGHLIGHT"
}

export interface TeamHighlightPayload {
  teamCode: TeamCode;
  highlight: TeamHighlight;
}

export class TeamHighlightAction extends MatchAction {
  readonly type = TEAM_HIGHLIGHT;
  constructor(public payload: TeamHighlightPayload, matchId: string) {
    super(matchId);
  }
}
