import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { IndoorRoot, indoorReducers } from '../../../../shared/reducers/indoor.reducer';
export * from '../../../../shared/reducers/indoor.reducer';

export const indoorReducerToken = new InjectionToken<ActionReducerMap<IndoorRoot>>('Indoor Reducers');

export const indoorReducerProvider = [
  { provide: indoorReducerToken, useValue: indoorReducers }
];
