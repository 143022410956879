import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { Player, Team } from 'app/models';

export type SubstitutionsExceededDialogResult = Player | "UNDO" | null;

export interface SubstitutionsExceededDialogData {
  team: Team;
  title: Observable<string>;
  isUndoable: boolean;
  availablePlayers: Player[];
}

@Component({
  selector: 'sams-substitutions-exceeded-dialog',
  template: `
    <div class="substitutions-exceeded-dialog">

      <div class="dialog-header">
        <sams-logo [src]="team.logoImageMax400x400?.url" [alt]="team.name" [letter]="team.singleLetterName" size="80px"></sams-logo>
        <div>
          <h1 mat-dialog-title>{{team.name}}</h1>
          <h1 mat-dialog-title>{{title | async}}</h1>
        </div>
      </div>

      <p *ngIf="showEndSetMsg" style="line-height: 22px;">{{'component.substitutions-exceeded-dialog.end_set_msg' | translate}}</p>

      <sams-player-select
        *ngIf="showPlayerSelect"
        availablePlayersTitle="component.substitution-dialog.available_players"
        [availablePlayers]="availablePlayers"
        (playerSelected)="onPlayerSelected($event)">
      </sams-player-select>

      <div mat-dialog-actions>
        <button mat-raised-button
          *ngIf="!showPlayerSelectButton || !showEndSetButton"
          class="confirm-button"
          [ngClass]="{ 'positive': selectedPlayer }"
          [disabled]="!selectedPlayer && !showEndSetMsg"
          (click)="onConfirm()">
          {{'app.confirm' | translate}}
        </button>
        <button *ngIf="showPlayerSelectButton" mat-raised-button (click)="onExceptionalSubstitution()">{{'component.substitutions-exceeded-dialog.exceptional_substitution' | translate}}</button>
        <button *ngIf="showEndSetButton" mat-raised-button (click)="onEndSet()" class="warn">{{'component.substitutions-exceeded-dialog.end_set' | translate}}</button>
        <button *ngIf="isUndoable" mat-raised-button class="negative" (click)="onUndo()">{{'app.undo' | translate}}</button>
        <button *ngIf="!isUndoable" mat-raised-button class="negative" (click)="onCancel()">{{'app.cancel' | translate}}</button>
      </div>

    </div>
  `,
  styles: [`
    .dialog-header {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    h1 {
      margin: 0;
      margin-left: 10px;
    }
    .confirm-button {
      margin-right: 30px
    }

  `]
})
export class SubstitutionsExceededDialogComponent {

  team: Team;

  title: Observable<string>;

  isUndoable: boolean;

  showPlayerSelect: boolean = false;

  showEndSetButton: boolean = true;

  showEndSetMsg: boolean = false;
  
  showPlayerSelectButton: boolean = true;

  availablePlayers: Player[];

  selectedPlayer: Player;

  constructor(public dialogRef: MatDialogRef<SubstitutionsExceededDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: SubstitutionsExceededDialogData) {
    this.availablePlayers = data.availablePlayers;
    this.isUndoable = data.isUndoable;
    this.title = data.title;
    this.team = data.team;
    this.dialogRef.disableClose = true;
  }

  onConfirm() {
    this.dialogRef.close(this.selectedPlayer || null);
  }

  onExceptionalSubstitution() {
    this.showPlayerSelect = true;
    this.showPlayerSelectButton = false;
    this.showEndSetButton = false;
  }

  onEndSet() {
    this.showEndSetButton = false;
    this.showPlayerSelectButton = false;
    this.showEndSetMsg = true;
  }

  onUndo() {
    this.dialogRef.close("UNDO");
  }

  onCancel() {
    this.dialogRef.close(null);
  }

  onPlayerSelected(p: Player) {
    this.selectedPlayer = p;
  }

}
