import { Component, Input } from '@angular/core';

import { Player } from 'app/models';

@Component({
  selector: 'sams-player-name-column',
  template: `
    <div class="first-name flex-center-vertical">
      {{player.firstName}}
    </div>
    <div class="short-name flex-center-vertical">
      {{player.firstName[0]}}.{{player.lastName}}
    </div>
    <div class="last-name flex-center-vertical">
      {{player.lastName}}
    </div>
  `,
  styles: [`
    :host {
      min-width: 70%;
    }
    .last-name {
      flex-grow: 0;
    }

    .first-name {
      flex-grow: 0;
      width: 50%;
    }

    .short-name {
      display: none;
    }

    @media all and (max-width: 1280px) {
      .first-name, .last-name {
        display: none;
      }
      .short-name {
        display: flex;
        flex-grow: 0;
        width: 65%;
      }

    }

  `],
  host: {'class': 'flex-center-vertical'}
})
export class PlayerNameColumnComponent {

  @Input() player: Player;

}
