import { Action } from '../../../interfaces/interfaces.js';
import { MatchFinalization } from '../../../interfaces/models/match.js';
import {
  CONFIRM_FINALIZATION,
  CONFIRM_FINALIZATION_SUCCESS,
  CONFIRM_FINALIZATION_FAIL,
  ConfirmFinalizationAction
} from './match-finalization.action.js';
import { MatchFinalizationAction } from './match-finalization.action.js';

const initialState: MatchFinalization = {
  finalized: false,
  successful: false,
  statusCode: null as number,
  refereeConfirmationPin: null as string,
  team1ConfirmationPin: null as string,
  team2ConfirmationPin: null as string
}

export const matchFinalizationReducer = (state: MatchFinalization = initialState, action: MatchFinalizationAction): MatchFinalization => {

  switch (action.type) {
    
    case CONFIRM_FINALIZATION: {
      const pin = action.payload;
      return {
        ...state,
        finalized: true,
        refereeConfirmationPin: pin.refereePin,
        team1ConfirmationPin: pin.team1Pin,
        team2ConfirmationPin: pin.team2Pin
      };
    }

    case CONFIRM_FINALIZATION_SUCCESS: {
      return {
        ...state,
        successful: true,
        statusCode: 200
      };
    }

    case CONFIRM_FINALIZATION_FAIL: {
      return {
        ...state,
        successful: false,
        statusCode: action.payload.status 
      };
    }

    default:
      return state;

  }

};

export const isMatchFinalized = (state: MatchFinalization) => state ? state.finalized : false;
export const isMatchSuccessfullyFinalized = (state: MatchFinalization) => state ? state.successful : false;
export const getStatusCode = (state: MatchFinalization) => state && state.statusCode;
export const isConfirmFinalizationAction = (action: Action): action is ConfirmFinalizationAction => action.type === CONFIRM_FINALIZATION
