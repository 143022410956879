import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import * as moment from 'moment'
import { UpdateService } from './update-service';
import { IndoorRoot } from './root/indoor.reducer';
import { BeachRoot } from './root/beach.reducer';
import { Location } from '@angular/common';
import * as fromShared from './root/shared.reducer';

moment.locale('de')

@Component({
  selector: 'sams-root',
  template: `
    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <div *ngIf="!isScoreSheetShown" class="bottom">
      <a class="version">sams-score © {{version$ | async}}</a>
      <a mat-button target="_blank" href="impressum.html">Impressum</a>
      <a mat-button target="_blank" href="datenschutz.html">Datenschutz</a>
    </div>
  `,
  styles: [`
    .content {
      padding-bottom: 50px;
    }
    .version {
      font-size: 10px;
    }
    .bottom {
      border-top: 1px solid lightgrey;
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      background-color: whitesmoke;
      color: #424242;
      text-align: right;
      z-index: 1; /* Set a lower z-index value to place the footer behind your content */

    }
  `]
})
export class AppComponent implements OnInit {

  version$: Observable<string>;


  constructor(public router: Router,
    public location: Location,
    public translate: TranslateService,
    public updateService: UpdateService,
    public store: Store<IndoorRoot | BeachRoot>) {

    translate.addLangs(['en', 'de']);

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    translate.use('de');
  }

  get isScoreSheetShown() {
    return this.location.path() === '/scoresheet'
  }

  ngOnInit() {
    this.version$ = this.store.select(fromShared.getVersion)
  }


  navigateHome () {
    this.router.navigate(['/']);
  }

}
