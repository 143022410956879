
import {
  Subscription,
  of
} from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { BeachMatchState } from '../../../../../shared/beach/reducers/match-states/beach-match-state.reducer';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { getBeachResultMatchState } from './beach-result-match-state';
import {
  BeachConfirmFinalizationAction,
  BeachConfirmFinalizationFailAction,
  BeachConfirmFinalizationSuccessAction
} from '../../../../../shared/beach/reducers/match-states/match-finalization/beach-match-finalization.action';
import { ConnectionsService } from '../../connections/connections.service';
import { DispatchService } from '../../connections/dispatch.service';

@Injectable()
export class BeachFinalizationService implements OnDestroy {

  private subscription = new Subscription();

  constructor(
    public connectionsService: ConnectionsService,
    public dispatchService: DispatchService,
    private http: HttpClient) {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  finalizeMatch(matchState: BeachMatchState) {
    const { uuid, resultPostUrl } = matchState.match
    const id_token = this.connectionsService.connections[uuid].auth?.id_token
    
    this.dispatchService.dispatchRemoteAction(new BeachConfirmFinalizationAction({}, uuid))

    const resultState = getBeachResultMatchState(matchState)
    this.subscription.add(
      this.http.post(resultPostUrl, resultState, {
        headers: { 'Authorization': 'Bearer ' + id_token }
      }).pipe(
          switchMap(_ => {
            return of(new BeachConfirmFinalizationSuccessAction(uuid))
          }),
          catchError((err: HttpErrorResponse) => {
            return of(new BeachConfirmFinalizationFailAction({ 
              status: err.status,
              message: err.message
            }, uuid))
          })
        ).subscribe(a => this.dispatchService.dispatchRemoteAction(a))
    )
  }

}
