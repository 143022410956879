import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { BeachRoot, beachReducers } from '../../../../shared/beach/reducers/beach.reducer';
export * from '../../../../shared/beach/reducers/beach.reducer';

export const beachReducerToken = new InjectionToken<ActionReducerMap<BeachRoot>>('Beach Reducers');

export const beachReducerProvider = [
  { provide: beachReducerToken, useValue: beachReducers }
];
