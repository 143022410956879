import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ErrorService } from './error.service';

@Component({
  selector: 'sams-error',
  template: `
  <div class="error-container">
    <img src="assets/icons/score-icon-2x.png">
    <h1 style="text-align: center;">{{'component.error.critical_error' | translate}}</h1>
    <button (click)="reload()" mat-raised-button color="primary">{{'app.reload' | translate}}</button>
  </div>

  `,
  styles: [`
    div.score-logo {
      margin: 20px;
      height: auto;
      width: 80%;
      
    }
    .error-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    button {
      height: 50px;
      width: 80%;
    }

  `]
})
export class ErrorComponent implements OnInit {

  constructor(public router: Router, public errorService: ErrorService) {
  }

  ngOnInit() {

  }

  reload() {
    this.router.navigate(['matches-overview']);
  }

}
