import { createSelector } from 'reselect';

import * as fromBeachMatchStates from './match-states/beach-match-states.reducer.js';
import * as fromBeachMatchState from './match-states/beach-match-state.reducer.js';
import * as fromBeachGameState from './match-states/game-state/beach-game-state.reducer.js';
import * as fromTeamSides from '../../reducers/game-state/match-sets/team-sides/team-sides.reducer.js';
import * as fromBeachMatchSets from './match-states/game-state/match-sets/beach-match-sets.reducer.js';
import * as fromBeachMatchSet from './match-states/game-state/match-sets/match-set/beach-match-set.reducer.js';
import * as fromBeachLineups from './match-states/game-state/match-sets/match-set/lineups/beach-lineups.reducer.js';
import * as fromTimeKeeping from '../../reducers/game-state/match-sets/time-keeping/time-keeping.reducer.js';
import * as fromServing from '../../reducers/game-state/match-sets/serving/serving.reducer.js';
import * as fromLineups from '../../reducers/game-state/match-sets/lineups/lineups.reducer.js';
import * as fromSanctions from '../../reducers/game-state/sanctions/sanctions.reducer.js';
import * as fromBeachTeamSquads from './match-states//team-squads/beach-team-squads.reducer.js';

import { FieldPositions } from '../../reducers/game-state/match-sets/lineups/lineups.reducer.js';
import {
  BeachMatchStates,
  beachMatchStatesReducer
} from './match-states/beach-match-states.reducer.js';
import { BeachPlayer } from '../model/beach-team.js';
import { BeachMatch } from '../model/beach-match.js';
import { BeachScoreConfiguration } from '../model/beach-score-configuration.js';
import {
  BeachLineup
} from './match-states/game-state/match-sets/match-set/lineups/beach-lineups.reducer.js';
import {
  BeachTeamSquad
} from './match-states/team-squads/beach-team-squad.reducer.js';
import {
  BeachMatchSets
} from './match-states/game-state/match-sets/beach-match-sets.reducer.js';
import {
  beachScoreMetaReducer,
  BeachScoreMeta,
  getScoreVersion
} from './beach-score-meta.reducer'
import { ByTeamCode, TeamCode } from '../../interfaces/models/team-codes.js';
import { ByTeamSide, TeamSide } from '../../interfaces/models/team-sides.js';
import {
  PositionsByTeamSides,
  Root,
  SanctionsByTeamSides,
  fromTeamCodesToTeamSides,
  getMatchId,
  projectSetNumber,
  projectTeamCode,
  projectTeamSide,
  sharedReducers
} from '../../reducers/shared.reducer.js';

export interface BeachRoot extends Root {
  matchStates: BeachMatchStates;
  samsScore: BeachScoreMeta
}

export const beachReducers = {
  ...sharedReducers,
  matchStates: beachMatchStatesReducer,
  samsScore: beachScoreMetaReducer
};

export type BeachLineups = ByTeamCode<BeachLineup>;

// ==== Selectors ====

// # match-states
export const getBeachMatchStates = (state: BeachRoot) => state.matchStates;
export const getMatchesList = createSelector(getBeachMatchStates, fromBeachMatchStates.getMatchesList);
export const getSelectedBeachMatch = createSelector(getBeachMatchStates, getMatchId, fromBeachMatchStates.getMatchById);
export const getBeachMatchState = createSelector(getBeachMatchStates, getMatchId, fromBeachMatchStates.getMatchState);
export const getGameState = createSelector(getBeachMatchState, fromBeachMatchState.getGameState);
export const getGameStates = createSelector(getBeachMatchState, fromBeachMatchState.getGameStates);
export const isGameStateTouched = createSelector(getBeachMatchState, fromBeachMatchState.isGameStateTouched);
export const getBeachMatch = createSelector(getBeachMatchState, (matchState) => matchState.match);
export const getBeachTeamSquads = createSelector(getBeachMatchState, fromBeachMatchState.getTeamSquads);
export const getGameHistory = createSelector(getBeachMatchState, fromBeachMatchState.getGameHistory);
export const getBeachComments = createSelector(getBeachMatchState, matchState => matchState.matchInfo.comments);
export const getBeachArbitration = createSelector(getBeachMatchState, matchState => matchState.matchPreparation.arbitration);
// # match-states

// # team-squads
export const getBeachMatchPreparation = createSelector(getBeachMatchState, state => state.matchPreparation)
export const getStartingTeamSides = createSelector(getBeachMatchState, state => state.matchPreparation.teamSides);
export const getStartingServingTeamCode = createSelector(getBeachMatchState, state => state.matchPreparation.servingTeamCode);
export const areBothBeachTeamSquadsSigned = createSelector(getBeachTeamSquads, fromBeachTeamSquads.areBothBeachTeamSquadsSigned);
// # team-squads

// # score-config
export const getBeachScoreConfiguration = createSelector(getBeachMatch, match => match.scoreConfiguration as BeachScoreConfiguration);
// # score-config

// # match
export const getBeachMatchConfiguration = createSelector(getBeachMatch, match => match.matchConfiguration);
export const getBeachTeam1 = createSelector(getBeachMatch, match => match.team1);
export const getBeachTeam2 = createSelector(getBeachMatch, match => match.team2);
export const getBeachTeam1Description = createSelector(getBeachMatch, match => match.team1Description);
export const getBeachTeam2Description = createSelector(getBeachMatch, match => match.team1Description);
export const getBeachTeamByCode = createSelector(getBeachMatch, projectTeamCode, (state: BeachMatch, teamCode: TeamCode) => state[teamCode]);
// # match

// # game-state
export const getBeachMatchSets = createSelector(getGameState, fromBeachGameState.getMatchSets);
// # game-state


// # match-sets
export const getCurrentBeachMatchSet = createSelector(getBeachMatchSets, fromBeachMatchSets.getCurrentMatchSet);
export const getMatchSetForSetNumber = createSelector(getBeachMatchSets, projectSetNumber, fromBeachMatchSets.getMatchSetForSetNumber)
export const getCurrentSetIndex = createSelector(getBeachMatchSets, fromBeachMatchSets.getCurrentSetIndex);
export const getCurrentSetNumber = createSelector(getBeachMatchSets, fromBeachMatchSets.getCurrentSetNumber);
export const getSetPointsByTeamCodes = createSelector(getBeachMatchSets, fromBeachMatchSets.getSetPoints);
export const getIsMatchFinished = createSelector(getBeachMatchSets, fromBeachMatchSets.isBeachMatchFinished);
export const getHasMatchStarted = createSelector(getBeachMatchSets, fromBeachMatchSets.isMatchStarted);
export const getMaxTimeoutsPerSet = createSelector(getBeachScoreConfiguration, config => config.maxTimeoutsPerSet);
export const isDecidingSet = createSelector(getBeachMatchSets, getBeachMatchConfiguration, fromBeachMatchSets.isDecidingSet);
export const getHasCurrentSetStarted = createSelector(getBeachMatchSets, getBeachMatchConfiguration, fromBeachMatchSets.getHasCurrentSetStarted);

export const getCurrentSetPauseStart = createSelector(getBeachMatchSets, getCurrentSetIndex, (state: BeachMatchSets, currentSetIndex: number) => {
  const previousSet = state.sets[currentSetIndex - 1];
  if (previousSet) {
    return previousSet.timeKeeping.endTimeMs;
  }
  return 0;
})
// # match-sets

// # match-set

export const getCurrentSetScore = createSelector(getCurrentBeachMatchSet, fromBeachMatchSet.getSetScore);
export const getIsSetRunning = createSelector(getCurrentBeachMatchSet, fromBeachMatchSet.isSetRunning);
export const getTeamSides = createSelector(getCurrentBeachMatchSet, fromBeachMatchSet.getTeamSides);
export const getLeftTeamCode = createSelector(getTeamSides, fromTeamSides.getLeftTeamCode);
export const getRightTeamCode = createSelector(getTeamSides, fromTeamSides.getRightTeamCode);
export const getLeftTeam = createSelector(getBeachMatch, getLeftTeamCode, (state: BeachMatch, teamCode: TeamCode) => state[teamCode]);
export const getRightTeam = createSelector(getBeachMatch, getRightTeamCode, (state: BeachMatch, teamCode: TeamCode) => state[teamCode]);
export const getTimeKeeping = createSelector(getCurrentBeachMatchSet, fromBeachMatchSet.getTimeKeeping);
export const getCoinTossWinner = createSelector(getBeachMatchPreparation, state => state.coinTossWinner);
export const getCurrentSetScoreByTeamSides = createSelector(getCurrentSetScore, getTeamSides, fromTeamCodesToTeamSides);
export const getSetPointsByTeamSides = createSelector(getSetPointsByTeamCodes, getTeamSides, fromTeamCodesToTeamSides);
export const getNumberOfTimeouts = createSelector(getTimeKeeping, fromTimeKeeping.getNumberOfTimeouts);

const getBeachLineups = createSelector(getCurrentBeachMatchSet, fromBeachMatchSet.getLineups);
export const getServing = createSelector(getBeachLineups, fromBeachLineups.getServing);
export const getCurrentlyServing = createSelector(getServing, fromServing.getCurrentlyServing);
export const isCurrentlyServing = createSelector(getCurrentlyServing, projectTeamCode, fromServing.isCurrentlyServing);

export const getNumberOfTimeoutsByTeamSides = createSelector(getNumberOfTimeouts, getTeamSides, fromTeamCodesToTeamSides);

export const getSanctions = createSelector(getGameState, fromBeachGameState.getSanctions);
export const getSanctionsByTeamSides = createSelector(getSanctions, getTeamSides, fromTeamCodesToTeamSides);

export const getSanctionsOfTeam = createSelector(getSanctionsByTeamSides, projectTeamSide, (state: SanctionsByTeamSides, teamSide: TeamSide) => state[teamSide]);
export const getIndividualSanctions = createSelector(getSanctionsOfTeam, fromSanctions.getIndividualSanctions)
export const getIndividualExpulsions = createSelector(getSanctionsOfTeam, fromSanctions.getIndividualExpulsions);
export const getIndividualDisqualifications = createSelector(getSanctionsOfTeam, fromSanctions.getIndividualDisqualifications);
export const getIndividualPenalties = createSelector(getSanctionsOfTeam, fromSanctions.getIndividualPenalties);
export const getIndividualWarnings = createSelector(getSanctionsOfTeam, fromSanctions.getIndividualWarnings);

export const getBeachTeamSquadsByTeamSides = createSelector(
  getBeachTeamSquads,
  getTeamSides,
  fromTeamCodesToTeamSides);

export const getBeachLineupsByTeamSides = createSelector(
  getBeachLineups,
  getTeamSides,
  fromTeamCodesToTeamSides);
export const getBeachTeamLineup = createSelector(getBeachLineupsByTeamSides, projectTeamSide, (state: BeachLineupsByTeamSides, teamSide: TeamSide) => state[teamSide]);

export const getPositionsByTeamSide = createSelector(getBeachScoreConfiguration, fromLineups.getPositionsByTeamSide);
export const getPositions = createSelector(getPositionsByTeamSide, projectTeamSide, (state: PositionsByTeamSides, teamSide: TeamSide) => state[teamSide]);
const orderCurrentLineupByPositions = (positions: FieldPositions, currentLineup: BeachPlayer[]) =>
  currentLineup.map((p, i) => currentLineup[positions[i] - 1]);

export const getCurrentBeachTeamLineup = createSelector(getBeachTeamLineup, lineup => lineup.current)
export const getBeachFieldPlayers = createSelector(getPositions, getCurrentBeachTeamLineup, orderCurrentLineupByPositions)

export const getBeachTeamSquadByTeamCode = createSelector(getBeachTeamSquads, projectTeamCode, (state: ByTeamCode<BeachTeamSquad>, teamCode: TeamCode) => state[teamCode]);
export const getBeachTeamSquad = createSelector(getBeachTeamSquadsByTeamSides, projectTeamSide, (state: BeachTeamSquadsByTeamSides, teamSide: TeamSide) => state[teamSide]);

export type BeachLineupsByTeamSides = ByTeamSide<BeachLineup>;
export type BeachTeamSquadsByTeamSides = ByTeamSide<BeachTeamSquad>;
