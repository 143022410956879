
import { debounceTime, take } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import * as moment from 'moment';

import { Match } from 'app/models';

import {
  ArbitrationMemberTypes,
  ArbitrationMemberType,
  ArbitrationMember
} from '../../../../shared/interfaces/models/arbitration';
import {
  ToggleArbitrationMemberAction,
  EditStartTimeAction,
  ConfirmArbitrationAction,
  EditArbitrationMemberAction
} from '../../../../shared/reducers/match-states/match-modification.action';

import * as fromIndoor from 'app/root/indoor.reducer';
import * as fromShared from 'app/root/shared.reducer';
import { RefereePinOrigins } from '../../../../shared/interfaces/models/referee-pin-origin';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '../match-view/dialogs/info-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ToggleLiberoRegistrationAction } from '../../../../shared/reducers/match-states/match-modification.action';
import { IndoorRoot } from 'app/root/indoor.reducer';
import { DispatchService } from 'app/connections/dispatch.service';


@Component({
  selector: 'sams-match-preparation',
  template: `
  <div class="match-preparation" *ngIf="selectedMatch">
    <sams-syncing *ngIf="!selectedMatch.isTestMatch" [matchId]="matchId"></sams-syncing>

    <mat-toolbar color="secondary">
      <button mat-button [disabled]="arbitrationConfirmed" (click)="navigateBack()">{{'app.back_to_team_squads' | translate}}</button>
      <span style="flex: 1 1 auto;"></span>
      <div (click)="navigateHome()" style="height: 100%; width: auto; cursor: pointer;">
        <sams-score-logo></sams-score-logo>
      </div>
      <span style="flex: 1 1 auto;"></span>
      <button mat-raised-button [ngClass]="{ 'forward': arbitrationConfirmed }" [disabled]="!arbitrationConfirmed" (click)="onForward()">{{'app.forward_to_match_view' | translate}}</button>
      <sams-connection></sams-connection>
    </mat-toolbar>

    <div class="container">
      <mat-card>
        <mat-card-content>
          <div class="flex-container">
            <div class="left-column">
              <div class="association">
                <img *ngIf="selectedMatch.association?.logoImage?.url" src="{{selectedMatch.association?.logoImage?.url}}" alt="{{selectedMatch.matchNumber}}" height="42" width="auto">
                <div class="association-name">
                  <p>{{selectedMatch.association?.name}}</p>
                  <p>{{selectedMatch.matchSeries?.name}}</p>
                </div>
              </div>

              <mat-checkbox class="text-wrap" *ngIf="isLiberoRegistrationSelect" (change)="onToggleLiberoRegistration()" [checked]="liberoRegistrationEnabled">
                {{'component.match-preparation.libero_registration' | translate}}
              </mat-checkbox>
              <p class="libero-registration-hint" *ngIf="isLiberoRegistrationSelect">
                {{'component.match-preparation.libero_registration_hint' | translate}}
              </p>

              <div class="required-label" *ngIf="numberOfSpectatorsSubmissionMandatory">*{{'app.required' | translate}}</div>
              <sams-num-of-spectators [matchId]="matchId" [numOfSpectators]="numOfSpectators$ | async" [required]="numberOfSpectatorsSubmissionMandatory" [placeholder]="'app.number_of_spectators'"></sams-num-of-spectators>

              <br>

              <div class="required-label">*{{'app.required' | translate}}</div>
              <mat-form-field class="input-container">
                <input matInput [ngClass]="{ 'warn': matchTimeDiffersByOverAnHour && matchTimeDiffersInfo }" type="datetime-local" [value]="date" (change)="onMatchStartTimeChange($event.target.value)" [placeholder]="'component.match-preparation.match_start_time' | translate" required>
              </mat-form-field>

              <br>

            </div>
              
            <div class="right-column">
              <button mat-raised-button (click)="onConfirm()" class="positive" *ngIf="!arbitrationConfirmed" [disabled]="!isConfirmationAvailable()">
                <mat-icon>send</mat-icon> {{'app.confirm' | translate}}
              </button>
              <div *ngIf="arbitrationConfirmed">
                <div class="pin-container" *ngIf="!pinUnknown">
                  <sams-pin
                    *ngIf="isRefereePinRequired"
                    placeholder="{{'app.referee_pin' | translate}}"
                    buttonLabel="{{'component.match-preparation.undo_confirmation' | translate}}"
                    buttonClass="negative"
                    buttonIcon="cancel"
                    [pin]="arbitrationPin$ | async"
                    (failure)="onRefereePinFailure($event)"
                    (success)="onUnconfirm()">
                  </sams-pin>
                  <button *ngIf="!isRefereePinRequired" mat-raised-button class="negative" (click)="onUnconfirm()">
                    <mat-icon>cancel</mat-icon> {{'component.match-preparation.undo_confirmation' | translate}}
                  </button>
                  <button *ngIf="teamPinFinalizationEnabled && refereePinFailure && !pinUnknown" mat-raised-button class="pin-unknown-button" (click)="onPinUnknown()">
                    {{'app.unknown_pin' | translate}}
                  </button>
                </div>


                <div *ngIf="pinUnknown">
                    <div class="team-pins">
                      <sams-pin
                        placeholder="{{team1.name}}"
                        buttonLabel="{{'app.confirm' | translate}}"
                        [pin]="team1.pin"
                        [disabled]="team1PinHash"
                        (success)="onTeam1PinSuccess($event)">
                      </sams-pin>
                      <sams-pin
                        placeholder="{{team2.name}}"
                        buttonLabel="{{'app.confirm' | translate}}"
                        [pin]="team2.pin"
                        [disabled]="team2PinHash"
                        (success)="onTeam2PinSuccess($event)">
                      </sams-pin>
                    </div>
                    <div class="action-buttons">
                      <button class="negative" [disabled]="!refereePinEnabled" mat-raised-button (click)="onUnconfirm()">
                        <mat-icon>cancel</mat-icon> {{'component.match-preparation.undo_confirmation' | translate}}
                      </button>
                      <button mat-raised-button (click)="onRevertPinUnknown()">
                        <mat-icon>cancel</mat-icon> {{'app.cancel' | translate}}
                      </button>
                    </div>
                </div>

              </div>
              
            </div>
          </div>

        <mat-accordion [multi]="true" class="referees">

          <sams-arbitration-selection
            [required]="true"
            [editable]="true"
            [arbitrationMember]="firstReferee || {}"
            (submit)="onArbitrationMemberEdited($event, ArbitrationMemberTypes.firstReferee)"
            (toggle)="onArbitrationMemberToggled(ArbitrationMemberTypes.firstReferee)"
            [selectable]="!arbitrationConfirmed"
            label="{{'app.first_referee' | translate}}">
          </sams-arbitration-selection>

          <sams-arbitration-selection
            [required]="true"
            [editable]="true"
            [arbitrationMember]="secondReferee || {}"
            (submit)="onArbitrationMemberEdited($event, ArbitrationMemberTypes.secondReferee)"
            (toggle)="onArbitrationMemberToggled(ArbitrationMemberTypes.secondReferee)"
            [selectable]="!arbitrationConfirmed"
            label="{{'app.second_referee' | translate}}">
          </sams-arbitration-selection>

          <sams-arbitration-selection
            *ngIf="isChallengeRefereeEnabled"
            [required]="false"
            [editable]="true"
            [arbitrationMember]="challengeReferee || {}"
            (submit)="onArbitrationMemberEdited($event, ArbitrationMemberTypes.challengeReferee)"
            (toggle)="onArbitrationMemberToggled(ArbitrationMemberTypes.challengeReferee)"
            [selectable]="!arbitrationConfirmed"
            label="{{'app.challenge_referee' | translate}}">
          </sams-arbitration-selection>
          
          <sams-arbitration-selection
            [required]="false"
            [editable]="true"
            [arbitrationMember]="firstLinesman || {}"
            (submit)="onArbitrationMemberEdited($event, ArbitrationMemberTypes.firstLinesman)"
            (toggle)="onArbitrationMemberToggled(ArbitrationMemberTypes.firstLinesman)"
            [selectable]="!arbitrationConfirmed"
            label="{{'app.first_linesman' | translate}}">
          </sams-arbitration-selection>
          
          <sams-arbitration-selection
            [required]="false"
            [editable]="true"
            [arbitrationMember]="secondLinesman || {}"
            (submit)="onArbitrationMemberEdited($event, ArbitrationMemberTypes.secondLinesman)"
            (toggle)="onArbitrationMemberToggled(ArbitrationMemberTypes.secondLinesman)"
            [selectable]="!arbitrationConfirmed"
            label="{{'app.second_linesman' | translate}}">
          </sams-arbitration-selection>

          <sams-arbitration-selection
            [required]="false"
            [editable]="true"
            [arbitrationMember]="thirdLinesman || {}"
            (submit)="onArbitrationMemberEdited($event, ArbitrationMemberTypes.thirdLinesman)"
            (toggle)="onArbitrationMemberToggled(ArbitrationMemberTypes.thirdLinesman)"
            [selectable]="!arbitrationConfirmed"
            label="{{'app.third_linesman' | translate}}">
          </sams-arbitration-selection>

          <sams-arbitration-selection
            [required]="false"
            [editable]="true"
            [arbitrationMember]="fourthLinesman || {}"
            (submit)="onArbitrationMemberEdited($event, ArbitrationMemberTypes.fourthLinesman)"
            (toggle)="onArbitrationMemberToggled(ArbitrationMemberTypes.fourthLinesman)"
            [selectable]="!arbitrationConfirmed"
            label="{{'app.fourth_linesman' | translate}}">
          </sams-arbitration-selection>

          <sams-arbitration-selection
            [required]="true"
            [editable]="!arbitrationConfirmed"
            [arbitrationMember]="scorer || {}"
            (submit)="onArbitrationMemberEdited($event, ArbitrationMemberTypes.scorer)"
            (toggle)="onArbitrationMemberToggled(ArbitrationMemberTypes.scorer)"
            [selectable]="!arbitrationConfirmed"
            label="{{'app.scorer' | translate}}">
          </sams-arbitration-selection>

          <sams-arbitration-selection
            [required]="true"
            [editable]="!arbitrationConfirmed"
            [arbitrationMember]="scorerAssistant || {}"
            (submit)="onArbitrationMemberEdited($event, ArbitrationMemberTypes.scorerAssistant)"
            (toggle)="onArbitrationMemberToggled(ArbitrationMemberTypes.scorerAssistant)"
            [selectable]="!arbitrationConfirmed"
            label="{{'app.scorer_assistant' | translate}}">
          </sams-arbitration-selection>

          <sams-arbitration-selection
            [required]="false"
            [editable]="true"
            [arbitrationMember]="observer || {}"
            (submit)="onArbitrationMemberEdited($event, ArbitrationMemberTypes.observer)"
            (toggle)="onArbitrationMemberToggled(ArbitrationMemberTypes.observer)"
            [selectable]="!arbitrationConfirmed"
            label="{{'app.observer' | translate}}">
          </sams-arbitration-selection>

        </mat-accordion>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  `,
  styles: [` 
  :host::ng-deep.mat-checkbox-layout{
    white-space: normal !important;
    }
    
    :host::ng-deep.mat-checkbox-inner-container{
        margin-top: 4px;
    }
    .left-column {
      width: 35%;
    }
    .libero-registration-hint {
      font-size: 10px;
      color: grey;
    }
    .pin-container {
      display: flex;
      flex-direction: column
    }
    .team-pins > * {
      margin-bottom: 15px;
    }
    .action-buttons {
    }
    .action-buttons > button {
      margin-right: 15px;
      margin-bottom: 15px;
    }
    .team-pins {
      display: flex;
      flex-direction: column;
    }
    sams-arbitration-selection {
      margin: 5px 0px;
      display: block;
    }
    .container {
      width: 630px;
      margin: 50px auto;
    }
    .arbitration {
      margin-top: 25px;
    }
    .forward {
      background-color: lightgreen;
    }

    .match-info {
      margin-top: 30px;
    }

    .flex-container {
      display: flex;
      justify-content: space-around;
    }

    .name {
      font-weight: bold;
    }
    .input-container {
      width: 100%;
    }
    .warn {
      color: white;
      background: tomato;
    }

  `]
})
export class MatchPreparationComponent implements OnInit, OnDestroy {

  numberOfSpectatorsSubmissionMandatory: boolean;

  numOfSpectators$: Observable<number>;

  selectedMatch: Match;

  arbitrationPin$: Observable<string>;

  private subscription = new Subscription();

  matchStartTimeChange = new Subject<string>();

  team1PinHash: string;

  team2PinHash: string;

  refereePinFailure = false;

  pinUnknown = false;

  matchTimeDiffersInfo = false;
  
  constructor(public store: Store<IndoorRoot>,
    public router: Router,
    public dispatchService: DispatchService,
    public dialog: MatDialog,
    public translate: TranslateService) {

  }

  ngOnInit() {

    this.subscription.add(
      this.store.select(fromIndoor.getMatch).subscribe(match => this.selectedMatch = match)
    );
    this.numOfSpectators$ = this.store.select(fromIndoor.getNumOfSpectators);

    this.subscription.add(
      this.store.select(fromIndoor.getNumberOfSpectatorsSubmissionMandatory)
        .subscribe(numberOfSpectatorsSubmissionMandatory =>
          this.numberOfSpectatorsSubmissionMandatory = numberOfSpectatorsSubmissionMandatory)
    );

    this.arbitrationPin$ = this.store.select(fromIndoor.getArbitrationPin);

    this.subscription.add(
      this.matchStartTimeChange.pipe(
        debounceTime(500))
        .subscribe(date => {
          this.dispatchService.dispatchRemoteAction(new EditStartTimeAction(new Date().toISOString(), this.matchId))
        })
    )

  }

  get matchTimeDiffersByOverAnHour() {
    const now = +new Date()
    const matchDate = +new Date(this.date)
    return Math.abs(now - matchDate) > 3600000
  }

  get matchTimeDiffersByOver48Hours() {
    const now = +new Date()
    const matchDate = +new Date(this.date)
    return Math.abs(now - matchDate) > 3600000 * 48
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  get isChallengeRefereeEnabled() {
    return this.selectedMatch.scoreConfiguration.videoChallengeAvailable;
  }

  get isLiberoRegistrationSelect() {
    return this.selectedMatch.scoreConfiguration.liberoRegistration === 'SELECT'
  }

  isConfirmationAvailable() {
    if (this.arbitrationConfirmed) {
      return false;
    }
    if (this.selectedMatch.numOfSpectators == null && this.numberOfSpectatorsSubmissionMandatory) {
      return false;
    }
    return this.isAllRequiredArbitrationMembersSelected();
  }

  isAllRequiredArbitrationMembersSelected() {
    if (this.arbitrationConfirmed) {
      return false;
    }
    for (const t of ['scorer', 'scorerAssistant', 'firstReferee', 'secondReferee']) {
      if (!this.arbitration[t] || !this.arbitration[t].selected) {
        return false;
      }
    }
    return true;
  }

  onForward() {
    this.navigate()
  }

  navigate() {
    this.router.navigate(['/match-view']);
  }

  navigateBack() {
    this.router.navigate(['/team-preparations']);
  }

  navigateHome() {
    this.router.navigate(['/matches-overview']);
  }

  onConfirm() {
    if (this.matchTimeDiffersByOver48Hours) {
      this.matchTimeDiffersInfo = false
      const dialogRef: MatDialogRef<InfoDialogComponent, boolean> = this.dialog.open(InfoDialogComponent);
      dialogRef.componentInstance.title = this.translate.get('component.match-time-info-dialog-48.title');
      dialogRef.componentInstance.content = this.translate.get('component.match-time-info-dialog-48.info_msg');
      dialogRef.componentInstance.buttonText = this.translate.get('component.match-time-info-dialog-48.ok');
      return
    }
    if (this.matchTimeDiffersByOverAnHour && !this.matchTimeDiffersInfo) {
      this.matchTimeDiffersInfo = true
      const dialogRef: MatDialogRef<InfoDialogComponent, boolean> = this.dialog.open(InfoDialogComponent);
      dialogRef.componentInstance.title = this.translate.get('component.match-time-info-dialog.title');
      dialogRef.componentInstance.content = this.translate.get('component.match-time-info-dialog.info_msg');
      dialogRef.componentInstance.buttonText = this.translate.get('component.match-time-info-dialog.ok');
    } else {
      this.dispatchService.dispatchRemoteAction(new ConfirmArbitrationAction(this.matchId));
    }
  }

  onUnconfirm() {
    this.pinUnknown = false;
    this.refereePinFailure = false;
    this.dispatchService.dispatchRemoteAction(new ConfirmArbitrationAction(this.matchId));
  }

  onRefereePinFailure() {
    this.refereePinFailure = true;
  }

  onPinUnknown() {
    this.pinUnknown = true;
  }

  get refereePinEnabled() {
    return typeof this.team1PinHash !== 'undefined'
      && typeof this.team2PinHash !== 'undefined';
  }

  onRevertPinUnknown() {
    this.refereePinFailure = false;
    this.pinUnknown = false;
  }

  onTeam1PinSuccess(newHash: string) {
    this.team1PinHash = newHash;
  }

  onTeam2PinSuccess(newHash: string) {
    this.team2PinHash = newHash;
  }

  onMatchStartTimeChange(date: string) {
    this.matchStartTimeChange.next(date)
  }

  onArbitrationMemberEdited(member: ArbitrationMember, type: ArbitrationMemberType) {
    this.dispatchService.dispatchRemoteAction(new EditArbitrationMemberAction({ member, type }, this.selectedMatch.uuid));
  }

  onArbitrationMemberToggled(type: ArbitrationMemberType) {
    this.dispatchService.dispatchRemoteAction(new ToggleArbitrationMemberAction(type, this.selectedMatch.uuid));
  }

  onToggleLiberoRegistration() {
    this.dispatchService.dispatchRemoteAction(new ToggleLiberoRegistrationAction(this.selectedMatch.uuid))
  }

  get arbitration() {
    return this.selectedMatch.arbitration;
  }

  get firstReferee() {
    return this.arbitration.firstReferee;
  }

  get secondReferee() {
    return this.arbitration.secondReferee;
  }

  get challengeReferee() {
    return this.arbitration.challengeReferee;
  }

  get firstLinesman() {
    return this.arbitration.firstLinesman;
  }

  get secondLinesman() {
    return this.arbitration.secondLinesman;
  }

  get thirdLinesman() {
    return this.arbitration.thirdLinesman;
  }

  get fourthLinesman() {
    return this.arbitration.fourthLinesman;
  }
  
  get ArbitrationMemberTypes() {
    return ArbitrationMemberTypes;
  }

  get scorer() {
    return this.arbitration.scorer;
  }

  get scorerAssistant() {
    return this.arbitration.scorerAssistant;
  }

  get observer() {
    return this.arbitration.observer;
  }

  get date() {
    return moment(this.selectedMatch.date).format('YYYY-MM-DDTHH:mm');
  }

  get numOfSpectators() {
    return this.selectedMatch.numOfSpectators;
  }

  get matchId() {
    return this.selectedMatch.uuid;
  }

  get arbitrationConfirmed() {
    return this.selectedMatch.arbitrationConfirmed === true;
  }

  get isRefereePinRequired() {
    return this.selectedMatch.scoreConfiguration.refereePinOrigin !== RefereePinOrigins.NONE
  }

  get teamPinFinalizationEnabled() {
    return this.selectedMatch.scoreConfiguration.teamPinFinalizationEnabled;
  }

  get team1() {
    return this.selectedMatch.team1;
  }
  get team2() {
    return this.selectedMatch.team2;
  }

  get liberoRegistrationEnabled() {
    return this.selectedMatch.liberoRegistrationEnabled
  }

}
