import { MatchAction } from '../../interfaces/interfaces.js';
import { Player } from '../../interfaces/models/player.js';
import { TeamCode } from '../../interfaces/models/team-codes.js';
import { ArbitrationMemberType, ArbitrationMember } from '../../interfaces/models/arbitration.js';
import { Official } from '../../interfaces/models/official.js';
import { SelectedJersey } from '../../interfaces/models/jerseys.js';
import { MatchFinalizationAction } from './match-finalization/match-finalization.action.js';

export const EDIT_ARBITRATION_MEMBER    = '[Match] Edit Arbitration Member';
export const ADD_PLAYER                 = '[Match] Add Player';
export const EDIT_PLAYER                = '[Match] Edit Player';
export const ADD_OFFICIAL               = '[Match] Add Official';
export const EDIT_OFFICIAL              = '[Match] Edit Official';
export const CHANGE_JERSEY_NUMBER       = '[Match] Change Jersey Number';
export const SAVE_COMMENTS              = '[Match] Save Comments';
export const TOGGLE_ARBITRATION_MEMBER  = '[Match] Toggle Referee';
export const EDIT_NUM_OF_SPECTATORS     = '[Match] Edit number of Spectators';
export const EDIT_START_TIME            = '[Match] Edit start time';
export const CONFIRM_ARBITRATION        = '[Match] Confirm arbitration';
export const SET_SHIRT_COLOR           = '[Match] Set shirt color';
export const SET_SHORTS_COLOR           = '[Match] Set shorts color';
export const CHANGE_JERSEY              = '[Match] Change jersey';
export const TOGGLE_LIBERO_REGISTRATION = '[Match] Toggle libero registration';
export const APPROVE_PLAYER_LICENSE     = '[Match] Approved player license';
export const DENY_PLAYER_LICENSE      = '[Match] Denied player license';

export interface ArbitrationMemberEditing {
  member: ArbitrationMember;
  type: ArbitrationMemberType;
}

export interface AddPlayer {
  player: Player;
  teamCode: TeamCode;
}

export interface AddOfficial {
  official: Official;
  teamCode: TeamCode;
}

export interface ApprovePlayerLicense {
  uuid: string;
  teamCode: TeamCode;
}

export interface DenyPlayerLicense {
  uuid: string;
  teamCode: TeamCode;
}

export type EditPlayer = AddPlayer;
export type EditOfficial = AddOfficial;

export interface ChangeJerseyNumber {
  jerseyNumber: string;
  playerId: string;
  teamCode: TeamCode;
}

export class AddPlayerAction extends MatchAction {
  readonly type = ADD_PLAYER;
  constructor(public payload: AddPlayer, matchId: string) {
    super(matchId);
  }
}

export class EditPlayerAction extends MatchAction {
  readonly type = EDIT_PLAYER;
  constructor(public payload: EditPlayer, matchId: string) {
    super(matchId);
  }
}

export class AddOfficialAction extends MatchAction {
  readonly type = ADD_OFFICIAL;
  constructor(public payload: AddOfficial, matchId: string) {
    super(matchId);
  }
}

export class EditOfficialAction extends MatchAction {
  readonly type = EDIT_OFFICIAL;
  constructor(public payload: EditOfficial, matchId: string) {
    super(matchId);
  }
}

export class EditArbitrationMemberAction extends MatchAction {
  readonly type = EDIT_ARBITRATION_MEMBER;
  constructor(public payload: ArbitrationMemberEditing, matchId: string) {
    super(matchId);
  }
}

export class ChangeJerseyNumberAction extends MatchAction {
  readonly type = CHANGE_JERSEY_NUMBER;
  constructor(public payload: ChangeJerseyNumber, matchId: string) {
    super(matchId);
  }
}

export class SaveCommentsAction extends MatchAction {
  readonly type = SAVE_COMMENTS;
  constructor(public payload: string, matchId: string) {
    super(matchId);
  }
}

export class EditNumOfSpectatorsAction extends MatchAction {
  readonly type = EDIT_NUM_OF_SPECTATORS;
  constructor(public payload: number, matchId: string) {
    super(matchId);
  }
}

export class EditStartTimeAction extends MatchAction {
  readonly type = EDIT_START_TIME;
  constructor(public payload: string, matchId: string) {
    super(matchId);
  }
}

export class ToggleArbitrationMemberAction extends MatchAction {
  readonly type = TOGGLE_ARBITRATION_MEMBER;
  constructor(public payload: ArbitrationMemberType, matchId: string) {
    super(matchId);
  }
}

export class ConfirmArbitrationAction extends MatchAction {
  readonly type = CONFIRM_ARBITRATION;
  constructor(matchId: string) {
    super(matchId);
  }
}

export interface SetColorPayload {
  teamCode: TeamCode
  color: string 
}

export class SetShirtColorAction extends MatchAction {
  readonly type = SET_SHIRT_COLOR;
  constructor(public payload: SetColorPayload, matchId: string) {
    super(matchId);
  }
}

export class SetShortsColorAction extends MatchAction {
  readonly type = SET_SHORTS_COLOR;
  constructor(public payload: SetColorPayload, matchId: string) {
    super(matchId);
  }
}

export interface ChangeJerseyPayload {
  teamCode: TeamCode
  jerseyType: SelectedJersey
}

export class ChangeJerseyAction extends MatchAction {
  readonly type = CHANGE_JERSEY;
  constructor(public payload: ChangeJerseyPayload, matchId: string) {
    super(matchId);
  }
}

export class ToggleLiberoRegistrationAction extends MatchAction {
  readonly type = TOGGLE_LIBERO_REGISTRATION;
  constructor(matchId: string) {
    super(matchId);
  }
}

export class ApprovePlayerLicenseAction extends MatchAction {
  readonly type = APPROVE_PLAYER_LICENSE;
  constructor(public payload: ApprovePlayerLicense, matchId: string) {
    super(matchId);
  }
}

export class DenyPlayerLicenseAction extends MatchAction {
  readonly type = DENY_PLAYER_LICENSE;
  constructor(public payload: DenyPlayerLicense, matchId: string) {
    super(matchId);
  }
}

export type MatchModificationAction
  = AddPlayerAction
  | EditPlayerAction
  | AddOfficialAction
  | EditOfficialAction
  | EditArbitrationMemberAction
  | ChangeJerseyNumberAction
  | SaveCommentsAction
  | EditStartTimeAction
  | EditNumOfSpectatorsAction
  | ToggleArbitrationMemberAction
  | ConfirmArbitrationAction
  | MatchFinalizationAction
  | SetShirtColorAction
  | SetShortsColorAction
  | ChangeJerseyAction
  | ToggleLiberoRegistrationAction
  | ApprovePlayerLicenseAction
  | DenyPlayerLicenseAction;
