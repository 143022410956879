
import { Subscription, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { TeamSides } from '../../../../shared/reducers/game-state/match-sets/team-sides/team-sides.reducer';
import * as fromIndoor from 'app/root/indoor.reducer';
import * as fromShared from 'app/root/shared.reducer';
import { Match, ByTeamSide } from 'app/models';

import { SwitchSidesAction } from '../../../../shared/reducers/game-state/match-sets/team-sides/team-sides.action';
import { LineupsByTeamSides, ChallengesByTeamSides } from '../root/indoor.reducer';
import { ScoreConfiguration } from '../../../../shared/interfaces/models/score-configuration';
import { TimeKeeping } from '../../../../shared/reducers/game-state/match-sets/time-keeping/time-keeping.reducer';
import { MatchSet } from '../../../../shared/reducers/game-state/match-sets/match-set.reducer';
import { MatchState } from '../../../../shared/reducers/match-states/match-state.reducer';
import { MatchSets } from '../../../../shared/reducers/game-state/match-sets/match-sets.reducer';
import { SetPointsByTeamSides } from 'app/root/shared.reducer';
import { SocketService } from 'app/connections/socket.service';

@Component({
  selector: 'sams-referee-view',
  template: `
  <div *ngIf="matchId" class="referee-view">

      <mat-toolbar color="secondary">
        <div (click)="navigateHome()" style="height: 100%; width: auto; cursor: pointer;">
          <sams-score-logo></sams-score-logo>
        </div>
      </mat-toolbar>
      
      <div class="flex-container">

        <div class="team-data left-team">
            <div class="team-score">{{currentSetScore.leftTeam}}</div>
          <img *ngIf="leftTeam.logoImageMax400x400?.url" [src]="leftTeam.logoImageMax400x400?.url" class="team-logo" [alt]="leftTeam.name">
          <h2>{{leftTeam.name}}</h2>
        </div>

        <div class="team-data right-team">
            <div class="team-score">{{currentSetScore.rightTeam}}</div>
          <img *ngIf="rightTeam.logoImageMax400x400?.url" [src]="rightTeam.logoImageMax400x400?.url" class="team-logo" [alt]="rightTeam.name">
          <h2>{{rightTeam.name}}</h2>
        </div>

      </div> <!-- flex-container -->

      <div class="flex-container">
        <div class="middle-column">

          <sams-lineups [readMode]="true"></sams-lineups>

          <div class="vertical-spacer"></div>

          <sams-countdown-display
            [matchId]="matchId"
            [timeKeeping]="timeKeeping$ | async"
            [scoreConfiguration]="scoreConfig"
            [hasSetStarted]="hasSetStarted"
            [isMatchFinished]="isMatchFinished$ | async"
            [currentMatchSet]="currentMatchSet"
            [currentSetPauseStart]="currentSetPauseStart$ | async"
            [readMode]="true"
            (cancelSetPause)="void(0)"
            (cancelTimeout)="void(0)"
            (cancelTechnicalTimeout)="void(0)">
          </sams-countdown-display>

          <div class="vertical-spacer"></div>

          <div class="stats-container">

            <div class="middle-column">
              <div class="info-container">

                <div class="set-points">
                  <div>{{setPoints.leftTeam}}</div>
                  Sätze
                  <div>{{setPoints.rightTeam}}</div>
                </div>

                <div class="number-of-timeouts">
                  <div [ngClass]="{ 'red': numberOfTimeoutsByTeamSides.leftTeam === maxTimeoutsPerSet, 'yellow' : numberOfTimeoutsByTeamSides.leftTeam === maxTimeoutsPerSet - 1 }">
                    {{numberOfTimeoutsByTeamSides.leftTeam}}
                  </div>
                  <mat-icon>timer</mat-icon>
                  <div [ngClass]="{ 'red': numberOfTimeoutsByTeamSides.rightTeam === maxTimeoutsPerSet, 'yellow' : numberOfTimeoutsByTeamSides.rightTeam === maxTimeoutsPerSet - 1 }">
                    {{numberOfTimeoutsByTeamSides.rightTeam}}
                  </div>
                </div>

                <div class="number-of-challenges">
                  <div [ngClass]="{ 'red': leftTeamChallengesFailed === numberOfChallengeRequestsByTeam, 'yellow' : leftTeamChallengesFailed === numberOfChallengeRequestsByTeam - 1 }">
                    {{leftTeamChallengesFailed}}
                  </div>
                  <mat-icon>videocam</mat-icon>
                  <div [ngClass]="{ 'red': rightTeamChallengesFailed === numberOfChallengeRequestsByTeam, 'yellow' : rightTeamChallengesFailed === numberOfChallengeRequestsByTeam - 1 }">
                    {{rightTeamChallengesFailed}}
                  </div>
                </div>

                <div class="num-of-substitutions">
                  <div [ngClass]="{
                    'yellow': lineups.leftTeam.numOfSubstitutions === (maxRegularSubstitutionsPerSet - 1),
                    'red': lineups.leftTeam.numOfSubstitutions === maxRegularSubstitutionsPerSet
                    }">
                    {{lineups.leftTeam.numOfSubstitutions}}
                  </div>
                  <mat-icon>compare_arrows</mat-icon>
                  <div [ngClass]="{
                    'yellow': lineups.rightTeam.numOfSubstitutions === (maxRegularSubstitutionsPerSet - 1),
                    'red': lineups.rightTeam.numOfSubstitutions === maxRegularSubstitutionsPerSet
                    }">
                    {{lineups.rightTeam.numOfSubstitutions}}
                  </div>
                </div>

              </div>

              <button class="change-serving-button" mat-raised-button (click)="switchSides()">{{'component.match-view.switch_sides' | translate}}</button>

              <div class="vertical-spacer"></div>

              <sams-match-sets class="referee-match-sets" [matchSets]="matchSets" [matchState]="matchState"></sams-match-sets>
            </div>


          </div>

        </div> <!-- middle-column -->
      </div>

  </div>
    
  `,
  styles: [`

    div.flex-container {
      display: flex;
      justify-content: center;
    }

    .info-container {
      background: #4d4d4d;
      padding: 15px;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    
    .info-container > div {
      display: flex;
      justify-content: space-between;
      padding: 5px 50px;
      border-bottom: 1px solid #404040;
      font-size: 40px;
    }

    div.middle-column {
      width: 90%;
      display: flex;
      flex-direction: column;
    }

    .team-data {
      display: flex;
      width: 45%;
      align-items: center;
    }

    .team-data h2 {
      padding: 5px;
      font-size: 4vw;
    }

    div.left-team {
      flex-direction: row;
      margin-right: 15px;
    }

    div.right-team {
      flex-direction: row-reverse;
      text-align: right;
      margin-left: 15px;
    }

    h2 {
      color: #424242;
    }

    .team-logo {
      max-width: 100px;
      max-height: 100px;
      margin: 15px;
    }

    .change-serving-button {
      margin-top: 15px;
      font-size: 16px;
    }

    div.stats-container {
      display: flex;
      justify-content: center;
      font-size: 20px;
    }

    div.team-score {
      text-align: center;
      font-size: 80px;
      width: 100px;
      height: 100px;
      letter-spacing: 0px;
      display: inline-block;
      color: tomato;
      background-color: #4d4d4d;
      padding: 0px 15px;
    }

    .yellow {
      color: gold;
    } 

    .red {
      color: tomato;
    }

  `]
})
export class RefereeViewComponent implements OnDestroy, OnInit {

  matchId: string;

  match: Match;

  teamSides: TeamSides;

  setPoints: SetPointsByTeamSides;

  currentSetScore: ByTeamSide<number>;

  lineups: LineupsByTeamSides;

  numberOfTimeoutsByTeamSides: ByTeamSide<number>;

  challengesByTeamSides: ChallengesByTeamSides;

  scoreConfig: ScoreConfiguration;

  hasSetStarted: boolean;

  currentMatchSet: MatchSet;

  matchState: MatchState;

  matchSets: MatchSets;

  currentSetPauseStart$: Observable<number>;

  timeKeeping$: Observable<TimeKeeping>;

  isMatchFinished$: Observable<boolean>;

  private subscription = new Subscription();

  constructor(public store: Store<fromIndoor.IndoorRoot>,
    public router: Router,
    public translate: TranslateService,
    public socketService: SocketService) {

  }

  ngOnInit() {

    this.subscription.add(
      this.store.select(fromShared.getMatchId).subscribe(id => this.matchId = id)
    );

    this.socketService.pullActionHistory(this.matchId)

    this.subscription.add(this.store.select(fromIndoor.getMatch).subscribe(match => this.match = match));

    this.subscription.add(
      this.store.select(fromIndoor.getSetPointsByTeamSides).subscribe(setPoints => this.setPoints = setPoints)
    );

    this.subscription.add(
      this.store.select(fromIndoor.getCurrentSetScoreByTeamSides).subscribe(currentSetScore => this.currentSetScore = currentSetScore)
    );

    this.subscription.add(
      this.store.select(fromIndoor.getTeamSides)
        .subscribe(teamSides => this.teamSides = teamSides)
    );

    this.subscription.add(
      this.store.select(fromIndoor.getLineupsByTeamSides).subscribe(lineups => this.lineups = lineups)
    );

    this.subscription.add(
      this.store.select(fromIndoor.getNumberOfTimeoutsByTeamSides)
        .subscribe(numberOfTimeoutsByTeamSides => this.numberOfTimeoutsByTeamSides = numberOfTimeoutsByTeamSides)
    );

    this.subscription.add(
      this.store.select(fromIndoor.getChallengesByTeamSides)
        .subscribe(challengesByTeamSides => this.challengesByTeamSides = challengesByTeamSides)
    )

    this.subscription.add(
      this.store.select(fromIndoor.getScoreConfiguration)
        .subscribe(scoreConfig => this.scoreConfig = scoreConfig)
    );

    this.currentSetPauseStart$ = this.store.select(fromIndoor.getCurrentSetPauseStart)

    this.timeKeeping$ = this.store.select(fromIndoor.getTimeKeeping);

    this.subscription.add(
      this.store.select(fromIndoor.getHasCurrentSetStarted).subscribe(hasSetStarted => this.hasSetStarted = hasSetStarted)
    );

    this.isMatchFinished$ = this.store.select(fromIndoor.getIsMatchFinished);

    this.subscription.add(
      this.store.select(fromIndoor.getCurrentMatchSet).subscribe(currentMatchSet => this.currentMatchSet = currentMatchSet)
    );

    this.subscription.add(
      this.store.select(fromIndoor.getMatchState).subscribe(matchState => this.matchState = matchState)
    );

    this.subscription.add(
      this.store.select(fromIndoor.getMatchSets).subscribe(matchSets => this.matchSets = matchSets)
    );

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  navigateHome() {
    this.router.navigate(['matches-overview']);
  }

  switchSides() {
    this.store.dispatch(new SwitchSidesAction(this.matchId));
  }

  get leftTeam() {
    return this.match[this.teamSides.leftTeam];
  }

  get rightTeam() {
    return this.match[this.teamSides.rightTeam];
  }

  get maxRegularSubstitutionsPerSet() {
    return this.scoreConfig.maxRegularSubstitutionsPerSet
  }

  get maxTimeoutsPerSet() {
    return this.scoreConfig.maxTimeoutsPerSet
  }

  get numberOfChallengeRequestsByTeam() {
    return this.scoreConfig.numberOfChallengeRequestsByTeam
  }

  get leftTeamChallengesFailed() {
    return this.challengesByTeamSides.leftTeam.numOfChallengesFailed
  }

  get rightTeamChallengesFailed() {
    return this.challengesByTeamSides.rightTeam.numOfChallengesFailed
  }

}
