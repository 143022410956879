import { ConnectionsService } from './connections.service';
import { ConnectionComponent } from './connection.component';
import { NotificationComponent } from './notification.component';
import { ListeningComponent } from './listening.component';
import { SyncingComponent } from './syncing.component';
import { SyncingDialogComponent } from './syncing-dialog.component';
import { DispatchService } from './dispatch.service';
import { SocketService } from './socket.service';

export const CONNECTIONS_DIALOGS = [
  SyncingDialogComponent
];

export const CONNECTIONS_SERVICES = [
  ConnectionsService,
  DispatchService,
  SocketService
];

export const CONNECTIONS_COMPONENTS = [
  ...CONNECTIONS_DIALOGS,
  ConnectionComponent,
  NotificationComponent,
  ListeningComponent,
  SyncingComponent
];
