import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Match, TeamCode } from '../../models';
import {
  ApprovePlayerLicense,
  ApprovePlayerLicenseAction,
  DenyPlayerLicense,
  DenyPlayerLicenseAction
} from '../../../../../shared/reducers/match-states/match-modification.action';
import {
  AdditionalClubPlayer,
  LicensedPlayer,
  Player
} from '../../../../../shared/interfaces/models/player';
import { Store } from '@ngrx/store';
import * as fromIndoor from 'app/root/indoor.reducer';
import { Subscription } from 'rxjs';
import { TeamSquad } from '../../../../../shared/reducers/match-preparation/team-squad.reducer';
import { DispatchService } from 'app/connections/dispatch.service';

export interface LicenseDialogData {
  matchId: string;
  teamCode: TeamCode;
}

@Component({
  selector: 'app-license-dialog',
  template: `
    <h2 mat-dialog-title>{{'component.license-dialog.title' | translate }} - {{team.name}}</h2>
    <mat-dialog-content>
      <mat-tab-group *ngIf="players.length > 0">
        <mat-tab
          *ngIf="pendingPlayers.length !== 0"
          [disabled]="pendingPlayers.length === 0"
          label="{{'component.license-dialog.pending_players' | translate: { pending: pendingPlayers.length } }}">
          <sams-license
            *ngIf="pendingPlayers.length > 0"
            [approvedView]="false"
            [players]="pendingPlayers"
            [team]="team"
            [match]="match"
            (approve)="approve($event)"
            (deny)="deny($event)"
            (finished)="finished()"
          ></sams-license>
        </mat-tab>
        <mat-tab
          [disabled]="approvedPlayers.length === 0"
          label="{{'component.license-dialog.approved_players' | translate: { approved: approvedPlayers.length } }}">
          <sams-license
            *ngIf="approvedPlayers.length > 0"
            [approvedView]="true"
            [players]="approvedPlayers"
            [team]="team"
            [match]="match"
            (approve)="approve($event)"
            (deny)="deny($event)"
            (finished)="finished()"
          ></sams-license>
        </mat-tab>
      </mat-tab-group>    
    </mat-dialog-content>

  `,
  styles: [`

    mat-dialog-content {
      display: inline;
    }
    h2 {
      margin: 0;
    }
  `]
})
export class LicenseDialogComponent {

  match: Match;

  matchId: string;

  teamCode: TeamCode;

  teamSquad: TeamSquad;

  private subscription = new Subscription();

  constructor(
    public store: Store<fromIndoor.IndoorRoot>,
    public dialogRef: MatDialogRef<LicenseDialogComponent>,
    public dispatchService: DispatchService,
    @Inject(MAT_DIALOG_DATA) public data: LicenseDialogData
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.matchId = this.data.matchId;
    this.teamCode = this.data.teamCode;

    this.subscription.add(
      this.store.select(fromIndoor.getMatch).subscribe(match => this.match = match),
    )
    this.subscription.add(
      this.store.select(root => fromIndoor.getTeamSquadByTeamCode(root, this.teamCode)).subscribe(teamSquad => this.teamSquad = teamSquad)
    )
  }

  sortPlayersBySelected(players: Player[] | AdditionalClubPlayer[]) {
    return [...players].sort((a, b) => {
      const aIsSelected = !!this.selected.find(p => p.uuid === a.uuid)
      const bIsSelected = !!this.selected.find(p => p.uuid === b.uuid)
      if (aIsSelected && !bIsSelected) {
        return -1;
      }
      if (!aIsSelected && bIsSelected) {
        return 1;
      }
      return 0;
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  get team() {
    return this.match[this.teamCode];
  }

  get players() {
    return this.sortPlayersBySelected([...this.team.players])
      .filter(p => p.license) as Player[]
  }

  get approvedPlayers() {
    return this.players.filter(p => p.license?.approved !== undefined);
  }

  get pendingPlayers() {
    return this.players.filter(p => p.license?.approved === undefined);
  }

  get selected() {
    return this.teamSquad.players;
  }

  approve(player: LicensedPlayer) {
    const approvePlayerLicense: ApprovePlayerLicense = {
      uuid: player.uuid,
      teamCode: this.teamCode
    }
    const action = new ApprovePlayerLicenseAction(approvePlayerLicense, this.matchId)
    this.dispatchService.dispatchRemoteAction(action);
  }

  deny(player: LicensedPlayer) {
    const denyPlayer: DenyPlayerLicense = {
      uuid: player.uuid,
      teamCode: this.teamCode
    }
    const action = new DenyPlayerLicenseAction(denyPlayer, this.matchId)
    this.dispatchService.dispatchRemoteAction(action);
  }

  get numberOfApprovedPlayers() {
    return this.players.filter(p => p.license?.approved).length;
  }

  get numberOfPlayers() {
    return this.players.length;
  }

  finished() {
    this.dialogRef.close();
  }

}
