import { MatchAction } from '../../../../interfaces/interfaces.js';
import { Challenge } from './challenges.reducer.js';

export const CHALLENGE = '[Challenges] Challenge';
export const CHALLENGE_SUCCESS = '[Challenges] Challenge success';
export const CHALLENGE_DECLINED = '[Challenges] Challenge Declined';

export enum ChallengeType {
  "BALL_IN_OUT" = "BALL_IN_OUT",
  "BLOCK_TOUCH" = "BLOCK_TOUCH",
  "NET_FAULT" = "NET_FAULT",
  "ANTENNA_CONTACT" = "ANTENNA_CONTACT",
  "FOOT_FAULT" = "FOOT_FAULT",
  "GROUND_CONTACT" = "GROUND_CONTACT"
}

export class ChallengeAction extends MatchAction {
  readonly type = CHALLENGE;
  constructor(public payload: Challenge, matchId: string) {
    super(matchId);
  }
}

export class ChallengeSuccessAction extends MatchAction {
  readonly type = CHALLENGE_SUCCESS;
  constructor(public payload: Challenge, matchId: string) {
    super(matchId);
  }
}

export class ChallengeDeclinedAction extends MatchAction {
  readonly type = CHALLENGE_DECLINED;
  constructor(public payload: Challenge, matchId: string) {
    super(matchId);
  }
}

export type ChallengesAction
  = ChallengeAction | ChallengeSuccessAction | ChallengeDeclinedAction;
