// this is no enum because enums get mapped to 0, 1 ...
// so we can do state[TeamSides.leftTeam]
export type TeamSide = 'leftTeam' | 'rightTeam';

const leftTeam: TeamSide = 'leftTeam';
const rightTeam: TeamSide = 'rightTeam';

export const TeamSideValues = {
  leftTeam,
  rightTeam
};

export interface ByTeamSide<T> {
  leftTeam: T;
  rightTeam: T;
}
