import { RemoteAction } from '../../interfaces/interfaces.js';
import { ScoreMeta } from '../../reducers/shared.reducer.js';

export interface BeachScoreMeta extends ScoreMeta {
  version: string;
}

export const initialState = {
  version: 'beach-0.1.4'
};

export const beachScoreMetaReducer = (state = initialState as BeachScoreMeta, action: RemoteAction): BeachScoreMeta => {
  return state;
};

export const getScoreVersion = (state: ScoreMeta) => state.version;
