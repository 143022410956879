import { Injectable, OnDestroy } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as fromIndoor from 'app/root/indoor.reducer';
import {
  getMatch
} from '../../../../shared/reducers/match-states/match-state.reducer';

@Injectable({
  providedIn: 'root'
})
export class MatchViewGuard implements CanActivate, OnDestroy {

  arbitrationConfirmed: boolean;

  finalized: boolean;

  private sub = new Subscription()

  constructor(public store: Store<fromIndoor.IndoorRoot>, private router: Router) {

    this.sub.add(
      this.store.select(fromIndoor.getMatchState).subscribe(ms => {
        if (!ms) {
          return
        }
        const match = getMatch(ms)
        this.arbitrationConfirmed = match.arbitrationConfirmed
        this.finalized = match.finalization?.successful
      })
    )
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (!this.arbitrationConfirmed) {
        return this.router.createUrlTree(['/match-preparation'])
      }
      if (this.finalized) {
        return this.router.createUrlTree(['/match-finalization'])
      }
      return this.arbitrationConfirmed ? true : this.router.createUrlTree(['/matches-overview'])
  }
  
}
