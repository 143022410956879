// this is no enum because enums get mapped to 0, 1 ...
// so we can do state[TeamCodes.team1]

export type TeamCode = 'team1' | 'team2';

const team1: TeamCode = 'team1';
const team2: TeamCode = 'team2';

export const TeamCodes = {
  team1,
  team2
};

export interface ByTeamCode<T> {
  team1: T;
  team2: T;
}

export class TeamCodeHelper {
  static other = (c: TeamCode): TeamCode => {
    if (c === TeamCodes.team1)
      return TeamCodes.team2;
    if (c === TeamCodes.team2)
      return TeamCodes.team1;
    return null;
  }
}
