import { MatchAction } from '../../../interfaces/interfaces.js';
import { TeamCode } from '../../../interfaces/models/team-codes.js';
import { LockStartingSixAction } from '../match-sets/starting-six.action.js';

export const LIBERO_UNABLE_TO_PLAY = '[Liberos] Libero unable to play';
export const ADD_NEW_LIBERO = '[Liberos] Add new libero';
export const SET_DEFAULT_LIBERO = '[Liberos] Set default libero';
export const LIBERO_PLAYED = '[Liberos] Libero played'

export interface LiberoUnableToPlay {
  teamCode: TeamCode;
  liberoId: string;
}

export interface SetDefaultLibero {
  teamCode: TeamCode;
  liberoId: string;
}

export interface LiberoPlayed {
  teamCode: TeamCode;
  liberoId: string;
}

export interface AddNewLibero {
  teamCode: TeamCode;
  playerId: string;
}

export class LiberoUnableToPlayAction extends MatchAction {
  readonly type = LIBERO_UNABLE_TO_PLAY;
  constructor(public payload: LiberoUnableToPlay, matchId: string) {
    super(matchId);
  }
}

export class SetDefaultLiberoAction extends MatchAction {
  readonly type = SET_DEFAULT_LIBERO;
  constructor(public payload: SetDefaultLibero, matchId: string) {
    super(matchId);
  }
}

export class AddNewLiberoAction extends MatchAction {
  readonly type = ADD_NEW_LIBERO;
  constructor(public payload: AddNewLibero, matchId: string) {
    super(matchId);
  }
}

export class LiberoPlayedAction extends MatchAction {
  readonly type = LIBERO_PLAYED;
  constructor(public payload: LiberoPlayed, matchId: string) {
    super(matchId);
  }
}

export type LiberosAction
  = LiberoUnableToPlayAction
  | AddNewLiberoAction
  | SetDefaultLiberoAction
  | LockStartingSixAction
  | LiberoPlayedAction;
