
import { first } from 'rxjs/operators';
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { SetErrMsgAction, SelectMatchAction, MatchIdentifiers } from '../../../../shared/reducers/matches-overview/matches-overview.action';
import { InitializeMatchAction } from '../../../../shared/reducers/match-states/match-state.action';
import { MatchStates } from '../../../../shared/reducers/match-states/match-states.reducer';
import { isMatchFinished } from '../../../../shared/reducers/game-state/match-sets/match-sets.reducer';

import { config } from 'app/config/config';
import { Match } from 'app/models';
import * as fromIndoor from 'app/root/indoor.reducer';
import * as fromShared from 'app/root/shared.reducer';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatchFinalizedWarningDialogComponent } from './match-finalized-warning-dialog';
import { MaximumMatchesWarningDialogComponent } from './maximum-matches-warning-dialog';
import { DispatchService } from 'app/connections/dispatch.service';
import { ConnectionsService } from 'app/connections/connections.service';
import { MatchesOverviewService } from './matches-overview.service';

@Component({
  selector: 'sams-matches-overview',
  template: `
    <mat-toolbar color="secondary">
      <div style="height: 100%; width: auto;">
        <sams-score-logo></sams-score-logo>
      </div>
      <span style="flex: 1 1 auto;"></span>
      <span style="flex: 1 1 auto;"></span>
      <sams-connection></sams-connection>
    </mat-toolbar>

    <div class="vertical-spacer"></div>

    <sams-token-form
      [title]="('component.matches-overview.add_match' | translate) + ' (' + matchesList.length + '/' + MAXIMUM_NUMBER_OF_MATCHES + ')'"
      [errMsg]="errMsg$ | async"
      (onSubmit)="addMatch($event)"
      (onAddTestMatch)="addTestMatch()">
    </sams-token-form>
    
    <div class="match-previews-container">

      <sams-match-preview *ngFor="let match of matchesList"
        [match]="match"
        [selected]="match.uuid === (matchId$ | async)"
        (removeMatch)="removeMatch($event)"
        (refreshMatch)="refreshMatch($event)"
        (selectMatch)="selectMatch($event)"
        (selectMatchReferee)="selectMatchReferee($event)">
      </sams-match-preview>

      <mat-card class="loading-card" *ngIf="loading$ | async">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      </mat-card>
      <div class="bottom">
        <a class="version">sams-score © {{version$ | async}}</a>
        <a mat-button target="_blank" href="impressum.html">Impressum</a>
        <a mat-button target="_blank" href="datenschutz.html">Datenschutz</a>
      </div>

    </div>
  `,
  styles: [`

    .version {
      font-size: 10px;
    }
    .bottom {
      border-top: 1px solid grey;
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      background-color: whitesmoke;
      color: #424242;
      text-align: right;
    }

    div.match-previews-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-bottom: 50px;
    }

    .loading-card {
      margin: 15px;
    }
    .score-logo {
      height: 90%;
      width: auto;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatchesOverviewComponent implements OnInit, OnDestroy {

  version$: Observable<string>;

  matchId$: Observable<string>;

  loading$: Observable<boolean>;

  errMsg$: Observable<string>;

  matchesList: Match[];

  matchStates: MatchStates;

  private subscription = new Subscription();

  MAXIMUM_NUMBER_OF_MATCHES = 5

  constructor(
    public store: Store<fromIndoor.IndoorRoot>,
    public router: Router,
    public matchesOverviewService: MatchesOverviewService,
    private connectionsService: ConnectionsService,
    private dispatchService: DispatchService,
    public dialog: MatDialog
  ) {

    this.matchId$ = store.select(fromShared.getMatchId);
    this.loading$ = store.select(fromShared.getLoading);
    this.errMsg$ = store.select(fromShared.getErrMsg);
    this.version$ = store.select(fromShared.getVersion);

    this.subscription.add(
      store.select(fromIndoor.getMatchesList).subscribe(matchesList => this.matchesList = matchesList)
    );

    this.subscription.add(
      store.select(fromIndoor.getMatchStates).subscribe(matchStates => this.matchStates = matchStates)
    );

  }

  ngOnInit() {
    this.matchesList.forEach(m => {
      if (this.shouldClearMatch(m.uuid)) {
        this.removeMatch(m.uuid)
      }
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  refreshMatch(matchIdentifiers: MatchIdentifiers) {
    const { matchId } = matchIdentifiers
    const auth = this.connectionsService.connections[matchId].auth
    if (this.isUnsuccessfullyFinalized(matchId)) {
      const dialogRef: MatDialogRef<MatchFinalizedWarningDialogComponent, string> = this.dialog.open(MatchFinalizedWarningDialogComponent);
      dialogRef.afterClosed().pipe(
        first())
        .subscribe(result => {
          if (result) {
            this.matchesOverviewService.refreshMatch(matchIdentifiers, auth)
          }
        });
    } else {
      this.matchesOverviewService.refreshMatch(matchIdentifiers, auth);
    }
  }

  removeMatch(matchId: string) {
    if (this.isUnsuccessfullyFinalized(matchId)) {
      const dialogRef: MatDialogRef<MatchFinalizedWarningDialogComponent, string> = this.dialog.open(MatchFinalizedWarningDialogComponent);
      dialogRef.afterClosed().pipe(
        first())
        .subscribe(result => {
          if (result) {
            this.matchesOverviewService.removeMatch(matchId)
          }
        });
    } else {
      this.matchesOverviewService.removeMatch(matchId);
    }
  }

  isMoreThanTwoWeeksOld(timestamp) {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const twoWeeksAgoTimestamp = currentTimestamp - (14 * 24 * 60 * 60);
    return timestamp < twoWeeksAgoTimestamp;
  }

  shouldClearMatch(matchId: string) {
    const matchState = this.matchStates[matchId]
    return this.isSuccessfullyFinalized(matchId) && this.isMoreThanTwoWeeksOld(matchState.match.loadingTimestamp)
  }

  isUnsuccessfullyFinalized(matchId: string) {
    const matchState = this.matchStates[matchId]
    return matchState
      && matchState.match
      && matchState.match.finalization
      && matchState.match.finalization.finalized
      && !matchState.match.finalization.successful
  }

  isSuccessfullyFinalized(matchId: string) {
    const matchState = this.matchStates[matchId]
    return matchState?.match?.finalization?.successful
  }

  addTestMatch() {
    if (this.matchesList.length >= this.MAXIMUM_NUMBER_OF_MATCHES) {
      this.dialog.open(MaximumMatchesWarningDialogComponent);
    } else {
      this.matchesOverviewService.addTestMatch();
    }
  }

  addMatch(accessToken: string) {
    this.store.dispatch(new SetErrMsgAction(null));
    if (this.matchesList.length >= this.MAXIMUM_NUMBER_OF_MATCHES) {
      this.dialog.open(MaximumMatchesWarningDialogComponent);
    } else {
      this.matchesOverviewService.addMatch(accessToken);
    }
  }

  selectMatchReferee(matchId: string) {
    this.store.dispatch(new SelectMatchAction(matchId));
    const matchState = this.matchStates[matchId];
    const match = matchState.match;
    const m = this.matchesList.find(m => m.uuid === matchId)
    if (m.complete || (match.finalization && match.finalization.successful)) {
      window.open(`${config.scoresheetServerUrl}${config.pdfScoresheetEndPoint}${m.uuid}/${m.matchNumber}`, '_blank').focus();
    }
    this.router.navigate(['/referee-view'])
  }

  selectMatch(matchId: string) {

    this.store.dispatch(new SelectMatchAction(matchId));

    const matchState = this.matchStates[matchId];

    const teamSquads = matchState.teamSquads;
    const match = matchState.match;
    const m = this.matchesList.find(m => m.uuid === matchId)

    if (this.connectionsService.connections[matchId].actionHistory.length === 0) {
      this.dispatchService.dispatchRemoteAction(new InitializeMatchAction(m, matchId));
    }

    const matchSets = matchState.gameStates.present.matchSets
    const matchFinished = isMatchFinished(matchSets, m.matchConfiguration);

    if (m.complete || (match.finalization && match.finalization.successful)) {
      window.open(`${config.scoresheetServerUrl}${config.pdfScoresheetEndPoint}${m.uuid}/${m.matchNumber}`, '_blank').focus();
    } else if (matchFinished) {
      this.router.navigate(['/match-finalization']);
    } else if (match.arbitrationConfirmed) {
      this.router.navigate(['/match-view']);
    } else if (teamSquads.team1.confirmed && teamSquads.team2.confirmed) {
      this.router.navigate(['/match-preparation']);
    } else {
      this.router.navigate(['/team-preparations']);
    }

  }

}
